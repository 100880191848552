/**
 *	iWear - Breadcrumb customization
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
	Breadcrumb,
	Button,
	Row,
	Col
} from 'antd';
import styles from './breadcrumb.module.css';

const renderItem = (items) => {
	return items.map((_e, _i) => {
		return (
			<Breadcrumb.Item key={_i}>
				<span className={'custom-iwear-global-font-color'}>
					{ _e }
				</span>
			</Breadcrumb.Item>
		)
	});
}

const iWear_Breadcrumb = (props) => {
	return (
		<div className={ styles.breadcrumbContainer }>
			<Row type={'flex'} align={'middle'} justify={'space-between'}>
				<Col>
					<Breadcrumb className={'custom-iwear-global-hover-cursor-default'} separator={'>'}>
						{ renderItem(props.paths) }
					</Breadcrumb>
				</Col>
				{
					(props.withGoBack) && (
						<Col>
							<Button onClick={ props.onClickGoBack }>
								<i className={'fas fa-chevron-left'}></i>&nbsp;
								<span className={'custom-iwear-global-font-color'}>
									ย้อนกลับ
								</span>
							</Button>
						</Col>
					)
				}
			</Row>
		</div>
	);
}

iWear_Breadcrumb.defaultProps = {
	withGoBack: false,
	onClickGoBack: () => {}
}

iWear_Breadcrumb.propTypes = {
	paths: PropTypes.array.isRequired,
	withGoBack: PropTypes.bool,
	onClickGoBack: PropTypes.func
}

export default iWear_Breadcrumb;
