/**
 *	Stock Import Manual - Model
 */

import PropTypes from 'prop-types';

export const ContainerPropTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export const ContentPropTypes = {
	productID: PropTypes.string.isRequired,
	productName: PropTypes.string.isRequired,
	productCode: PropTypes.string.isRequired,
	productAmount: PropTypes.number.isRequired,
	branch: PropTypes.string.isRequired,
	branchList: PropTypes.array.isRequired,
	tableData: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	suggestionDataProductCode: PropTypes.array.isRequired,
	suggestionDataProductName: PropTypes.array.isRequired,

	onChangeTableData: PropTypes.func.isRequired,
	onChangeQR: PropTypes.func.isRequired,
	onChangeProduct: PropTypes.func.isRequired,
	onChangeQuantity: PropTypes.func.isRequired,

	onScan: PropTypes.func.isRequired,
	onSelectProductCode: PropTypes.func.isRequired,
	onSelectProductName: PropTypes.func.isRequired,
	onAddData: PropTypes.func.isRequired,
	onClickConfirm: PropTypes.func.isRequired,
	onClickGoBack: PropTypes.func.isRequired,

	globalReducer: PropTypes.object.isRequired
}

export const Actions = {
	SET_PRODUCT_ID: "SET_PRODUCT_ID",
	SET_PRODUCT_NAME: "SET_PRODUCT_NAME",
	SET_PRODUCT_CODE: "SET_PRODUCT_CODE",
	SET_PRODUCT_AMOUNT: "SET_PRODUCT_AMOUNT",
	SET_PRODUCT_CODE_SUGGESTION: "SET_PRODUCT_CODE_SUGGESTION",
	SET_PRODUCT_NAME_SUGGESTION: "SET_PRODUCT_NAME_SUGGESTION",
	SET_BRANCH: "SET_BRANCH",
	SET_BRANCH_LIST: "SET_BRANCH_LIST",
	SET_TABLE_DATA: "SET_TABLE_DATA",
	SET_EDIT_IDX: "SET_EDIT_IDX",
	SET_IS_EDITING: "SET_IS_EDITING",
	SET_IS_LOADING: "SET_IS_LOADING"
}
