/**
 *	iWear - Dashboard screen
 */

import React from 'react';
import {
	DatePicker,
	Row,
	Col
} from 'antd';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import Breadcrumb from '../../../components/breadcrumb';
import IWearDropdown from '../../../components/dropdown';

import InfoCard from './components/info-card';
import BranchTotalSaleChart from './components/branch-sale-chart';
import PaymentChannelChart from './components/payment-channel-chart';
import PromoRatioChart from './components/promotion-ratio-chart';
import { ContentPropTypes } from './dashboard.model'
import styles from './dashboard.module.css';

const renderBranchSelector = (props) => {
	let _arr = [];
	props.branchList.forEach((_e) => { _arr.push(_e.branch_name); });

	return (
		<span className={'has-text-black'}>
			สาขา&nbsp;
			<IWearDropdown
				styleJSX={{ minWidth: '80px', paddingRight: '40px' }}
				displayValue={ props.branch || 'ทั้งหมด' }
				choices={[ 'ทั้งหมด', ..._arr ]}
				onSelect={(selectedVal, selectedIdx) => {
					if(selectedVal === 'ทั้งหมด' || selectedIdx === 0) {
						props.onChangeBranch('');
					}
					else {
						props.onChangeBranch(selectedVal);
					}
				}}
			/>
		</span>
	);
}

const renderInfoCards = (props) => {
	return (
		<div className={ styles.cardContainer }>
			<Row gutter={8}>
				<Col sm={24} md={8}>
					<InfoCard
						cardValue={ props.totalSale }
						cardLabel={'ยอดขาย'}
						isLoading={ props.isLoading }
					/>
				</Col>
				<Col sm={24} md={8}>
					<InfoCard
						cardValue={ props.monthSale }
						cardLabel={'ยอดขายเดือนนี้'}
						isLoading={ props.isLoading }
					/>
				</Col>
				<Col sm={24} md={8}>
					<InfoCard
						cardValue={ props.todaySale }
						cardLabel={'ยอดขายวันนี้'}
						isLoading={ props.isLoading }
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row gutter={8}>
				<Col sm={24} md={8}>
					<InfoCard
						cardValue={ props.todayOrder }
						cardLabel={'จำนวนคำสั่งซื้อ'}
						isLoading={ props.isLoading }
					/>
				</Col>
				<Col sm={24} md={8}>
					<InfoCard
						cardValue={0}
						cardLabel={'จำนวนกรอบที่ขาย / มูลค่า'}
						cardRender={
							<span className={'is-size-4 has-text-weight-bold'}>
								<NumberFormat
									thousandSeparator
									displayType={'text'}
									value={ props.frameSale.count }
								/>
								&nbsp;/&nbsp;
								<NumberFormat
									thousandSeparator
									displayType={'text'}
									value={ props.frameSale.value }
								/>
							</span>
						}
						isLoading={ props.isLoading }
					/>
				</Col>
				<Col sm={24} md={8}>
					<InfoCard
						cardValue={0}
						cardLabel={'จำนวนเลนส์ที่ขาย / มูลค่า'}
						cardRender={
							<span className={'is-size-4 has-text-weight-bold'}>
								<NumberFormat
									thousandSeparator
									displayType={'text'}
									value={ props.lensSale.count }
								/>
								&nbsp;/&nbsp;
								<NumberFormat
									thousandSeparator
									displayType={'text'}
									value={ props.lensSale.value }
								/>
							</span>
						}
						isLoading={ props.isLoading }
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row gutter={8}>
				<Col sm={24} md={8}>
					<InfoCard
						cardValue={ props.avgAge }
						cardLabel={'อายุเฉลี่ยของลูกค้า'}
						isLoading={ props.isLoading }
					/>
				</Col>
				<Col sm={24} md={8}>
					<InfoCard
						cardValue={ props.avgExpense }
						cardLabel={'รายจ่ายเฉลี่ยต่อลูกค้า'}
						cardUnit={' บาท'}
						isLoading={ props.isLoading }
					/>
				</Col>
				<Col sm={24} md={8}>
					<InfoCard
						cardValue={0}
						cardLabel={'ลูกค้าใหม่ : ลูกค้าเก่า'}
						cardRender={
							<span className={'is-size-4 has-text-weight-bold'}>
								<NumberFormat
									thousandSeparator
									displayType={'text'}
									value={ props.customerRatio.newCustomer }
								/>
								&nbsp;:&nbsp;
								<NumberFormat
									thousandSeparator
									displayType={'text'}
									value={ props.customerRatio.oldCustomer }
								/>
							</span>
						}
						isLoading={ props.isLoading }
					/>
				</Col>
			</Row>
		</div>
	)
}

const renderChartCards = (props) => {
	return (
		<React.Fragment>
			<div className={'custom-iwear-dashboard-card-container is-hidden-touch'}>
				<Row gutter={8}>
					<Col span={12}>

						<Row gutter={8}>
							<Col span={24}>
								<PaymentChannelChart data={ props.chartData.payment } />
							</Col>
						</Row>
						<div style={{ margin: '10px' }}></div>
						<Row gutter={8}>
							<Col span={24}>
								<BranchTotalSaleChart data={ props.chartData.branch } />
							</Col>
						</Row>

					</Col>
					<Col span={12}>
						<PromoRatioChart data={ props.chartData.promo } />
					</Col>
				</Row>
			</div>
			<div className={'custom-iwear-dashboard-card-container is-hidden-desktop'}>
				<Row gutter={8}>
					<Col span={24}>
						<PaymentChannelChart data={ props.chartData.payment } />
					</Col>
				</Row>
				<div style={{ margin: '10px' }}></div>
				<Row gutter={8}>
					<Col span={24}>
						<BranchTotalSaleChart data={ props.chartData.branch } />
					</Col>
				</Row>
				<div style={{ margin: '10px' }}></div>
				<Row gutter={8}>
					<Col span={24}>
						<PromoRatioChart data={ props.chartData.promo } />
					</Col>
				</Row>
			</div>
		</React.Fragment>
	)
}

const Dashboard = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'Dashboard', 'Statistic' ]} />
			<div style={{ margin: '10px' }}></div>
			<Row type={'flex'} align={'middle'} justify={'end'} gutter={12}>
				<Col>
					{ renderBranchSelector(props) }
				</Col>
				<Col>
					<span className={'has-text-black'}>
						เลือกวันที่&nbsp;
						<DatePicker.RangePicker
							format={'DD/MM/YYYY'}
							defaultValue={ props.filterDate.value }
							disabledDate={(current) => { return current > moment().endOf('day'); }}
							onChange={ props.onChangeFilterDateRange }
						/>
					</span>
				</Col>
			</Row>
			{ renderInfoCards(props) }
			<div style={{ margin: '10px' }}></div>
			{ renderChartCards(props) }
		</div>
	);
}

Dashboard.propTypes = ContentPropTypes;

export default Dashboard;
