/**
 *	iWear - Customer list container
 *
 *		@using React Hook
 */

import React from 'react';
import {
	Layout
} from 'antd';
import swal from 'sweetalert';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../environments';
import { downloadFromBuffer } from '../../../../functions';
import GlobalStore from '../../../../store';
import LocalStore from './customers-list.store';
import Content from './customers-list.content';
import { ContainerPropTypes, Actions } from './customers-list.model';

const Container = (props) => {
	const CustomersReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData({ page: CustomersReducer.state.page, pageSize: CustomersReducer.state.pageSize, searchKey: CustomersReducer.state.searchKey }, CustomersReducer.dispatch, state.auth);
	}, [ CustomersReducer.state.page, CustomersReducer.state.pageSize, CustomersReducer.state.searchKey, CustomersReducer.dispatch, state.auth ]);

	const fetchData = async (localState, localDispatch, authState) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		let branchID = authState.branchInfo.branch_id;

		let res = await fetch(`${API_ENDPOINT}/customers?branch_id=${encodeURIComponent(branchID)}&offset=${(localState.page - 1) * localState.pageSize}&limit=${localState.pageSize}&search=${encodeURIComponent(localState.searchKey)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authState.token,
				'Content-Type': 'application/json'
			}
		});

		let json = await res.json();

		localDispatch({ type: Actions.SET_TABLE_DATA, value: [ ...json.list ] });
		localDispatch({ type: Actions.SET_TOTAL_DATA, value: json.count });
		localDispatch({ type: Actions.SET_TOTAL_PAGE, value: json.total_pages * localState.pageSize });

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onClickViewDetail = (customer_info) => {
		props.history.push('/sales/customer/detail', {
			customer_id: customer_info.customer_id,
			customer_name: customer_info.full_name,
			customer_phone_number: customer_info.phone_number
		});
	}

	const onClickCreate = () => {
		props.history.push('/sales/customer/create');
	}

	const onClickUpdate = (customer_info) => {
		props.history.push('/sales/customer/update', {
			customer_id: customer_info.customer_id,
			customer_name: customer_info.full_name,
			customer_phone_number: customer_info.phone_number
		});
	}

	const onChangePage = (page, pageSize) => {
		CustomersReducer.dispatch({
			type: Actions.SET_PAGE,
			value: page
		});
	}

	const onChangePageSize = (selectedVal) => {
		// Change from dropdown
		CustomersReducer.dispatch({
			type: Actions.SET_PAGE,
			value: 1
		});

		CustomersReducer.dispatch({
			type: Actions.SET_PAGE_SIZE,
			value: selectedVal
		});
	}

	const onSearch = (value) => {
		CustomersReducer.dispatch({
			type: Actions.SET_PAGE,
			value: 1
		});

		CustomersReducer.dispatch({
			type: Actions.SET_SEARCH_KEY,
			value: value
		});
	}

	const onClickFileExport = async () => {
		CustomersReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: true });
		let _url = `${API_ENDPOINT}/customers
			?branch_id=${encodeURIComponent(state.auth.branchInfo.branch_id)}
			&search=${encodeURIComponent(CustomersReducer.state.searchKey)}
			&is_report=true
		`;
		let res = await fetch(`${_url}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let data = await res.json();
		if(res.status === 200) {
			let _timer = 5000 + (Number.parseInt(CustomersReducer.state.totalData / 5000, 10) * 1000);
			setTimeout(() => {
				downloadFromBuffer(data.report_path, 'customer_report.xlsx');
				CustomersReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: false });
			}, _timer);
		}
		else {
			CustomersReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: false });
			swal({
				title: 'เกิดข้อผิดพลาด',
				text: 'ไม่สามารถ export ได้ในขณะนี้',
				icon: 'error'
			});
		}
	}

	return (
		<Layout>
			<Sidebar mode={'sales'} currentRoute={'/sales/customer'} history={ props.history } />
			<Layout>
				<TopBar mode={'sales'} currentRoute={'/sales/customer'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...CustomersReducer.state }
						dispatcher={ CustomersReducer.dispatch }

						onClickCreate={ onClickCreate }
						onClickUpdate={ onClickUpdate }
						onClickViewDetail={ onClickViewDetail }

						onChangePage={ onChangePage }
						onChangePageSize={ onChangePageSize }
						onSearch={ onSearch }
						onClickFileExport={ onClickFileExport }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
