/**
 *	Customers Detail - Model
 */

import PropTypes from 'prop-types';

export const ContainerPropTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export const ContentPropTypes = {
	tel: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	gender: PropTypes.string.isRequired,
	birthdate: PropTypes.any.isRequired,
	age: PropTypes.number.isRequired,
	customerChannel: PropTypes.array.isRequired,
	addrZone: PropTypes.string.isRequired,
	province: PropTypes.string.isRequired,
	extInfo: PropTypes.string.isRequired,

	oldMeasureData: PropTypes.array.isRequired,
	newMeasureData: PropTypes.array.isRequired,
	inspector: PropTypes.string.isRequired,
	inspectorData: PropTypes.array.isRequired,
	latestOrderData: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,

	onClickGoBack: PropTypes.func.isRequired,
	onClickViewDetail: PropTypes.func.isRequired
}

export const Actions = {
	SET_TEL: "SET_TEL",
	SET_NAME: "SET_NAME",
	SET_GENDER: "SET_GENDER",
	SET_BIRTHDATE: "SET_BIRTHDATE",
	SET_AGE: "SET_AGE",
	SET_CUSTOMER_CHANNEL: "SET_CUSTOMER_CHANNEL",
	SET_ADDR_ZONE: "SET_ADDR_ZONE",
	SET_PROVINCE: "SET_PROVINCE",
	SET_EXT_INFO: "SET_EXT_INFO",
	SET_OLD_MEASURE_DATA: "SET_OLD_MEASURE_DATA",
	SET_NEW_MEASURE_DATA: "SET_NEW_MEASURE_DATA",
	SET_INSPECTOR: "SET_INSPECTOR",
	SET_INSPECTOR_DATA: "SET_INSPECTOR_DATA",
	SET_LATEST_ORDER_DATA: "SET_LATEST_ORDER_DATA",
	SET_IS_LOADING: "SET_IS_LOADING"
}
