/**
 *	Login - State Management
 */

import React from 'react';
import { Actions } from './login.model';

const initState = {
	username: '',
	password: '',
	errorUsername: false,
	errorPassword: false,
	isSubmitting: false
}

const reducer = (state, action) => {
	switch(action.type) {
		case Actions.SET_USERNAME:
			return {
				...state,
				username: action.value
			}
		case Actions.SET_PASSWORD:
			return {
				...state,
				password: action.value
			}
		case Actions.SET_ERROR_USERNAME:
			return {
				...state,
				errorUsername: action.value
			}
		case Actions.SET_ERROR_PASSWORD:
			return {
				...state,
				errorPassword: action.value
			}
		case Actions.SET_IS_SUBMITTING:
			return {
				...state,
				isSubmitting: action.value
			}
		default:
			return { ...state }
	}
}

const LoginReducer = () => {
	const [ state, dispatch ] = React.useReducer(reducer, initState);

	React.useEffect(() => {}, []);

	return {
		state,
		dispatch
	}
}

export default LoginReducer;
