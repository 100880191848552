/**
 *	iWear - Stock Export screen
 */

import React from 'react'
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import {
	Row,
	Col,
	Button,
	// Dropdown,
	// Menu,
	Input,
	Pagination
} from 'antd';
import ReactToPrint from 'react-to-print';

import Breadcrumb from '../../../../components/breadcrumb';
import IWearDropdown from '../../../../components/dropdown';
import ExcelButton from '../../../../components/excel-button';
import ToPDF from '../../../../components/to-pdf';

const StockExportList = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'คลังสินค้า', 'สินค้าส่งออก' ]} />
			{ renderContent(props) }
		</div>
	);
}

const renderContent = (props) => {
	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
			<Row>
				<Col span={24}>
					{ renderOptions(props) }
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					{ renderTable(props) }
				</Col>
			</Row>
			<Row type={'flex'} align={'middle'} justify={'end'}>
				<Col style={{ paddingBottom: '10px' }}>
					<Pagination
						className={'custom-iwear-global-table-pagination-container is-pulled-right'}
						current={ props.page }
						pageSize={ props.pageSize }
						total={ props.totalPage }
						onChange={ props.onChangePage }
					/>
				</Col>
			</Row>
		</div>
	)
}

const renderOptions = (props) => {
	return (
		<div className={'custom-iwear-global-table-option-panel'}>
			<Row gutter={16} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={12}>
					<span className={'custom-iwear-global-font-color has-text-weight-bold is-size-5'}>
						ผลลัพธ์ข้อมูล&nbsp;
						<NumberFormat
							thousandSeparator
							displayType={'text'}
							value={ props.totalData }
						/>
						&nbsp;ข้อมูล
					</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						ค้นหา
					</span>&nbsp;&nbsp;&nbsp;
					<Input.Search
						enterButton
						placeholder={'Search'}
						allowClear={true}
						style={{ maxWidth: '70%' }}
						onSearch={ props.onChangeSearchKey }
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row gutter={16} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={12}>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						แสดง
					</span>&nbsp;&nbsp;&nbsp;
					<IWearDropdown
						styleJSX={{ minWidth: '80px', paddingRight: '20px' }}
						displayValue={ String(props.pageSize) }
						choices={[ 10, 20, 50, 100 ]}
						onSelect={ props.onChangePageSize }
					/>
				</Col>
				<Col span={12}>
					<Row gutter={8} type={'flex'} align={'middle'} justify={'end'}>
						<Col>
							<Button onClick={ props.onClickCreate }>
								<i className={'fas fa-plus custom-iwear-global-font-color'}></i>&nbsp;
								<span className={'custom-iwear-global-font-color'}>เพิ่มสินค้าส่งออก</span>
							</Button>
						</Col>
						<Col>
							<ExcelButton
								modalTitle={'เลือกช่วงวันที่ส่งออก'}
								exportType={'export'}
								dispatcher={ props.dispatcher }
								isLoading={ props.isExporting }

								branchName={''}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
}

const renderTable = (props) => {
	return (
		<div className={'custom-iwear-global-table-container'}>
			<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-left-th'}>
							ลำดับ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							รหัสใบส่งออก
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							วันที่ส่งออก
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							สถานะ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							จำนวนรายการสินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ไปยังสาขา
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							เพิ่มเติม
						</th>
					</tr>
				</thead>
				<tbody>
					{ renderTableData(props) }
				</tbody>
			</table>
		</div>
	);
}

const renderTableData = (props) => {
	if(props.isLoading) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* กำลังโหลด... */}
				</td>
			</tr>
		);
	}
	else if(!props.isLoading && props.tableData.length === 0) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* ไม่มีข้อมูล */}
				</td>
			</tr>
		);
	}

	return props.tableData.map((_e, _i) => {
		let exportRef = null;

		return (
			<tr key={_i} className={'custom-iwear-global-hover-cursor-pointer'}>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
					onClick={() => { props.onClickViewDetail(_e.exchange_code); }}
				>
					{ _i + 1 + ((props.page - 1) * props.pageSize) }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
					onClick={() => { props.onClickViewDetail(_e.exchange_code); }}
				>
					{ _e.exchange_code }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
					onClick={() => { props.onClickViewDetail(_e.exchange_code); }}
				>
					{ XDate(_e.create_at).toString('dd/MM/yyyy HH:mm:ss') }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
					onClick={() => { props.onClickViewDetail(_e.exchange_code); }}
				>
					{ (_e.status === 'pending') ? 'รอรับของ' : (_e.status === 'completed') ? 'รับของแล้ว' : '' }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
					onClick={() => { props.onClickViewDetail(_e.exchange_code); }}
				>
					{ _e.exchange_detail.length }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
					onClick={() => { props.onClickViewDetail(_e.exchange_code); }}
				>
					{ (_e.dest_branch.branch_name !== null) ? _e.dest_branch.branch_name : '-'  }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					<div style={{ display: 'none' }}>
						<ToPDF.Stock ref={el => (exportRef = el)} inventoryInfo={_e} />
					</div>
					<ReactToPrint
						trigger={() =>
							<Button>
								<i className={'far fa-file-pdf is-size-5 custom-iwear-global-font-color'}/>
							</Button>
						}
						content={() => exportRef}
					/>
				</td>
				{/*<td
					className={'has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					<button className={'button '}>
						<i className={'fas fa-ellipsis-v custom-iwear-global-table-text-color'}></i>
					</button>
				</td>*/}
			</tr>
		);
	});
}

StockExportList.propTypes = {
	tableData: PropTypes.array.isRequired,
	totalData: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	totalPage: PropTypes.number.isRequired,
	searchKey: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,

	onChangePage: PropTypes.func.isRequired,
	onChangePageSize: PropTypes.func.isRequired,
	onChangeSearchKey: PropTypes.func.isRequired,

	onClickCreate: PropTypes.func.isRequired,
	onClickViewDetail: PropTypes.func.isRequired,

	onClickFileExport: PropTypes.func.isRequired,

	globalReducer: PropTypes.object.isRequired
}

export default StockExportList;
