/**
 *	Custom Provider
 */

import React from 'react';
import PropTypes from 'prop-types';
import reducers from './store.reducers';
import initializer from './store.initialize';

const StateContext = React.createContext(null);

const useState = () => {
	return React.useContext(StateContext);
}

const Provider = ({ children }) => {
	return (
		<StateContext.Provider value={ React.useReducer(reducers, initializer) }>
			{ children }
		</StateContext.Provider>
	)
}

Provider.propTypes = {
	children: PropTypes.node.isRequired
}

const GlobalState = {
	Provider,
	useState
};

export default GlobalState;
