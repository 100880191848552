/**
 *	Logout - Content
 */

import React from 'react';

const Content = () => {
	return (
		<React.Fragment>
			<div></div>
		</React.Fragment>
	);
}

export default Content;
