/**
 *	iWear - Stock Container
 *
 *		@using React Hook
 */

import React from 'react';
import { Layout } from 'antd';
import swal from 'sweetalert';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../environments';
import { downloadFromBuffer } from '../../../../functions';
import GlobalStore from '../../../../store';

import LocalStore from './stock-list.store';
import Content from './stock-list.content';
import { ContainerPropTypes, Actions } from './stock-list.model';

const Container = (props) => {
	const StockReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData({
			page: StockReducer.state.page,
			pageSize: StockReducer.state.pageSize,
			searchKey: StockReducer.state.searchKey,
			branchName: StockReducer.state.branchName
		}, StockReducer.dispatch, state.auth.token);
	}, [ StockReducer.state.page, StockReducer.state.pageSize, StockReducer.state.searchKey, StockReducer.state.branchName, StockReducer.dispatch, state.auth.token ]);

	const fetchData = async (params, localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		let res_branch_list = await fetch(`${API_ENDPOINT}/branches?limit=9999`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json_branch_list = await res_branch_list.json();
		localDispatch({ type: Actions.SET_BRANCH_LIST, value: [ ...json_branch_list.list ] });

		if(params.branchName && params.branchName !== 'ทั้งหมด') {
			let res_branch = await fetch(`${API_ENDPOINT}/branches?branch_name=${encodeURIComponent(params.branchName)}`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + authToken,
		    		'Content-Type': 'application/json'
				}
			});

			let json_branch = await res_branch.json();
			let _branchID = json_branch.list[0].branch_id;

			let res_product = await fetch(`${API_ENDPOINT}/inventory-items?branch_id=${encodeURIComponent(_branchID)}&search=${encodeURIComponent(params.searchKey)}&limit=${params.pageSize}&offset=${(params.page - 1) * params.pageSize}`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + authToken,
					'Content-Type': 'application/json'
				}
			});

			let json_product = await res_product.json();
			localDispatch({ type: Actions.SET_TABLE_DATA, value: [ ...json_product.list ] });
			localDispatch({ type: Actions.SET_TOTAL_DATA, value: json_product.count });
			localDispatch({ type: Actions.SET_TOTAL_PAGE, value: json_product.total_pages * params.pageSize });
			localDispatch({ type: Actions.SET_FRAME_COUNT, value: json_product.frame_count || 0 });
			localDispatch({ type: Actions.SET_LENS_COUNT, value: json_product.lens_count || 0 });
		}
		else {
			let res_product = await fetch(`${API_ENDPOINT}/inventory-items?search=${encodeURIComponent(params.searchKey)}&limit=${params.pageSize}&offset=${(params.page - 1) * params.pageSize}`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + authToken,
					'Content-Type': 'application/json'
				}
			});

			let json_product = await res_product.json();
			localDispatch({ type: Actions.SET_TABLE_DATA, value: [ ...json_product.list ] });
			localDispatch({ type: Actions.SET_TOTAL_DATA, value: json_product.count });
			localDispatch({ type: Actions.SET_TOTAL_PAGE, value: json_product.total_pages * params.pageSize });
			localDispatch({ type: Actions.SET_FRAME_COUNT, value: json_product.frame_count || 0 });
			localDispatch({ type: Actions.SET_LENS_COUNT, value: json_product.lens_count || 0 });
		}

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onChangeBranch = (selectedValue) => {
		StockReducer.dispatch({ type: Actions.SET_BRANCH_NAME, value: `${selectedValue}` })
	}

	const onChangePage = (page, pageSize) => {
		StockReducer.dispatch({ type: Actions.SET_PAGE, value: page });
	}

	const onChangePageSize = (selectedVal) => {
		StockReducer.dispatch({ type: Actions.SET_PAGE, value: 1 });
		StockReducer.dispatch({ type: Actions.SET_PAGE_SIZE, value: Number.parseInt(selectedVal, 10) });
	}

	const onChangeSearchKey = (value) => {
		StockReducer.dispatch({ type: Actions.SET_PAGE, value: 1 });
		StockReducer.dispatch({ type: Actions.SET_SEARCH_KEY, value: value });
	}

	const onClickViewDetail = (itemID) => {
		//
	}

	const onClickImport = () => {
		props.history.push('/admin/stock/import');
	}

	const onClickExport = () => {
		props.history.push('/admin/stock/export');
	}

	const onClickFileExport = async () => {
		StockReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: true });

		let [ _branch ] = StockReducer.state.branchList.filter((_f) => { return _f.branch_name === StockReducer.state.branchName; });

		let _url = '';

		if(_branch && _branch.branch_id) {
			_url = `
				${API_ENDPOINT}/inventory-items
				?branch_id=${_branch.branch_id}
				&search=${StockReducer.state.searchKey}
				&is_report=true
			`;
		}
		else {
			_url = `
				${API_ENDPOINT}/inventory-items
				?search=${StockReducer.state.searchKey}
				&is_report=true
			`;
		}

		let res = await fetch(`${_url}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let data = await res.json();

		if(res.status === 200) {
			let _timer = 5000 + (Number.parseInt(StockReducer.state.totalData / 5000, 10) * 1000);
			setTimeout(() => {
				downloadFromBuffer(data.report_path, 'stock_report.xlsx');
				StockReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: false });
			}, _timer);
		}
		else {
			StockReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: false });
			swal({
				title: 'เกิดข้อผิดพลาด',
				text: 'ไม่สามารถ export ได้ในขณะนี้',
				icon: 'error'
			});
		}
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/stock'} history={ props.history } />
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/stock'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...StockReducer.state }
						dispatcher={ StockReducer.dispatch }

						onChangeBranch={ onChangeBranch }
						onChangePage={ onChangePage }
						onChangePageSize={ onChangePageSize }
						onChangeSearchKey={ onChangeSearchKey }

						onClickViewDetail={ onClickViewDetail }
						onClickImport={ onClickImport }
						onClickExport={ onClickExport }
						onClickFileExport={ onClickFileExport }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
