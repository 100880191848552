/**
 *	Order List - Method
 */

import { API_ENDPOINT } from '../../../../environments';
import { Actions } from './order-list.model';

const fetchData = async (params, localDispatch, authToken) => {
	localDispatch({ type: Actions.SET_IS_LOADING, value: true });

	let branchInfo = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info;
	let res = await fetch(
		`${API_ENDPOINT}/orders?branch_id=${encodeURIComponent(branchInfo.branch_id)}
		&offset=${(params.page - 1) * params.pageSize}
		&limit=${params.pageSize}
		&search=${encodeURIComponent(params.searchKey)}`,
		{
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		}
	);

	let json = await res.json();

	localDispatch({ type: Actions.SET_TABLE_DATA, value: [ ...json.list ] });
	localDispatch({ type: Actions.SET_TOTAL_DATA, value: json.count || json.list.length });
	localDispatch({ type: Actions.SET_TOTAL_PAGE, value: json.total_pages * params.pageSize });

	localDispatch({ type: Actions.SET_IS_LOADING, value: false });
}

const generatePDF = () => {
	// let res_order = await fetch(`${API_ENDPOINT}/orders/${encodeURIComponent(order_id)}`, {
	// 	method: 'GET',
	// 	headers: {
	// 		'Authorization': 'Bearer ' + authToken,
	// 		'Content-Type': 'application/json'
	// 	}
	// });

	// let res_eye = await fetch(`${API_ENDPOINT}/eye-inspector?customer_id=${encodeURIComponent(customer_id)}`, {
	// 	method: 'GET',
	// 	headers: {
	// 		'Authorization': 'Bearer ' + authToken,
	// 		'Content-Type': 'application/json'
	// 	}
	// });

	// let res_customer = await fetch(`${API_ENDPOINT}/customer-with-eye?search=${encodeURIComponent(fullname)}`, {
	// 	method: 'GET',
	// 	headers: {
	// 		'Authorization': 'Bearer ' + authToken,
	// 		'Content-Type': 'application/json'
	// 	}
	// });

	// let json_order = await res_order.json();
	// let json_eye = await res_eye.json();
	// let json_customer = await res_customer.json();

	// let _eyesData = [];
	// if (json_customer.list[0].eye_info.length > 0) {
	// 	 _eyesData = [ ...json_customer.list[0].eye_info ];
	// }
	// else {
	// 	_eyesData = [{
	// 		add_left: 0,
	// 		add_right: 0,
	// 		axis_left: 0,
	// 		axis_right: 0,
	// 		cyl_left: 0,
	// 		cyl_right: 0,
	// 		dpd_left: 0,
	// 		dpd_right: 0,
	// 		inspector_id: 0,
	// 		is_lastest:0,
	// 		mono_va_left: 0,
	// 		mono_va_right: 0,
	// 		npd_left: 0,
	// 		npd_right: 0,
	// 		prism_left:0,
	// 		prism_right: 0,
	// 		seg_ht_left: 0,
	// 		seg_ht_right: 0,
	// 		sphere_left: 0,
	// 		sphere_right: 0
	// 	}];
	// }

	// let _order = [];
	// json_order.order_detail.forEach((_e, _i) => {
	// 	_order.push({
	// 		order: _i + 1,
	// 		id: _e.item_id,
	// 		item_code: _e.product_info.item_code,
	// 		name: _e.product_info.name,
	// 		amount: _e.quantity,
	// 		price: _e.product_info.price
	// 	})
	// });

	// localDispatch({
	// 	type: Actions.SET_OLD_MEASURE_DATA,
	// 	value: [
	// 		{ type: 'Sphere', leftValue: _eyesData[_eyesData.length - 1].sphere_left, rightValue: _eyesData[_eyesData.length - 1].sphere_right },
	// 		{ type: 'CYL', leftValue: _eyesData[_eyesData.length - 1].cyl_left, rightValue: _eyesData[_eyesData.length - 1].cyl_right },
	// 		{ type: 'AXIS', leftValue: _eyesData[_eyesData.length - 1].axis_left, rightValue: _eyesData[_eyesData.length - 1].axis_right },
	// 		{ type: 'ADD', leftValue: _eyesData[_eyesData.length - 1].add_left, rightValue: _eyesData[_eyesData.length - 1].add_right },
	// 		{ type: 'DPD', leftValue: _eyesData[_eyesData.length - 1].dpd_left, rightValue: _eyesData[_eyesData.length - 1].dpd_right },
	// 		{ type: 'NPD', leftValue: _eyesData[_eyesData.length - 1].npd_left, rightValue: _eyesData[_eyesData.length - 1].npd_right },
	// 		{ type: 'SegHT', leftValue: _eyesData[_eyesData.length - 1].seg_ht_left, rightValue: _eyesData[_eyesData.length - 1].seg_ht_right },
	// 		{ type: 'Prism', leftValue: _eyesData[_eyesData.length - 1].prism_left, rightValue: _eyesData[_eyesData.length - 1].prism_right },
	// 		{ type: 'Mono-VA', leftValue: _eyesData[_eyesData.length - 1].mono_va_left, rightValue: _eyesData[_eyesData.length - 1].mono_va_right },
	// 	]
	// });

	// localDispatch({
	// 	type: Actions.SET_NEW_MEASURE_DATA,
	// 	value: [
	// 		{ type: 'Sphere', leftValue: 0, rightValue: 0 },
	// 		{ type: 'CYL', leftValue: 0, rightValue: 0 },
	// 		{ type: 'AXIS', leftValue: 0, rightValue: 0 },
	// 		{ type: 'ADD', leftValue: 0, rightValue: 0 },
	// 		{ type: 'DPD', leftValue: 0, rightValue: 0 },
	// 		{ type: 'NPD', leftValue: 0, rightValue: 0 },
	// 		{ type: 'SegHT', leftValue: 0, rightValue: 0 },
	// 		{ type: 'Prism', leftValue: 0, rightValue: 0 },
	// 		{ type: 'Mono-VA', leftValue: 0, rightValue: 0 },
	// 	]
	// });
}

const Methods = {
	fetchData,
	generatePDF
}

export default Methods;
