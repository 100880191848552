/**
 *	iWear - DataBranch Container
 *
 *		@using React Hook
 */

import React from 'react';
import { Layout } from 'antd';
import swal from 'sweetalert';
import PropTypes from 'prop-types';

import TopBar from '../../../../../components/layout/header';
import Sidebar from '../../../../../components/layout/sider';
import BottomBar from '../../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../../environments';
import { downloadFromBuffer } from '../../../../../functions';
import WebCookie from '../../../../../lib/web-cookie';
import AdminDataBranch from './screen';

const AdminDataBranchContainer = (props) => {
	const [ tableData, setTableData ] = React.useState([]);
	const [ totalData, setTotalData ] = React.useState(0);
	const [ page, setPage ] = React.useState(1);
	const [ pageSize, setPageSize ] = React.useState(10);
	const [ totalPage, setTotalPage ] = React.useState(1);
	const [ searchKey, setSearchKey ] = React.useState('');
	const [ isLoading, setIsLoading ] = React.useState(false);
	const [ isExporting, setIsExporting ] = React.useState(false);

	React.useEffect(() => {
		fetchData({ page, pageSize, searchKey });
	}, [ page, pageSize, searchKey ]);

	const fetchData = async (state) => {
		setIsLoading(true);

		let res = await fetch(`${API_ENDPOINT}/branches?branch_name=${encodeURIComponent(state.searchKey)}&offset=${(state.page - 1) * state.pageSize}&limit=${state.pageSize}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		let json = await res.json();

		setTableData([ ...json.list ]);
		setTotalData(json.count);
		setTotalPage(json.total_pages || 1);

		setIsLoading(false);
	}

	const onClickAdd = () => {
		props.history.push('/admin/data/branch/add');
	}

	const onClickEdit = (idx) => {
		props.history.push('/admin/data/branch/edit', { branchID: idx });
	}

	const onClickFileExport = async () => {
		setIsExporting(true);
		let res = await fetch(`${API_ENDPOINT}/branches?is_report=true`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		let data = await res.json();

		if(res.status === 200) {
			let _timer = 5000 + (Number.parseInt(totalData / 5000, 10) * 1000);
			setTimeout(() => {
				// window.open(data.report_path, 'branch_report');
				downloadFromBuffer(data.report_path, 'branch_report.xlsx');

				setIsExporting(false);
			}, _timer);
		}
		else {
			setIsExporting(false);
			swal({
				title: 'เกิดข้อผิดพลาด',
				text: 'ไม่สามารถ export ได้ในขณะนี้',
				icon: 'error'
			});
		}
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/data/branch'} history={ props.history } />
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/data/branch'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<AdminDataBranch
						dispatcher={({ type, value }) => { setIsExporting(value) }}
						tableData={ tableData }
						totalData={ totalData }
						page={ page }
						pageSize={ pageSize }
						totalPage={ totalPage }
						searchKey={ searchKey }
						isLoading={ isLoading }
						isExporting={ isExporting }

						setTableData={ setTableData }
						setTotalData={ setTotalData }
						setPage={ setPage }
						setPageSize={ setPageSize }
						setTotalPage={ setTotalPage }
						setSearchKey={ setSearchKey }
						setIsLoading={ setIsLoading }
						setIsExporting={ setIsExporting }

						onClickAdd={ onClickAdd }
						onClickEdit={ onClickEdit }
						onClickFileExport={ onClickFileExport }
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

AdminDataBranchContainer.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default AdminDataBranchContainer;
