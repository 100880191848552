/**
 *	Order Add New - Model
 */

import PropTypes from 'prop-types';

export const ContainerPropTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export const ContentPropTypes = {
	tel: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	age: PropTypes.number.isRequired,
	gender: PropTypes.string.isRequired,
	birthdate: PropTypes.any.isRequired,
	customerChannel: PropTypes.array.isRequired,
	addrZone: PropTypes.string.isRequired,
	province: PropTypes.object.isRequired,
	extInfo: PropTypes.string.isRequired,
	tableData: PropTypes.array.isRequired,
	productData: PropTypes.array.isRequired,
	productCode: PropTypes.string.isRequired,
	productName: PropTypes.string.isRequired,
	productAmount: PropTypes.number.isRequired,
	productPrice: PropTypes.number.isRequired,
  claimModalVisible: PropTypes.bool.isRequired,
  includeVat: PropTypes.bool.isRequired,
  vatValue: PropTypes.number.isRequired,
	reqAssemble: PropTypes.bool.isRequired,
	oldMeasureData: PropTypes.array.isRequired,
	newMeasureData: PropTypes.array.isRequired,
	inspector: PropTypes.object.isRequired,
	inspectorData: PropTypes.array.isRequired,
	measureModalVisible: PropTypes.bool.isRequired,
	promoData: PropTypes.array.isRequired,
	promoType: PropTypes.string.isRequired,
	promoValue: PropTypes.number.isRequired,
	pastDate: PropTypes.string.isRequired,
	isPastOrder: PropTypes.bool.isRequired,
	priceSum: PropTypes.number.isRequired,
	deliverDate: PropTypes.string.isRequired,
	paymentType: PropTypes.string.isRequired,
	remark: PropTypes.string.isRequired,
	payment: PropTypes.number.isRequired,
	moneyReceive: PropTypes.number.isRequired,
	editIdx: PropTypes.number.isRequired,
	isEditing: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,

	onChangeQR: PropTypes.func.isRequired,
	onChangeProduct: PropTypes.func.isRequired,
	onChangeQuantity: PropTypes.func.isRequired,
	onChangePrice: PropTypes.func.isRequired,
	onChangeOldMeasureData: PropTypes.func.isRequired,
	onChangeNewMeasureData: PropTypes.func.isRequired,
	onChangeTel: PropTypes.func.isRequired,
	onChangeName: PropTypes.func.isRequired,
	onChangeGender: PropTypes.func.isRequired,
	onChangeBirthdate: PropTypes.func.isRequired,
	onChangeAge: PropTypes.func.isRequired,
	onChangeCustomerChannel: PropTypes.func.isRequired,
	onChangeProvince: PropTypes.func.isRequired,
	onChangeAddrZone: PropTypes.func.isRequired,
	onChangeExtInfo: PropTypes.func.isRequired,
	onChangeClaimModalVisible: PropTypes.func.isRequired,
	onChangeInspector: PropTypes.func.isRequired,
	onChangePromoValue: PropTypes.func.isRequired,
	onTickPastOrderOption: PropTypes.func.isRequired,
	onChangePastOrderDate: PropTypes.func.isRequired,
	onChangePromoType: PropTypes.func.isRequired,
	onChangeDeliverDate: PropTypes.func.isRequired,
	onChangePayment: PropTypes.func.isRequired,
	onChangePaymentType: PropTypes.func.isRequired,
	onChangeMoneyReceive: PropTypes.func.isRequired,
	onChangeRemark: PropTypes.func.isRequired,

	onScan: PropTypes.func.isRequired,
	onAddData: PropTypes.func.isRequired,
	onEditData: PropTypes.func.isRequired,
	onDeleteData: PropTypes.func.isRequired,
	onClickPurchase: PropTypes.func.isRequired,

	globalReducer: PropTypes.object.isRequired
}

export const Actions = {
	SET_CREATED_ORDER_ID: "SET_CREATED_ORDER_ID",
	SET_TEL: "SET_TEL",
	SET_NAME: "SET_NAME",
	SET_AGE: "SET_AGE",
	SET_GENDER: "SET_GENDER",
	SET_BIRTHDATE: "SET_BIRTHDATE",
	SET_CUSTOMER_CHANNEL: "SET_CUSTOMER_CHANNEL",
	SET_ADDR_ZONE: "SET_ADDR_ZONE",
	SET_PROVINCE: "SET_PROVINCE",
	SET_EXT_INFO: "SET_EXT_INFO",
	SET_PRODUCT_CODE_SUGGESTION: "SET_PRODUCT_CODE_SUGGESTION",
	SET_PRODUCT_NAME_SUGGESTION: "SET_PRODUCT_NAME_SUGGESTION",
	SET_TABLE_DATA: "SET_TABLE_DATA",
	SET_PRODUCT_DATA: "SET_PRODUCT_DATA",
	SET_PRODUCT_CODE: "SET_PRODUCT_CODE",
	SET_PRODUCT_NAME: "SET_PRODUCT_NAME",
	SET_PRODUCT_AMOUNT: "SET_PRODUCT_AMOUNT",
	SET_PRODUCT_PRICE: "SET_PRODUCT_PRICE",
  SET_CLAIM_MODAL_VISIBLE: "SET_CLAIM_MODAL_VISIBLE",
  SET_INCLUDE_VAT: "SET_INCLUDE_VAT",
  SET_VAT_VALUE: "SET_VAT_VALUE",
	SET_REQ_ASSEMBLE: "SET_REQ_ASSEMBLE",
	SET_OLD_MEASURE_DATA: "SET_OLD_MEASURE_DATA",
	SET_NEW_MEASURE_DATA: "SET_NEW_MEASURE_DATA",
	SET_INSPECTOR: "SET_INSPECTOR",
	SET_INSPECTOR_DATA: "SET_INSPECTOR_DATA",
	SET_MEASURE_MODAL_VISIBLE: "SET_MEASURE_MODAL_VISIBLE",
	SET_PROMO_DATA: "SET_PROMO_DATA",
	SET_PROMO_TYPE: "SET_PROMO_TYPE",
	SET_PROMO_VALUE: "SET_PROMO_VALUE",
	SET_PAST_DATE: "SET_PAST_DATE",
	SET_IS_PAST_ORDER: "SET_IS_PAST_ORDER",
	SET_PICK_UP_DATE: "SET_PICK_UP_DATE",
	SET_IS_PICK_UP: "SET_IS_PICK_UP",
	SET_PRICE_SUM: "SET_PRICE_SUM",
	SET_DELIVER_DATE: "SET_DELIVER_DATE",
	SET_PAYMENT_TYPE: "SET_PAYMENT_TYPE",
	SET_REMARK: "SET_REMARK",
	SET_PAYMENT: "SET_PAYMENT",
	SET_MONEY_RECEIVE: "SET_MONEY_RECEIVE",
	SET_EDIT_IDX: "SET_EDIT_IDX",
	SET_IS_EDITING: "SET_IS_EDITING",
	SET_IS_LOADING: "SET_IS_LOADING",
	SET_IS_SUBMITTING: "SET_IS_SUBMITTING"
}
