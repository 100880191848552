/**
 *	Order List component - Pick Up modal
 */

import React from 'react';
import {
	Row,
	Col,
	Button,
	Modal,
	DatePicker
} from 'antd';
import moment from 'moment';

const PickUpModal = (props) => {
	const [ orderID, setOrderID ] = React.useState('');
	const [ orderCode, setOrderCode ] = React.useState('');
	const [ date, setDate ] = React.useState(moment().format('YYYY-MM-DD'));
	const [ pickupDate, setPickupDate ] = React.useState(null);
	const [ updateMode, setUpdateMode ] = React.useState(false);
	const [ submitable, setSubmitable ] = React.useState(false);

	React.useEffect(() => {
		prepareData(props.orderInfo, setOrderID, setOrderCode, setPickupDate, setUpdateMode, setSubmitable);
	}, [ props.orderInfo, setOrderID, setOrderCode, setPickupDate, setUpdateMode, setSubmitable ]);

	const prepareData = (order_info, set_order_id, set_order_code, set_pickup_date, set_update_mode, set_submitable) => {
		set_order_id(order_info.order_id);
		set_order_code(order_info.order_code)
		set_pickup_date((order_info.pick_up_date) ? moment(order_info.pick_up_date) : moment(order_info.receive_date));
		set_update_mode((order_info.pick_up_date) ? true : false);
		set_submitable(true);
	}
	const onChangeDate = (date, dateString) => {
		console.log(updateMode)

		if(date) {
			setDate(date.format('YYYY-MM-DD'));
			setPickupDate(date);
			setSubmitable(true);
		}
		else {
			setDate('');
			setPickupDate(null);
			setSubmitable(false);
		}
	}

	const submit = () => {
		let _orderID = orderID;
		let _date = date;
		setOrderID('');
		setOrderCode('');
		setDate('');
		setPickupDate(moment());
		setSubmitable(false);
		props.onSubmitModal(props.orderInfo, _orderID, 1, _date);
	}

	return (
		<Modal
			title={'การรับสินค้า'}
			visible={ props.isVisible }
			onCancel={ props.onCloseModal }
			footer={[
				<Button key={'cancel'} onClick={ props.onCloseModal }>
					ยกเลิก
				</Button>,
				<Button key={'confirm'} disabled={ !submitable } onClick={ submit }>
					ยืนยัน
				</Button>
			]}
		>
			<Row>
				<Col span={24}>
					<span>
						เลขที่สั่งซื้อ:&nbsp;{ orderCode }
					</span>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col span={24}>
					<span>วันที่รับสินค้า:</span>&nbsp;
					<DatePicker
						format={'DD/MM/YYYY'}
						value={ pickupDate }
						onChange={ onChangeDate }
						// disabledDate={(current) => {
						// 	if(updateMode) {
						// 		return current < moment(props.orderInfo.pick_up_date).subtract(1, 'day').endOf('day');
						// 	}
						// 	else {
						// 		return current < moment(props.orderInfo.receive_date).subtract(1, 'day').endOf('day');
						// 	}
						// }}
					/>
				</Col>
			</Row>
		</Modal>
	);
}

export default PickUpModal;