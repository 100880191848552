/**
 *	Stock List - Content
 */

import React from 'react';
import NumberFormat from 'react-number-format';
import {
	Row,
	Col,
	Button,
	Input,
	Pagination,
	Card
} from 'antd';

import Breadcrumb from '../../../../components/breadcrumb';
import IWearDropdown from '../../../../components/dropdown';
import ExcelButton from '../../../../components/excel-button';
import { ContentPropTypes } from './stock-list.model';

const Content = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'คลังสินค้า' ]} />
			<Row gutter={16} type={'flex'} align={'middle'} justify={'center'} style={{ marginTop: '20px' }}>
				<Col span={12}>
					<Card bordered={ false }>
						<span className={'custom-iwear-global-font-color has-text-weight-bold is-size-5'}>
							จำนวนกรอบ&nbsp;
							<NumberFormat
								thousandSeparator
								displayType={'text'}
								value={ props.frameCount }
							/>
						</span>
					</Card>
				</Col>
				<Col span={12}>
					<Card bordered={ false }>
						<span className={'custom-iwear-global-font-color has-text-weight-bold is-size-5'}>
							จำนวนเลนส์&nbsp;
							<NumberFormat
								thousandSeparator
								displayType={'text'}
								value={ props.lensCount }
							/>
						</span>
					</Card>
				</Col>
			</Row>
			{ renderContent(props) }
		</div>
	);
}

const renderContent = (props) => {
	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'} style={{ marginTop: '20px' }}>
			<Row>
				<Col span={24}>
					{ renderOptions(props) }
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					{ renderTable(props) }
				</Col>
			</Row>
			<Row type={'flex'} align={'middle'} justify={'end'}>
				<Col style={{ paddingBottom: '10px' }}>
					<Pagination
						className={'custom-iwear-global-table-pagination-container is-pulled-right'}
						current={ props.page }
						pageSize={ props.pageSize }
						total={ props.totalPage }
						onChange={ props.onChangePage }
					/>
				</Col>
			</Row>
		</div>
	);
}

const renderOptions = (props) => {
	let _branches = [];
	props.branchList.forEach((_e) => {
		_branches.push(_e.branch_name);
	});

	return (
		<div className={'custom-iwear-global-table-option-panel'}>
			<Row gutter={16} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={12}>
					<span className={'custom-iwear-global-font-color has-text-weight-bold is-size-5'}>
						ผลลัพธ์ข้อมูล&nbsp;
						<NumberFormat
							thousandSeparator
							displayType={'text'}
							value={ props.totalData }
						/>
						&nbsp;ข้อมูล
					</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						ค้นหา
					</span>&nbsp;&nbsp;&nbsp;
					<Input.Search
						enterButton
						placeholder={'Search'}
						allowClear={true}
						style={{ maxWidth: '70%' }}
						onSearch={ props.onChangeSearchKey }
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row gutter={16} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={12}>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						แสดง
					</span>&nbsp;&nbsp;&nbsp;
					<IWearDropdown
						styleJSX={{ minWidth: '80px', paddingRight: '20px' }}
						displayValue={ String(props.pageSize) }
						choices={[ 10, 20, 50, 100 ]}
						onSelect={ props.onChangePageSize }
					/>
				</Col>
				<Col span={12}>
					<Row gutter={8} type={'flex'} align={'middle'} justify={'end'}>
						<Col>
							<span className={'custom-iwear-global-font-color is-size-5'}>
								สาขา
							</span>&nbsp;
							<IWearDropdown
								styleJSX={{ minWidth: '100px', paddingRight: '25px' }}
								displayValue={ props.branchName || 'ทั้งหมด' }
								choices={[ 'ทั้งหมด', ..._branches ]}
								onSelect={ props.onChangeBranch }
							/>
						</Col>
						<Col>
							<Button onClick={ props.onClickImport }>
								<i className={'fas fa-download custom-iwear-global-font-color'}></i>&nbsp;
								<span className={'custom-iwear-global-font-color'}>สินค้านำเข้า</span>
							</Button>
						</Col>
						<Col>
							<Button onClick={ props.onClickExport }>
								<i className={'fas fa-upload custom-iwear-global-font-color'}></i>&nbsp;
								<span className={'custom-iwear-global-font-color'}>สินค้าส่งออก</span>
							</Button>
						</Col>
						<Col>
							<ExcelButton
								modalTitle={'เลือกช่วงวันที่'}
								exportType={'inventory'}
								dispatcher={ props.dispatcher }
								isLoading={ props.isExporting }

								branchName={ (props.branchName !== 'ทั้งหมด') ? props.branchName : '' }
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
}

const renderTable = (props) => {
	return (
		<div className={'custom-iwear-global-table-container'}>
			<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ลำดับที่
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							รหัสสินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							สินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							จำนวน
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ราคาต่อหน่วย
						</th>
						{/*<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							เพิ่มเติม
						</th>*/}
					</tr>
				</thead>
				<tbody>
					{ renderTableData(props) }
				</tbody>
			</table>
		</div>
	);
}

const renderTableData = (props) => {
	if(props.isLoading) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* กำลังโหลด... */}
				</td>
			</tr>
		);
	}
	else if(!props.isLoading && props.tableData.length === 0) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* ไม่มีข้อมูล */}
				</td>
			</tr>
		);
	}

	return props.tableData.map((_e, _i) => {
		return (
			<tr key={_i} className={'custom-iwear-global-hover-cursor-default'}>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
					onClick={() => { props.onClickViewDetail(_e.inventory_info.product_item_id); }}
				>
					{ _i + 1 + ((props.page - 1) * props.pageSize) }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
					onClick={() => { props.onClickViewDetail(_e.inventory_info.product_item_id); }}
				>
					{ _e.product_info.item_code }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
					onClick={() => { props.onClickViewDetail(_e.inventory_info.product_item_id); }}
				>
					{ _e.product_info.name }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
					onClick={() => { props.onClickViewDetail(_e.inventory_info.product_item_id); }}
				>
					{ _e.inventory_info.quantity }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
					onClick={() => { props.onClickViewDetail(_e.inventory_info.product_item_id); }}
				>
					{ _e.product_info.price }
				</td>
				{/* <td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					<button className={'button '}>
						<i className={'fas fa-ellipsis-v custom-iwear-global-table-text-color'}></i>
					</button>
				</td>*/}
			</tr>
		);
	});
}

Content.propTypes = ContentPropTypes;

export default Content;
