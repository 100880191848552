/**
 *	iWear - Error screen
 */

import React from 'react';
import {
	Row,
	Col
} from 'antd';
import PropTypes from 'prop-types';

const Content = (props) => {
	return (
		<React.Fragment>
			<div className={'container'}>
				<Row type={'flex'} align={'middle'} justify={'center'} style={{ width: '100%', height: '100vh' }}>
					<Col span={24}>
						{ renderCountDown(props) }
					</Col>
				</Row>
			</div>
		</React.Fragment>
	)
}

const renderCountDown = (props) => {
	return (
		<div style={{ textAlign: 'center' }}>
			<span className={'has-text-black is-size-2'}>
				404 Request Error
			</span><br/><br/>
			<span className={'has-text-black is-size-4'}>
				The given url is invalid.
			</span><br/>
			<span className={'has-text-black is-size-4'}>
				The system will be automatically redirecting to PREVIOUS page in { props.counter } seconds
			</span>
		</div>
	);
}

Content.propTypes = {
	counter: PropTypes.number.isRequired
}

export default Content;
