/**
 *	Customers List - Container
 */

import React from 'react';
import {
	Layout
} from 'antd';
import swal from 'sweetalert';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../environments';
import { downloadFromBuffer } from '../../../../functions';
import GlobalStore from '../../../../store';
import LocalStore from './customers-list.store';
import Content from './customers-list.content';
import { ContainerPropTypes, Actions } from './customers-list.model';

const Container = (props) => {
	const CustomerReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData({ page: CustomerReducer.state.page, pageSize: CustomerReducer.state.pageSize, searchKey: CustomerReducer.state.searchKey, branchName: CustomerReducer.state.branchName }, CustomerReducer.dispatch, state.auth.token);
	}, [ CustomerReducer.state.page, CustomerReducer.state.pageSize, CustomerReducer.state.searchKey, CustomerReducer.state.branchName, CustomerReducer.dispatch, state.auth.token ]) // @update on `page` or `pageSize` state changed

	const fetchData = async (params, localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		let res_branch_list = await fetch(`${API_ENDPOINT}/branches?limit=9999`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json_branch_list = await res_branch_list.json();
		localDispatch({ type: Actions.SET_BRANCH_LIST, value: [ ...json_branch_list.list ] });

		if(params.branchName && params.branchName !== 'ทั้งหมด') {
			let res_branch = await fetch(`${API_ENDPOINT}/branches?branch_name=${encodeURIComponent(params.branchName)}`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + authToken,
		    		'Content-Type': 'application/json'
				}
			});

			let json_branch = await res_branch.json();
			let _branchID = json_branch.list[0].branch_id;

			let _url = `
				${API_ENDPOINT}/customers
				?branch_id=${encodeURIComponent(_branchID)}
				&search=${encodeURIComponent(params.searchKey)}
				&offset=${(params.page - 1) * params.pageSize}
				&limit=${params.pageSize}
			`;
			let res = await fetch(`${_url}`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + authToken,
					'Content-Type': 'application/json'
				}
			});

			let json = await res.json();
			localDispatch({ type: Actions.SET_TABLE_DATA, value: [ ...json.list ] });
			localDispatch({ type: Actions.SET_TOTAL_DATA, value: json.count });
			localDispatch({ type: Actions.SET_TOTAL_PAGE, value: json.total_pages * params.pageSize });
		}
		else {
			let _url = `
				${API_ENDPOINT}/customers
				?search=${encodeURIComponent(params.searchKey)}
				&offset=${(params.page - 1) * params.pageSize}
				&limit=${params.pageSize}
			`;
			let res = await fetch(`${_url}`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + authToken,
					'Content-Type': 'application/json'
				}
			});

			let json = await res.json();
			localDispatch({ type: Actions.SET_TABLE_DATA, value: [ ...json.list ] });
			localDispatch({ type: Actions.SET_TOTAL_DATA, value: json.count });
			localDispatch({ type: Actions.SET_TOTAL_PAGE, value: json.total_pages * params.pageSize });
		}

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onChangeBranch = (selectedValue) => {
		CustomerReducer.dispatch({ type: Actions.SET_PAGE, value: 1 });
		CustomerReducer.dispatch({ type: Actions.SET_BRANCH_NAME, value: `${selectedValue}` });
	}

	const onChangePage = (page, pageSize) => {
		CustomerReducer.dispatch({ type: Actions.SET_PAGE, value: page });
	}

	const onChangePageSize = (selectedVal) => {
		CustomerReducer.dispatch({ type: Actions.SET_PAGE, value: 1 });
		CustomerReducer.dispatch({ type: Actions.SET_PAGE_SIZE, value: Number.parseInt(selectedVal, 10) });
	}

	const onChangeSearchKey = (value) => {
		CustomerReducer.dispatch({ type: Actions.SET_PAGE, value: 1 });
		CustomerReducer.dispatch({ type: Actions.SET_SEARCH_KEY, value: value });
	}

	const onClickViewDetail = (customer_info) => {
		props.history.push('/admin/customer/detail', {
			branch_id: customer_info.branch_id,
			customer_id: customer_info.customer_id,
			customer_name: customer_info.full_name,
			customer_phone_number: customer_info.phone_number
		});
	}

	const onClickCreate = () => {
		// props.history.push('/sales/customer/create');
	}

	const onClickUpdate = (customer_info) => {
		// props.history.push('/sales/customer/update', {
		// 	customer_id: customer_info.customer_id,
		// 	customer_name: customer_info.full_name
		// });
	}

	const onClickFileExport = async () => {
		CustomerReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: true });

		let [ _branch ] = CustomerReducer.state.branchList.filter((_f) => { return _f.branch_name === CustomerReducer.state.branchName; });

		let _url = '';

		if(_branch && _branch.branch_id) {
			_url = `
				${API_ENDPOINT}/customers
				?branch_id=${_branch.branch_id}
				&search=${CustomerReducer.state.searchKey}
				&is_report=true
			`;
		}
		else {
			_url = `
				${API_ENDPOINT}/customers
				?search=${CustomerReducer.state.searchKey}
				&is_report=true
			`;
		}

		let res = await fetch(`${_url}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let data = await res.json();
		if(res.status === 200) {
			let _timer = 5000 + (Number.parseInt(CustomerReducer.state.totalData / 5000, 10) * 1000);
			setTimeout(() => {
				downloadFromBuffer(data.report_path, 'customer_report.xlsx');
				CustomerReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: false });
			}, _timer);
		}
		else {
			CustomerReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: false });
			swal({
				title: 'เกิดข้อผิดพลาด',
				text: 'ไม่สามารถ export ได้ในขณะนี้',
				icon: 'error'
			});
		}
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/customer'} history={ props.history } />
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/customer'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...CustomerReducer.state }
						dispatcher={ CustomerReducer.dispatch }

						onChangeBranch={ onChangeBranch }
						onChangePage={ onChangePage }
						onChangePageSize={ onChangePageSize }
						onChangeSearchKey={ onChangeSearchKey }

						onClickCreate={ onClickCreate }
						onClickUpdate={ onClickUpdate }
						onClickViewDetail={ onClickViewDetail }
						onClickFileExport={ onClickFileExport }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes

export default Container;
