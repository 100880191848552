/**
 *	iWear - Functions
 */

import React from 'react';

export function phoneMaskText(text) {
	if(text.trim().length === 10) {
		let cleanText = text.replace(/ /g, '');
	    let newText = cleanText.slice(0, 3) + '-' + cleanText.slice(3, 6) + '-' + cleanText.slice(6);
	    return newText;
	}
	else {
		console.warn('[PhoneMaskText]: Error masking source', text);
		// console.log('Error masking source', text);
		return text;
	}
}

/* CUSTOM REACT HOOK */
export function useDebounce(value, delay) {
	const [ debounceValue, setDebounceValue ] = React.useState(value);

	React.useEffect(() => {
		let timerHandler = setTimeout(() => {
			setDebounceValue(value);
		}, delay);

		return () => { clearTimeout(timerHandler); }
	}, [ value, delay ]);

	return debounceValue;
}

export function downloadFromBuffer(buffer, filename, type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
	const blob = new Blob([new Uint8Array(buffer.data).buffer], {type: type})
	const url = URL.createObjectURL(blob)

	const a = document.createElement('a');
	document.body.appendChild(a);
	a.href = url;
	a.download = filename;
	a.click();
	setTimeout(() => {
		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);
	}, 0)
}

export function downloadFromRawBuffer(buffer, filename, type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
	const blob = new Blob([buffer], {type: type})
	const url = URL.createObjectURL(blob)

	const a = document.createElement('a');
	document.body.appendChild(a);
	a.href = url;
	a.download = filename;
	a.click();
	setTimeout(() => {
		window.URL.revokeObjectURL(url);
		document.body.removeChild(a);
	}, 0)
}
