/**
 *	Stock Export component - Export to print
 *
 *		Inventory Info Props
 *			- exchange_detail: Array
 *			- exchange_code: String
 *			- source_branch: Object
 *			- dest_branch: Object
 *			- create_at: Date
 */

import React from 'react';
import PropTypes from 'prop-types'
import * as QRCode from 'qrcode.react'
import XDate from 'xdate';

import styles from './to-pdf-stock.module.css'

class ExportToPrint extends React.PureComponent {

	renderDataTable = () => {
		if(this.props.inventoryInfo && this.props.inventoryInfo.exchange_detail) {
			return this.props.inventoryInfo.exchange_detail.map((_e, _i) => {
				return (
					<tr key={_i}>
						<td
							className={'custom-iwear-global-table-text-color has-text-centered	custom-iwear-global-table-bottom-left-td'}
							style={{verticalAlign: 'middle'}}
						>
							{_i + 1}
						</td>
						<td
							className={'custom-iwear-global-table-text-color has-text-centered	custom-iwear-global-table-bottom-left-td'}
							style={{verticalAlign: 'middle'}}
						>
							{ _e.product_info.item_code }
						</td>
						<td
							className={'custom-iwear-global-table-text-color has-text-centered '}
							style={{verticalAlign: 'middle'}}
						>
							{ _e.product_info.name }
						</td>
						<td
							className={'custom-iwear-global-table-text-color has-text-centered '}
							style={{verticalAlign: 'middle'}}
						>
							{ _e.quantity }
						</td>
						<td
							className={'custom-iwear-global-table-text-color has-text-centered '}
							style={{verticalAlign: 'middle'}}
						>
							{ _e.product_info.price }
						</td>
					</tr>
				);
			});
		}
		else {
			return null;
		}
	}

	renderContent = () => {
		return (
			<div className={ styles.stockPrintExportContainer }>
				{/* title */}
				<div className={ styles.stockPrintExportTitle }>
					<h1>ใบโอนย้ายสินค้า</h1>
					<div className={'stockPrintLogo'}>
						<figure className={'image'} style={{ width: '128px' }}>
							<img
								src={process.env.PUBLIC_URL + '/static/images/iWearLogo.svg'}
								alt={'iWear Logo'}
							/>
						</figure>
					</div>
				</div>

				{/* header */}
				<div className={ styles.stockPrintHeader }>

					{/* left side */}
					<div className={ styles.stockPrintHeaderLeftSide }>
						<ul style={{ listStyleType: 'none' }}>
							<li style={{ listStyleType: 'none' }}>รหัสใบส่งออก: { (this.props.inventoryInfo && this.props.inventoryInfo.exchange_code) ? this.props.inventoryInfo.exchange_code : '' }</li>
							<li style={{ listStyleType: 'none' }}>สาขาต้นทาง: { (this.props.inventoryInfo && this.props.inventoryInfo.source_branch) ? this.props.inventoryInfo.source_branch.branch_name !== undefined ? this.props.inventoryInfo.source_branch.branch_name: '' : '' }</li>
							<li style={{ listStyleType: 'none' }}>สาขาปลายทาง: { (this.props.inventoryInfo && this.props.inventoryInfo.dest_branch) ? this.props.inventoryInfo.dest_branch.branch_name : '' }</li>
						</ul>
					</div>

					{/* right side */}
					<div className={ styles.stockPrintHeaderRightSide }>
						<ul style={{ listStyleType: 'none' }}>
							<li style={{ listStyleType: 'none' }}>วันที่ส่งออก: { XDate( this.props.inventoryInfo.create_at).toString('dd/MM/yyyy') }</li>
							<li style={{ listStyleType: 'none' }}>Qr code: <br/>
								<div className={ styles.stockPrintHeaderQrCode }>
									{ <QRCode value={ (this.props.inventoryInfo && this.props.inventoryInfo.exchange_code) ? this.props.inventoryInfo.exchange_code : '' } /> }
								</div>
							</li>
						</ul>
					</div>
				</div>

				<div className={ styles.stockPrintProductOrder }>
					<h2>รายการสินค้า</h2>
				</div>

				{/* table data */}
				<div className={'custom-iwear-global-table-container table-container'}>
					<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
						<thead>
							<tr>
								<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
									ลำดับ
								</th>
								<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
									รหัสสินค้า
								</th>
								<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
									ชื่อสินค้า
								</th>
								<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
									จำนวนสินค้า
								</th>
								<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
									จำนวนเงิน
								</th>
							</tr>
						</thead>
						<tbody>
							{ this.renderDataTable() }
						</tbody>
					</table>
				</div>

				{/* Footer */}
				{/*<div style={{ borderTop: '3px solid #000000' }}></div>*/}
				<div className={ styles.stockPrintFooter }>
					<div className={ styles.stockPrintBoxSigning }>
						<div className={ styles.stockPrintSignField }></div>
						<div className={ styles.stockPrintSignLabel }>
							<span className={'is-size-6 has-text-black has-text-weight-bold'}>
								ผู้จ่าย / ผู้นำสินค้าส่ง
							</span>
						</div>
					</div>
					<div className={ styles.stockPrintBoxSigning }>
						<div className={ styles.stockPrintSignField }></div>
						<div className={ styles.stockPrintSignLabel }>
							<span className={'is-size-6 has-text-black has-text-weight-bold'}>
								ผู้รับของ
							</span>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return this.renderContent();
	}
}

ExportToPrint.propTypes = {
	inventoryInfo: PropTypes.object.isRequired,
}

export default ExportToPrint;
