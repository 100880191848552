/**
 *	Order Detail - Container
 */

import React from 'react';
import XDate from 'xdate';
import {
	Layout
} from 'antd';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../environments';
import GlobalStore from '../../../../store';
import LocalStore from './order-detail.store';
import Content from './order-detail.content';
import { ContainerPropTypes, Actions } from './order-detail.model';

const Container = (props) => {
	const OrderDetailReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		if(props.location.state.order_id) {
			fetchData(
				props.location.state.order_id,
				props.location.state.customer_id,
				props.location.state.fullname,
				props.location.state.tel,
				OrderDetailReducer.dispatch,
				state.auth.token
			);
		}
		else {
			props.history.goBack();
		}
	}, [ props.history, props.location.state.order_id, props.location.state.customer_id, props.location.state.fullname, props.location.state.tel, OrderDetailReducer.dispatch, state.auth.token ]); // No dependency = Call only once

	const fetchData = async (order_id, customer_id, fullname, tel, localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		let res_order = await fetch(`${API_ENDPOINT}/orders/${encodeURIComponent(order_id)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let res_eye = await fetch(`${API_ENDPOINT}/eye-inspector?customer_id=${encodeURIComponent(customer_id)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let res_customer = await fetch(`${API_ENDPOINT}/customer-with-eye?search=${encodeURIComponent(tel)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let res_product = await fetch(`${API_ENDPOINT}/product-items`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let res_promo = await fetch(`${API_ENDPOINT}/promotions?is_active=true&branch=${encodeURIComponent(branchID)}&limit=9999`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});
		let json_promo = await res_promo.json();
		localDispatch({ type: Actions.SET_PROMO_DATA, value: [ ...json_promo.list ] });

		let json_product = await res_product.json();

		let _products = [];
		json_product.list.forEach((_e) => {
			_products.push({ id: _e.item_id, item_code: _e.item_code, name: _e.name, price: _e.price });
		});
		localDispatch({ type: Actions.SET_PRODUCT_DATA, value: [ ..._products ] });

		let json_order = await res_order.json();
		let json_eye = await res_eye.json();
		let json_customer = await res_customer.json();

		let _eyesData = [];
		if (json_customer.list[0].eye_info.length > 0) {
			 _eyesData = [ ...json_customer.list[0].eye_info ];
		}
		else {
			_eyesData = [{
				add_left: 0,
				add_right: 0,
				axis_left: 0,
				axis_right: 0,
				cyl_left: 0,
				cyl_right: 0,
				dpd_left: 0,
				dpd_right: 0,
				inspector_id: 0,
				is_lastest:0,
				mono_va_left: 0,
				mono_va_right: 0,
				npd_left: 0,
				npd_right: 0,
				prism_left:0,
				prism_right: 0,
				seg_ht_left: 0,
				seg_ht_right: 0,
				sphere_left: 0,
				sphere_right: 0
			}];
		}

		console.log('json_order', json_order)
		const _customerInfo = json_order.customer_info;
		localDispatch({ type: Actions.SET_TEL, value: _customerInfo.phone_number });
		localDispatch({ type: Actions.SET_NAME, value: _customerInfo.full_name });
		localDispatch({ type: Actions.SET_AGE, value: _customerInfo.age });
		localDispatch({ type: Actions.SET_GENDER, value: _customerInfo.sex });
		localDispatch({ type: Actions.SET_BIRTHDATE, value: (_customerInfo.birth_date !== null) ? XDate(new Date(_customerInfo.birth_date)).toString('dd/MM/yyyy') : '' });
		localDispatch({ type: Actions.SET_CUSTOMER_CHANNEL, value: _customerInfo.customer_channel.split('|') });
		localDispatch({ type: Actions.SET_ADDR_ZONE, value: _customerInfo.district });
		localDispatch({ type: Actions.SET_INCLUDE_VAT, value: json_order.vat > 0 });
		localDispatch({ type: Actions.SET_VAT_VALUE, value: json_order.vat });
		localDispatch({ type: Actions.SET_REQ_ASSEMBLE, value: json_order.is_custom_assemble });
		localDispatch({ type: Actions.SET_PROVINCE, value: json_customer.list[0].province });
		localDispatch({ type: Actions.SET_EXT_INFO, value: _customerInfo.description });
		localDispatch({ type: Actions.SET_EYE_HISTORY_DATA, value: [ ..._eyesData ] });

		let _order = [];
		json_order.order_detail.forEach((_e, _i) => {
			_order.push({
				order: _i + 1,
				id: _e.item_id,
				item_code: _e.product_info.item_code,
				name: _e.product_info.name,
				amount: _e.quantity,
				price: _e.product_info.price
			})
		});
		localDispatch({ type: Actions.SET_TABLE_DATA, value: [ ..._order ] });

		if (json_eye.list[json_eye.list.length - 1] !== undefined) {
			localDispatch({ type: Actions.SET_INSPECTOR, value: json_eye.list[json_eye.list.length - 1].staff_info.full_name });
		} else {
			localDispatch({ type: Actions.SET_INSPECTOR, value: '' });
		}

		localDispatch({
			type: Actions.SET_OLD_MEASURE_DATA,
			value: [
				{ type: 'Sphere', leftValue: _eyesData[_eyesData.length - 1].sphere_left, rightValue: _eyesData[_eyesData.length - 1].sphere_right },
				{ type: 'CYL', leftValue: _eyesData[_eyesData.length - 1].cyl_left, rightValue: _eyesData[_eyesData.length - 1].cyl_right },
				{ type: 'AXIS', leftValue: _eyesData[_eyesData.length - 1].axis_left, rightValue: _eyesData[_eyesData.length - 1].axis_right },
				{ type: 'ADD', leftValue: _eyesData[_eyesData.length - 1].add_left, rightValue: _eyesData[_eyesData.length - 1].add_right },
				{ type: 'DPD', leftValue: _eyesData[_eyesData.length - 1].dpd_left, rightValue: _eyesData[_eyesData.length - 1].dpd_right },
				{ type: 'NPD', leftValue: _eyesData[_eyesData.length - 1].npd_left, rightValue: _eyesData[_eyesData.length - 1].npd_right },
				{ type: 'SegHT', leftValue: _eyesData[_eyesData.length - 1].seg_ht_left, rightValue: _eyesData[_eyesData.length - 1].seg_ht_right },
				{ type: 'Prism', leftValue: _eyesData[_eyesData.length - 1].prism_left, rightValue: _eyesData[_eyesData.length - 1].prism_right },
				{ type: 'Mono-VA', leftValue: _eyesData[_eyesData.length - 1].mono_va_left, rightValue: _eyesData[_eyesData.length - 1].mono_va_right },
			]
		});

		localDispatch({
			type: Actions.SET_NEW_MEASURE_DATA,
			value: [
				{ type: 'Sphere', leftValue: 0, rightValue: 0 },
				{ type: 'CYL', leftValue: 0, rightValue: 0 },
				{ type: 'AXIS', leftValue: 0, rightValue: 0 },
				{ type: 'ADD', leftValue: 0, rightValue: 0 },
				{ type: 'DPD', leftValue: 0, rightValue: 0 },
				{ type: 'NPD', leftValue: 0, rightValue: 0 },
				{ type: 'SegHT', leftValue: 0, rightValue: 0 },
				{ type: 'Prism', leftValue: 0, rightValue: 0 },
				{ type: 'Mono-VA', leftValue: 0, rightValue: 0 },
			]
		});

		localDispatch({ type: Actions.SET_PROMO_TYPE, value: String(json_order.promotion_info.code) });
		localDispatch({ type: Actions.SET_PROMO_VALUE, value: json_order.discount });

		let _priceSum = 0;
		json_order.order_detail.forEach((_e) => {
			_priceSum += (_e.product_info.price * _e.quantity);
		});

		let _payment = 0;
		json_order.payment_info.forEach((_e) => {
			_payment += _e.amount;
		});

		localDispatch({ type: Actions.SET_PRICE_SUM, value: _priceSum });
		localDispatch({ type: Actions.SET_CREATED_DATE, value: XDate(new Date(json_order.created_at)).toString('dd/MM/yyyy') });
		localDispatch({ type: Actions.SET_PICK_UP_DATE, value: (json_order.pick_up_date) ? XDate(new Date(json_order.pick_up_date)).toString('dd/MM/yyyy') : '-' });
		localDispatch({ type: Actions.SET_DELIVER_DATE, value: XDate(new Date(json_order.receive_date)).toString('dd/MM/yyyy') });
		localDispatch({ type: Actions.SET_REMARK, value: json_order.note });
		localDispatch({ type: Actions.SET_PAYMENT, value: _payment });
		localDispatch({ type: Actions.SET_PAYMENT_TYPE, value: json_order.payment_info[json_order.payment_info.length - 1].payment_type });
		localDispatch({ type: Actions.SET_MONEY_RECEIVE, value: json_order.payment_info[json_order.payment_info.length - 1].amount });

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onChangeClaimModalVisible = (val) => { OrderDetailReducer.dispatch({ type: Actions.SET_CLAIM_MODAL_VISIBLE, value: val }); }

	const onChangeMeasureModalVisible = (val) => { OrderDetailReducer.dispatch({ type: Actions.SET_MEASURE_MODAL_VISIBLE, value: val }); }

	const onClickGoBack = () => {
		props.history.goBack();
	}

	return (
		<Layout>
			<Sidebar mode={'sales'} currentRoute={'/sales/order'} history={ props.history } />
			<Layout>
				<TopBar mode={'sales'} currentRoute={'/sales/order'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...OrderDetailReducer.state }

						onChangeClaimModalVisible={ onChangeClaimModalVisible }
						onChangeMeasureModalVisible={ onChangeMeasureModalVisible }

						onClickGoBack={ onClickGoBack }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
