/**
 *	Stock Export Inventory - State Management
 */

import React from 'react';
import { Actions } from './stock-export-inventory.model';

const initState = {
	productID: '',
	productName: '',
	productCode: '',
	productAmount: 0,
	productPrice: 0,
	suggestionDataProductCode: [],
	suggestionDataProductName: [],
	exportInfo: {
		exchange_detail: [],
		exchange_code: 'ex code',
		source_branch: { branch_name: 'src branch name' },
		dest_branch: { branch_name: 'dest branch name' },
		create_at: new Date()
	},
	branch: '',
	branchList: [],
	tableData: [],
	page: 1,
	pageSize: 10,
	editIdx: -1,
	isEditing: false,
	isLoading: false
}

const reducer = (state, action) => {
	switch(action.type) {
		case Actions.SET_PRODUCT_ID:
			return {
				...state,
				productID: action.value
			}
		case Actions.SET_PRODUCT_NAME:
			return {
				...state,
				productName: action.value
			}
		case Actions.SET_PRODUCT_CODE:
			return {
				...state,
				productCode: action.value
			}
		case Actions.SET_PRODUCT_AMOUNT:
			return {
				...state,
				productAmount: action.value
			}
		case Actions.SET_PRODUCT_PRICE:
			return {
				...state,
				productPrice: action.value
			}
		case Actions.SET_PRODUCT_CODE_SUGGESTION:
			return {
				...state,
				suggestionDataProductCode: action.value
			}
		case Actions.SET_PRODUCT_NAME_SUGGESTION:
			return {
				...state,
				suggestionDataProductName: action.value
			}
		case Actions.SET_EXPORT_INFO:
			return {
				...state,
				exportInfo: action.value
			}
		case Actions.SET_BRANCH:
			return {
				...state,
				branch: action.value
			}
		case Actions.SET_BRANCH_LIST:
			return {
				...state,
				branchList: action.value
			}
		case Actions.SET_TABLE_DATA:
			return {
				...state,
				tableData: action.value
			}
		case Actions.SET_PAGE:
			return {
				...state,
				page: action.value
			}
		case Actions.SET_PAGE_SIZE:
			return {
				...state,
				pageSize: action.value
			}
		case Actions.SET_EDIT_IDX:
			return {
				...state,
				editIdx: action.value
			}
		case Actions.SET_IS_EDITING:
			return {
				...state,
				isEditing: action.value
			}
		case Actions.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.value
			}
		default:
			return { ...state }
	}
}

const StockExportInventoryReducer = () => {
	const [ state, dispatch ] = React.useReducer(reducer, initState);

	React.useEffect(() => {}, []);

	return {
		state,
		dispatch
	}
}

export default StockExportInventoryReducer;
