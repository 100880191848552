/**
 *	iWear - Side bar menu
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
	Layout,
	Menu
} from 'antd';
import styles from './sider.module.css';

const adminMenu = [
	{ name: 'Dashboard', route: '/admin/dashboard', icon: 'fas fa-home', hasSub: false },
	{ name: 'สั่งซื้อ', route: '/admin/order', icon: 'far fa-list-alt', hasSub: false },
	{ name: 'การเงิน', route: '/admin/finance', icon: 'fas fa-coins', hasSub: false },
	{ name: 'การนัดหมาย', route: '/admin/appointment', icon: 'far fa-calendar-alt', hasSub: false },
	{ name: 'การจัดการลูกค้า', route: '/admin/customer', icon: 'fas fa-users', hasSub: false },
	{ name: 'คลังสินค้า', route: '/admin/stock', icon: 'fas fa-warehouse', hasSub: true },
	// { name: 'รายงาน', route: '/admin/report', icon: 'far fa-file-alt', hasSub: true },
	{ name: 'จัดการข้อมูล', route: '/admin/data', icon: 'far fa-file-alt', hasSub: true },
];

const salesMenu = [
	{ name: 'Dashboard', route: '/sales/dashboard', icon: 'fas fa-home', hasSub: false },
	{ name: 'สั่งซื้อ', route: '/sales/order', icon: 'far fa-list-alt', hasSub: false },
	{ name: 'การนัดหมาย', route: '/sales/appointment', icon: 'far fa-calendar-alt', hasSub: false },
	{ name: 'การจัดการลูกค้า', route: '/sales/customer', icon: 'fas fa-users', hasSub: false },
	{ name: 'คลังสินค้า', route: '/sales/stock', icon: 'fas fa-warehouse', hasSub: true },
	{ name: 'DIVIDER', route: '' },
	{ name: 'เพิ่มการสั่งซื้อใหม่', route: '/sales/order/create', icon: 'fas fa-plus', hasSub: true }
];

const rxmallMenu = [
	{ name: 'สั่งซื้อ', route: '/lab/order', icon: 'far fa-list-alt', hasSub: false },
	{ name: 'การนัดหมาย', route: '/lab/appointment', icon: 'far fa-calendar-alt', hasSub: false },
	{ name: 'คลังสินค้า', route: '/lab/stock', icon: 'fas fa-warehouse', hasSub: true },
	{ name: 'จัดการข้อมูล', route: '/lab/data', icon: 'far fa-file-alt', hasSub: true }
];

const menuGenerator = (props, isCollapse) => {
	if(props.mode === 'admin') {
		return adminMenu.map((_e, _i) => {
			if(_e.name === 'DIVIDER') {
				return (
					<Menu.Divider key={_i} />
				);
			}
			else if(_e.hasSub && _e.name === 'คลังสินค้า') {
				return (
					<Menu.SubMenu
						key={'admin-stock'}
						title={
							<React.Fragment>
								<i className={ _e.icon } style={{ minWidth: '25px' }}></i>
								{
									(!isCollapse) && (
										_e.name
									)
								}
							</React.Fragment>
						}
					>

						<Menu.Item key={'/admin/stock'}>
							สต็อกสินค้า
						</Menu.Item>
						<Menu.Item key={'/admin/stock/import'}>
							สินค้าเข้า
						</Menu.Item>
						<Menu.Item key={'/admin/stock/export'}>
							สินค้าส่งออก
						</Menu.Item>

					</Menu.SubMenu>
				);
			}
			else if(_e.hasSub && _e.name === 'เพิ่มการสั่งซื้อใหม่') {
				return (
					<Menu.SubMenu
						key={'admin-order'}
						title={
							<React.Fragment>
								<i className={ _e.icon } style={{ minWidth: '25px' }}></i>
								{
									(!isCollapse) && (
										_e.name
									)
								}
							</React.Fragment>
						}
					>

						<Menu.Item key={'/admin/order/create/new'}>
							ลูกค้าใหม่
						</Menu.Item>
						<Menu.Item key={'/admin/order/create/old'}>
							ลูกค้าเก่า
						</Menu.Item>

					</Menu.SubMenu>
				);
			}
			else if(_e.hasSub && _e.name === 'จัดการข้อมูล') {
				return (
					<Menu.SubMenu
						key={'admin-data'}
						title={
							<React.Fragment>
								<i className={ _e.icon } style={{ minWidth: '25px' }}></i>
								{
									(!isCollapse) && (
										_e.name
									)
								}
							</React.Fragment>
						}
					>

						<Menu.Item key={'/admin/data/branch'}>
							สาขา
						</Menu.Item>
						<Menu.Item key={'/admin/data/brand'}>
							แบรนด์
						</Menu.Item>
						<Menu.Item key={'/admin/data/staff'}>
							พนักงาน
						</Menu.Item>
						<Menu.Item key={'/admin/data/product'}>
							สินค้า
						</Menu.Item>
						<Menu.Item key={'/admin/data/product/category'}>
							ชนิดสินค้า
						</Menu.Item>
						<Menu.Item key={'/admin/data/promotion'}>
							โปรโมชั่น
						</Menu.Item>

					</Menu.SubMenu>
				);
			}

			return (
				<Menu.Item key={_e.route}>
        			<i className={ _e.icon } style={{ minWidth: '25px' }}></i>
					{
						(!isCollapse) && (
							_e.name
						)
					}
      			</Menu.Item>
			);
		});
	}
	else if(props.mode === 'sales') { // props.mode === 'sales' | or whatever
		return salesMenu.map((_e, _i) => {
			if(_e.name === 'DIVIDER') {
				return (
					<Menu.Divider key={_i} />
				);
			}
			else if(_e.hasSub && _e.name === 'คลังสินค้า') {
				return (
					<Menu.SubMenu
						key={'sales-stock'}
						title={
							<React.Fragment>
								<i className={ _e.icon } style={{ minWidth: '25px' }}></i>
								{
									(!isCollapse) && (
										_e.name
									)
								}
							</React.Fragment>
						}
					>

						<Menu.Item key={'/sales/stock'}>
							สต็อกสินค้า
						</Menu.Item>
						<Menu.Item key={'/sales/stock/import'}>
							สินค้าเข้า
						</Menu.Item>
						<Menu.Item key={'/sales/stock/export'}>
							สินค้าส่งออก
						</Menu.Item>

					</Menu.SubMenu>
				);
			}
			else if(_e.hasSub && _e.name === 'เพิ่มการสั่งซื้อใหม่') {
				return (
					<Menu.SubMenu
						key={'sales-order'}
						title={
							<React.Fragment>
								<i className={ _e.icon } style={{ minWidth: '25px' }}></i>
								{
									(!isCollapse) && (
										_e.name
									)
								}
							</React.Fragment>
						}
					>

						<Menu.Item key={'/sales/order/create/new'}>
							ลูกค้าใหม่
						</Menu.Item>
						<Menu.Item key={'/sales/order/create/old'}>
							ลูกค้าเก่า
						</Menu.Item>

					</Menu.SubMenu>
				);
			}

			return (
				<Menu.Item key={_e.route}>
        			<i className={ _e.icon } style={{ minWidth: '25px' }}></i>
					{
						(!isCollapse) && (
							_e.name
						)
					}
      			</Menu.Item>
			);
		});
	}
	else if(props.mode === 'rxmall') {
		return rxmallMenu.map((_e, _i) => {
			if(_e.hasSub && _e.name === 'จัดการข้อมูล') {
				return (
					<Menu.SubMenu
						key={'lab-data'}
						title={
							<React.Fragment>
								<i className={ _e.icon } style={{ minWidth: '25px' }}></i>
								{
									(!isCollapse) && (
										_e.name
									)
								}
							</React.Fragment>
						}
					>

						<Menu.Item key={'/lab/data/branch'}>
							สาขา
						</Menu.Item>
						<Menu.Item key={'/lab/data/brand'}>
							แบรนด์
						</Menu.Item>
						<Menu.Item key={'/lab/data/staff'}>
							พนักงาน
						</Menu.Item>
						<Menu.Item key={'/lab/data/product'}>
							สินค้า
						</Menu.Item>
						<Menu.Item key={'/lab/data/product/category'}>
							ชนิดสินค้า
						</Menu.Item>
						<Menu.Item key={'/lab/data/promotion'}>
							โปรโมชั่น
						</Menu.Item>

					</Menu.SubMenu>
				);
			}
			else if(_e.hasSub && _e.name === 'คลังสินค้า') {
				return (
					<Menu.SubMenu
						key={'lab-stock'}
						title={
							<React.Fragment>
								<i className={ _e.icon } style={{ minWidth: '25px' }}></i>
								{
									(!isCollapse) && (
										_e.name
									)
								}
							</React.Fragment>
						}
					>

						<Menu.Item key={'/lab/stock'}>
							สต็อกสินค้า
						</Menu.Item>
						<Menu.Item key={'/lab/stock/import'}>
							สินค้าเข้า
						</Menu.Item>
						<Menu.Item key={'/lab/stock/export'}>
							สินค้าส่งออก
						</Menu.Item>

					</Menu.SubMenu>
				);
			}

			return (
				<Menu.Item key={ _e.route }>
        			<i className={ _e.icon } style={{ minWidth: '25px' }}></i>
					{
						(!isCollapse) && (
							_e.name
						)
					}
      			</Menu.Item>
			);
		});
	}
}

const Sidebar = (props) => {
	const [ isCollapse, setIsCollapse ] = React.useState(false);

	React.useEffect(() => {
		init({ sidebarState: localStorage.getItem('iwear_side_state') })
	}, []);

	const init = (params) => {
		if(params.sidebarState !== null) {
			setIsCollapse(JSON.parse(params.sidebarState).collapse);
		}
	}

	const onToggle = () => {
		localStorage.setItem('iwear_side_state', JSON.stringify({ collapse: !isCollapse }));
		setIsCollapse(!isCollapse);
	}

	const keysResolver = () => {
		if(props.mode === 'admin') {
			if(props.currentRoute === '/admin/stock' || props.currentRoute === '/admin/stock/import' || props.currentRoute === '/admin/stock/export') {
				return ['admin-stock'];
			}
			else if(props.currentRoute === '/admin/order' || props.currentRoute === '/admin/order/create/new' || props.currentRoute === '/admin/order/create/old') {
				return ['admin-order'];
			}
			else if(props.currentRoute === '/admin/data/branch' || props.currentRoute === '/admin/data/brand' || props.currentRoute === '/admin/data/staff' || props.currentRoute === '/admin/data/product' || props.currentRoute === '/admin/data/product/category' || props.currentRoute === '/admin/data/promotion') {
				return ['admin-data'];
			}
			else if(props.currentRoute === '/report/frame' || props.currentRoute === '/report/lens' || props.currentRoute === '/report/daily') {
				return ['admin-report'];
			}
		}
		else if(props.mode === 'sales' ) {
			if(props.currentRoute === '/sales/stock' || props.currentRoute === '/sales/stock/import' || props.currentRoute === '/sales/stock/export') {
				return ['sales-stock'];
			}
			else if(props.currentRoute === '/sales/order' || props.currentRoute === '/sales/order/create/new' || props.currentRoute === '/sales/order/create/old') {
				return ['sales-order'];
			}
			else if(props.currentRoute === '/report/frame' || props.currentRoute === '/report/lens' || props.currentRoute === '/report/daily') {
				return ['sales-report'];
			}
		}
		else if(props.mode === 'rxmall' ) {
			if(props.currentRoute === '/lab/data/branch' || props.currentRoute === '/lab/data/brand' || props.currentRoute === '/lab/data/staff' || props.currentRoute === '/lab/data/product' || props.currentRoute === '/lab/data/product/category' || props.currentRoute === '/lab/data/promotion') {
				return ['lab-data'];
			}
			if(props.currentRoute === '/lab/stock' || props.currentRoute === '/lab/stock/import' || props.currentRoute === '/lab/stock/export') {
				return ['lab-stock'];
			}
		}
		else {
			return [];
		}
	}

	return (
		<React.Fragment>
			<Layout.Sider
				className={`${styles.sidebarContainer} is-hidden-touch`}
				trigger={null}
				collapsible
				collapsed={ (localStorage.getItem('iwear_side_state') !== null) ? JSON.parse(localStorage.getItem('iwear_side_state')).collapse : isCollapse }
			>
				<div className={ styles.sidebarLogo }>
					<figure className={'image is-128x128'}>
						<img
							src={process.env.PUBLIC_URL + '/static/images/iWearLogo.svg'}
							alt={'iWear Logo'}
						/>
					</figure>
					<div
						className={ styles.sidebarCollapseContainer }
						onClick={ onToggle }
					>
						<i className={`fas fa-${(isCollapse) ? 'indent' : 'outdent'}`}></i>
					</div>
				</div>
				<Menu
					theme={'light'}
					mode={'inline'}
					multiple={false}
					selectedKeys={[ props.currentRoute ]}
					defaultOpenKeys={ keysResolver() }
					onClick={(menu) => {
						if(!(props.history.location.pathname === menu.key)) {
							props.history.push(menu.key);
						}
					}}
				>
					{ menuGenerator(props, isCollapse) }
				</Menu>
				{/*<aside className={'menu'}>
					<ul className={'menu-list'}>
						{ menuGenerator(props) }
					</ul>
				</aside>*/}
			</Layout.Sider>
		</React.Fragment>
	);
}

Sidebar.propTypes = {
	mode: PropTypes.string,
	currentRoute: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired
}

export default Sidebar;
