/**
 *	DataProduct component - Attribute modal
 */

import React from 'react';
import {
	Modal,
	Row,
	Col,
	Input,
	Button
} from 'antd';
import swal from 'sweetalert';
import PropTypes from 'prop-types';

const renderChoices = (state, props) => {
	return state.choices.map((_e, _i) => {
		return (
			<Row key={_i} style={{ marginBottom: '5px' }}>
				<Col>
					<Input
						autoFocus
						value={ _e }
						onChange={(evt) => {
							let _arr = [ ...state.choices ];
							_arr[_i] = evt.target.value;
							state.setChoices([ ..._arr ]);
						}}
					/>
				</Col>
			</Row>
		);
	});
}

const AttributeModal = (props) => {
	const [ name, setName ] = React.useState('');
	const [ choices, setChoices ] = React.useState([]);

	React.useEffect(() => {
		setName(props.attr);
		setChoices([ ...props.choices ]);
	}, [ props.attr, props.choices ]);

	const modalAddInput = () => {
		let _arr = [ ...choices ];
		_arr.push('');
		setChoices([ ..._arr ]);
	}

	const modalSubmit = () => {
		if(name === '') {
			swal({
				title: 'เพิ่มคุณสมบัติล้มเหลว',
				text: 'ไม่มีชื่อคุณสมบัติ',
				icon: 'error'
			});
		}
		else if(choices.length === 0) {
			swal({
				title: 'เพิ่มคุณสมบัติล้มเหลว',
				text: 'ไม่มีตัวเลือก',
				icon: 'error'
			});
		}
		else {
			props.submitModal({ name: name, choices: [ ...choices ] });
			setName('');
			setChoices([]);
		}
	}

	return (
		<Modal
			title={ props.title }
			visible={ props.visible }
			onOk={ modalSubmit }
			onCancel={ props.closeModal }
			okText={'ยืนยัน'}
			cancelText={'ยกเลิก'}
		>
			<Row>
				<Col>
					<label className={'custom-iwear-global-font-color'}>
						* คุณสมบัติ
					</label>
					<Input
						value={ name }
						onChange={(evt) => { setName(evt.target.value); }}
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col>
					<label className={'custom-iwear-global-font-color'}>
						* ตัวเลือก
					</label>
				</Col>
				<Col>
					<Button onClick={ modalAddInput }>
						<i className={'fas fa-plus custom-iwear-global-font-color'}></i>
					</Button>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			{ renderChoices({ choices, setChoices }, props) }
		</Modal>
	);
}

AttributeModal.defaultProps = {
	title: '',
	attr: '',
	choices: [],
	visible: false
}

AttributeModal.propTypes = {
	title: PropTypes.string,
	attr: PropTypes.string,
	choices: PropTypes.array,
	visible: PropTypes.bool,
	submitModal: PropTypes.func.isRequired,
	closeModal: PropTypes.func.isRequired,
}

export default AttributeModal;
