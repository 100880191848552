/**
 *	iWear - Order Detail screen
 */

import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import {
	Radio,
	Input,
	InputNumber,
	Row,
	Col,
	Button
} from 'antd';

import Breadcrumb from '../../../../components/breadcrumb';
import IWearDropdown from '../../../../components/dropdown';
import CustomerChannelSelect from '../../../../components/select-customer-channel';
import EyeMeasureTableData from '../../../../components/eye-measure-table';

// import ClaimProductModal from './components/ClaimProductModal';
import styles from './styles.module.css';

const renderTable = (props) => {
	return (
		<div className={'custom-iwear-global-table-container'}>
			<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-left-th'}>
							ลำดับที่
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							รหัสสินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							สินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							จำนวน
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ราคา
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-right-th'}>
							เพิ่มเติม
						</th>
					</tr>
				</thead>
				<tbody>
					{ renderTableData(props) }
				</tbody>
			</table>
		</div>
	)
}

const renderTableData = (props) => {
	if(props.isLoading) {
		return (
			<tr>
				<td colSpan={6} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* กำลังโหลด... */}
				</td>
			</tr>
		);
	}
	else if(!props.isLoading && props.tableData.length === 0) {
		return (
			<tr>
				<td colSpan={6} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* ไม่มีข้อมูล */}
				</td>
			</tr>
		);
	}

	return props.tableData.map((_e, _i) => {
		return (
			<tr key={_i}>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered  custom-iwear-global-table-bottom-left-td'}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.order }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.item_code }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.name }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.amount }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					<NumberFormat
						thousandSeparator
						displayType={'text'}
						value={ _e.price }
					/>
				</td>
				<td
					className={'has-text-centered  custom-iwear-global-table-bottom-right-td'}
					style={{ verticalAlign: 'middle' }}
				>
					<Button disabled={ true }>
						<i className={'fas fa-ellipsis-v custom-iwear-global-table-text-color'}></i>
					</Button>
				</td>
			</tr>
		);
	});
}

const renderOldEyeMeasureTable = (props) => {
	return (
		<EyeMeasureTableData
			type={'old'}
			tableName={'ค่าสายตาเก่า'}
			data={ props.oldMeasureData }
			setData={() => {}}
			disabled={true}
		/>
	);
}

const renderFirstSection = (props) => {
	return (
		<React.Fragment>
			<Row>
				<Col>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						1. ข้อมูลลูกค้า
					</span>
				</Col>
			</Row>
			<div style={{ margin: '20px' }}></div>

			<Row>
				<Col span={12}>
					<Row type={'flex'} align={'bottom'} justify={'center'}>
						<Col span={12}>
							<label className={'custom-iwear-global-font-color'}>
								* เบอร์โทรศัพท์
							</label>
							<Input
								disabled
								className={'custom-iwear-global-font-color'}
								value={ props.tel }
								maxLength={13}
								onChange={(evt) => {}}
							/>
						</Col>
						<Col span={2}></Col>
						<Col span={10}>
							{/*<Button disabled>
								<i className={'fas fa-search'}></i>&nbsp;
								<span className={'custom-iwear-global-font-color'}>
									ค้นหา
								</span>
							</Button>*/}
						</Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row>
						<Col span={12}>
							<label className={'custom-iwear-global-font-color'}>
								ชื่อ-นามสกุล
							</label>
							<Input
								disabled
								value={ props.name }
								className={'custom-iwear-global-font-color'}
							/>
						</Col>
						<Col span={2}></Col>
						<Col span={10}>
							<label className={'custom-iwear-global-font-color'}>
								เพศ
							</label><br />
							<Radio.Group
								defaultValue={ props.gender }
								buttonStyle={'solid'}
							>
								<Radio.Button disabled value={'male'}>
									<span className={'custom-iwear-global-font-color'}>ชาย</span>
								</Radio.Button>
								<Radio.Button disabled value={'female'}>
									<span className={'custom-iwear-global-font-color'}>หญิง</span>
								</Radio.Button>
							</Radio.Group>
						</Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row>
						<Col span={12}>
							<label className={'custom-iwear-global-font-color'}>
								วันเกิด
							</label><br/>
							<Input
								disabled
								className={'custom-iwear-global-font-color'}
								value={ props.birthdate }
							/>
						</Col>
						<Col span={2}></Col>
						<Col span={10}>
							<label className={'custom-iwear-global-font-color'}>
								อายุ
							</label><br />
							<InputNumber
								disabled
								className={'custom-iwear-global-font-color'}
								value={ props.age }
								min={1}
							/>
						</Col>
					</Row>
				</Col>
				<Col span={12}>
					<Row gutter={8}>
						<Col span={8}>
							<label className={'custom-iwear-global-font-color'}>
								* ช่องทางลูกค้า
							</label>
							<CustomerChannelSelect
								disabled isFull
								selected={ props.customerChannel }
							/>
						</Col>
						<Col span={8}>
							<label className={'custom-iwear-global-font-color'}>
								จังหวัด
							</label>
							<IWearDropdown
								isFull
								dropdownStyle={{ disabled: true }}
								displayValue={ props.province }
								choices={[]}
								onSelect={(selectedVal) => {}}
							/>
						</Col>
						<Col span={8}>
							<label className={'custom-iwear-global-font-color'}>
								เขต
							</label>
							<IWearDropdown
								isFull
								dropdownStyle={{ disabled: true }}
								displayValue={ props.addrZone }
								choices={[]}
								onSelect={(selectedVal) => {}}
							/>
						</Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row>
						<Col>
							<label className={'custom-iwear-global-font-color'}>
								ข้อมูลเพิ่มเติม
							</label>
							<Input.TextArea
								disabled
								value={ props.extInfo }
								className={'custom-iwear-global-font-color'}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
}

const renderSecondSection = (props) => {
	return (
		<React.Fragment>
			<Row>
				<Col>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						2. รายการสินค้า
					</span>
				</Col>
			</Row>
			<div style={{ margin: '20px' }}></div>
			<Row>
				<Col>
					{ renderTable(props) }
					<Row>
						<Col span={16}></Col>
						<Col span={8} style={{ textAlign: 'right' }}>
							{/*<ClaimProductModal
								originData={ props.tableData }
								productData={[ ...props.productData ]}
								isVisible={ props.claimModalVisible }
								setIsVisible={ props.setClaimModalVisible }
							/>*/}
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
}

const renderThirdSection = (props) => {
	return (
		<React.Fragment>
			<Row>
				<Col>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						3. การตรวจวัดสายตา
					</span>
				</Col>
			</Row>
			<div style={{ margin: '20px' }}></div>

			<Row>
				<Col span={8}>
					<div className={'custom-iwear-customer-create-inspector-wrapper'}>
						<span style={{ minWidth: '100px'}}>ผู้ตรวจวัดสายตา</span>&nbsp;&nbsp;
						<IWearDropdown
							isFull
							dropdownStyle={{ disabled: true }}
							displayValue={ props.inspector }
							choices={[]}
							onSelect={(selectedVal) => {}}
						/>
					</div>
				</Col>
				<Col span={8}></Col>
				<Col span={8}></Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col span={12}>
					{ renderOldEyeMeasureTable(props) }
				</Col>
				<Col span={12}></Col>
			</Row>
		</React.Fragment>
	);
}

const renderFourthSection = (props) => {
	return (
		<React.Fragment>
			<Row>
				<Col>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						4. โปรโมชั่น
					</span>
				</Col>
			</Row>
			<div style={{ margin: '20px' }}></div>

			<Row>
				<Col>
					<div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
						<div className={'control'}>
							{ renderPromoList(props) }
						</div>
					</div>
				</Col>
			</Row>
			<div style={{ marginBottom: '30px' }}></div>

			<Row>
				<Col>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						5. ส่วนลด
					</span>
				</Col>
			</Row>
			<div style={{ margin: '20px' }}></div>

			<Row>
				<Col>
					<label className={'custom-iwear-global-font-color'}>
						<InputNumber
							disabled
							className={'custom-iwear-global-font-color'}
							value={ props.promoValue }
							min={0}
							onChange={(val) => {
								if(!Number.isNaN(Number.parseInt(val, 10))) {
									props.setPromoValue(Number.parseInt(val, 10));
								}
							}}
						/>
						&nbsp;บาท
					</label>
				</Col>
			</Row>
		</React.Fragment>
	);
}

const renderPromoList = (props) => {
	return props.promoData.map((_e, _i) => {
		return (
			<React.Fragment key={_i}>
				<label className={'radio custom-iwear-global-font-color'}>
					<input
						readOnly
						disabled
						checked={(props.promoType === _e.code)}
						type={'radio'}
						name={'promo'}
						onClick={() => {
							props.setPromoType(_e.code);
						}}
					/>&nbsp;&nbsp;
					{ _e.name }
				</label>
				<br/>
			</React.Fragment>
		);
	});
}

const renderSummary = (props) => {
	return (
		<div className={ styles.orderSummaryContainer }>
			<Row gutter={8} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={18}>

					<Row type={'flex'} align={'middle'} justify={'center'}>
						<Col span={8}>
							<span className={'is-size-6 custom-iwear-global-font-color'}>
								วันส่งมอบสินค้า (วันนัดหมาย)
							</span><br/>
							<Input
								disabled
								className={'custom-iwear-global-font-color'}
								value={ props.deliverDate }
							/>
						</Col>
						<Col span={8}>
							<Row type={'flex'} align={'middle'} justify={'center'}>
								<Col span={8}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										ยอดรวม
									</span>
								</Col>
								<Col span={8} style={{ textAlign: 'right' }}>
									<span className={'is-size-5 custom-iwear-global-font-color'}>
										<NumberFormat
											thousandSeparator
											displayType={'text'}
											value={ Number.parseFloat(props.priceSum).toFixed(2) }
										/>
									</span>
								</Col>
								<Col span={2} style={{ textAlign: 'right' }}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										&nbsp;บาท
									</span>
								</Col>
							</Row>
						</Col>
						<Col span={8}>
							<Row type={'flex'} align={'middle'} justify={'center'}>
								<Col span={10}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										ชำระเงิน
									</span>
								</Col>
								<Col span={8} style={{ textAlign: 'right' }}>
									<InputNumber
										disabled
										className={'is-size-5 custom-iwear-global-font-color'}
										value={ props.payment }
										min={0}
										onChange={(val) => {
											if(!Number.isNaN(Number.parseInt(val))) {
												props.setEarnest(Number.parseInt(val));
											}
										}}
									/>
								</Col>
								<Col span={2} style={{ textAlign: 'right' }}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										&nbsp;บาท
									</span>
								</Col>
							</Row>
						</Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row type={'flex'} align={'middle'} justify={'center'}>
						<Col span={8}>
							<span className={'is-size-6 custom-iwear-global-font-color'}>
								ชำระโดย
							</span><br/>
							<IWearDropdown
								isFull
								dropdownStyle={{ disabled: true }}
								displayValue={ props.paymentType.toUpperCase() }
								choices={[ 'บัตรเครดิต', 'จ่ายผ่อน', 'เงินสด', 'โอนผ่าน Application' ]}
								onSelect={(selectedVal) => {}}
							/>
						</Col>
						<Col span={8}>
							<Row type={'flex'} align={'middle'} justify={'center'}>
								<Col span={8}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										ส่วนลด
									</span>
								</Col>
								<Col span={8} style={{ textAlign: 'right' }}>
									<span className={'is-size-5 custom-iwear-global-font-color'}>
										<NumberFormat
											thousandSeparator
											displayType={'text'}
											value={ Number.parseFloat(props.promoValue).toFixed(2) }
										/>
									</span>
								</Col>
								<Col span={2} style={{ textAlign: 'right' }}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										&nbsp;บาท
									</span>
								</Col>
							</Row>
						</Col>
						<Col span={8}>
							<Row type={'flex'} align={'middle'} justify={'center'}>
								<Col span={10}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										รับเงิน
									</span>
								</Col>
								<Col span={8} style={{ textAlign: 'right' }}>
									<InputNumber
										disabled
										className={'is-size-5 custom-iwear-global-font-color'}
										value={ props.moneyReceive }
										min={0}
										onChange={(val) => {}}
									/>
								</Col>
								<Col span={2} style={{ textAlign: 'right' }}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										&nbsp;บาท
									</span>
								</Col>
							</Row>
						</Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row type={'flex'} align={'middle'} justify={'center'}>
						<Col span={8}>
							<span className={'is-size-6 custom-iwear-global-font-color'}>
								หมายเหตุ
							</span><br/>
							<Input
								disabled
								value={ props.remark }
							/>
						</Col>
						<Col span={8}>
							<Row type={'flex'} align={'middle'} justify={'center'}>
								<Col span={8}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										VAT (7%)
									</span>
								</Col>
								<Col span={8} style={{ textAlign: 'right' }}>
									<span className={'is-size-5 custom-iwear-global-font-color'}>
										<NumberFormat
											thousandSeparator
											displayType={'text'}
											value={ Number.parseFloat(`${ props.vatValue || 0 }`).toFixed(2) }
										/>
									</span>
								</Col>
								<Col span={2} style={{ textAlign: 'right' }}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										&nbsp;บาท
									</span>
								</Col>
							</Row>
							<Row type={'flex'} align={'middle'} justify={'center'}>
								<Col span={8}>
									<span className={' is-size-6 custom-iwear-global-font-color'}>
										ราคาสุทธิ
									</span>
								</Col>
								<Col span={8} style={{ textAlign: 'right' }}>
									<span className={'is-size-5 custom-iwear-global-font-color'}>
										<NumberFormat
											thousandSeparator
											displayType={'text'}
											value={ Number.parseFloat(props.priceSum - props.promoValue + props.vatValue).toFixed(2) }
										/>
									</span>
								</Col>
								<Col span={2} style={{ textAlign: 'right' }}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										&nbsp;บาท
									</span>
								</Col>
							</Row>
						</Col>
						<Col span={8}>
							<Row type={'flex'} align={'middle'} justify={'center'}>
								<Col span={10}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										ยอดชำระคงค้าง
									</span>
								</Col>
								<Col span={8} style={{ textAlign: 'right' }}>
									<span className={'is-size-5 custom-iwear-global-font-color'}>
										<NumberFormat
											thousandSeparator
											displayType={'text'}
											value={ props.priceSum - props.payment - props.promoValue + props.vatValue }
										/>
									</span>
								</Col>
								<Col span={2} style={{ textAlign: 'right' }}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										&nbsp;บาท
									</span>
								</Col>
							</Row>
						</Col>
					</Row>

				</Col>
				<Col span={6} style={{ height: '185px' }}>
					<Button
						type={'primary'} block disabled
						style={{ height: '100%' }}
						onClick={ props.onClickPurchase }
					>
						<span className={'is-size-3'}>
							สั่งซื้อ
						</span>
					</Button>
				</Col>
			</Row>
		</div>
	)
}

const renderSectors = (props) => {
	return (
		<div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>
			{
				(props.isLoading)
				? <span>กำลังโหลด</span>
				: (
					<React.Fragment>
						<Row>
							<Col>
								{ renderFirstSection(props) }
							</Col>
						</Row>
						<div style={{ marginBottom: '30px' }}></div>
						<Row>
							<Col>
								{ renderSecondSection(props) }
							</Col>
						</Row>
						<div style={{ marginBottom: '30px' }}></div>
						<Row>
							<Col>
								{ renderThirdSection(props) }
							</Col>
						</Row>
						<div style={{ marginBottom: '30px' }}></div>
						<Row>
							<Col>
								{ renderFourthSection(props) }
							</Col>
						</Row>
					</React.Fragment>
				)
			}
		</div>
	);
}

const renderContent = (props) => {
	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
			<div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>
				<Row>
					<Col>
						<div className={'custom-iwear-global-table-option-panel'}>
							<Row>
								<Col span={12}>
									<span
										className={'custom-iwear-global-font-color is-size-5'}
										style={{ textDecorationLine: 'underline' }}
									>
										รายละเอียดการสั่งซื้อ
									</span>
								</Col>
								<Col span={12} style={{ textAlign: 'right' }}>
									<span className={'custom-iwear-global-font-color is-size-3'}>
										ราคารวม&nbsp;
										<NumberFormat
											thousandSeparator
											displayType={'text'}
											value={ Number.parseFloat(props.priceSum).toFixed(2) }
										/>
										&nbsp;บาท
									</span>
								</Col>
							</Row>
							<Row>
								<Col span={12}></Col>
								<Col span={12} style={{ textAlign: 'right' }}>
									<span className={'custom-iwear-global-font-color is-size-3'}>
										{ (!props.isLoading) && 'วันที่ทำรายการ:' }&nbsp;
										{ (!props.isLoading) && props.createdDate }
									</span>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						{ renderSectors(props) }
					</Col>
				</Row>
			</div>
		</div>
	);
}

const OrderDetail = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'การสั่งซื้อ', 'รายการ', 'ดูรายละเอียด' ]} withGoBack={true} onClickGoBack={ props.onClickGoBack } />
			{ renderContent(props) }
			{ renderSummary(props) }
		</div>
	);
}

OrderDetail.propTypes = {
	tel: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	age: PropTypes.number.isRequired,
	gender: PropTypes.string.isRequired,
	birthdate: PropTypes.string.isRequired,
	customerChannel: PropTypes.array.isRequired,
	addrZone: PropTypes.string.isRequired,
	province: PropTypes.string.isRequired,
	extInfo: PropTypes.string.isRequired,
	tableData: PropTypes.array.isRequired,
	productData: PropTypes.array.isRequired,
	oldMeasureData: PropTypes.array.isRequired,
	newMeasureData: PropTypes.array.isRequired,
	inspector: PropTypes.string.isRequired,
	claimModalVisible: PropTypes.bool.isRequired,
	measureModalVisible: PropTypes.bool.isRequired,
	promoData: PropTypes.array.isRequired,
	promoType: PropTypes.string.isRequired,
	promoValue: PropTypes.number.isRequired,
	priceSum: PropTypes.number.isRequired,
	deliverDate: PropTypes.string.isRequired,
	remark: PropTypes.string.isRequired,
	payment: PropTypes.number.isRequired,
	paymentType: PropTypes.string.isRequired,
	moneyReceive: PropTypes.number.isRequired,
	isLoading: PropTypes.bool.isRequired,

	onClickGoBack: PropTypes.func.isRequired,

	globalReducer: PropTypes.object.isRequired
}

export default OrderDetail;
