/**
 *	iWear - Environment variables
 */

export const APP_NAME = 'iWear';
export const API_ENDPOINT = 'https://api.iwearsystem.com/v1';

export const SITE_VERSION = '1.8.3';

export const ROLE = {
	admin: 'MANAGER',
	sales: 'STAFF',
	rxmall: 'RXMALL',
	any: 'any'
};

export const ITEM_STATUS = [
	'สินค้าปกติ',
	'สินค้าชำรุด',
	'สินค้าสูญหาย'
];

export const BRANCH_LIST = [
	'ทุกสาขา',
	'ปั้ม ปตท.มาลัยออยล์',
	'ปั้ม ปตท. ถนนแพรกษา',
	'ปั้ม ปตท.เพชรพันปีลำลูกกาคลอง 4',
	'ศูนย์การค้า The Bright พระราม 2',
	'หมู่บ้านสัมมากร',
	'The Street รัชดา ชั้น 1'
];
