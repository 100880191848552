/**
 *	iWear - Sales sector
 */

import React from 'react';
import PropTypes from 'prop-types';

const SalesSector = (props) => {
	React.useEffect(() => {
		props.history.push('/sales/dashboard');
	}, [ props.history ]);

	return (
		<React.Fragment />
	);
}

SalesSector.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default SalesSector;
