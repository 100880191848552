/**
 *	Stock List - Model
 */

import PropTypes from 'prop-types';

export const ContainerPropTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export const ContentPropTypes = {
	tableData: PropTypes.array.isRequired,
	totalData: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	totalPage: PropTypes.number.isRequired,
	searchKey: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,

	setTableData: PropTypes.func.isRequired,
	setTotalData: PropTypes.func.isRequired,
	setPage: PropTypes.func.isRequired,
	setPageSize: PropTypes.func.isRequired,
	setTotalPage: PropTypes.func.isRequired,
	setSearchKey: PropTypes.func.isRequired,
	setIsLoading: PropTypes.func.isRequired,

	onClickViewDetail: PropTypes.func.isRequired,
	onClickImport: PropTypes.func.isRequired,
	onClickExport: PropTypes.func.isRequired,
	onClickFileExport: PropTypes.func.isRequired
}

export const Actions = {
	SET_BRANCH_NAME: "SET_BRANCH_NAME",
	SET_TABLE_DATA: "SET_TABLE_DATA",
	SET_TOTAL_DATA: "SET_TOTAL_DATA",
	SET_FRAME_COUNT: "SET_FRAME_COUNT",
	SET_LENS_COUNT: "SET_LENS_COUNT",
	SET_PAGE: "SET_PAGE",
	SET_PAGE_SIZE: "SET_PAGE_SIZE",
	SET_TOTAL_PAGE: "SET_TOTAL_PAGE",
	SET_SEARCH_KEY: "SET_SEARCH_KEY",
	SET_IS_LOADING: "SET_IS_LOADING",
	SET_IS_EXPORTING: "SET_IS_EXPORTING"
}
