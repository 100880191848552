/**
 *	iWear - Ant Design Dropdown Wrapper
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
	Dropdown,
	Menu,
	Input,
	Button
} from 'antd';

const IWearDropdown = (props) => {
	const [ filterText, setFilterText ] = React.useState('');
	const [ choices, setChoices ] = React.useState([]);

	React.useEffect(() => {
		//
	}, []);

	return (
		<Dropdown
			{...props.dropdownStyle}
			overlay={() => renderDropdownMenu({ filterText, choices, setFilterText, setChoices }, props)}
			trigger={['click']}
		>
			<Button block={ (props.isFull) } size={ props.size } style={{...props.styleJSX}}>
				<span className={'custom-iwear-global-font-color'}>
					{ props.displayValue }
				</span>
				<div style={{ position: 'absolute', right: 10, top: 6 }}>
					<i className={'fas fa-angle-down custom-iwear-order-create-button-dropdown-icon custom-iwear-global-font-color'}></i>
				</div>
			</Button>
		</Dropdown>
	);
}

const renderDropdownMenu = (state, props) => {
	return (
		<Menu style={{ maxHeight: '400px', overflowY: 'auto' }}>
			{
				(props.isFilterable) && (
					<div style={{ display: 'flex', padding: '5px 12px' }}>
						{/*<Input.Search
				      		enterButton
				      		size={'large'}
				      		onSearch={(value) => {
								setChoices([]);
								setFilterText(value);

								let _arr = [];
								_arr = props.choices.filter((filt) => {
									return filt.toLowerCase().includes(value.toLowerCase());
								});
								setChoices([ ..._arr ]);
							}}
					    />*/}
						<Input
							allowClear={ true }
							onChange={(evt) => {
								// console.log('DropdownFilter', evt.target.value);
								state.setChoices([]);
								state.setFilterText(evt.target.value);

								let _arr = [];
								_arr = props.choices.filter((filt) => {
									return filt.toLowerCase().includes(evt.target.value.toLowerCase());
								});
								state.setChoices([ ..._arr ]);
							}}
						/>
					</div>
				)
			}
			{ renderChoices(state.filterText, state.choices, props) }
		</Menu>
	)
}

const renderChoices = (filterText, choices, props) => {
	if(filterText !== '') {
		if(choices.length === 0) {
			return (
				<Menu.Item key={'NoData-State'}>
					<span className={'custom-iwear-global-font-color'}>
						ไม่มีข้อมูล
					</span>
				</Menu.Item>
			);
		}

		return choices.map((_e, _i) => {
			if(_e) {
				return (
					<Menu.Item key={`${_e} #${_i}`}>
						{/* eslint-disable-next-line */}
						<a onClick={() => { props.onSelect(_e, _i); }}>
							<span className={'custom-iwear-global-font-color'}>
								{ _e }
							</span>
						</a>
					</Menu.Item>
				);
			}
			else { return null; }
		});
	}
	else if(props.choices.length === 0) {
		return (
			<Menu.Item key={'NoData-Props'}>
				<span className={'custom-iwear-global-font-color'}>
					ไม่มีข้อมูล
				</span>
			</Menu.Item>
		);
	}
	else {
		return props.choices.map((_e, _i) => {
			if(_e) {
				return (
					<Menu.Item key={_i}>
						{/* eslint-disable-next-line */}
						<a onClick={() => { props.onSelect(_e, _i); }}>
							<span className={'custom-iwear-global-font-color'}>
								{ _e }
							</span>
						</a>
					</Menu.Item>
				);
			}
			else { return null; }
		});
	}
}

IWearDropdown.defaultProps = {
	size: 'default',
	isFull: false,
	isFilterable: false,
	styleJSX: {},
	dropdownStyle: {}
}

IWearDropdown.propTypes = {
	displayValue: PropTypes.string.isRequired,
	choices: PropTypes.array.isRequired,
	onSelect: PropTypes.func.isRequired,

	size: PropTypes.string,
	isFull: PropTypes.bool,
	isFilterable: PropTypes.bool,
	styleJSX: PropTypes.object,
	dropdownStyle: PropTypes.object
}

export default IWearDropdown;
