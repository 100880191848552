/**
 *	iWear - RxMall sector
 */

import React from 'react';
import PropTypes from 'prop-types';

const RxMallSector = (props) => {
	React.useEffect(() => {
		props.history.push('/lab/order');
	}, [ props.history ])

	return (
		<React.Fragment />
	);
}

RxMallSector.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default RxMallSector
