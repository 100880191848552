/**
 *	Dashboard - Model
 */

import PropTypes from 'prop-types';

export const ContainerPropTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export const ContentPropTypes = {
	todayOrder: PropTypes.number.isRequired,
	framePeriodSale: PropTypes.number.isRequired,
	lensPeriodSale: PropTypes.number.isRequired,
	totalSale: PropTypes.number.isRequired,
	todaySale: PropTypes.number.isRequired,
	periodSale: PropTypes.number.isRequired,
	filterDate: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,

	setFilterDate: PropTypes.func.isRequired,

	globalReducer: PropTypes.object.isRequired
}

export const Actions = {
	SET_TODAY_ORDER: "SET_TODAY_ORDER",
	SET_PERIOD_ORDER: "SET_PERIOD_ORDER",
	SET_FRAME_PERIOD_SALE: "SET_FRAME_PERIOD_SALE",
	SET_LENS_PERIOD_SALE: "SET_LENS_PERIOD_SALE",
	SET_TODAY_SALE: "SET_TODAY_SALE",
	SET_MONTH_SALE: "SET_MONTH_SALE",
	SET_TOTAL_SALE: "SET_TOTAL_SALE",
	SET_PERIOD_SALE: "SET_PERIOD_SALE",
	SET_FILTER_DATE: "SET_FILTER_DATE",
	SET_IS_LOADING: "SET_IS_LOADING"
}
