/**
 *	iWear - DataBrand Container
 *
 *		@using React Hook
 */

import React from 'react';
import { Layout } from 'antd';
import swal from 'sweetalert';
import PropTypes from 'prop-types';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../environments';
import WebCookie from '../../../../lib/web-cookie';
import AdminDataBrand from './screen';

const AdminDataBrandContainer = (props) => {
	const [ tableData, setTableData ] = React.useState([]);
	const [ totalData, setTotalData ] = React.useState(0);
	const [ page, setPage ] = React.useState(1);
	const [ pageSize, setPageSize ] = React.useState(10);
	const [ totalPage, setTotalPage ] = React.useState(1);
	const [ searchKey, setSearchKey ] = React.useState('');
	const [ isLoading, setIsLoading ] = React.useState(false);
	const [ isExporting, setIsExporting ] = React.useState(false);

	const [ modalData, setModalData ] = React.useState({ id: 0, name: '' });
	const [ addModalVisible, setAddModalVisible ] = React.useState(false);
	const [ editModalVisible, setEditModalVisible ] = React.useState(false);
	const [ removeModalVisible, setRemoveModalVisible ] = React.useState(false);

	const [ fetchTrigger, setFetchTrigger ] = React.useState(0);

	React.useEffect(() => {
		fetchData(
			{ page, pageSize, searchKey },
			{ setTableData, setTotalData, setTotalPage, setIsLoading }
		);
	}, [ page, pageSize, searchKey, setTableData, setTotalData, setTotalPage, setIsLoading, fetchTrigger ]);

	const fetchData = async (state, localDispatch) => {
		localDispatch.setIsLoading(true);

		let res = await fetch(`${API_ENDPOINT}/product-attribute-values?attribute_id=1&limit=${state.pageSize}&offset=${(state.page - 1) * state.pageSize}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		let json = await res.json();
		localDispatch.setTableData([ ...json.list ]);
		localDispatch.setTotalData(json.count || json.list.length);
		localDispatch.setTotalPage(json.total_pages || 1);

		localDispatch.setIsLoading(false);
	}

	const reFetch = async () => {
		if(page > 1) { setPage(1); }
		else { setFetchTrigger(fetchTrigger + 1); }
	}

	const onClickAdd = () => {
		setModalData({ id: 0, name: '' });
		setAddModalVisible(true);
	}

	const onClickEdit = (data) => {
		setModalData({ id: data.id, name: data.name });
		setEditModalVisible(true);
	}

	const onClickRemove = (data) => {
		setModalData({ id: data.id, name: data.name });
		setRemoveModalVisible(true);
	}

	const addModalOnConfirm = async () => {
		setIsLoading(true);

		let res = await fetch(`${API_ENDPOINT}/product-attribute-values`, {
			method: 'POST',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				attribute_id: 1,
				name: modalData.name
			})
		});

		let json = await res.json();
		console.log(json);
		if(res.status === 200) {
			swal({
				title: 'เพิ่มแบรนด์สำเร็จ',
				icon: 'success'
			}).then((val) => {
				setAddModalVisible(false);
				reFetch();
			})
		}
		else {
			swal({
				title: 'เพิ่มแบรนด์ล้มเหลว',
				text: 'ข้อมูลไม่ครบถ้วน หรือ มีข้อผิดพลาดบางอย่าง',
				icon: 'warning'
			});
		}

		setIsLoading(false);
	}

	const editModalOnConfirm = async () => {
		setIsLoading(true);

		let res = await fetch(`${API_ENDPOINT}/product-attribute-values/${encodeURIComponent(modalData.id)}`, {
			method: 'PATCH',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				attribute_id: 1,
				name: modalData.name
			})
		});

		let json = await res.json();
		console.log(json);
		if(res.status === 200) {
			swal({
				title: 'แก้ไขแบรนด์ค้าสำเร็จ',
				icon: 'success'
			}).then((val) => {
				setEditModalVisible(false);
				reFetch();
			})
		}
		else {
			swal({
				title: 'แก้ไขแบรนด์ค้าล้มเหลว',
				text: 'ข้อมูลไม่ครบถ้วน หรือ มีข้อผิดพลาดบางอย่าง',
				icon: 'warning'
			});
		}

		setIsLoading(false);
	}

	const removeModalOnConfirm = async () => {
		setIsLoading(true);

		let res = await fetch(`${API_ENDPOINT}/product-attribute-values/${encodeURIComponent(modalData.id)}`, {
			method: 'DELETE',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				soft_delete: true
			})
		});

		let json = await res.json();
		console.log(json);
		if(res.status === 200) {
			swal({
				title: 'ลบแบรนด์ค้าสำเร็จ',
				icon: 'success'
			}).then((val) => {
				setRemoveModalVisible(false);
				reFetch();
			})
		}
		else {
			swal({
				title: 'ลบแบรนด์ค้าล้มเหลว',
				text: 'ข้อมูลไม่ครบถ้วน หรือ มีข้อผิดพลาดบางอย่าง',
				icon: 'warning'
			});
		}

		setIsLoading(false);
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/data/brand'} history={ props.history } />
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/data/brand'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<AdminDataBrand
						dispatcher={({ type, value }) => { setIsExporting(value) }}
						tableData={ tableData }
						totalData={ totalData }
						page={ page }
						pageSize={ pageSize }
						totalPage={ totalPage }
						searchKey={ searchKey }
						isLoading={ isLoading }
						isExporting={ isExporting }

						modalData={ modalData }
						addModalVisible={ addModalVisible }
						editModalVisible={ editModalVisible }
						removeModalVisible={ removeModalVisible }

						setTableData={ setTableData }
						setTotalData={ setTotalData }
						setPage={ setPage }
						setPageSize={ setPageSize }
						setTotalPage={ setTotalPage }
						setSearchKey={ setSearchKey }
						setIsLoading={ setIsLoading }
						setIsExporting={ setIsExporting }

						setModalData={ setModalData }
						setAddModalVisible={ setAddModalVisible }
						setEditModalVisible={ setEditModalVisible }
						setRemoveModalVisible={ setRemoveModalVisible }

						onClickAdd={ onClickAdd }
						onClickEdit={ onClickEdit }
						onClickRemove={ onClickRemove }
						addModalOnConfirm={ addModalOnConfirm }
						editModalOnConfirm={ editModalOnConfirm }
						removeModalOnConfirm={ removeModalOnConfirm }
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

AdminDataBrandContainer.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default AdminDataBrandContainer;
