/**
 *	iWear - Stock Import Inventor Manual screen
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
	Row,
	Col,
	Button,
	InputNumber
} from 'antd';

import Breadcrumb from '../../../../components/breadcrumb';
import TableFooter from '../../../../components/table-footer';

const StockImportInventoryManual = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'คลังสินค้า', 'สินค้านำเข้า', 'กำหนดเอง' ]} withGoBack onClickGoBack={ props.onClickGoBack } />
			{ renderContent(props) }
		</div>
	);
}

const renderContent = (props) => {
	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
			<Row>
				<Col span={24}>
					{ renderOptions(props) }
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					{ renderTable(props) }
				</Col>
			</Row>
			<Row type={'flex'} align={'middle'} justify={'end'}>
				<Col style={{ paddingBottom: '10px', paddingRight: '20px' }}>
					<Button onClick={ props.onClickConfirm }>
						<span className={'custom-iwear-global-font-color'}>
							เพิ่มลงคลังสินค้า
						</span>
					</Button>
				</Col>
			</Row>
		</div>
	)
}

const renderOptions = (props) => {
	return (
		<div className={'custom-iwear-global-table-option-panel'}>
			<Row>
				<Col span={24}>
					<span
						className={'custom-iwear-global-font-color is-size-5'}
						style={{ textDecorationLine: 'underline' }}
					>
						รับสินค้าเข้าใหม่
					</span>
				</Col>
			</Row>
		</div>
	);
}

const renderTable = (props) => {
	return (
		<div className={'custom-iwear-global-table-container'}>
			<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-left-th'}>
							ลำดับ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							รหัสสินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							สินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							จำนวน
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-right-th'}>
							เพิ่มเติม
						</th>
					</tr>
				</thead>
				<tbody>
					{ renderTableData(props) }
					{ renderTableFooter(props) }
				</tbody>
			</table>
		</div>
	);
}

const renderTableData = (props) => {
	if(props.isLoading) {
		return null;
	}
	else if(!props.isLoading && props.tableData.length === 0) {
		return null;
	}

	return props.tableData.map((_e, _i) => {
		if(_i === props.editIdx) {
			return (
				<tr key={_i}>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.order }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.code }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.name }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						<InputNumber
							value={ props.tableData[_i].amount }
							min={1}
							onChange={(val) => {
								let _arr = [ ...props.tableData ];
								_arr[_i].amount = val;
								props.onChangeTableData([ ..._arr ]);
							}}
						/>
					</td>
					<td
						className={'has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						<Button onClick={ props.onClickFinishEditing }>
							<span>
								เสร็จ
							</span>
						</Button>
					</td>
				</tr>
			);
		}
		else {
			return (
				<tr key={_i}>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.order }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.code }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.name }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.amount }
					</td>
					<td
						className={'has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						<Button onClick={() => { props.onEditData(_i); }}>
							<span>
								แก้ไข
							</span>
						</Button>
						&nbsp;
						<Button onClick={() => { props.onDeleteData(_i); }}>
							<span>
								ลบ
							</span>
						</Button>
					</td>
				</tr>
			);
		}
	});
}

const renderTableFooter = (props) => {
	return (
		<TableFooter.Stock
			seq={ props.tableData.length + 1 }

			qrValue={ props.productCode }
			qrSuggestion={[ ...props.suggestionDataProductCode ]}
			onChangeQR={ props.onChangeQR }
			onKeyEnterQR={ props.onScan }
			onSelectQR={(valueId, valueObj) => { props.onSelectProductCode(valueId); }}

			productValue={ props.productName }
			productSuggestion={[ ...props.suggestionDataProductName ]}
			onChangeProduct={ props.onChangeProduct }
			onSelectProduct={(valueId, valueObj) => { props.onSelectProductName(valueId); }}

			quantityValue={ props.productAmount }
			onChangeQuantity={ props.onChangeQuantity }

			priceValue={() => {}}
			onChangePrice={(val) => {}}

			onAddData={ props.onAddData }
		/>
	);
}

StockImportInventoryManual.propTypes = {
	productID: PropTypes.string.isRequired,
	productName: PropTypes.string.isRequired,
	productCode: PropTypes.string.isRequired,
	productAmount: PropTypes.number.isRequired,
	branch: PropTypes.string.isRequired,
	branchList: PropTypes.array.isRequired,
	tableData: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,
	suggestionDataProductCode: PropTypes.array.isRequired,
	suggestionDataProductName: PropTypes.array.isRequired,

	onChangeTableData: PropTypes.func.isRequired,
	onChangeQR: PropTypes.func.isRequired,
	onChangeProduct: PropTypes.func.isRequired,
	onChangeQuantity: PropTypes.func.isRequired,

	onScan: PropTypes.func.isRequired,
	onSelectProductCode: PropTypes.func.isRequired,
	onSelectProductName: PropTypes.func.isRequired,
	onAddData: PropTypes.func.isRequired,
	onClickConfirm: PropTypes.func.isRequired,
	onClickGoBack: PropTypes.func.isRequired,

	globalReducer: PropTypes.object.isRequired
}

export default StockImportInventoryManual;
