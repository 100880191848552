/**
 *	iWear - DataProduct screen
 */

import React from 'react';
import NumberFormat from 'react-number-format';
import {
	Row,
	Col,
	Button,
	Input,
	Pagination
} from 'antd';
import XDate from 'xdate';
import PropTypes from 'prop-types';

import Breadcrumb from '../../../../components/breadcrumb';
import IWearDropdown from '../../../../components/dropdown';
import ExcelButton from '../../../../components/excel-button';
import PromoModal from './components/PromoModal';

const addPromoModal = (props) => {
	return (
		<PromoModal
			title={'เพิ่มโปรโมชั่น'}
			visible={ props.addModalVisible }
			modalType={'ADD'}
			code={ props.modalCode }
			name={ props.modalName }
			expDate={ props.modalExpDate }
			onClickConfirm={ props.onClickAdd }
			onClickCancel={() => {
				props.setAddModalVisible(false);
				props.setEditIdx(-1);
				props.setModalCode('');
				props.setModalName('')
				props.setModalExpDate({ value: null, dateString: '' });
			}}
			onChangeCode={(evt) => {
				props.setModalCode(evt.target.value);
			}}
			onChangeName={(evt) => {
				props.setModalName(evt.target.value);
			}}
			onChangeExpDate={(data) => {
				props.setModalExpDate({ value: data.value, dateString: data.dateString });
			}}
		/>
	);
}

const editPromoModal = (props) => {
	return (
		<PromoModal
			title={'แก้ไขโปรโมชั่น'}
			visible={ props.editModalVisible }
			modalType={'EDIT'}
			code={ props.modalCode }
			name={ props.modalName }
			expDate={ props.modalExpDate }
			onClickConfirm={ props.onClickEdit }
			onClickCancel={() => {
				props.setEditModalVisible(false);
				props.setEditIdx(-1);
				props.setModalCode('');
				props.setModalName('')
				props.setModalExpDate({ value: null, dateString: '' });
			}}
			onChangeCode={(evt) => {
				props.setModalCode(evt.target.value);
			}}
			onChangeName={(evt) => {
				props.setModalName(evt.target.value);
			}}
			onChangeExpDate={(data) => {
				props.setModalExpDate({ value: data.value, dateString: data.dateString });
			}}
		/>
	);
}

const rmPromoModal = (props) => {
	return (
		<PromoModal
			title={'ลบโปรโมชั่น'}
			visible={ props.removeModalVisible }
			modalType={'REMOVE'}
			code={ props.modalCode }
			name={ props.modalName }
			expDate={ props.modalExpDate }
			onClickConfirm={ props.onClickRemove }
			onClickCancel={() => {
				props.setRemoveModalVisible(false);
				props.setEditIdx(-1);
				props.setModalCode('');
				props.setModalName('')
				props.setModalExpDate({ value: null, dateString: '' });
			}}
			onChangeCode={(evt) => {}}
			onChangeName={(evt) => {}}
			onChangeExpDate={(data) => {}}
		/>
	);
}

const renderOptions = (props) => {
	return (
		<div className={'custom-iwear-global-table-option-panel'}>
			<Row gutter={16} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={12}>
					<span className={'custom-iwear-global-font-color has-text-weight-bold is-size-5'}>
						ผลลัพธ์ข้อมูล&nbsp;
						<NumberFormat
							thousandSeparator
							displayType={'text'}
							value={ props.totalData }
						/>
						&nbsp;ข้อมูล
					</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						ค้นหา
					</span>&nbsp;&nbsp;&nbsp;
					<Input.Search
						enterButton
						placeholder={'Search'}
						allowClear={true}
						style={{ maxWidth: '70%' }}
						onSearch={(value) => {
							props.setPage(1);
							props.setSearchKey(value);
						}}
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row gutter={16} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={12}>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						แสดง
					</span>&nbsp;&nbsp;&nbsp;
					<IWearDropdown
						styleJSX={{ minWidth: '80px', paddingRight: '20px' }}
						displayValue={ String(props.pageSize) }
						choices={[ 10, 20, 50, 100 ]}
						onSelect={(selectedVal) => {
							props.setPage(1);
							props.setPageSize(Number.parseInt(selectedVal, 10));
						}}
					/>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<Button onClick={() => { props.setAddModalVisible(true); }}>
						<i className={'fas fa-plus custom-iwear-global-font-color'}></i>&nbsp;
						<span className={'custom-iwear-global-font-color'}>เพิ่ม</span>
					</Button>&nbsp;
					<ExcelButton
						modalTitle={'โปรโมชั่น'}
						exportType={'data_promotion'}
						dispatcher={ props.dispatcher }
						isLoading={ props.isExporting }

						branchName={''}
					/>
				</Col>
			</Row>
		</div>
	);
}

const renderTable = (props) => {
	return (
		<div className={'custom-iwear-global-table-container'}>
			<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ลำดับ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							รหัสโปรโมชั่น
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ขื่อโปรโมชั่น
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							วันที่สิ้นสุด
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							เพิ่มเติม
						</th>
					</tr>
				</thead>
				<tbody>
					{ renderTableData(props) }
				</tbody>
			</table>
		</div>
	);
}

const renderTableData = (props) => {
	if(props.isLoading) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* กำลังโหลด... */}
				</td>
			</tr>
		);
	}
	else if(!props.isLoading && props.tableData.length === 0) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* ไม่มีข้อมูล */}
				</td>
			</tr>
		);
	}

	return props.tableData.map((_e, _i) => {
		return (
			<tr key={_i} className={'custom-iwear-global-hover-cursor-default'}>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _i + 1 + ((props.page - 1) * props.pageSize) }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.code }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle', maxWidth: '350px' }}
				>
					{ _e.name }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ XDate(_e.expire_date).toString('dd/MM/yyyy') }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					<Button
						onClick={() => {
							props.setEditIdx(_e.promotion_id);
							props.setModalCode(_e.code);
							props.setModalName(_e.name);
							props.setModalExpDate({ value: null, dateString: _e.expire_date });
							props.setEditModalVisible(true);
						}}
					>
						<i className={'fas fa-edit'}></i>
					</Button>
					&nbsp;
					<Button
						onClick={() => {
							props.setEditIdx(_e.promotion_id);
							props.setModalCode(_e.code);
							props.setModalName(_e.name);
							props.setModalExpDate({ value: null, dateString: _e.expire_date });
							props.setRemoveModalVisible(true);
						}}
					>
						<i className={'fas fa-trash'}></i>
					</Button>
				</td>
			</tr>
		);
	});
}

const renderContent = (props) => {
	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
			<Row>
				<Col span={24}>
						{ renderOptions(props) }
				</Col>
			</Row>
			<Row>
				<Col span={24}>
						{ renderTable(props) }
				</Col>
			</Row>
			<Row type={'flex'} align={'middle'} justify={'end'}>
				<Col style={{ paddingBottom: '10px' }}>
					<Pagination
						className={'custom-iwear-global-table-pagination-container is-pulled-right'}
						current={ props.page || 1 }
						pageSize={ props.pageSize || 10 }
						total={ props.totalPage * props.pageSize }
						onChange={(page, pageSize) => {
							props.setPage(page);
						}}
					/>
				</Col>
			</Row>
		</div>
	)
}

const AdminDataPromotion = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'การจัดการข้อมูล', 'โปรโมชั่น' ]} />
			{ renderContent(props) }
			{ addPromoModal(props) }
			{ editPromoModal(props) }
			{ rmPromoModal(props) }
		</div>
	);
}

AdminDataPromotion.propTypes = {
	tableData: PropTypes.array.isRequired,
	totalData: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	totalPage: PropTypes.number.isRequired,
	searchKey: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,

	modalCode: PropTypes.string.isRequired,
	modalName: PropTypes.string.isRequired,
	modalExpDate: PropTypes.object.isRequired,
	addModalVisible: PropTypes.bool.isRequired,
	editModalVisible: PropTypes.bool.isRequired,

	setTableData: PropTypes.func.isRequired,
	setTotalData: PropTypes.func.isRequired,
	setPage: PropTypes.func.isRequired,
	setPageSize: PropTypes.func.isRequired,
	setTotalPage: PropTypes.func.isRequired,
	setSearchKey: PropTypes.func.isRequired,
	setIsLoading: PropTypes.func.isRequired,

	setModalCode: PropTypes.func.isRequired,
	setModalName: PropTypes.func.isRequired,
	setModalExpDate: PropTypes.func.isRequired,
	setAddModalVisible: PropTypes.func.isRequired,
	setEditModalVisible: PropTypes.func.isRequired,

	onClickAdd: PropTypes.func.isRequired,
	onClickEdit: PropTypes.func.isRequired,
	onClickFileExport: PropTypes.func.isRequired
}

export default AdminDataPromotion;
