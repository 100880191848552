/**
 *	Authentication Reducer
 */

import Actions from './actions';

const auth = (state, action) => {
	switch(action.type) {
		case Actions.SIGN_IN:
			return {
				...state,
				userInfo: action.userData,
				branchInfo: action.branchData,
				token: action.token
			}
		case Actions.SIGN_OUT:
			return {
				...state,
				userInfo: {
					full_name: '',
					userId: 0,
					permission: ''
				},
				branchInfo: {
					branch_id: 0,
					branch_name: '',
					district: '',
					location: '',
					branch_code: ''
				},
				token: ''
			}
		default:
			return { ...state }
	}
}

export default auth;
