/**
 *	To PDF component
 */

import Order from './order';
import Stock from './stock';

const toPDF = {
	Order,
	Stock
}

export default toPDF;
