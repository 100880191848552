/**
 *	iWear - Data Product Edit screen
 */

import React from 'react';
import {
	Row,
	Col,
	Button,
	Input,
	InputNumber
} from 'antd';
import PropTypes from 'prop-types';

import Breadcrumb from '../../../../../components/breadcrumb';
// import IWearDropdown from '../../../../../../components/dropdown';

const EditDataProduct = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'การจัดการข้อมูล', 'สินค้า', 'แก้ไขข้อมูล' ]} withGoBack onClickGoBack={ props.onClickGoBack } />
			{ renderContent(props) }
		</div>
	);
}

const renderContent = (props) => {
	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
			<div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>
				<Row>
					<Col span={24}>
						{ renderOptions(props) }
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						{ renderFields(props) }
					</Col>
				</Row>
				<div style={{ margin: '10px' }}></div>
				<Row type={'flex'} align={'middle'} justify={'end'}>
					<Col style={{ textAlign: 'right' }}>
						<Button onClick={ props.onClickSave }>
							<span className={'custom-iwear-global-font-color'}>
								บันทึกข้อมูล
							</span>
						</Button>
					</Col>
				</Row>
			</div>
		</div>
	);
}

const renderOptions = (props) => {
	return (
		<div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
			<Row type={'flex'} align={'middle'} justify={'space-between'}>
				<Col>
					<span
						className={'custom-iwear-global-font-color is-size-5'}
						style={{ textDecorationLine: 'underline' }}
					>
						แก้ไขข้อมูลสินค้า
					</span>
				</Col>
				<Col>
					<Button onClick={ props.onClickSave }>
						<span className={'custom-iwear-global-font-color'}>
							บันทึกข้อมูล
						</span>
					</Button>
				</Col>
			</Row>
		</div>
	);
}

const renderFields = (props) => {
	return (
		<React.Fragment>
			<Row>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						* ชื่อสินค้า
					</label>
					<Input
						value={ props.name || '' }
						onChange={(evt) => {
							props.setName(evt.target.value);
						}}
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row gutter={16}>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						รูปภาพสินค้า
					</label>
					<div className={'file is-centered is-boxed is-success has-name is-fullwidth'}>
						<label className={'file-label'}>
							<input
								className={'file-input'}
								type={'file'}
								onChange={(evt) => {
									let _file = evt.target.files[0];
									if(_file) {
										let _reader = new FileReader();
										_reader.readAsDataURL(_file);
										_reader.onload = (_e) => {
											props.setPicture({
												preview: _e.target.result,
												file: _file
											})
										}
									}
								}}
							/>
							<span className={'file-cta'}>
								<span className={'file-icon'}>
									<i className={'fas fa-upload'}></i>
								</span>
								<span className={'file-label has-text-centered'}>
									อัพโหลด (ขนาดที่แนะนำ 4:3)
								</span>
							</span>
						</label>
					</div>
				</Col>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						ตัวอย่างภาพ
					</label>
					{
						(props.picture.preview !== '') && (
							<div className={'custom-iwear-admin-data-image-preview-box'}>
								<figure className={'image'}>
									{/* eslint-disable-next-line */}
									<img
										src={ props.picture.preview }
										alt={'No image'}
									/>
								</figure>
							</div>
						)
					}
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row>
				{/*<div className={'column'}>
					<label className={'custom-iwear-global-font-color'}>
						* ชนิดสินค้า
					</label>
					<IWearDropdown
						isFull
						displayValue={ props.productType }
						choices={ props.productTypeList }
						onSelect={(selectedVal) => {
							props.setFrameType('');
							props.setLensType('');
							props.setProductType(selectedVal);
						}}
					/>
				</div>*/}
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						* ราคาสินค้า
					</label>
					<InputNumber
						style={{ width: '100%' }}
						value={ props.price || 0 }
						min={0}
						onChange={(value) => {
							props.setPrice(value);
						}}
					/>
				</Col>
			</Row>
		</React.Fragment>
	);
}

EditDataProduct.propTypes = {
	name: PropTypes.string.isRequired,
	picture: PropTypes.object.isRequired,
	price: PropTypes.number.isRequired,

	branch: PropTypes.string.isRequired,
	productType: PropTypes.string.isRequired,
	brand: PropTypes.string.isRequired,

	setName: PropTypes.func.isRequired,
	setPicture: PropTypes.func.isRequired,
	setPrice: PropTypes.func.isRequired,

	setBranch: PropTypes.func.isRequired,
	setProductType: PropTypes.func.isRequired,
	setBrand: PropTypes.func.isRequired,

	onClickGoBack: PropTypes.func.isRequired,
	onClickSave: PropTypes.func.isRequired
}

export default EditDataProduct;
