/**
 *	Stock Import Bill - Content
 */

import React from 'react';
import {
	Row,
	Col,
	Button,
	Input
} from 'antd';

import Breadcrumb from '../../../../components/breadcrumb';
import IWearDropdown from '../../../../components/dropdown';
import { ITEM_STATUS } from '../../../../environments';

import { ContentPropTypes } from './stock-import-bill.model';

const Content = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'คลังสินค้า', 'สินค้านำเข้า', 'โดยใบรับสินค้า' ]} withGoBack onClickGoBack={ props.onClickGoBack } />
			{ renderContent(props) }
		</div>
	);
}

const renderContent = (props) => {
	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
			<Row>
				<Col span={24}>
					{ renderOptions(props) }
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					<div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
						<span className={'custom-iwear-global-font-color'}>{/* Exchange ID */}
							1. สแกนคิวอาร์โค้ดของใบรับสินค้า
						</span><br/><br/>
						<Input
							autoFocus
							style={{ maxWidth: '40%' }}
							value={ props.barcode }
							placeholder={'สแกน QR กดที่นี่'}
							addonAfter={<i className={'fas fa-qrcode custom-iwear-global-font-color'}></i>}
							onChange={ props.onChangeBarcode }
							onPressEnter={(evt) => { props.onScan(props.barcode); }}
						/>
						<br/><br/>
					</div>
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					{ renderTable(props) }
				</Col>
			</Row>
			<Row type={'flex'} align={'middle'} justify={'end'}>
				<Col style={{ paddingBottom: '10px', paddingRight: '20px' }}>
					<Button onClick={ props.onClickConfirm }>
						<span className={'custom-iwear-global-font-color'}>
							ยืนยันการรับสินค้า
						</span>
					</Button>
				</Col>
			</Row>
		</div>
	)
}

const renderOptions = (props) => {
	return (
		<div className={'custom-iwear-global-table-option-panel'}>
			<Row>
				<Col span={24}>
					<span
						className={'custom-iwear-global-font-color is-size-5'}
						style={{ textDecorationLine: 'underline' }}
					>
						นำสินค้าเข้าโดยใบรับสินค้า
					</span>
				</Col>
			</Row>
		</div>
	);
}

const renderTable = (props) => {
	return (
		<div className={'custom-iwear-global-table-container'}>
			<span className={'custom-iwear-global-font-color'}>
				2. ตรวจสอบรายการสินค้านำเข้า
			</span><br/><br/>
			<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ลำดับ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							รหัสสินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ชื่อสินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ประเภท
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							จำนวน
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ตรวจสอบ
						</th>
					</tr>
				</thead>
				<tbody>
					{ renderTableData(props) }
				</tbody>
			</table>
		</div>
	);
}

const renderTableData = (props) => {
	if(props.isLoading) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* กำลังโหลด... */}
				</td>
			</tr>
		);
	}
	else if(!props.isLoading && props.tableData.length === 0) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* ไม่มีข้อมูล */}
				</td>
			</tr>
		);
	}

	return props.tableData.map((_e, _i) => {
		return (
			<tr key={_i}>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ _i + 1 }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.product_info.item_code }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.product_info.name }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.product_info.variant }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.quantity }
				</td>
				<td
					className={'has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{/*<button className={'button '}>
						<i className={'fas fa-ellipsis-v custom-iwear-global-table-text-color'}></i>
					</button>*/}
					<IWearDropdown
						isFull
						displayValue={ props.itemsStatus[_i] || '' }
						choices={[ ...ITEM_STATUS ]}
						onSelect={(selectedVal) => {
							let _arr = [ ...props.itemsStatus ];
							_arr[_i] = selectedVal;
							props.onChangeItemStatus([ ..._arr ]);
						}}
					/>
				</td>
			</tr>
		);
	});
}

Content.propTypes = ContentPropTypes;

export default Content;
