/**
 *	iWear - Bottom bar customization
 */

import React from 'react';
// import PropTypes from 'prop-types';
import {
	Layout
} from 'antd';

import { SITE_VERSION } from '../../environments';
import styles from './bottom-bar.module.css';

const BottomBar = () => {
	return (
		<Layout.Footer className={`${styles.bottombarContainer} has-text-centered`}>
			<span className={'custom-iwear-global-font-color'}>
				&copy; Copyright - Version {SITE_VERSION}
			</span>
		</Layout.Footer>
	)
}

BottomBar.propTypes = {
	//
}

export default BottomBar;
