/**
 *	iWear - Data Staff Add container
 */

import React from 'react';
import { Layout } from 'antd';
import swal from 'sweetalert';
import PropTypes from 'prop-types';

import TopBar from '../../../../../components/layout/header';
import Sidebar from '../../../../../components/layout/sider';
import BottomBar from '../../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../../environments';
import WebCookie from '../../../../../lib/web-cookie';
import EditDataStaff from './screen';

const EditDataStaffContainer = (props) => {
	const [ username, setUsername ] = React.useState('');
	const [ password, setPassword ] = React.useState('');
	const [ fullname, setFullname ] = React.useState('');
	const [ branch, setBranch ] = React.useState('');
	const [ permissionLevel, setPermissionLevel ] = React.useState('');
	const [ branchDisabled, setBranchDisabled ] = React.useState(false);

	const [ branchList, setBranchList ] = React.useState([]);

	React.useEffect(() => {
		fetchData(props.location.state.staffID);
	}, [ props.location.state.staffID ]);

	const fetchData = async (staffID) => {
		let res = await fetch(`${API_ENDPOINT}/staffs/${encodeURIComponent(staffID)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		let json = await res.json();
		setUsername(json.staff_info.username);
		setFullname(json.staff_info.full_name);
		setPermissionLevel(json.staff_info.permission || '');
		setBranch(json.branch_info.branch_name);
		setBranchDisabled((json.staff_info.permission.toUpperCase() === 'MANAGER') ? true : false);

		let res_branch = await fetch(`${API_ENDPOINT}/branches?limit=9999`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		let json_branch = await res_branch.json();

		let _arr = [];
		[ ...json_branch.list ].forEach((_e) => {
			_arr.push({ id: _e.branch_id, text: _e.branch_name });
		});

		setBranchList([ ..._arr ]);
	}

	const onClickGoBack = () => {
		props.history.goBack();
	}

	const onClickSave = async () => {
		if(fullname === '') {
			swal({
				title: 'เพิ่มพนักงานล้มเหลว',
				text: 'ไม่ระบุชื่อ-นามสกุล',
				icon: 'error'
			});
		}
		else if(permissionLevel === '') {
			swal({
				title: 'เพิ่มพนักงานล้มเหลว',
				text: 'ไม่ระบุสิทธิ์การเข้าถึง',
				icon: 'error'
			});
		}
		else if(branch === '') {
			swal({
				title: 'เพิ่มพนักงานล้มเหลว',
				text: 'ไม่ระบุสาขา',
				icon: 'error'
			});
		}
		else {
			let _branch = [ ...branchList ].find((_f) => { return _f.text === branch; }).id;

			let res = await fetch(`${API_ENDPOINT}/staffs/${encodeURIComponent(props.location.state.staffID)}`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					username: username,
					password: password,
					full_name: fullname,
					branch_id: _branch,
					permission: permissionLevel.toUpperCase() || 'MANAGER'
				})
			});

			let json = await res.json();
			console.log('CreateStaff', json);

			if(res.status === 200) {
				swal({
					title: 'แก้ไขพนักงานสำเร็จ',
					icon: 'success'
				}).then((val) => {
					props.history.goBack();
				});
			}
			else {
				swal({
					title: 'แก้ไขงานล้มเหลว',
					text: (json.msg) ? json.msg : 'ไม่ทราบสาเหตุ',
					icon: 'error'
				});
			}
		}
	}

	return (
		<Layout>
			<Sidebar mode={'rxmall'} currentRoute={'/lab/data/staff'} history={ props.history } />
			<Layout>
				<TopBar mode={'rxmall'} currentRoute={'/lab/data/staff'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<EditDataStaff
						username={ username }
						password={ password }
						fullname={ fullname }
						branch={ branch }
						permissionLevel={ permissionLevel }
						branchDisabled={ branchDisabled }

						branchList={ branchList }

						setUsername={ setUsername }
						setPassword={ setPassword }
						setFullname={ setFullname }
						setBranch={ setBranch }
						setPermissionLevel={ setPermissionLevel }
						setBranchDisabled={ setBranchDisabled }

						setBranchList={ setBranchList }

						onClickGoBack={ onClickGoBack }
						onClickSave={ onClickSave }
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

EditDataStaffContainer.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default EditDataStaffContainer;
