/**
 *	iWear - Appointment state management
 */

import React from 'react';
import Actions from './actions.json';

const currentDate = new Date();

const initState = {
	branchList: [],
	branchName: '',
	appointmentData: {},
	selectedDate: currentDate,
	isLoading: false,
	isExporting: false
}

const reducer = (state, action) => {
	switch(action.type) {
		case Actions.SET_BRANCH_LIST:
			return {
				...state,
				branchList: action.value
			}
		case Actions.SET_BRANCH_NAME:
			return {
				...state,
				branchName: action.value
			}
		case Actions.SET_APPOINTMENT_DATA:
			return {
				...state,
				appointmentData: action.value
			}
		case Actions.SET_SELECTED_DATE:
			return {
				...state,
				selectedDate: action.value
			}
		case Actions.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.value
			}
		case Actions.SET_IS_EXPORTING:
			return {
				...state,
				isExporting: action.value
			}
		default:
			return { ...state }
	}
}

const AppointmentReducer = () => {
	const [ state, dispatch ] = React.useReducer(reducer, initState);

	React.useEffect(() => {}, []);

	return {
		state,
		dispatch
	}
}

export default AppointmentReducer;
