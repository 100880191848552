/**
 *	iWear - Order list screen
 */

import React from 'react';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';
import XDate from 'xdate';
import {
	Empty,
	Spin,
	Row,
	Col,
	Input,
	Pagination
} from 'antd';

import Breadcrumb from '../../../../components/breadcrumb';
import IWearDropdown from '../../../../components/dropdown';
import ExcelButton from '../../../../components/excel-button';
import { phoneMaskText } from '../../../../functions';

const renderOptions = (props) => {
	let _branches = [];
	props.branchList.forEach((_e) => {
		_branches.push(_e.branch_name);
	});

	return (
		<div className={'custom-iwear-global-table-option-panel'}>
			<Row gutter={16} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={12}>
					<span className={'custom-iwear-global-font-color has-text-weight-bold is-size-5'}>
						ผลลัพธ์ข้อมูล&nbsp;
						<NumberFormat
							thousandSeparator
							displayType={'text'}
							value={ props.totalData }
						/>
						&nbsp;ข้อมูล
					</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						ค้นหา
					</span>&nbsp;
					<Input.Search
						enterButton
						placeholder={'Search'}
						allowClear={true}
						style={{ maxWidth: '60%' }}
						onSearch={ props.onChangeSearchKey }
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row gutter={16} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={12}>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						แสดง
					</span>&nbsp;&nbsp;&nbsp;
					<IWearDropdown
						styleJSX={{ minWidth: '80px', paddingRight: '20px' }}
						displayValue={ String(props.pageSize) }
						choices={[ 10, 20, 50, 100 ]}
						onSelect={ props.onChangePageSize }
					/>
				</Col>
				<Col span={12}>
					<Row gutter={8} type={'flex'} align={'middle'} justify={'end'}>
						<Col>
							<span className={'custom-iwear-global-font-color is-size-5'}>
								สาขา
							</span>&nbsp;
							<IWearDropdown
								styleJSX={{ minWidth: '100px', paddingRight: '25px' }}
								displayValue={ props.branchName || 'ทั้งหมด' }
								choices={[ 'ทั้งหมด', ..._branches ]}
								onSelect={ props.onChangeBranch }
							/>
						</Col>
						<Col>
							<ExcelButton
								modalTitle={'เลือกช่วงของวันที่สั่งซื้อ'}
								exportType={'order'}
								dispatcher={ props.dispatcher }
								isLoading={ props.isExporting }

								branchName={ (props.branchName !== 'ทั้งหมด') ? props.branchName : '' }
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
}

const renderTable = (props) => {
	return (
		<div className={'custom-iwear-global-table-container'}>
			<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-left-th'}>
							ลำดับ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							เลขที่สั่งซื้อ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ชื่อ-นามสกุลลูกค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							โทร
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							วันที่สั่ง
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							จำนวนเงินมัดจำ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							สถานะของการสั่งซื้อ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							สถานะของการจัดส่ง
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							สาขา
						</th>
					</tr>
				</thead>
				<tbody>
					{ renderTableData(props) }
				</tbody>
			</table>
		</div>
	);
}

const renderTableData = (props) => {
	if(props.isLoading) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					<Spin />
				</td>
			</tr>
		);
	}
	else if(!props.isLoading && props.tableData.length === 0) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>กรุณาเลือกสาขา</span>} />
				</td>
			</tr>
		);
	}

	return props.tableData.map((_e, _i) => {
		return (
			<tr key={_i} className={'custom-iwear-global-hover-cursor-pointer'}>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _i + 1 + ((props.page - 1) * props.pageSize) }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.order_code }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.customer_info.full_name }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ phoneMaskText(_e.customer_info.phone_number) }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ XDate(new Date(_e.receive_date)).toString('dd/MM/yyyy') }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.payment_info[ _e.payment_info.findIndex((_find) => { return _find.is_earnest === true; }) ].amount }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ itemStatusResolver(_e.status) }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					<IWearDropdown
						styleJSX={{ minWidth: '100px', paddingRight: '25px' }}
						dropdownStyle={{ disabled: _e.status === 'cancelled' }}
						displayValue={ (_e.status !== 'cancelled') ? preparationStatusResolver(_e.rxmall_status) : '-' }
						choices={[ 'สั่งซื้อแล้ว', 'กำลังดำเนินการ', 'กำลังส่งสินค้า', 'ส่งสินค้าเรียบร้อยแล้ว', 'กำลังส่งกลับไปแก้ไข', 'กำลังแก้ไข' ]}
						onSelect={(val) => { props.onChangePreparationStatus(val, _i, _e); }}
					/>
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ branchResolver(props.branchList, _e.branch_id) }
				</td>
			</tr>
		);
	});
}

const itemStatusResolver = (status) => {
	if(status === 'cancelled') { return 'ยกเลิกแล้ว'; }
	else if(status === 'paid') { return 'ชำระเงินแล้ว'; }
	else { return 'มัดจำแล้ว'; }
}

const preparationStatusResolver = (status) => {
	if(status === 'IN_PROGRESS') { return 'กำลังดำเนินการ'; }
	else if(status === 'SHIPPING') { return 'กำลังส่งสินค้า'; }
	else if(status === 'DONE') { return 'ส่งสินค้าเรียบร้อยแล้ว'; }
	else if(status === 'RETURNING') { return 'กำลังส่งกลับไปแก้ไข'; }
	else if(status === 'REVISE') { return 'กำลังแก้ไข'; }
	else { /* ORDERED */ return 'สั่งซื้อแล้ว'; }
}

const branchResolver = (branchList, branch_id) => {
	let [ _branch ] = branchList.filter((_f) => { return _f.branch_id === branch_id; });
	if(_branch) { return _branch.branch_name; }
	else { return '-'; }
}

const renderContent = (props) => {
	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
			<Row>
				<Col span={24}>
					{ renderOptions(props) }
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					{ renderTable(props) }
				</Col>
			</Row>
			<Row type={'flex'} align={'middle'} justify={'end'} style={{ paddingBottom: '10px' }}>
				<Col>
					<Pagination
						className={'custom-iwear-global-table-pagination-container is-pulled-right'}
						current={ props.page }
						pageSize={ props.pageSize }
						total={ props.totalPage }
						onChange={ props.onChangePage }
					/>
				</Col>
			</Row>
		</div>
	)
}

const OrderList = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'การสั่งซื้อ', 'รายการ' ]} />
			{ renderContent(props) }
		</div>
	);
}

OrderList.propTypes = {
	tableData: PropTypes.array.isRequired,
	totalData: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	totalPage: PropTypes.number.isRequired,
	searchKey: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,

	onChangePage: PropTypes.func.isRequired,
	onChangePageSize: PropTypes.func.isRequired,
	onChangeSearchKey: PropTypes.func.isRequired,
	onClickViewDetail: PropTypes.func.isRequired,

	globalReducer: PropTypes.object.isRequired
}

export default OrderList;
