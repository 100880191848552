/**
 *	Order Detail - Model
 */

import PropTypes from 'prop-types';

export const ContainerPropTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export const ContentPropTypes = {
	tel: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	age: PropTypes.number.isRequired,
	gender: PropTypes.string.isRequired,
	birthdate: PropTypes.string.isRequired,
	customerChannel: PropTypes.array.isRequired,
	addrZone: PropTypes.string.isRequired,
	province: PropTypes.string.isRequired,
	extInfo: PropTypes.string.isRequired,
	tableData: PropTypes.array.isRequired,
	productData: PropTypes.array.isRequired,
	includeVat: PropTypes.bool.isRequired,
  vatValue: PropTypes.number.isRequired,
	reqAssemble: PropTypes.bool.isRequired,
	oldMeasureData: PropTypes.array.isRequired,
	newMeasureData: PropTypes.array.isRequired,
	inspector: PropTypes.string.isRequired,
	promoData: PropTypes.array.isRequired,
	promoType: PropTypes.string.isRequired,
	promoValue: PropTypes.number.isRequired,
	priceSum: PropTypes.number.isRequired,
	deliverDate: PropTypes.string.isRequired,
	remark: PropTypes.string.isRequired,
	payment: PropTypes.number.isRequired,
	paymentType: PropTypes.string.isRequired,
	moneyReceive: PropTypes.number.isRequired,
	claimModalVisible: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,

	onChangeClaimModalVisible: PropTypes.func.isRequired,
	onChangeMeasureModalVisible: PropTypes.func.isRequired,

	onClickGoBack: PropTypes.func.isRequired,

	globalReducer: PropTypes.object.isRequired
}

export const Actions = {
	SET_TEL: "SET_TEL",
	SET_NAME: "SET_NAME",
	SET_AGE: "SET_AGE",
	SET_GENDER: "SET_GENDER",
	SET_BIRTHDATE: "SET_BIRTHDATE",
	SET_CUSTOMER_CHANNEL: "SET_CUSTOMER_CHANNEL",
	SET_ADDR_ZONE: "SET_ADDR_ZONE",
	SET_PROVINCE: "SET_PROVINCE",
	SET_EXT_INFO: "SET_EXT_INFO",
	SET_TABLE_DATA: "SET_TABLE_DATA",
	SET_PRODUCT_DATA: "SET_PRODUCT_DATA",
	SET_INCLUDE_VAT: "SET_INCLUDE_VAT",
  SET_VAT_VALUE: "SET_VAT_VALUE",
	SET_REQ_ASSEMBLE: "SET_REQ_ASSEMBLE",
	SET_OLD_MEASURE_DATA: "SET_OLD_MEASURE_DATA",
	SET_NEW_MEASURE_DATA: "SET_NEW_MEASURE_DATA",
	SET_EYE_HISTORY_DATA: "SET_EYE_HISTORY_DATA",
	SET_INSPECTOR: "SET_INSPECTOR",
	SET_CLAIM_MODAL_VISIBLE: "SET_CLAIM_MODAL_VISIBLE",
	SET_MEASURE_MODAL_VISIBLE: "SET_MEASURE_MODAL_VISIBLE",
	SET_PROMO_DATA: "SET_PROMO_DATA",
	SET_PROMO_TYPE: "SET_PROMO_TYPE",
	SET_PROMO_VALUE: "SET_PROMO_VALUE",
	SET_PRICE_SUM: "SET_PRICE_SUM",
	SET_CREATED_DATE: "SET_CREATED_DATE",
	SET_PICK_UP_DATE: "SET_PICK_UP_DATE",
	SET_DELIVER_DATE: "SET_DELIVER_DATE",
	SET_REMARK: "SET_REMARK",
	SET_PAYMENT: "SET_PAYMENT",
	SET_PAYMENT_TYPE: "SET_PAYMENT_TYPE",
	SET_MONEY_RECEIVE: "SET_MONEY_RECEIVE",
	SET_IS_LOADING: "SET_IS_LOADING"
}
