/**
 *	iWear - Data Product File Upload Container
 *
 *		@using React Hook
 */

import React from 'react'
import PropTypes from 'prop-types';
import { Layout } from 'antd';
import swal from 'sweetalert';

import TopBar from '../../../../../components/layout/header';
import Sidebar from '../../../../../components/layout/sider';
import BottomBar from '../../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../../environments';
import GlobalStore from '../../../../../store';
import LocalStore from './state';
import UploadDataProduct from './screen';

import Actions from './actions.json';

const DataProductUploadContainer = (props) => {
	const DataProductReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData(DataProductReducer.dispatch, state.auth.token);
	}, [ DataProductReducer.dispatch, state.auth.token ]);

	const fetchData = async (localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		// let res_attr = await fetch(`${API_ENDPOINT}/product-attribute`, {
		// 	method: 'GET',
		// 	headers: {
		// 		'Authorization': 'Bearer ' + authToken,
		// 		'Content-Type': 'application/json'
		// 	}
		// });
		//
		// let json_attr = await res_attr.json();

		// let res_type = await fetch(`${API_ENDPOINT}/product-attribute-values?attribute_id=3&limit=100`, {
		// 	method: 'GET',
		// 	headers: {
		// 		'Authorization': 'Bearer ' + authToken,
		// 		'Content-Type': 'application/json'
		// 	}
		// });
		//
		// let json_type = await res_type.json();
		//
		// localDispatch({ type: Actions.SET_PRODUCT_TYPE_LIST, value: [ ...json_type.list ] });
		// localDispatch({ type: Actions.SET_FRAME_TYPE_LIST, value: [ ...json_attr.frame_type ] });
		// localDispatch({ type: Actions.SET_LENS_TYPE_LIST, value: [ ...json_attr.lens_type ] });

		let res_branch = await fetch(`${API_ENDPOINT}/branches?limit=999`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json_branch = await res_branch.json();

		localDispatch({ type: Actions.SET_BRANCH_LIST, value: [ ...json_branch.list ] });

		localDispatch({ type: Actions.SET_PRODUCT_TYPE_LIST, value: [
			{ attribute_id: 2, name: 'กรอบแว่น' },
            { attribute_id: 4, name: 'เลนส์' },
            { attribute_id: 5, name: 'Accessory' },
			{ attribute_id: 6, name: 'กรอบพร้อมเลนส์' },
			{ attribute_id: 7, name: 'คอนแทคเลนส์' }
		] });

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onChangeFile = (info) => {
		DataProductReducer.dispatch({
			type: Actions.SET_FILE, value: {
				value: info.file.originFileObj,
				text: info.file.name
			}
		});
	}

	const onChangeBranch = (selectedVal) => {
		DataProductReducer.dispatch({ type: Actions.SET_BRANCH, value: selectedVal })
	}

	const onChangeProductType = (selectedVal) => {
		DataProductReducer.dispatch({ type: Actions.SET_FRAME_TYPE, value: '' });
		DataProductReducer.dispatch({ type: Actions.SET_LENS_TYPE, value: '' });
		DataProductReducer.dispatch({ type: Actions.SET_PRODUCT_TYPE, value: selectedVal });
	}

	const onChangeFrameType = (selectedVal) => {
		DataProductReducer.dispatch({ type: Actions.SET_FRAME_TYPE, value: selectedVal });
	}

	const onChangeLensType = (selectedVal) => {
		DataProductReducer.dispatch({ type: Actions.SET_LENS_TYPE, value: selectedVal });
	}

	const onClickConfirm = async () => {
		DataProductReducer.dispatch({ type: Actions.SET_IS_LOADING, value: true });

		if(DataProductReducer.state.branch === '') {
			swal({
				title: 'ไม่สามารถเพิ่มสินค้าได้',
				text: 'กรุณาเลือก สาขา',
				icon: 'error'
			});

			DataProductReducer.dispatch({ type: Actions.SET_IS_LOADING, value: false });
			return;
		}
		else if(DataProductReducer.state.productType === '') {
			swal({
				title: 'ไม่สามารถเพิ่มสินค้าได้',
				text: 'กรุณาเลือก ชนิดสินค้า',
				icon: 'error'
			});

			DataProductReducer.dispatch({ type: Actions.SET_IS_LOADING, value: false });
			return;
		}
		else if(DataProductReducer.state.file.value === null) {
			swal({
				title: 'ไม่สามารถเพิ่มสินค้าได้',
				text: 'กรุณาเลือก Excel ไฟล์',
				icon: 'error'
			});

			DataProductReducer.dispatch({ type: Actions.SET_IS_LOADING, value: false });
			return;
		}

		let [_attr] = DataProductReducer.state.productTypeList.filter((_f) => {
			return _f.name === DataProductReducer.state.productType;
		});

		let [ _branch ] = DataProductReducer.state.branchList.filter((_f) => { return _f.branch_name === DataProductReducer.state.branch; });

		let formData = new FormData();
		formData.append('excel', DataProductReducer.state.file.value);
		formData.append('attribute_id', _attr.attribute_id);
		formData.append('dest_branch_id', _branch.branch_id);

		let res = await fetch(`${API_ENDPOINT}/product-items/upload`, {
			method: 'POST',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token
			},
			body: formData
		});

		let json = await res.json();
		console.log('Upload', json);

		if(res.status === 200) {
			swal({
				title: 'สำเร็จ',
				text: 'ทำการอัพโหลดเสร็จเรียบร้อย',
				icon: 'success'
			}).then((val) => {
				DataProductReducer.dispatch({ type: Actions.SET_IS_LOADING, value: false });
				props.history.goBack();
			});
		}
		else {
			swal({
				title: 'ล้มเหลว',
				text: 'เกิดข้อผิดพลาดบางอย่าง',
				icon: 'error'
			}).then((val) => {
				DataProductReducer.dispatch({ type: Actions.SET_IS_LOADING, value: false });
			});
		}
	}

	const onClickGoBack = () => {
		props.history.goBack();
	}

	return (
		<Layout>
			<Sidebar mode={'rxmall'} currentRoute={'/lab/data/product'} history={ props.history } />
			<Layout>
				<TopBar mode={'rxmall'} currentRoute={'/lab/data/product'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<UploadDataProduct
						{ ...DataProductReducer.state }

						onChangeFile={ onChangeFile }
						onChangeBranch={ onChangeBranch }
						onChangeProductType={ onChangeProductType }
						onChangeFrameType={ onChangeFrameType }
						onChangeLensType={ onChangeLensType }
						onClickConfirm={ onClickConfirm }
						onClickGoBack={ onClickGoBack }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

DataProductUploadContainer.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default DataProductUploadContainer;
