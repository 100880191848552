/**
 *	Dashboard - Model
 */

import PropTypes from 'prop-types';

export const ContainerPropTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export const ContentPropTypes = {
	branch: PropTypes.string.isRequired,
	branchList: PropTypes.array.isRequired,
	filterDate: PropTypes.object.isRequired,
	totalSale: PropTypes.number.isRequired,
	monthSale: PropTypes.number.isRequired,
	todaySale: PropTypes.number.isRequired,
	todayOrder: PropTypes.number.isRequired,
	frameSale: PropTypes.object.isRequired,
	lensSale: PropTypes.object.isRequired,
	avgAge: PropTypes.number.isRequired,
	avgExpense: PropTypes.number.isRequired,
	customerRatio: PropTypes.object.isRequired,
	chartData: PropTypes.object.isRequired,
	isLoading: PropTypes.bool.isRequired,

	onChangeFilterDateRange: PropTypes.func.isRequired,

	globalReducer: PropTypes.object.isRequired
}

export const Actions = {
	SET_BRANCH: "SET_BRANCH",
	SET_BRANCH_LIST: "SET_BRANCH_LIST",
	SET_FILTER_DATE: "SET_FILTER_DATE",
	SET_TOTAL_SALE: "SET_TOTAL_SALE",
	SET_MONTH_SALE: "SET_MONTH_SALE",
	SET_TODAY_SALE: "SET_TODAY_SALE",
	SET_TODAY_ORDER: "SET_TODAY_ORDER",
	SET_FRAME_SALE: "SET_FRAME_SALE",
	SET_LENS_SALE: "SET_LENS_SALE",
	SET_AVG_AGE: "SET_AVG_AGE",
	SET_AVG_EXPENSE: "SET_AVG_EXPENSE",
	SET_CUSTOMER_RATIO: "SET_CUSTOMER_RATIO",
	SET_CHART_DATA: "SET_CHART_DATA",
	SET_IS_LOADING: "SET_IS_LOADING"
}
