/**
 *	iWear - DataStaff Container
 *
 *		@using React Hook
 */

import React from 'react';
import { Layout } from 'antd';
import swal from 'sweetalert';
import PropTypes from 'prop-types';

import TopBar from '../../../../../components/layout/header';
import Sidebar from '../../../../../components/layout/sider';
import BottomBar from '../../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../../environments';
import { downloadFromBuffer } from '../../../../../functions';
import GlobalStore from '../../../../../store';
import AdminDataStaff from './screen';

const AdminDataStaffContainer = (props) => {
	const [ branchList, setBranchList ] = React.useState([]);
	const [ branchName, setBranchName ] = React.useState('');
	const [ tableData, setTableData ] = React.useState([]);
	const [ totalData, setTotalData ] = React.useState(0);
	const [ page, setPage ] = React.useState(1);
	const [ pageSize, setPageSize ] = React.useState(10);
	const [ totalPage, setTotalPage ] = React.useState(1);
	const [ searchKey, setSearchKey ] = React.useState('');
	const [ isLoading, setIsLoading ] = React.useState(false);
	const [ isExporting, setIsExporting ] = React.useState(false);
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData(
			{ state, dispatch },
			{ page, pageSize, searchKey },
			{
				setBranchList, setBranchName, setTableData, setTotalData, setPage, setPageSize, setTotalPage, setSearchKey, setIsLoading, setIsExporting
			},
			branchName
		);
	}, [ state, dispatch, page, pageSize, searchKey, setBranchList, setBranchName, setTableData, setTotalData, setPage, setPageSize, setTotalPage, setSearchKey, setIsLoading, setIsExporting, branchName ]);

	const fetchData = async (globalReducer, localState, localDispatch, currentBranchName) => {
		localDispatch.setIsLoading(true);

		let res_branch_list = await fetch(`${API_ENDPOINT}/branches?limit=9999`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + globalReducer.state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let json_branch_list = await res_branch_list.json();
		localDispatch.setBranchList([ ...json_branch_list.list ]);

		if(currentBranchName && currentBranchName !== 'ทั้งหมด') {
			let res_branch = await fetch(`${API_ENDPOINT}/branches?branch_name=${encodeURIComponent(currentBranchName)}`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + globalReducer.state.auth.token,
					'Content-Type': 'application/json'
				}
			});

			let json_branch = await res_branch.json();
			let _branchID = json_branch.list[0].branch_id;

			let _url = `${API_ENDPOINT}/staffs
				?branch_id=${encodeURIComponent(_branchID)}
				&staff_name=${encodeURIComponent(localState.searchKey)}
				&limit=${localState.pageSize}
				&offset=${(localState.page - 1) * localState.pageSize}
			`;
			let res = await fetch(`${_url}`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + globalReducer.state.auth.token,
					'Content-Type': 'application/json'
				}
			});

			let json = await res.json();

			localDispatch.setTableData([ ...json.list ]);
			localDispatch.setTotalData(json.count);
			localDispatch.setTotalPage(json.total_pages || 1);
		}
		else {
			let _url = `${API_ENDPOINT}/staffs
				?staff_name=${encodeURIComponent(localState.searchKey)}
				&limit=${localState.pageSize}
				&offset=${(localState.page - 1) * localState.pageSize}
			`;
			let res = await fetch(`${_url}`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + globalReducer.state.auth.token,
					'Content-Type': 'application/json'
				}
			});

			let json = await res.json();

			localDispatch.setTableData([ ...json.list ]);
			localDispatch.setTotalData(json.count);
			localDispatch.setTotalPage(json.total_pages || 1);
		}

		localDispatch.setIsLoading(false);
	}

	const onChangeBranch = (selectedValue) => {
		setPage(1);
		setBranchName(`${selectedValue}`);
	}

	const onClickAdd = () => {
		props.history.push('/admin/data/staff/add');
	}

	const onClickEdit = (idx) => {
		props.history.push('/admin/data/staff/edit', { staffID: idx });
	}

	const onClickRemove = async (idx) => {
		let res = await fetch(`${API_ENDPOINT}/staffs/${idx}`, {
			method: 'DELETE',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let json = await res.json();
		console.log('Remove', json);

		if(res.status === 200) {
			swal({
				title: 'สำเร็จ',
				text: 'ทำการลบพนักงานเรียบร้อย',
				icon: 'success'
			}).then((val) => {
				window.location.reload();
			});
		}
		else {
			swal({
				title: 'ล้มเหลว',
				text: 'เกิดข้อผิดพลาดบางอย่าง',
				icon: 'error'
			}).then(() => {
				//
			});
		}
	}

	const onClickFileExport = async () => {
		setIsExporting(true);

		let [ _branch ] = branchList.filter((_f) => { return _f.branch_name === branchName; });

		let _url = '';

		if(_branch && _branch.branch_id) {
			_url = `
				${API_ENDPOINT}/staffs
				?branch_id=${_branch.branch_id}
				&staff_name=${searchKey}
				&is_report=true
			`;
		}
		else {
			_url = `
				${API_ENDPOINT}/staffs
				?staff_name=${searchKey}
				&is_report=true
			`;
		}

		let res = await fetch(`${_url}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let data = await res.json();

		if(res.status === 200) {
			let _timer = 5000 + (Number.parseInt(totalData / 5000, 10) * 1000);
			setTimeout(() => {
				downloadFromBuffer(data.report_path, 'staff_report.xlsx');
				setIsExporting(false);
			}, _timer);
		}
		else {
			setIsExporting(false);
			swal({
				title: 'เกิดข้อผิดพลาด',
				text: 'ไม่สามารถ export ได้ในขณะนี้',
				icon: 'error'
			});
		}
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/data/staff'} history={ props.history } />
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/data/staff'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<AdminDataStaff
						dispatcher={({ type, value }) => { setIsExporting(value) }}
						branchList={ branchList }
						branchName={ branchName }
						tableData={ tableData }
						totalData={ totalData }
						page={ page }
						pageSize={ pageSize }
						totalPage={ totalPage }
						searchKey={ searchKey }
						isLoading={ isLoading }
						isExporting={ isExporting }

						setBranchList={ setBranchList }
						setBranchName={ setBranchName }
						setTableData={ setTableData }
						setTotalData={ setTotalData }
						setPage={ setPage }
						setPageSize={ setPageSize }
						setTotalPage={ setTotalPage }
						setSearchKey={ setSearchKey }
						setIsLoading={ setIsLoading }
						setIsExporting={ setIsExporting }

						onChangeBranch={ onChangeBranch }
						onClickAdd={ onClickAdd }
						onClickEdit={ onClickEdit }
						onClickRemove={ onClickRemove }
						onClickFileExport={ onClickFileExport }
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

AdminDataStaffContainer.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default AdminDataStaffContainer;
