/**
 *	iWear - Admin sector
 */

import React from 'react';
import PropTypes from 'prop-types';

const AdminSector = (props) => {
	React.useEffect(() => {
		props.history.push('/admin/dashboard');
	}, [ props.history ]);

	return (
		<React.Fragment />
	);
}

AdminSector.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default AdminSector
