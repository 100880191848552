/**
 *	Customer Add - Container
 */

import React from 'react';
import {
	Layout
} from 'antd';
import swal from 'sweetalert';
import XDate from 'xdate';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import WebCookie from '../../../../lib/web-cookie';
import { API_ENDPOINT } from '../../../../environments';
import GlobalStore from '../../../../store';
import LocalStore from './customers-add.store';
import Content from './customers-add.content';
import { ContainerPropTypes, Actions } from './customers-add.model';

const Container = (props) => {
	const CustomerCreateReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData(CustomerCreateReducer.dispatch, state.auth.token);
	}, [ CustomerCreateReducer.dispatch, state.auth.token ]);

	const fetchData = async (localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		let res = await fetch(`${API_ENDPOINT}/staffs?branch_id=${encodeURIComponent(branchID)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json = await res.json();

		let _inspectors = [];
		json.list.forEach((_e) => { _inspectors.push({ id: _e.staff_info.staff_id, name: _e.staff_info.full_name }); });
		localDispatch({ type: Actions.SET_INSPECTOR_DATA, value: [ ..._inspectors ] });

		localDispatch({
			type: Actions.SET_OLD_MEASURE_DATA,
			value: [
				{ type: 'Sphere', leftValue: 0, rightValue: 0 }, // +-2000 step 25
				{ type: 'CYL', leftValue: 0, rightValue: 0 }, // +-2000 step 25
				{ type: 'AXIS', leftValue: 0, rightValue: 0 }, // 0~180 step 25
				{ type: 'ADD', leftValue: 0, rightValue: 0 }, // 0~2000 step 25
				{ type: 'DPD', leftValue: 0, rightValue: 0 }, // 0~2000 step 25
				{ type: 'NPD', leftValue: 0, rightValue: 0 }, // 0~2000 step 25
				{ type: 'SegHT', leftValue: 0, rightValue: 0 }, // 0~2000 step 25
				{ type: 'Prism', leftValue: 0, rightValue: 0 }, // 0~2000 step 25
				{ type: 'Mono-VA', leftValue: 0, rightValue: 0 }, // 0~2000 step 25
			]
		});

		localDispatch({
			type: Actions.SET_NEW_MEASURE_DATA,
			value: [
				{ type: 'Sphere', leftValue: 0, rightValue: 0 }, // +-2000 step 25
				{ type: 'CYL', leftValue: 0, rightValue: 0 }, // +-2000 step 25
				{ type: 'AXIS', leftValue: 0, rightValue: 0 }, // 0~180 step 25
				{ type: 'ADD', leftValue: 0, rightValue: 0 }, // 0~2000 step 25
				{ type: 'DPD', leftValue: 0, rightValue: 0 }, // 0~2000 step 25
				{ type: 'NPD', leftValue: 0, rightValue: 0 }, // 0~2000 step 25
				{ type: 'SegHT', leftValue: 0, rightValue: 0 }, // 0~2000 step 25
				{ type: 'Prism', leftValue: 0, rightValue: 0 }, // 0~2000 step 25
				{ type: 'Mono-VA', leftValue: 0, rightValue: 0 }, // 0~2000 step 25
			]
		});

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onChangeTel = (evt) => {
		if(evt.target.value.match(/^[\d+-]*$/g) !== null) {
			CustomerCreateReducer.dispatch({ type: Actions.SET_TEL, value: evt.target.value });
		}
		else {
			// CustomerCreateReducer.dispatch({ type: Actions.SET_TEL, value: '' });
			swal({
				title: 'เกิดข้อผิดพลาด',
				text: 'รูปแบบเบอร์โทรต้องเป็น ตัวเลข เครื่องหมายบวก หรือ เครื่องหมายลบ เท่านั้น',
				icon: 'warning'
			});
		}
	}

	const onChangeName = (evt) => {
		CustomerCreateReducer.dispatch({ type: Actions.SET_NAME, value: evt.target.value });
	}

	const onChangeGender = (evt) => {
		CustomerCreateReducer.dispatch({ type: Actions.SET_GENDER, value: evt.target.value });
	}

	const onChangeBirthdate = (dateObj, dateString) => {
		CustomerCreateReducer.dispatch({ type: Actions.SET_BIRTHDATE, value: dateObj._d });

		let _age = Number.parseInt(XDate(new Date()).toString('yyyy'), 10) - Number.parseInt(XDate(dateObj._d).toString('yyyy'), 10)
		CustomerCreateReducer.dispatch({ type: Actions.SET_AGE, value: _age });
	}

	const onChangeAge = (val) => {
		if(!Number.isNaN(Number.parseInt(val, 10))) {
			CustomerCreateReducer.dispatch({ type: Actions.SET_AGE, value: Number.parseInt(val, 10) });
		}
	}

	const onChangeExtInfo = (evt) => {
		CustomerCreateReducer.dispatch({ type: Actions.SET_EXT_INFO, value: evt.target.value });
	}

	const onChangeCustomerChannel = (value) => {
		CustomerCreateReducer.dispatch({ type: Actions.SET_CUSTOMER_CHANNEL, value: value });
	}

	const onChangeProvince = (selectedVal, selectedIndex) => {
		CustomerCreateReducer.dispatch({
			type: Actions.SET_PROVINCE,
			value: { index: selectedIndex, text: selectedVal }
		});
	}

	const onChangeAddrZone = (selectedVal) => {
		CustomerCreateReducer.dispatch({ type: Actions.SET_ADDR_ZONE, value: selectedVal });
	}

	const onChangeOldMeasureData = (value) => {
		CustomerCreateReducer.dispatch({
			type: Actions.SET_OLD_MEASURE_DATA,
			value: value
		});
	}

	const onChangeNewMeasureData = (value) => {
		CustomerCreateReducer.dispatch({
			type: Actions.SET_NEW_MEASURE_DATA,
			value: value
		});
	}

	const onClickSave = async () => {
		if(CustomerCreateReducer.state.tel === '' || CustomerCreateReducer.state.tel.length < 10) {
			swal({
				title: 'เพิ่มลูกค้าล้มเหลว',
				text: 'ข้อมูลเบอร์โทรศัพท์ไม่ถูกต้อง',
				icon: 'warning'
			});
		}
		else if(CustomerCreateReducer.state.name === '') {
			swal({
				title: 'เพิ่มลูกค้าล้มเหลว',
				text: 'ไม่มีชื่อลูกค้า',
				icon: 'warning'
			});
		}
		else if(CustomerCreateReducer.state.gender === '') {
			swal({
				title: 'เพิ่มลูกค้าล้มเหลว',
				text: 'ไม่เลือกเพศของลูกค้า',
				icon: 'warning'
			});
		}
		else if(CustomerCreateReducer.state.age === 0) {
			swal({
				title: 'เพิ่มลูกค้าล้มเหลว',
				text: 'ไม่ระบุอายุของลูกค้า',
				icon: 'warning'
			});
		}
		else {
			let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

			let postParams = {
				branch_id: branchID,
				full_name: CustomerCreateReducer.state.name,
				phone_number: CustomerCreateReducer.state.tel,
				age: CustomerCreateReducer.state.age,
				sex: CustomerCreateReducer.state.gender,
				description: CustomerCreateReducer.state.extInfo,
				customer_channel: CustomerCreateReducer.state.customerChannel.join('|')
			};

			if(CustomerCreateReducer.state.birthdate !== '') {
				postParams.birth_date = XDate(CustomerCreateReducer.state.birthdate).toString('yyyy-MM-dd');
			}

			if(CustomerCreateReducer.state.province.text !== '') {
				postParams.province = CustomerCreateReducer.state.province.text;
			}

			if(CustomerCreateReducer.state.addrZone !== '') {
				postParams.district = CustomerCreateReducer.state.addrZone;
			}

			let res = await fetch(`${API_ENDPOINT}/customers`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(postParams)
			});

			let json = await res.json();
			if(res.status === 200) {
				if(CustomerCreateReducer.state.inspector.id === -1 && CustomerCreateReducer.state.inspector.name === '') {
					swal({
						title: 'เพิ่มลูกค้าสำเร็จ',
						icon: 'success'
					}).then((value) => {
						props.history.push('/sales/customer');
					});
				}
				else {
					let res_eye = await fetch(`${API_ENDPOINT}/eye-inspector`, {
						method: 'POST',
						headers: {
							'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							staff_id: CustomerCreateReducer.state.inspector.id,
							customer_id: json.customer_id,
							is_latest: true,
							sphere_left: CustomerCreateReducer.state.newMeasureData[0].leftValue,
							sphere_right: CustomerCreateReducer.state.newMeasureData[0].rightValue,
							cyl_left: CustomerCreateReducer.state.newMeasureData[1].leftValue,
							cyl_right: CustomerCreateReducer.state.newMeasureData[1].rightValue,
							axis_right: CustomerCreateReducer.state.newMeasureData[2].leftValue,
							axis_left: CustomerCreateReducer.state.newMeasureData[2].rightValue,
							add_left: CustomerCreateReducer.state.newMeasureData[3].leftValue,
							add_right: CustomerCreateReducer.state.newMeasureData[3].rightValue,
							dpd_left: CustomerCreateReducer.state.newMeasureData[4].leftValue,
							dpd_right: CustomerCreateReducer.state.newMeasureData[4].rightValue,
							npd_left: CustomerCreateReducer.state.newMeasureData[5].leftValue,
							npd_right: CustomerCreateReducer.state.newMeasureData[5].rightValue,
							seg_ht_left: CustomerCreateReducer.state.newMeasureData[6].leftValue,
							seg_ht_right: CustomerCreateReducer.state.newMeasureData[6].rightValue,
							prism_left: CustomerCreateReducer.state.newMeasureData[7].leftValue,
							prism_right: CustomerCreateReducer.state.newMeasureData[7].rightValue,
							mono_va_left: CustomerCreateReducer.state.newMeasureData[8].leftValue,
							mono_va_right: CustomerCreateReducer.state.newMeasureData[8].rightValue
						})
					});

					let json_eye = await res_eye.json();

					if(json_eye.hasOwnProperty('staff_id')) {
						swal({
							title: 'เพิ่มลูกค้าสำเร็จ',
							icon: 'success'
						}).then((value) => {
							props.history.push('/sales/customer');
						});
					}
					else {
						swal({
							title: 'เพิ่มลูกค้าสำเร็จ แต่ไม่สามารถเพิ่มข้อมูลค่าสายตา',
							icon: 'success'
						}).then((value) => {
							props.history.push('/sales/customer');
						});
					}
				}
			}
			else if(json.msg === 'mobile phone is already exists') {
				swal({
					title: 'เพิ่มลูกค้าล้มเหลว',
					text: 'เบอร์โทรลูกค้าซ้ำกับลูกค้าท่านอื่น',
					icon: 'warning'
				});
			}
			else {
				swal({
					title: 'เพิ่มลูกค้าล้มเหลว',
					text: 'มีข้อผิดพลาดบางอย่าง',
					icon: 'warning'
				});
			}
		}
	}

	const onClickGoBack = () => {
		props.history.goBack();
	}

	return (
		<Layout>
			<Sidebar mode={'sales'} currentRoute={'/sales/customer'} history={ props.history } />
			<Layout>
				<TopBar mode={'sales'} currentRoute={'/sales/customer'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...CustomerCreateReducer.state }

						onChangeTel={ onChangeTel }
						onChangeName={ onChangeName }
						onChangeGender={ onChangeGender }
						onChangeBirthdate={ onChangeBirthdate }
						onChangeAge={ onChangeAge }
						onChangeCustomerChannel={ onChangeCustomerChannel }
						onChangeProvince={ onChangeProvince }
						onChangeAddrZone={ onChangeAddrZone }
						onChangeExtInfo={ onChangeExtInfo }
						onChangeOldMeasureData={ onChangeOldMeasureData }
						onChangeNewMeasureData={ onChangeNewMeasureData }
						onClickSave={ onClickSave }
						onClickGoBack={ onClickGoBack }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
