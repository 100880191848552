/**
 *	Order List - Model
 */

import PropTypes from 'prop-types';

export const ContainerPropTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export const ContentPropTypes = {
	tableData: PropTypes.array.isRequired,
	totalData: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	totalPage: PropTypes.number.isRequired,
	searchKey: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,
	orderID: PropTypes.number.isRequired,
	modalFullPrice: PropTypes.number.isRequired,
	modalPayment: PropTypes.number.isRequired,
	payModalVisible: PropTypes.bool.isRequired,
	cancelModalVisible: PropTypes.bool.isRequired,
	cancelType: PropTypes.string.isRequired,
	cancelReason: PropTypes.string.isRequired,

	onChangePage: PropTypes.func.isRequired,
	onChangePageSize: PropTypes.func.isRequired,
	onChangeSearchKey: PropTypes.func.isRequired,
	onClickClosePaymentModal: PropTypes.func.isRequired,

	onClickCreate: PropTypes.func.isRequired,
	onClickViewDetail: PropTypes.func.isRequired,
	modalOnClickPaid: PropTypes.func.isRequired,
	onClickCancel: PropTypes.func.isRequired,

	onClickFileExport: PropTypes.func.isRequired,

	globalReducer: PropTypes.object.isRequired
}

export const Actions = {
	SET_BRANCH_LIST: "SET_BRANCH_LIST",
	SET_BRANCH_NAME: "SET_BRANCH_NAME",
	SET_TABLE_DATA: "SET_TABLE_DATA",
	SET_TOTAL_DATA: "SET_TOTAL_DATA",
	SET_PAGE: "SET_PAGE",
	SET_PAGE_SIZE: "SET_PAGE_SIZE",
	SET_TOTAL_PAGE: "SET_TOTAL_PAGE",
	SET_SEARCH_KEY: "SET_SEARCH_KEY",
	SET_IS_LOADING: "SET_IS_LOADING",
	SET_IS_EXPORTING: "SET_IS_EXPORTING",
	SET_ORDER_ID: "SET_ORDER_ID",
	SET_MODAL_FULL_PRICE: "SET_MODAL_FULL_PRICE",
	SET_MODAL_PAYMENT: "SET_MODAL_PAYMENT",
	SET_PAY_MODAL_VISIBLE: "SET_PAY_MODAL_VISIBLE",
	SET_CANCEL_MODAL_VISIBLE: "SET_CANCEL_MODAL_VISIBLE",
	SET_CANCEL_TYPE: "SET_CANCEL_TYPE",
	SET_CANCEL_REASON: "SET_CANCEL_REASON"
}
