/**
 *	iWear - Top bar customization
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
	Layout,
	Avatar,
	Menu,
	Dropdown,
	Button
} from 'antd';

import styles from './header.module.css';

const adminMenu = [
	{ name: 'Dashboard', route: '/admin/dashboard', icon: 'fas fa-home', hasSub: false },
	{ name: 'สั่งซื้อ', route: '/admin/order', icon: 'far fa-list-alt', hasSub: false },
	{ name: 'การนัดหมาย', route: '/admin/appointment', icon: 'far fa-calendar-alt', hasSub: false },
	{ name: 'การจัดการลูกค้า', route: '/admin/customer', icon: 'fas fa-users', hasSub: false },
	{ name: 'คลังสินค้า', route: '/admin/stock', icon: 'fas fa-warehouse', hasSub: true },
	{ name: 'จัดการข้อมูล', route: '/admin/data', icon: 'far fa-file-alt', hasSub: true }
];

const salesMenu = [
	{ name: 'Dashboard', route: '/sales/dashboard', icon: 'fas fa-home', hasSub: false },
	{ name: 'สั่งซื้อ', route: '/sales/order', icon: 'far fa-list-alt', hasSub: false },
	{ name: 'การนัดหมาย', route: '/sales/appointment', icon: 'far fa-calendar-alt', hasSub: false },
	{ name: 'การจัดการลูกค้า', route: '/sales/customer', icon: 'fas fa-users', hasSub: false },
	{ name: 'คลังสินค้า', route: '/sales/stock', icon: 'fas fa-warehouse', hasSub: true },
	{ name: 'เพิ่มการสั่งซื้อใหม่', route: '/sales/order/create', icon: 'fas fa-plus', hasSub: true }
];

const rxmallMenu = [
	{ name: 'สั่งซื้อ', route: '/lab/order', icon: 'far fa-list-alt' },
	{ name: 'คลังสินค้า', route: '/lab/stock', icon: 'fas fa-warehouse' }
];

const TopBar = (props) => {
	React.useEffect(() => {
		// console.log('LocalStorage', webStorage.get('authCredential'));
	});

	return (
		<React.Fragment>
			<Layout.Header className={ styles.topBarContainer }>
				<div className={`${styles.topBarLogo} is-hidden-desktop`}>
					<figure className={'image is-128x128'}>
						<img
							src={process.env.PUBLIC_URL + '/static/images/iWearLogo.svg'}
							alt={'iWear Logo'}
						/>
					</figure>
					<div style={{ position: 'absolute', top: 0, right: '100px' }}>
						{ renderDropdown(props) }
					</div>
				</div>
				<Dropdown overlay={() => accessorMenu(props)} trigger={['click']}>
					<div
						className={`is-hidden-touch ${styles.topBarAccessorDropdown} custom-iwear-global-hover-cursor-pointer`}
						style={{
							minWidth: '80px',
						 	height: '100%',
						 	float: 'right',
							paddingLeft: '10px',
							paddingRight: '10px'
						}}
					>
						<Avatar icon={'user'} />&nbsp;
						<span>
							{ localStorage.getItem('iwear_accessor') && JSON.parse(localStorage.getItem('iwear_accessor')).user_info.permission.toUpperCase() }
						</span>
					</div>
				</Dropdown>
			</Layout.Header>
		</React.Fragment>
	);
}

const renderDropdownMenu = (props) => {
	return (
		<Menu>
			<div className={ styles.topBarAccessorDropdownContent }>
				<div className={'is-flex'} style={{ alignItems: 'center' }}>
					<Avatar icon={'user'} />
					<div style={{ marginLeft: '10px' }}></div>
					<div className={'custom-iwear-topbar-dropdown-accessor-info-container'}>
						<span>{ localStorage.getItem('iwear_accessor') && JSON.parse(localStorage.getItem('iwear_accessor')).user_info.permission.toUpperCase() }</span>
						<br/>
						<span>{ localStorage.getItem('iwear_accessor') && JSON.parse(localStorage.getItem('iwear_accessor')).user_info.full_name }</span>
						<br/>
						<span>{ localStorage.getItem('iwear_accessor') && JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_name }</span>
					</div>
				</div>
			</div>
			<Menu.Divider />
			{ renderDropdownChoice(props) }
			<Menu.Divider />
			<Menu.Item key={'signout'}>
				<a href={'/logout'}>
					<i className={'fas fa-sign-out-alt'} style={{ minWidth: '25px' }}></i>ลงชื่อออก
				</a>
			</Menu.Item>
		</Menu>
	);
}

const renderDropdownChoice = (props) => {
	if(props.mode === 'admin') {
		return adminMenu.map((_e, _i) => {
			if(_e.name === 'DIVIDER') {
				return (
					<Menu.Divider key={_i} />
				);
			}
			else if(_e.hasSub && _e.name === 'คลังสินค้า') {
				return (
					<Menu.SubMenu
						key={'admin-stock'}
						title={
							<React.Fragment>
								<i className={ _e.icon } style={{ minWidth: '25px' }}></i>
								{ _e.name }
							</React.Fragment>
						}
					>

						<Menu.Item key={'/admin/stock'}>
							<a href={'/admin/stock'}>
								สต็อกสินค้า
							</a>
						</Menu.Item>
						<Menu.Item key={'/admin/stock/import'}>
							<a href={'/admin/stock/import'}>
								สินค้าเข้า
							</a>
						</Menu.Item>
						<Menu.Item key={'/admin/stock/export'}>
							<a href={'/admin/stock/export'}>
								สินค้าส่งออก
							</a>
						</Menu.Item>

					</Menu.SubMenu>
				);
			}
			else if(_e.hasSub && _e.name === 'เพิ่มการสั่งซื้อใหม่') {
				return (
					<Menu.SubMenu
						key={'admin-order'}
						title={
							<React.Fragment>
								<i className={ _e.icon } style={{ minWidth: '25px' }}></i>
								{ _e.name }
							</React.Fragment>
						}
					>

						<Menu.Item key={'/admin/order/create/new'}>
							<a href={'/admin/order/create/new'}>
								ลูกค้าใหม่
							</a>
						</Menu.Item>
						<Menu.Item key={'/admin/order/create/old'}>
							<a href={'/admin/order/create/old'}>
								ลูกค้าเก่า
							</a>
						</Menu.Item>

					</Menu.SubMenu>
				);
			}
			else if(_e.hasSub && _e.name === 'จัดการข้อมูล') {
				return (
					<Menu.SubMenu
						key={'admin-data'}
						title={
							<React.Fragment>
								<i className={ _e.icon } style={{ minWidth: '25px' }}></i>
								{ _e.name }
							</React.Fragment>
						}
					>

						<Menu.Item key={'/admin/data/branch'}>
							<a href={'/admin/data/branch'}>
								สาขา
							</a>
						</Menu.Item>
						<Menu.Item key={'/admin/data/brand'}>
							<a href={'/admin/data/brand'}>
								แบรนด์
							</a>
						</Menu.Item>
						<Menu.Item key={'/admin/data/staff'}>
							<a href={'/admin/data/staff'}>
								พนักงาน
							</a>
						</Menu.Item>
						<Menu.Item key={'/admin/data/product'}>
							<a href={'/admin/data/product'}>
								สินค้า
							</a>
						</Menu.Item>
						<Menu.Item key={'/admin/data/product/category'}>
							<a href={'/admin/data/product/category'}>
								ชนิดสินค้า
							</a>
						</Menu.Item>
						<Menu.Item key={'/admin/data/promotion'}>
							<a href={'/admin/data/promotion'}>
								โปรโมชั่น
							</a>
						</Menu.Item>

					</Menu.SubMenu>
				);
			}
			return (
				<Menu.Item key={ _i }>
					<a href={ _e.route }>
						<i className={`${_e.icon}`} style={{ minWidth: '25px' }}></i>
						{ _e.name }
					</a>
				</Menu.Item>
			);
		});
	}
	else if(props.mode === 'sales') {
		return salesMenu.map((_e, _i) => {
			if(_e.name === 'DIVIDER') {
				return (
					<Menu.Divider key={_i} />
				);
			}
			else if(_e.hasSub && _e.name === 'คลังสินค้า') {
				return (
					<Menu.SubMenu
						key={'sales-stock'}
						title={
							<React.Fragment>
								<i className={ _e.icon } style={{ minWidth: '25px' }}></i>
								{ _e.name }
							</React.Fragment>
						}
					>

						<Menu.Item key={'/sales/stock'}>
							สต็อกสินค้า
						</Menu.Item>
						<Menu.Item key={'/sales/stock/import'}>
							สินค้าเข้า
						</Menu.Item>
						<Menu.Item key={'/sales/stock/export'}>
							สินค้าส่งออก
						</Menu.Item>

					</Menu.SubMenu>
				);
			}
			else if(_e.hasSub && _e.name === 'เพิ่มการสั่งซื้อใหม่') {
				return (
					<Menu.SubMenu
						key={'sales-order'}
						title={
							<React.Fragment>
								<i className={ _e.icon } style={{ minWidth: '25px' }}></i>
								{ _e.name }
							</React.Fragment>
						}
					>

						<Menu.Item key={'/sales/order/create/new'}>
							ลูกค้าใหม่
						</Menu.Item>
						<Menu.Item key={'/sales/order/create/old'}>
							ลูกค้าเก่า
						</Menu.Item>

					</Menu.SubMenu>
				);
			}
			return (
				<Menu.Item key={ _i }>
					<a href={ _e.route }>
						<i className={`${_e.icon}`} style={{ minWidth: '25px' }}></i>
						{ _e.name }
					</a>
				</Menu.Item>
			);
		});
	}
	else if(props.mode === 'rxmall') {
		return rxmallMenu.map((_e, _i) => {
			return (
				<Menu.Item key={ _i }>
					<a href={ _e.route }>
						<i className={`${_e.icon}`} style={{ minWidth: '25px' }}></i>
						{ _e.name }
					</a>
				</Menu.Item>
			);
		});
	}
}

const renderDropdown = (props) => {
	return (
		<Dropdown overlay={() => renderDropdownMenu(props)} trigger={['click']}>
			<Button>
				<i className={'fas fa-bars'}></i>
			</Button>
		</Dropdown>
	);
}

const accessorMenu = (props) => {
	return (
		<Menu>
			<div className={ styles.topBarAccessorDropdownContent }>
				{ localStorage.getItem('iwear_accessor') && JSON.parse(localStorage.getItem('iwear_accessor')).user_info.full_name }
			</div>
			<div className={ styles.topBarAccessorDropdownContent }>
				{ localStorage.getItem('iwear_accessor') && JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_name }
			</div>
			<Menu.Divider />
			<Menu.Item key={'signout'}>
				<a href={'/logout'}>
					<i className={'fas fa-sign-out-alt'}></i>&nbsp;ลงชื่อออก
				</a>
			</Menu.Item>
		</Menu>
	);
}

TopBar.propTypes = {
	mode: PropTypes.string.isRequired,
	history: PropTypes.object.isRequired
}

export default TopBar;
