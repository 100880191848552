/**
 *	iWear - Stock Import Inventory state management
 */

import React from 'react';
import { Actions } from './stock-import-bill.model';

const initState = {
	barcode: '',
	tableData: [],
	srcBranch: 0,
	itemsStatus: [],
	isLoading: false
}

const reducer = (state, action) => {
	switch(action.type) {
		case Actions.SET_BARCODE:
			return {
				...state,
				barcode: action.value
			}
		case Actions.SET_TABLE_DATA:
			return {
				...state,
				tableData: action.value
			}
		case Actions.SET_SRC_BRANCH:
			return {
				...state,
				srcBranch: action.value
			}
		case Actions.SET_ITEM_STATUS:
			return {
				...state,
				itemsStatus: action.value
			}
		case Actions.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.value
			}
		default:
			return { ...state }
	}
}

const StockImportInventoryReducer = () => {
	const [ state, dispatch ] = React.useReducer(reducer, initState);

	React.useEffect(() => {}, []);

	return {
		state,
		dispatch
	}
}

export default StockImportInventoryReducer;
