/**
 *	iWear - Error Container
 */

import React from 'react';
import PropTypes from 'prop-types';
import Content from './error.content';

const Container = (props) => {
	const [ counter, setCounter ] = React.useState(3);

	React.useEffect(() => {
		if(counter > 0) {
			setTimeout(() => {
				setCounter(counter - 1);
			}, 1000);
		}
		else {
			props.history.goBack();
		}
	}, [ props.history, counter ]);

	return (
		<React.Fragment>
			<Content
				counter={ counter }
			/>
		</React.Fragment>
	);
}

Container.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default Container
