/**
 *  Appointment - Content
 */

import React from 'react';
import XDate from 'xdate';
import moment from 'moment';
import {
	DatePicker,
	Row,
	Col,
	Empty,
	Spin
} from 'antd';

import ContactPanel from '../../../components/appointment-contact-panel';
import Breadcrumb from '../../../components/breadcrumb';
import IWearDropdown from '../../../components/dropdown';
import ExcelButton from '../../../components/excel-button';
import { ContentPropTypes } from './appointment.model';
import styles from './appointment.module.css';

const Content = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'การนัดหมาย' ]}/>
			{renderContent(props)}
		</div>
	);
}

const renderContent = (props) => {
	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
  			<Row>
				<Col>
					{ renderOptions(props) }
				</Col>
			</Row>
  			<Row>
				<Col>
					{ renderColumns(props) }
				</Col>
			</Row>
		</div>
	);
}

const renderOptions = (props) => {
	let _branches = [];
	props.branchList.forEach((_e) => {
		_branches.push(_e.branch_name);
	});

	return (
		<div className={'custom-iwear-global-table-option-panel'}>
			<Row gutter={8} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={8} style={{ textAlign: 'right' }}>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						สาขา
					</span>&nbsp;
					<IWearDropdown
						styleJSX={{ minWidth: '100px', paddingRight: '25px' }}
						size={'large'}
						displayValue={ props.branchName || 'ทั้งหมด' }
						choices={[ 'ทั้งหมด', ..._branches ]}
						onSelect={ props.onChangeBranch }
					/>
				</Col>
				<Col span={8} style={{ textAlign: 'center' }}>
					<DatePicker
						defaultValue={moment(props.selectedDate, 'DD/MM/YYYY')}
						allowClear={false}
						size={'large'}
						placeholder={'Date'}
						format={['DD/MM/YYYY']}
						onChange={ props.onChangeDate }
						onOpenChange={(flag) => {
							console.log('OpenChange flag', flag);
						}}
					/>
				</Col>
				<Col span={8} style={{ textAlign: 'left' }}>
					<ExcelButton
						modalTitle={'เลือกช่วงวันที่นัดหมาย'}
						exportType={'appointment'}
						dispatcher={ props.dispatcher }
						isLoading={ props.isExporting }

						branchName={ (props.branchName !== 'ทั้งหมด') ? props.branchName : '' }
					/>
				</Col>
			</Row>
		</div>
	);
}

const renderColumns = (props) => {
	let nextDay = new Date(props.selectedDate);
	let nextTwoDay = new Date(props.selectedDate);
	nextDay.setDate(nextDay.getDate() + 1);
	nextTwoDay.setDate(nextTwoDay.getDate() + 2);

	return (
		<div className={ styles.columnContainer }>
			<Row gutter={8}>
				<Col sm={24} md={6} style={{ textAlign: 'center' }}>
					<span className={'is-size-6 has-text-weight-bold'}>
						ยังไม่ได้รับ
					</span>
					<div style={{ margin: '10px' }}></div>
					<div className={ styles.columnCategoryContainer }>
						{ renderDataColumn(props.appointmentData.not_receive, props.onPanelClick, props.onSubmitNewAppointmentDate, 'not_receive', props.isLoading) }
					</div>
				</Col>
				<Col sm={24} md={6} style={{ textAlign: 'center' }}>
					<span className={'is-size-6 has-text-weight-bold'}>
						วันนี้
					</span>
					<div style={{ margin: '10px' }}></div>
					<div className={ styles.columnCategoryContainer }>
						{ renderDataColumn(props.appointmentData.current_date, props.onPanelClick, props.onSubmitNewAppointmentDate, 'current_date', props.isLoading) }
					</div>
				</Col>
				<Col sm={24} md={6} style={{ textAlign: 'center' }}>
					<span className={'is-size-6 has-text-weight-bold'}>
						{ XDate(nextDay).toString('dd/MM/yyyy') }
					</span>
					<div style={{ margin: '10px' }}></div>
					<div className={ styles.columnCategoryContainer }>
						{ renderDataColumn(props.appointmentData.next_date, props.onPanelClick, props.onSubmitNewAppointmentDate, 'next_date', props.isLoading) }
					</div>
				</Col>
				<Col sm={24} md={6} style={{ textAlign: 'center' }}>
					<span className={'is-size-6 has-text-weight-bold'}>
						{XDate(nextTwoDay).toString('dd/MM/yyyy')}
					</span>
					<div style={{ margin: '10px' }}></div>
					<div className={ styles.columnCategoryContainer }>
						{ renderDataColumn(props.appointmentData.double_date, props.onPanelClick, props.onSubmitNewAppointmentDate, 'double_date', props.isLoading) }
					</div>
				</Col>
			</Row>
		</div>
	);
}

const renderDataColumn = (orders, onPanelClick, onSubmitNewAppointmentDate, type, isLoading) => {
	if(isLoading === true) {
		return (
			<div>
				<br/>
				<Spin />
			</div>
		);
	}

	if (orders !== undefined && orders.length !== 0) {
		return orders.map((_e, _i) => {
			return (
				<ContactPanel
					key={ _i }
	                name={ _e.customer_info.full_name }
	          		tel={ _e.customer_info.phone_number }
	              	appointedDate={ _e.order_info.receive_date }
	      			orderDate={ _e.order_info.receive_date }
					orderID={ _e.order_info.order_id }
					customerID={ _e.customer_info.customer_id }
					onPanelClick={ onPanelClick }
					onSubmitNewAppointmentDate={ onSubmitNewAppointmentDate }
					appointmentType={ type }
				/>
			);
		});
	}
	else {
		return (
			<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span></span>} />
		);
	}
}

Content.propTypes = ContentPropTypes;

export default Content;
