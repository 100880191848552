/**
 *	Logout - Container
 */

import React from 'react';
import PropTypes from 'prop-types';

import GlobalStore from '../../../store';
import WebCookie from '../../../lib/web-cookie';
import Content from './logout.content';

const Container = (props) => {
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		WebCookie.remove('iwear_credential');
		localStorage.removeItem('iwear_accessor');
		dispatch({ type: 'SIGN_OUT' });
		props.history.push('/');
	}, [ dispatch, props.history ]);

	return (
		<Content globalStore={{ state, dispatch }} />
	);
}

Container.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default Container;
