/**
 *	iWear - Data Product Add container
 */

import React from 'react';
import { Layout } from 'antd';
import swal from 'sweetalert';
import PropTypes from 'prop-types';

import TopBar from '../../../../../components/layout/header';
import Sidebar from '../../../../../components/layout/sider';
import BottomBar from '../../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../../environments';
import WebCookie from '../../../../../lib/web-cookie';
import AddDataProduct from './screen';

const AddDataProductContainer = (props) => {
	const [ name, setName ] = React.useState('');
	const [ code, setCode ] = React.useState('');
	const [ picture, setPicture ] = React.useState({ file: null, preview: '' }); // FILE
	const [ brand, setBrand ] = React.useState('');
	const [ brandList, setBrandList ] = React.useState([]);
	const [ price, setPrice ] = React.useState(0);
	const [ editAttrIdx, setEditAttrIdx ] = React.useState(-1);

	const [ branchList, setBranchList ] = React.useState([]);
	const [ productType, setProductType ] = React.useState('');
	const [ frameType, setFrameType ] = React.useState('');
	const [ lensType, setLensType ] = React.useState('');
	const [ productTypeList, setProductTypeList ] = React.useState([]);
	const [ frameTypeList, setFrameTypeList ] = React.useState([]);
	const [ lensTypeList, setLensTypeList ] = React.useState([]);

	const [ attrTableData, setAttrTableData ] = React.useState([]);

	const [ attrModalIdx, setAttrModalIdx ] = React.useState(-1);
	const [ attrModalName, setAttrModalName ] = React.useState('');
	const [ attrModalChoices, setAttrModalChoices ] = React.useState([]);
	const [ attrModalVisible, setAttrModalVisible ] = React.useState(false);

	React.useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		let res_branch = await fetch(`${API_ENDPOINT}/branches?limit=9999`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		let json_branch = await res_branch.json();

		let res_attr = await fetch(`${API_ENDPOINT}/product-attribute`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		let json_attr = await res_attr.json();

		let res_type = await fetch(`${API_ENDPOINT}/product-attribute-values?attribute_id=3&limit=100`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		let json_type = await res_type.json();

		setProductTypeList(json_type.list || []);
		setBrandList(json_attr.brand || []);
		setFrameTypeList(json_attr.frame_type || []);
		setLensTypeList(json_attr.lens_type || []);
		setBranchList([ ...json_branch.list ]);
	}

	const onEditAttrData = (dataIndex) => {
		let _arr = [ ...attrTableData[dataIndex].choices ];
		setAttrModalIdx(dataIndex);
		setAttrModalName( attrTableData[dataIndex].name );
		setAttrModalChoices([ ..._arr ]);
		setAttrModalVisible(true);
	}

	const onDelAttrData = (dataIndex) => {
		let _arr =[ ...attrTableData ];
		_arr.splice(dataIndex, 1);
		setAttrTableData([ ..._arr ]);
	}

	const onClickGoBack = () => {
		props.history.goBack();
	}

	const onClickSave = async () => {
		if(name === '') {
			swal({
				title: 'เพิ่มสินค้าล้มเหลว',
				text: 'กรุณาระบุชื่อสินค้า',
				icon: 'error'
			});
		}
		else if(code === '') {
			swal({
				title: 'เพิ่มสินค้าล้มเหลว',
				text: 'กรุณาระบุรหัสสินค้า',
				icon: 'error'
			});
		}
		// else if(picture.file === null || picture.preview === '') {
		// 	swal({
		// 		title: 'เพิ่มสินค้าล้มเหลว',
		// 		text: 'กรุณาเลือกรูปภาพสินค้า',
		// 		icon: 'error'
		// 	});
		// }
		else if(productType === '') {
			swal({
				title: 'เพิ่มสินค้าล้มเหลว',
				text: 'กรุณาเลือกชนิดสินค้า',
				icon: 'error'
			});
		}
		else if(price === 0) {
			swal({
				title: 'เพิ่มสินค้าล้มเหลว',
				text: 'กรุณาระบุราคาสินค้า',
				icon: 'error'
			});
		}
		else if(productType !== '' && (productType === 'frame' || productType === 'lens') && brand === '') {
			swal({
				title: 'เพิ่มสินค้าล้มเหลว',
				text: 'กรุณาเลือกแบรนด์สินค้า',
				icon: 'error'
			});
		}
		else if(productType === 'กรอบแว่น' && frameType === '') {
			swal({
				title: 'เพิ่มสินค้าล้มเหลว',
				text: 'กรุณาเลือกชนิดกรอบ',
				icon: 'error'
			});
		}
		else if(productType === 'เลนส์' && lensType === '') {
			swal({
				title: 'เพิ่มสินค้าล้มเหลว',
				text: 'กรุณาเลือกชนิดเลนส์',
				icon: 'error'
			});
		}
		else if(productType !== 'Accessories' && attrTableData.length === 0) {
			swal({
				title: 'เพิ่มสินค้าล้มเหลว',
				text: 'กรุณาระบุคุณสมบัติสินค้า',
				icon: 'error'
			});
		}
		// else if(productType !== '' && productType !== 'กรอบแว่น' && productType !== 'เลนส์' && brand === '') {
		// 	//
		// }
		else {
			// let formData = new FormData();
			// formData.append('file', picture.file);
			// let res_image = await fetch(`${API_ENDPOINT}/upload`, {
			// 	method: 'POST',
			// 	headers: {
			// 		'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
			// 	},
			// 	body: formData
			// });

			// let json_image = await res_image.json();
			// console.log('json_image', json_image); // json_image.path

			// if(res_image.status !== 200) { return; }

			let res_product = await fetch(`${API_ENDPOINT}/products`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({ name: name })
			});

			let json_product = await res_product.json();
			// console.log('json_product', json_product)
			if(res_product.status === 200) {
				let res_option = null;
				let json_option = null;
				let res_sku = null;
				let json_sku = null;
				let res_item = null;
				let json_item = null;
				let res_invItem = null;
				let json_invItem = null;
				let _arr = [];

				if(productType !== 'Accessories') {
					attrTableData[0].choices.forEach((_e) => { _arr.push({ type: _e, value: _e }); });
				}
				else {
					_arr.push({ type: 'ประดับ', value: 'ประดับ' });
				}

				if(productType !== 'Accessories') {
					for(let i = 0 ; i < attrTableData.length ; i++) {
						res_option = await fetch(`${API_ENDPOINT}/options`, {
							method: 'POST',
							headers: {
								'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
								'Content-Type': 'application/json'
							},
							body: JSON.stringify({
								name: attrTableData[i].name,
								product_id: json_product.product_id,
								order_values: [ ..._arr ] // option_values
							})
						});
	
						if(res_option.status !== 200) { return; }
						else {
							json_option = await res_option.json();
							for(let j = 0 ; j < json_option.order_values.length ; j++) {
								res_sku = await fetch(`${API_ENDPOINT}/product-skus`, {
									method: 'POST',
									headers: {
										'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
										'Content-Type': 'application/json'
									},
									body: JSON.stringify({
										product_id: json_product.product_id,
										value_id: json_option.order_values[j].value_id,
										variant: name
									})
								});
	
								if(res_sku.status !== 200) { break; }
								else {
									json_sku = await res_sku.json();
									res_item = await fetch(`${API_ENDPOINT}/product-items`, {
										method: 'POST',
										headers: {
											'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
											'Content-Type': 'application/json'
										},
										body: JSON.stringify({
											sku_id: json_sku.sku_id,
											name: name,
											price: price,
											image_url: '',
											item_code: code
										})
									});
	
									if(res_item.status !== 200) { break; }
									else {
										json_item = await res_item.json();
										console.log('Item', json_item);
										res_invItem = await fetch(`${API_ENDPOINT}/inventory-items`, {
											method: 'POST',
											headers: {
												'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
												'Content-Type': 'application/json'
											},
											body: JSON.stringify({
												main_branch: true,
												code: '',
												branch_id: 8, // _branchData.branch_id,
												product_item_id: json_item.item_id,
												quantity: 99999999,
												inventory_items: [
													{
														ex_item_id: 0,
														inventory_id: 8,
														src_inv_id: 0,
														status: 'normal',
														product_item_id: json_item.item_id,
														quantity: 99999999
													}
												]
											})
										});
										
										if(res_invItem.status !== 200) { break; }
										json_invItem = await res_invItem.json();
										console.log('json_invItem', json_invItem);
										swal({
											title: 'เพิ่มสินค้าสำเร็จ',
											icon: 'success'
										}).then((value) => {
											props.history.goBack();
										});
									}
								}
							}
						}
					}
				}
				else {
					res_option = await fetch(`${API_ENDPOINT}/options`, {
						method: 'POST',
						headers: {
							'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
							'Content-Type': 'application/json'
						},
						body: JSON.stringify({
							name: 'ประดับ',
							product_id: json_product.product_id,
							order_values: [ ..._arr ] // option_values
						})
					});

					if(res_option.status !== 200) { return; }
					else {
						json_option = await res_option.json();
						for(let j = 0 ; j < json_option.order_values.length ; j++) {
							res_sku = await fetch(`${API_ENDPOINT}/product-skus`, {
								method: 'POST',
								headers: {
									'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
									'Content-Type': 'application/json'
								},
								body: JSON.stringify({
									product_id: json_product.product_id,
									value_id: json_option.order_values[j].value_id,
									variant: name
								})
							});

							if(res_sku.status !== 200) { return; }
							else {
								json_sku = await res_sku.json();
								res_item = await fetch(`${API_ENDPOINT}/product-items`, {
									method: 'POST',
									headers: {
										'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
										'Content-Type': 'application/json'
									},
									body: JSON.stringify({
										sku_id: json_sku.sku_id,
										name: name,
										price: price,
										image_url: '',
										item_code: code
									})
								});

								if(res_item.status !== 200) { return; }
								else {
									json_item = await res_item.json();
									console.log('Item', json_item);
									res_invItem = await fetch(`${API_ENDPOINT}/inventory-items`, {
										method: 'POST',
										headers: {
											'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
											'Content-Type': 'application/json'
										},
										body: JSON.stringify({
											main_branch: true,
											code: '',
											branch_id: 8, // _branchData.branch_id,
											product_item_id: json_item.item_id,
											quantity: 99999999,
											inventory_items: [
												{
													ex_item_id: 0,
													inventory_id: 8,
													src_inv_id: 0,
													status: 'normal',
													product_item_id: json_item.item_id,
													quantity: 99999999
												}
											]
										})
									});
									
									if(res_invItem.status !== 200) { return; }
									json_invItem = await res_invItem.json();
									console.log('json_invItem', json_invItem);
									swal({
										title: 'เพิ่มสินค้าสำเร็จ',
										icon: 'success'
									}).then((value) => {
										props.history.goBack();
									});
								}
							}
						}
					}
				}

				// let res_attr_detail = await fetch(`${API_ENDPOINT}/`, {
				// 	method: 'POST',
				// 	headers: {
				// 		'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				// 		'Content-Type': 'application/json'
				// 	},
				// 	body: JSON.stringify({
				// 		product_id: json_product.product_id,
				// 		attribute_id: 0,
				// 		attribute_value_id: 0
				// 	})
				// });
				// let json_attr_detail = null;
			}
			else {
				swal({
					title: 'เพิ่มสินค้าล้มเหลว',
					text: 'เกิดข้อผิดพลาด ไม่ทราบสาเหตุ',
					icon: 'error'
				});
			}
		}
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/data/product'} history={ props.history } />
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/data/product'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<AddDataProduct
						name={ name }
						code={ code }
						picture={ picture }
						price={ price }
						editAttrIdx={ editAttrIdx }

						branchList={ branchList }
						brand={ brand }
						brandList={ brandList }
						productType={ productType }
						frameType={ frameType }
						lensType={ lensType }
						productTypeList={ productTypeList }
						frameTypeList={ frameTypeList }
						lensTypeList={ lensTypeList }
						attrTableData={ attrTableData }

						attrModalIdx={ attrModalIdx }
						attrModalName={ attrModalName }
						attrModalChoices={ attrModalChoices }
						attrModalVisible={ attrModalVisible }

						setName={ setName }
						setCode={ setCode }
						setPicture={ setPicture }
						setPrice={ setPrice }
						setEditAttrIdx={ setEditAttrIdx }

						setBranchList={ setBranchList }
						setBrand={ setBrand }
						setBrandList={ setBrandList }
						setProductType={ setProductType }
						setFrameType={ setFrameType }
						setLensType={ setLensType }
						setProductTypeList={ setProductTypeList }
						setFrameTypeList={ setFrameTypeList }
						setLensTypeList={ setLensTypeList }
						setAttrTableData={ setAttrTableData }

						setAttrModalIdx={ setAttrModalIdx }
						setAttrModalName={ setAttrModalName }
						setAttrModalChoices={ setAttrModalChoices }
						setAttrModalVisible={ setAttrModalVisible }

						onEditAttrData={ onEditAttrData }
						onDelAttrData={ onDelAttrData }
						onClickGoBack={ onClickGoBack }
						onClickSave={ onClickSave }
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

AddDataProductContainer.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default AddDataProductContainer;
