/**
 *	Stock List - Container
 */

import React from 'react';
import {
	Layout
} from 'antd';
import swal from 'sweetalert';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import { downloadFromBuffer } from '../../../../functions';
import { API_ENDPOINT } from '../../../../environments';
import GlobalStore from '../../../../store';
import LocalStore from './stock-list.store';
import Content from './stock-list.content';
import { ContainerPropTypes, Actions } from './stock-list.model';

const Container = (props) => {
	const StockReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData({ page: StockReducer.state.page, pageSize: StockReducer.state.pageSize, searchKey: StockReducer.state.searchKey }, StockReducer.dispatch, state.auth.token);
	}, [ StockReducer.state.page, StockReducer.state.pageSize, StockReducer.state.searchKey, StockReducer.dispatch, state.auth.token ]); // @update on `page` or `pageSize` state changed

	const fetchData = async (params, localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		let res_product = await fetch(`${API_ENDPOINT}/inventory-items?search=${encodeURIComponent(params.searchKey)}&limit=${params.pageSize}&offset=${(params.page - 1) * params.pageSize}&branch_id=${encodeURIComponent(branchID)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json_product = await res_product.json();

		localDispatch({ type: Actions.SET_TABLE_DATA, value: [ ...json_product.list ] });
		localDispatch({ type: Actions.SET_TOTAL_DATA, value: json_product.count });
		localDispatch({ type: Actions.SET_TOTAL_PAGE, value: json_product.total_pages * params.pageSize });
		localDispatch({ type: Actions.SET_FRAME_COUNT, value: json_product.frame_count || 0 });
		localDispatch({ type: Actions.SET_LENS_COUNT, value: json_product.lens_count || 0 });

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onChangePage = (page, pageSize) => { StockReducer.dispatch({ type: Actions.SET_PAGE, value: page }); }

	const onChangePageSize = (selectedVal) => {
		StockReducer.dispatch({ type: Actions.SET_PAGE, value: 1 });
		StockReducer.dispatch({ type: Actions.SET_PAGE_SIZE, value: selectedVal });
	}

	const onChangeSearchKey = (value) => {
		StockReducer.dispatch({ type: Actions.SET_PAGE, value: 1 });
		StockReducer.dispatch({ type: Actions.SET_SEARCH_KEY, value: value });
	}

	const onClickViewDetail = (itemID) => {
		//
	}

	const onClickImport = () => {
		props.history.push('/sales/stock/import');
	}

	const onClickExport = () => {
		props.history.push('/sales/stock/export');
	}

	const onClickFileExport = async () => {
		StockReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: true });
		let _url = `${API_ENDPOINT}/inventory-items
			?branch_id=${encodeURIComponent(state.auth.branchInfo.branch_id)}
			&search=${encodeURIComponent(StockReducer.state.searchKey)}
			&is_report=true
		`;
		let res = await fetch(`${_url}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let data = await res.json();

		if(res.status === 200) {
			let _timer = 5000 + (Number.parseInt(StockReducer.state.totalData / 5000, 10) * 1000);
			setTimeout(() => {
				downloadFromBuffer(data.report_path, 'stock_report.xlsx');
				StockReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: false });
			}, _timer);
		}
		else {
			StockReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: false });
			swal({
				title: 'เกิดข้อผิดพลาด',
				text: 'ไม่สามารถ export ได้ในขณะนี้',
				icon: 'error'
			});
		}
	}

	return (
		<Layout>
			<Sidebar mode={'sales'} currentRoute={'/sales/stock'} history={ props.history } />
			<Layout>
				<TopBar mode={'sales'} currentRoute={'/sales/stock'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...StockReducer.state }
						dispatcher={ StockReducer.dispatch }

						onChangePage={ onChangePage }
						onChangePageSize={ onChangePageSize }
						onChangeSearchKey={ onChangeSearchKey }

						onClickViewDetail={ onClickViewDetail }
						onClickImport={ onClickImport }
						onClickExport={ onClickExport }
						onClickFileExport={ onClickFileExport }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
