/**
 *	Login - Model
 */

import PropTypes from 'prop-types';

export const ContainerPropTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export const ContentPropTypes = {
	username: PropTypes.string.isRequired,
	password: PropTypes.string.isRequired,
	errorUsername: PropTypes.bool.isRequired,
	errorPassword: PropTypes.bool.isRequired,
	isSubmitting: PropTypes.bool.isRequired,

	onChangeUsername: PropTypes.func.isRequired,
	onChangePassword: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,

	globalReducer: PropTypes.object.isRequired
}

export const Actions = {
	SET_USERNAME: "SET_USERNAME",
	SET_PASSWORD: "SET_PASSWORD",
	SET_ERROR_USERNAME: "SET_ERROR_USERNAME",
	SET_ERROR_PASSWORD: "SET_ERROR_PASSWORD",
	SET_IS_SUBMITTING: "SET_IS_SUBMITTING"
}
