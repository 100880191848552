/**
 *	iWear - Dinance state management
 */

import React from 'react';
import moment from 'moment';
import { Actions } from './finance.model';

const initState = {
	viewMode: 'list', // 'list' || 'graph'
	branchList: [],

	paymentRatio: [],
	ratioSelectedBranch: 'ทั้งหมด',
	ratioDateStartFilter: moment().startOf('month'),
	ratioDateEndFilter: moment(),

	tableData: [],
	totalData: 0,
	totalRemaining: 0,
	listDateStartFilter: moment(),
	listDateEndFilter: moment(),
	listSearchFilter: '',
	listSelectedBranch: '',
	page: 1,
	pageSize: 10,
	totalPage: 1,
	isLoading: false
}

const reducer = (state, action) => {
	switch(action.type) {
		case Actions.SET_VIEW_MODE:
			return ({ ...state, viewMode: action.value })
		case Actions.SET_BRANCH_LIST:
			return ({ ...state, branchList: action.value })
		case Actions.SET_PAYMENT_RATIO:
			return ({ ...state, paymentRatio: action.value })
		case Actions.SET_RATIO_SELECTED_BRANCH:
			return ({ ...state, ratioSelectedBranch: action.value })
		case Actions.SET_RATIO_DATE_START_FILTER:
			return ({ ...state, ratioDateStartFilter: action.value })
		case Actions.SET_RATIO_DATE_END_FILTER:
			return ({ ...state, ratioDateEndFilter: action.value })
		case Actions.SET_TABLE_DATA:
			return ({ ...state, tableData: action.value })
		case Actions.SET_TOTAL_DATA:
			return ({ ...state, totalData: action.value })
		case Actions.SET_TOTAL_REMAINING:
			return ({ ...state, totalRemaining: action.value })
		case Actions.SET_LIST_DATE_START_FILTER:
			return ({ ...state, listDateStartFilter: action.value })
		case Actions.SET_LIST_DATE_END_FILTER:
			return ({ ...state, listDateEndFilter: action.value })
		case Actions.SET_LIST_SEARCH_FILTER:
			return ({ ...state, listSearchFilter: action.value })
		case Actions.SET_LIST_SELECTED_BRANCH:
			return ({ ...state, listSelectedBranch: action.value })
		case Actions.SET_PAGE:
			return ({ ...state, page: action.value })
		case Actions.SET_PAGE_SIZE:
			return ({ ...state, pageSize: action.value })
		case Actions.SET_TOTAL_PAGE:
			return ({ ...state, totalPage: action.value })
		case Actions.SET_IS_LOADING:
			return ({ ...state, isLoading: action.value })
		case Actions.RESET:
			return ({
				...state,
				ratioSelectedBranch: 'ทั้งหมด',
				ratioDateRangeFilter: moment(),
				listDateRangeFilter: moment(),
				listSearchFilter: '',
				listSelectedBranch: '',
				page: 1,
				pageSize: 10,
				isLoading: false
			})
		default:
			return { ...state }
	}
}

const Store = () => {
	const [ state, dispatch ] = React.useReducer(reducer, initState);

	React.useEffect(() => {}, []);

	return {
		state,
		dispatch
	}
}

export default Store;
