/**
 *	Application Navigator
 */

import React from 'react';
import { Switch, Route, Redirect } from 'react-router';

import RestrictedRoute from './components/restricted-route';
import { ROLE } from './environments';

import Login from './features/common/login';
import Logout from './features/common/logout';
import ErrorComponent from './features/common/error';
// import ExperimentArea from './features/common/experiment-area';

import routes from './routes';

const renderRoutes = () => {
	return routes.map((_e, _i) => {
		return (
			<RestrictedRoute key={_i} exact path={ _e.path } component={ _e.component } rolePermission={ _e.role } />
		);
	});
}

const Navigator = () => {
	return (
		<Switch>
			<Route exact path={'/'} component={ Login } />
			{/* <Route exact path={'/developer-experiment-area'} component={ ExperimentArea } /> */}
			<RestrictedRoute exact path={'/logout'} component={ Logout } rolePermission={ ROLE.any } />

			{ renderRoutes() }

			{/* ERROR */}
			<Route path={'/404'} component={ ErrorComponent } />
			<Redirect from={'*'} to={'/404'} />
		</Switch>
	);
}

export default Navigator;
