/**
 *	Order Add Old - Content
 */

import React from 'react';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import NumberFormat from 'react-number-format';
import {
	AutoComplete,
	Checkbox,
	DatePicker,
	Radio,
	Input,
	InputNumber,
	Row,
	Col,
	Button
} from 'antd';

import Breadcrumb from '../../../../components/breadcrumb';
import IWearDropdown from '../../../../components/dropdown';
import CustomerChannelSelect from '../../../../components/select-customer-channel';
import EyeMeasureTableData from '../../../../components/eye-measure-table';
import ClaimProductModal from '../../../../components/order-claim-product-modal';
import TableFooter from '../../../../components/table-footer';
import ToPDF from '../../../../components/to-pdf';

import { ContentPropTypes } from './order-add-old.model';
import styles from './order-add-old.module.css';

const Content = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'การสั่งซื้อ', 'เพิ่มรายการสั่งซื้อ', 'ลูกค้าเก่า' ]} />
			{ renderContent(props) }
			{ renderSummary(props) }
		</div>
	);
}

const renderContent = (props) => {
	return (
		<React.Fragment>
			<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
				<div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>
					<Row>
						<Col>
							<div className={'custom-iwear-global-table-option-panel'}>
								<Row>
									<Col span={12}>
										<span
											className={'custom-iwear-global-font-color is-size-5'}
											style={{ textDecorationLine: 'underline' }}
										>
											เพิ่มการสั่งซื้อ
										</span>
									</Col>
									<Col span={12} style={{ textAlign: 'right' }}>
										<span className={'custom-iwear-global-font-color is-size-3'}>
											ราคารวม&nbsp;
											<NumberFormat
												thousandSeparator
												displayType={'text'}
												value={ Number.parseFloat(props.priceSum).toFixed(2) }
											/>
											&nbsp;บาท
										</span>
									</Col>
								</Row>
								<Row>
									<Col span={12}></Col>
									<Col span={12} style={{ textAlign: 'right' }}>
										<span className={'custom-iwear-global-font-color is-size-3'}>
											วันที่ทำรายการ:&nbsp;
											{
												(props.pastDate)
												? moment(new Date(props.pastDate)).format('DD/MM/YYYY')
												: moment().format('DD/MM/YYYY')
											}
										</span>
									</Col>
								</Row>
							</div>
						</Col>
					</Row>
					<Row>
						<Col>
							{ renderSectors(props) }
						</Col>
					</Row>
				</div>
			</div>
			<ReactToPrint
				ref={ props.printRef }
				trigger={() => (
					<Button style={{ display: 'none' }}>
						<i className={'fas fa-file-excel custom-iwear-global-font-color'}/>
					</Button>
				)}
				content={() => props.exportRef.current}
				onBeforeGetContent={() => { return props.beforePrintHandler(); }}
				removeAfterPrint={ true }
			/>
			<div style={{ display: 'none', width: '100%', height: '100%' }}>
				<ToPDF.Order ref={ props.exportRef } data={ props.printData } />
			</div>
		</React.Fragment>
	);
}

const renderSectors = (props) => {
	return (
		<React.Fragment>
			<div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>
				<Row>
					<Col>
						{ renderFirstSection(props) }
					</Col>
				</Row>
				<div style={{ marginBottom: '30px' }}></div>
				<Row>
					<Col>
						{ renderSecondSection(props) }
					</Col>
				</Row>
				<div style={{ marginBottom: '30px' }}></div>
				<Row>
					<Col>
						{ renderThirdSection(props) }
					</Col>
				</Row>
				<div style={{ marginBottom: '30px' }}></div>
				<Row>
					<Col>
						{ renderFourthSection(props) }
					</Col>
				</Row>
				<div style={{ marginBottom: '30px' }}></div>
				<Row>
					<Col>
						{ renderFifthSection(props) }
					</Col>
				</Row>
			</div>
		</React.Fragment>
	);
}

const renderTable = (props) => {
	return (
		<div className={'custom-iwear-global-table-container'}>
			<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-left-th'}>
							ลำดับที่
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							รหัสสินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							สินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							จำนวน
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ราคา
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-right-th'}>
							เพิ่มเติม
						</th>
					</tr>
				</thead>
				<tbody>
					{ renderTableData(props) }
					{ (props.tableData.length < 5) && renderTableFooter(props) }
				</tbody>
			</table>
		</div>
	)
}

const renderTableData = (props) => {
	if(props.isLoading) {
		return null;
	}
	else if(!props.isLoading && props.tableData.length === 0) {
		return null;
	}

	return props.tableData.map((_e, _i) => {
		if(_i === props.editIdx) {
			return (
				<tr key={_i}>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered'}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.order }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered'}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.code }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered'}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.name }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered'}
						style={{ verticalAlign: 'middle' }}
					>
						<InputNumber
							value={ props.tableData[_i].amount }
							min={1}
							onChange={(val) => {
								let _arr = [ ...props.tableData ];
								_arr[_i].amount = val;
								props.onChangeTableData([ ..._arr ]);
							}}
						/>
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered'}
						style={{ verticalAlign: 'middle' }}
					>
						<NumberFormat
							thousandSeparator
							displayType={'text'}
							value={ _e.price }
						/>
					</td>
					<td
						className={'has-text-centered'}
						style={{ verticalAlign: 'middle' }}
					>
						<Button
							onClick={() => { props.onEditData(_i) }}
						>
							<span>
								เสร็จ
							</span>
						</Button>
					</td>
				</tr>
			);
		}

		return (
			<tr key={_i}>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.order }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.code }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.name }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.amount }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					<NumberFormat
						thousandSeparator
						displayType={'text'}
						value={ _e.price }
					/>
				</td>
				<td
					className={'has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{/*<Dropdown overlay={() => renderDropdownMenu(props.tableData, props.setTableData, _i)} trigger={['click']}>
						<button className={'button '}>
							<i className={'fas fa-ellipsis-v custom-iwear-global-table-text-color'}></i>
						</button>
					</Dropdown>*/}
					<Button onClick={() => { props.onEditData(_i); }}>
						<span>
							แก้ไข
						</span>
					</Button>
					&nbsp;
					<Button onClick={() => { props.onDeleteData(_i); }}>
						<span>
							ลบ
						</span>
					</Button>
				</td>
			</tr>
		);
	});
}

const renderTableFooter = (props) => {
	return (
		<TableFooter.Order
			seq={ props.tableData.length + 1 }

			qrValue={ props.productCode }
			qrSuggestion={[ ...props.suggestionDataProductCode ]}
			onChangeQR={ props.onChangeQR }
			onKeyEnterQR={ props.onScan }
			onSelectQR={(valueId, valueObj) => { props.onSelectProductCode(valueId); }}

			productValue={ props.productName }
			productSuggestion={[ ...props.suggestionDataProductName ]}
			onChangeProduct={ props.onChangeProduct }
			onSelectProduct={(valueId, valueObj) => { props.onSelectProductName(valueId); }}

			quantityValue={ props.productAmount }
			onChangeQuantity={ props.onChangeQuantity }

			priceValue={ props.productPrice }
			onChangePrice={ props.onChangePrice }

			onAddData={ props.onAddData }
		/>
	);
}

const renderOldEyeMeasureTable = (props) => {
	return (
		<EyeMeasureTableData
			type={'old'}
			tableName={'ค่าสายตาเก่า'}
			data={ props.oldMeasureData }
			setData={ props.onChangeOldMeasureData }
			disabled={true}
		/>
	);
}

const renderNewEyeMeasureTable = (props) => {
	return (
		<EyeMeasureTableData
			type={'new'}
			tableName={'ค่าสายตาใหม่'}
			data={ props.newMeasureData }
			setData={ props.onChangeNewMeasureData }
		/>
	);
}

const renderFirstSection = (props) => {
	return (
		<React.Fragment>
			<Row>
				<Col>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						1. ข้อมูลลูกค้า
					</span>
				</Col>
			</Row>
			<div style={{ margin: '20px' }}></div>

			<Row>
				<Col span={12}>

					<Row type={'flex'} align={'bottom'} justify={'center'}>
						<Col span={12}>
							<label className={'custom-iwear-global-font-color'}>
								* เบอร์โทรศัพท์
							</label>
							<Input
								required
								value={ props.tel }
								maxLength={13}
								onChange={ props.onChangeTel }
							/>
						</Col>
						<Col span={2}></Col>
						<Col span={10}>
							<Button
								onClick={ props.onClickSearch }
								disabled={ props.name.length <= 2 && props.tel.length <= 9 }
							>
								<i className={'fas fa-search'}></i>&nbsp;
								<span className={'custom-iwear-global-font-color'}>
									ค้นหา
								</span>
							</Button>
						</Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row>
						<Col span={12}>
							<label className={'custom-iwear-global-font-color'}>
								* ชื่อ-นามสกุล
							</label>
							<AutoComplete
								style={{ width: '100%' }}
								value={ props.name }
								dataSource={[ ...props.suggestionDataCustomerName ]}
								onChange={ props.onChangeName }
								onSelect={ props.onSelectName }
							/>
						</Col>
						<Col span={2}></Col>
						<Col span={10}>
							<label className={'custom-iwear-global-font-color'}>
								* เพศ
							</label><br />
							<Radio.Group
								value={ props.gender }
								buttonStyle={'solid'}
								onChange={ props.onChangeGender }
							>
								<Radio.Button disabled value={'male'}>
									<span className={'custom-iwear-global-table-text-color'}>
										ชาย
									</span>
								</Radio.Button>
								<Radio.Button disabled value={'female'}>
									<span className={'custom-iwear-global-table-text-color'}>
										หญิง
									</span>
								</Radio.Button>
							</Radio.Group>
						</Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row>
						<Col span={12}>
							<label className={'custom-iwear-global-font-color'}>
								วันเกิด
							</label>
							<Input
								disabled
								className={'custom-iwear-global-table-text-color'}
								value={ props.birthdate }
							/>
						</Col>
						<Col span={2}></Col>
						<Col span={10}>
							<label className={'custom-iwear-global-font-color'}>
								* อายุ
							</label><br />
							<InputNumber
								disabled
								value={ props.age }
								min={0}
								max={300}
							/>
						</Col>
					</Row>

				</Col>
				<Col span={12}>

					<Row gutter={8}>
						<Col span={8}>
							<label className={'custom-iwear-global-font-color'}>
								* ช่องทางลูกค้า
							</label>
							<CustomerChannelSelect
								disabled isFull
								selected={ props.customerChannel }
								onChange={ props.onChangeCustomerChannel }
							/>
						</Col>
						<Col span={8}>
							<label className={'custom-iwear-global-font-color'}>
								จังหวัด
							</label>
							<IWearDropdown
								isFull
								dropdownStyle={{ disabled: true }}
								displayValue={ props.province }
								choices={[]}
								onSelect={(selectedVal) => {}}
							/>
						</Col>
						<Col span={8}>
							<label className={'custom-iwear-global-font-color'}>
								เขต
							</label>
							<IWearDropdown
								isFull
								dropdownStyle={{ disabled: true }}
								displayValue={ props.addrZone }
								choices={[]}
								onSelect={(selectedVal) => {}}
							/>
						</Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row>
						<Col>
							<label className={'custom-iwear-global-font-color'}>
								ข้อมูลเพิ่มเติม
							</label>
							<Input.TextArea disabled value={ props.extInfo } />
						</Col>
					</Row>

				</Col>
			</Row>
		</React.Fragment>
	);
}

const renderSecondSection = (props) => {
	return (
		<React.Fragment>
			<Row>
				<Col>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						2. รายการสินค้า
					</span>
				</Col>
			</Row>
			<div style={{ margin: '20px' }}></div>
			<Row>
				<Col>
					{ renderTable(props) }
					<Row>
						<Col span={16}></Col>
						<Col span={8} style={{ textAlign: 'right' }}>
              <Row gutter={12} align={'middle'} justify={'end'}>
								<Col span={12}>
									<Checkbox onChange={ props.onTickIncludeVat }>
										VAT (7%)
									</Checkbox>
								</Col>
								<Col span={12}>
									<Checkbox onChange={ props.onTickAssemble }>
										ต้องการประกอบเอง
									</Checkbox>
								</Col>
							</Row>
							<ClaimProductModal
								originData={ props.tableData }
								productData={[ ...props.productData ]}
								isVisible={ props.claimModalVisible }
								setIsVisible={ props.onChangeClaimModalVisible }
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</React.Fragment>
	);
}

const renderThirdSection = (props) => {
	let _inspector = ['ไม่มีการตรวจวัดสายตา'];
	[ ...props.inspectorData ].forEach((_e) => { _inspector.push(_e.name); });

	return (
		<React.Fragment>
			<Row>
				<Col>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						3. การตรวจวัดสายตา
					</span>
				</Col>
			</Row>
			<div style={{ margin: '20px' }}></div>
			<Row>
				<Col span={8}>
					<div className={'custom-iwear-customer-create-inspector-wrapper'}>
						<span style={{ minWidth: '100px'}}>ผู้ตรวจวัดสายตา</span>&nbsp;&nbsp;
						<IWearDropdown
							isFull
							displayValue={ props.inspector.name }
							choices={[ ..._inspector ]}
							onSelect={ props.onSelectInspector }
						/>
					</div>
				</Col>
				<Col span={8}></Col>
				<Col span={8}>
					{/*<div className={'is-pulled-right'}>
						<span
							className={'custom-iwear-global-hover-cursor-pointer'}
							style={{ textDecorationLine: 'underline' }}
							onClick={() => { props.setMeasureModalVisible(true); }}
						>
							ดูประวัติค่าสายตา
						</span>
					</div>
					<EyeMeasurementHistoryModal
						data={[]}
						isVisible={ props.measureModalVisible }
						setIsVisible={ props.setMeasureModalVisible }
					/>*/}
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col span={12}>
					{ renderNewEyeMeasureTable(props) }
				</Col>
				<Col span={12}>
					{ renderOldEyeMeasureTable(props) }
				</Col>
			</Row>
		</React.Fragment>
	);
}

const renderFourthSection = (props) => {
	return (
		<React.Fragment>
			<Row>
				<Col>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						4. โปรโมชั่น
					</span>
				</Col>
			</Row>
			<div style={{ margin: '20px' }}></div>

			<Row>
				<Col>
					<div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
						<div className={'control'}>
							{ renderPromoList(props) }
						</div>
					</div>
				</Col>
			</Row>
			<div style={{ marginBottom: '30px' }}></div>

			<Row>
				<Col>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						5. ส่วนลด
					</span>
				</Col>
			</Row>
			<div style={{ margin: '20px' }}></div>

			<Row>
				<Col>
					<label className={'custom-iwear-global-font-color'}>
						<InputNumber
							disabled={ (props.priceSum === 0) }
							value={ props.promoValue }
							min={0}
							max={ (props.priceSum === 0) ? props.priceSum : props.priceSum }
							onChange={ props.onChangePromoValue }
						/>
						&nbsp;บาท
					</label>
				</Col>
			</Row>
		</React.Fragment>
	);
}

const renderFifthSection = (props) => {
	return (
		<React.Fragment>
			<Row>
				<Col>
					<Checkbox
						checked={ props.isPickUp }
						onChange={ props.onTickPickUpOption }
					>
						<span className={'is-size-6 custom-iwear-global-font-color'}>
							รับสินค้า
						</span>
					</Checkbox>
				</Col>
			</Row>
			{
				(props.isPickUp) && (
					<Row>
						<Col>
							<span className={'is-size-6 custom-iwear-global-font-color'}>
								วันที่รับสินค้า
							</span><br/>
							<DatePicker
								format={'DD/MM/YYYY'}
								allowClear={false}
								placeholder={'วันที่รับสินค้า'}
								onChange={ props.onChangePickUpDate }
								disabledDate={(current) => {
									if(props.isPastOrder) {
										return current < moment(props.pastDate).subtract(1, 'day').endOf('day');
									}
									else if(props.deliverDate) {
										return current < moment(props.deliverDate).subtract(1, 'day').endOf('day');
									}
									else {
										return current < moment().subtract(1, 'day').endOf('day');
									}
								}}
							/>
						</Col>
					</Row>
				)
			}
			<br/>
			<Row>
				<Col>
					<Checkbox
						checked={ props.isPastOrder }
						onChange={ props.onTickPastOrderOption }
					>
						<span className={'is-size-6 custom-iwear-global-font-color'}>
							ทำรายการย้อนหลัง
						</span>
					</Checkbox>
				</Col>
			</Row>
			{
				(props.isPastOrder) && (
					<Row>
						<Col>
							<span className={'is-size-6 custom-iwear-global-font-color'}>
								วันที่ทำรายการ
							</span><br/>
							<DatePicker
								format={'DD/MM/YYYY'}
								allowClear={false}
								placeholder={'วันที่ทำรายการ'}
								onChange={ props.onChangePastOrderDate }
							/>
						</Col>
					</Row>
				)
			}
		</React.Fragment>
	);
}

const renderPromoList = (props) => {
	return props.promoData.map((_e, _i) => {
		return (
			<React.Fragment key={_i}>
				<label className={'radio custom-iwear-global-font-color'}>
					<input
						readOnly
						checked={(props.promoType === _e.code)}
						type={'radio'}
						name={'promo'}
						onClick={() => {
							props.onChangePromoType(_e.code);
						}}
					/>&nbsp;&nbsp;
					{ _e.name }
				</label>
				<br/>
			</React.Fragment>
		);
	});
}

const renderSummary = (props) => {
	return (
		<div className={ styles.orderSummaryContainer }>
			<Row gutter={8} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={18}>

					<Row type={'flex'} align={'middle'} justify={'center'}>
						<Col span={8}>
							<span className={'is-size-6 custom-iwear-global-font-color'}>
								วันส่งมอบสินค้า (วันนัดหมาย)
							</span><br/>
							<DatePicker
								format={'DD/MM/YYYY'}
								allowClear={false}
								placeholder={'วันส่งมอบสินค้า (วันนัดหมาย)'}
								disabledDate={(current) => {
									if(props.isPastOrder) {
										return current < moment(props.pastDate).subtract(1, 'day').endOf('day');
									}
									else {
										return current < moment().subtract(1, 'day').endOf('day');
									}
								}}
								style={{ width: '100%' }}
								onChange={ props.onChangeDeliverDate }
							/>
						</Col>
						<Col span={8}>
							<Row type={'flex'} align={'middle'} justify={'center'}>
								<Col span={8}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										ยอดรวม
									</span>
								</Col>
								<Col span={8} style={{ textAlign: 'right' }}>
									<span className={'is-size-5 custom-iwear-global-font-color'}>
										<NumberFormat
											thousandSeparator
											displayType={'text'}
											value={ Number.parseFloat(props.priceSum).toFixed(2) }
										/>
									</span>
								</Col>
								<Col span={2} style={{ textAlign: 'right' }}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										&nbsp;บาท
									</span>
								</Col>
							</Row>
						</Col>
						<Col span={8}>
							<Row type={'flex'} align={'middle'} justify={'center'}>
								<Col span={10}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										ชำระเงิน
									</span>
								</Col>
								<Col span={8} style={{ textAlign: 'right' }}>
									<InputNumber
										className={'is-size-5'}
										disabled={ (props.priceSum === 0) }
										value={ props.payment }
										min={0}
										max={ (props.priceSum === 0) ? props.priceSum : props.priceSum - props.promoValue + props.vatValue }
										onChange={ props.onChangePayment }
									/>
								</Col>
								<Col span={2} style={{ textAlign: 'right' }}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										&nbsp;บาท
									</span>
								</Col>
							</Row>
						</Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row type={'flex'} align={'middle'} justify={'center'}>
						<Col span={8}>
							<span className={'is-size-6 custom-iwear-global-font-color'}>
								ชำระโดย
							</span><br/>
							<IWearDropdown
								isFull
								displayValue={ props.paymentType }
								choices={[ 'บัตรเครดิต', 'จ่ายผ่อน', 'เงินสด', 'โอนผ่าน Application' ]}
								onSelect={ props.onChangePaymentType }
							/>
						</Col>
						<Col span={8}>
							<Row type={'flex'} align={'middle'} justify={'center'}>
								<Col span={8}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										ส่วนลด
									</span>
								</Col>
								<Col span={8} style={{ textAlign: 'right' }}>
									<span className={'is-size-5 custom-iwear-global-font-color'}>
										<NumberFormat
											thousandSeparator
											displayType={'text'}
											value={ Number.parseFloat(props.promoValue).toFixed(2) }
										/>
									</span>
								</Col>
								<Col span={2} style={{ textAlign: 'right' }}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										&nbsp;บาท
									</span>
								</Col>
							</Row>
						</Col>
						<Col span={8}>
							<Row type={'flex'} align={'middle'} justify={'center'}>
								<Col span={10}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										รับเงิน
									</span>
								</Col>
								<Col span={8} style={{ textAlign: 'right' }}>
									<InputNumber
										className={'is-size-5'}
										disabled={ (props.priceSum === 0) }
										value={ props.moneyReceive }
										min={ props.payment || 0 }
										onChange={ props.onChangeMoneyReceive }
									/>
								</Col>
								<Col span={2} style={{ textAlign: 'right' }}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										&nbsp;บาท
									</span>
								</Col>
							</Row>
						</Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row type={'flex'} align={'middle'} justify={'center'}>
						<Col span={8}>
							<span className={'is-size-6 custom-iwear-global-font-color'}>
								หมายเหตุ
							</span><br/>
							<Input
								value={ props.remark }
								onChange={ props.onChangeRemark }
							/>
						</Col>
						<Col span={8}>
              <Row type={'flex'} align={'middle'} justify={'center'}>
                <Col span={8}>
                  <span className={'is-size-6 custom-iwear-global-font-color'}>
                    VAT (7%)
                  </span>
                </Col>
                <Col span={8} style={{ textAlign: 'right' }}>
									<span className={'is-size-5 custom-iwear-global-font-color'}>
                    <NumberFormat
                      thousandSeparator
                      displayType={'text'}
                      value={ Number.parseFloat(`${ props.vatValue || 0 }`).toFixed(2) }
                    />
									</span>
								</Col>
								<Col span={2} style={{ textAlign: 'right' }}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										&nbsp;บาท
									</span>
								</Col>
              </Row>
							<Row type={'flex'} align={'middle'} justify={'center'}>
								<Col span={8}>
									<span className={' is-size-6 custom-iwear-global-font-color'}>
										ราคาสุทธิ
									</span>
								</Col>
								<Col span={8} style={{ textAlign: 'right' }}>
									<span className={'is-size-5 custom-iwear-global-font-color'}>
										<NumberFormat
											thousandSeparator
											displayType={'text'}
											value={ Number.parseFloat(props.priceSum - props.promoValue + props.vatValue).toFixed(2) }
										/>
									</span>
								</Col>
								<Col span={2} style={{ textAlign: 'right' }}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										&nbsp;บาท
									</span>
								</Col>
							</Row>
						</Col>
						<Col span={8}>
							<Row type={'flex'} align={'middle'} justify={'center'}>
								<Col span={10}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										ยอดชำระคงค้าง
									</span>
								</Col>
								<Col span={8} style={{ textAlign: 'right' }}>
									<span className={'is-size-5 custom-iwear-global-font-color'}>
										<NumberFormat
											thousandSeparator
											displayType={'text'}
											value={ Number.parseFloat(`${ props.priceSum - props.payment - props.promoValue + props.vatValue }`).toFixed(2) }
										/>
									</span>
								</Col>
								<Col span={2} style={{ textAlign: 'right' }}>
									<span className={'is-size-6 custom-iwear-global-font-color'}>
										&nbsp;บาท
									</span>
								</Col>
							</Row>
						</Col>
					</Row>

				</Col>
				<Col span={6} style={{ height: '185px' }}>
					<Button
						type={'primary'} block
						style={{ height: '100%' }}
						loading={ props.isSubmitting }
						onClick={ props.onClickPurchase }
					>
						<span className={'is-size-3'}>
							สั่งซื้อ
						</span>
					</Button>
				</Col>
			</Row>
		</div>
	);
}

Content.propTypes = ContentPropTypes;

export default Content;
