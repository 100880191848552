/**
 *	iWear - DataProduct Add/Edit modal
 */

import React from 'react';
import {
	Modal,
	Input,
	DatePicker
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

const AdminDataPromotionModal = (props) => {
	return (
		<Modal
			title={ props.title }
			visible={ props.visible }
			onOk={ props.onClickConfirm }
			onCancel={ props.onClickCancel }
		>
			<div className={'field'}>
				<label className={'label'}>รหัสโปรโมชั่น</label>
				<div className={'control'}>
					<Input
						disabled={ (props.modalType === 'REMOVE') }
						allowClear
						value={ props.code }
						onChange={ props.onChangeCode }
					/>
				</div>
			</div>
			<div style={{ margin: '10px' }}></div>
			<div className={'field'}>
				<label className={'label'}>ชื่อโปรโมชั่น</label>
				<div className={'control'}>
					<Input
						disabled={ (props.modalType === 'REMOVE') }
						allowClear
						value={ props.name }
						onChange={ props.onChangeName }
					/>
				</div>
			</div>
			<div style={{ margin: '10px' }}></div>
			<div className={'field'}>
				<label className={'label'}>วันสิ้นสุด</label>
				<div className={'control'}>
					{
						(props.modalType === 'ADD') && (
							<DatePicker
								value={ props.expDate.value }
								format={'DD/MM/YYYY'}
								allowClear={false}
								disabledDate={(current) => { return current && current < moment().endOf('day'); }}
								style={{ width: '100%' }}
								onChange={(dateObj, stringValue) => {
									props.onChangeExpDate({
										value: dateObj,
										dateString: dateObj.toDate().toISOString()
									});
								}}
							/>
						)
					}
					{
						(props.modalType === 'EDIT') && (
							<DatePicker
								value={ (props.visible) ?
									(props.expDate.value === null)
										? moment(props.expDate.dateString)
										: props.expDate.value
									: null }
								format={'DD/MM/YYYY'}
								allowClear={false}
								disabledDate={(current) => { return current && current < moment().endOf('day'); }}
								style={{ width: '100%' }}
								onChange={(dateObj, stringValue) => {
									props.onChangeExpDate({
										value: dateObj,
										dateString: dateObj.toDate().toISOString()
									});
								}}
							/>
						)
					}
					{
						(props.modalType === 'REMOVE') && (
							<DatePicker
								disabled
								value={ (props.visible) ?
									(props.expDate.value === null)
										? moment(props.expDate.dateString)
										: props.expDate.value
									: null }
								format={'DD/MM/YYYY'}
								allowClear={false}
								style={{ width: '100%' }}
								onChange={(dateObj, stringValue) => {}}
							/>
						)
					}
				</div>
			</div>
		</Modal>
	);
}

AdminDataPromotionModal.propTypes = {
	title: PropTypes.string.isRequired,
	visible: PropTypes.bool.isRequired,
	modalType: PropTypes.string.isRequired,
	code: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	expDate: PropTypes.object.isRequired,
	onClickConfirm: PropTypes.func.isRequired,
	onClickCancel: PropTypes.func.isRequired,
	onChangeCode: PropTypes.func.isRequired,
	onChangeName: PropTypes.func.isRequired,
	onChangeExpDate: PropTypes.func.isRequired,
}

export default AdminDataPromotionModal;
