/**
 *	Pages - Sales pages path
 */

import { ROLE } from '../environments';

import Sales from '../features/sales';
import SalesDashboard from '../features/sales/dashboard';
import SalesAppointment from '../features/sales/appointment';
import SalesCustomerList from '../features/sales/customers/list';
import SalesCustomerCreate from '../features/sales/customers/add';
import SalesCustomerUpdate from '../features/sales/customers/edit';
import SalesCustomerDetail from '../features/sales/customers/detail';
import SalesOrderList from '../features/sales/order/list';
import SalesOrderCreateNew from '../features/sales/order/add-new';
import SalesOrderCreateOld from '../features/sales/order/add-old';
import SalesOrderDetail from '../features/sales/order/detail';
import SalesStock from '../features/sales/stock/list';
import SalesExportList from '../features/sales/stock/export-list';
import SalesExportListDetail from '../features/sales/stock/export-detail';
import SalesExportInventory from '../features/sales/stock/export-inventory';
import SalesImportList from '../features/sales/stock/import-list';
import SalesImportListDetail from '../features/sales/stock/import-detail';
import SalesImportInventoryBill from '../features/sales/stock/import-inventory-bill';

const saleRoutes = [
	{ path: '/sales/auth', component: Sales, role: ROLE.sales },
	{ path: '/sales/dashboard', component: SalesDashboard, role: ROLE.sales },
	{ path: '/sales/appointment', component: SalesAppointment, role: ROLE.sales },
	{ path: '/sales/customer', component: SalesCustomerList, role: ROLE.sales },
	{ path: '/sales/customer/create', component: SalesCustomerCreate, role: ROLE.sales },
	{ path: '/sales/customer/update', component: SalesCustomerUpdate, role: ROLE.sales },
	{ path: '/sales/customer/detail', component: SalesCustomerDetail, role: ROLE.sales },
	{ path: '/sales/order', component: SalesOrderList, role: ROLE.sales },
	{ path: '/sales/order/create/new', component: SalesOrderCreateNew, role: ROLE.sales },
	{ path: '/sales/order/create/old', component: SalesOrderCreateOld, role: ROLE.sales },
	{ path: '/sales/order/detail', component: SalesOrderDetail, role: ROLE.sales },
	{ path: '/sales/stock', component: SalesStock, role: ROLE.sales },
	{ path: '/sales/stock/export', component: SalesExportList, role: ROLE.sales },
	{ path: '/sales/stock/export/detail', component: SalesExportListDetail, role: ROLE.sales },
	{ path: '/sales/stock/export/inventory', component: SalesExportInventory, role: ROLE.sales },
	{ path: '/sales/stock/import', component: SalesImportList, role: ROLE.sales },
	{ path: '/sales/stock/import/detail', component: SalesImportListDetail, role: ROLE.sales },
	{ path: '/sales/stock/import/inventory', component: SalesImportInventoryBill, role: ROLE.sales }
];

export default saleRoutes;
