/**
 *	iWear - Stock Export Detail Container
 *
 *		@using React Hook
 */

import React from 'react';
import { Layout } from 'antd';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import WebCookie from '../../../../lib/web-cookie';
import { API_ENDPOINT } from '../../../../environments';
import Content from './stock-export-detail.content';
import { ContainerPropTypes } from './stock-export-detail.model';

const Container = (props) => {
	const [ tableData, setTableData ] = React.useState([]);

	React.useEffect(() => {
		fetchData(props.location.state.exchangeCode);
	}, [ props.location.state.exchangeCode ]);

	const fetchData = async (exchangeCode) => {
		const res = await fetch(`${API_ENDPOINT}/exchanges?export_code=${encodeURIComponent(exchangeCode)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		const json = await res.json();
		const _data = (json && json.list && json.list.length > 0 && json.list[0].exchange_detail) ? json.list[0].exchange_detail : [{}]
		setTableData(_data);
	}

	const onClickGoBack = () => {
		props.history.goBack();
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/stock/export'} history={ props.history } />
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/stock/export'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						tableData={ tableData }
						onClickGoBack={ onClickGoBack }
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
