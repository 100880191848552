/**
 *	iWear - Data Product Upload screen
 */

import React from 'react';
import {
	Row,
	Col,
	Button,
	Upload
} from 'antd';
import PropTypes from 'prop-types';

import Breadcrumb from '../../../../../components/breadcrumb';
import IWearDropdown from '../../../../../components/dropdown';

const renderOptions = (props) => {
	return (
		<div className={'custom-iwear-global-table-option-panel'}>
			<Row type={'flex'} align={'middle'} justify={'space-between'}>
				<Col>
					<span
						className={'custom-iwear-global-font-color is-size-5'}
						style={{ textDecorationLine: 'underline' }}
					>
						เพิ่มสินค้า
					</span>
				</Col>
				<Col>
					<Button loading={ props.isLoading } onClick={ props.onClickConfirm }>
						<span className={'custom-iwear-global-font-color'}>
							ยืนยันการเพิ่มสินค้า
						</span>
					</Button>
				</Col>
			</Row>
		</div>
	);
}

const renderContent = (props) => {
	let _typeArr = []; let _branchArr = [];
	props.productTypeList.forEach((_e) => { _typeArr.push(_e.name) });
	props.branchList.forEach((_e) => { _branchArr.push(_e.branch_name); });

	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
			<Row>
				<Col span={24}>
					{ renderOptions(props) }
				</Col>
			</Row>
			<Row gutter={8}>
				<Col span={8}>
					<div className={'custom-iwear-global-table-container'}>
						<span className={'custom-iwear-global-font-color'}>
							* สาขา
						</span>
						<IWearDropdown
							isFull
							displayValue={ props.branch }
							choices={[ ..._branchArr ]}
							onSelect={ props.onChangeBranch }
						/>
					</div>
				</Col>
				<Col span={8}>
					<div className={'custom-iwear-global-table-container'}>
						<label className={'custom-iwear-global-font-color'}>
							* ชนิดสินค้า
						</label>
						<IWearDropdown
							isFull
							displayValue={ props.productType }
							choices={[ ..._typeArr ]}
							onSelect={ props.onChangeProductType }
						/>
					</div>
				</Col>
				<Col span={8}></Col>
			</Row>
			<Row>
				<Col span={24}>
					<div className={'custom-iwear-global-table-container'}>
						<Upload
							accept={'application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}
							customRequest={({ file, onSuccess }) => { setTimeout(() => { onSuccess('ok'); }, 0); }}
							listType={'text'}
							onChange={ props.onChangeFile }
						>
							<Button>
								<i className={'fas fa-file-upload'}></i>&nbsp;อัพโหลดไฟล์ Excel
							</Button>
						</Upload>
					</div>
				</Col>
			</Row>
			<Row type={'flex'} align={'middle'} justify={'end'}>
				<Col style={{ paddingBottom: '10px', paddingRight: '20px' }}>
					<Button
						loading={ props.isLoading }
						onClick={ props.onClickConfirm }
					>
						<span className={'custom-iwear-global-font-color'}>
							ยืนยันการเพิ่มสินค้า
						</span>
					</Button>
				</Col>
			</Row>
		</div>
	);
}

const DataProductUpload = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'การจัดการข้อมูล', 'สินค้า', 'อัพโหลด' ]} withGoBack onClickGoBack={ props.onClickGoBack } />
			{ renderContent(props) }
		</div>
	);
}

DataProductUpload.propTypes = {
	file: PropTypes.object.isRequired,
	productType: PropTypes.string.isRequired,
	productTypeList: PropTypes.array.isRequired,
	frameType: PropTypes.string.isRequired,
	frameTypeList: PropTypes.array.isRequired,
	lensType: PropTypes.string.isRequired,
	lensTypeList: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,

	onChangeFile: PropTypes.func.isRequired,
	onChangeProductType: PropTypes.func.isRequired,
	onChangeFrameType: PropTypes.func.isRequired,
	onChangeLensType: PropTypes.func.isRequired,
	onClickConfirm: PropTypes.func.isRequired,
	onClickGoBack: PropTypes.func.isRequired,

	globalReducer: PropTypes.object.isRequired
}

export default DataProductUpload;
