/**
 *	Login - Container
 */

import React from 'react';
import swal from 'sweetalert';
import { Layout } from 'antd';

import WebCookie from '../../../lib/web-cookie';
import { API_ENDPOINT } from '../../../environments';
import GlobalStore from '../../../store';
import LocalStore from './login.store';
import LoginScreen from './login.content';
import { ContainerPropTypes, Actions } from './login.model';

const Container = (props) => {
	const LoginReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		if(WebCookie.get('iwear_credential') &&
			localStorage.getItem('iwear_accessor') !== null &&
			JSON.parse(localStorage.getItem('iwear_accessor')).user_info.permission === 'MANAGER'
		) {
			props.history.push('/admin/auth');
		}
		else if(WebCookie.get('iwear_credential') &&
			localStorage.getItem('iwear_accessor') !== null &&
			JSON.parse(localStorage.getItem('iwear_accessor')).user_info.permission === 'STAFF'
		) {
			props.history.push('/sales/auth');
		}
		else if(WebCookie.get('iwear_credential') &&
			localStorage.getItem('iwear_accessor') !== null &&
			JSON.parse(localStorage.getItem('iwear_accessor')).user_info.permission === 'RXMALL'
		) {
			props.history.push('/lab/auth');
		}
	}, [ props.history ]);

	const onChangeUsername = (evt) => {
		LoginReducer.dispatch({ type: Actions.SET_USERNAME, value: evt.target.value });
	}

	const onChangePassword = (evt) => {
		LoginReducer.dispatch({ type: Actions.SET_PASSWORD, value: evt.target.value });
	}

	const onSubmit = (evt) => {
		evt.preventDefault();
		LoginReducer.dispatch({ type: Actions.SET_IS_SUBMITTING, value: true });

		if(LoginReducer.state.username.length < 4 || LoginReducer.state.password.length < 8) {
			if(LoginReducer.state.username.length < 4) {
				LoginReducer.dispatch({ type: Actions.SET_ERROR_USERNAME, value: true });
			}
			else {
				LoginReducer.dispatch({ type: Actions.SET_ERROR_USERNAME, value: false });
			}

			if(LoginReducer.state.password.length < 8) {
				LoginReducer.dispatch({ type: Actions.SET_ERROR_PASSWORD, value: true });
			}
			else {
				LoginReducer.dispatch({ type: Actions.SET_ERROR_PASSWORD, value: false });
			}

			LoginReducer.dispatch({ type: Actions.SET_IS_SUBMITTING, value: false });
		}
		else {
			LoginReducer.dispatch({ type: Actions.SET_ERROR_USERNAME, value: false });
			LoginReducer.dispatch({ type: Actions.SET_ERROR_PASSWORD, value: false });
			authorization();
		}
	}

	const authorization = async () => {
		let res = await fetch(`${API_ENDPOINT}/login`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				username: LoginReducer.state.username,
				password: LoginReducer.state.password
			})
		});

		let json = await res.json();

		// console.log('LoginResponse', res);
		if(res.status === 200) {
			WebCookie.set('iwear_credential', json.token, { path: '/', expires: new Date(json.exp * 1000) });
			localStorage.setItem('iwear_accessor', JSON.stringify({ ...json }));
			dispatch({
				type: 'SIGN_IN',
				userData: json.user_info || {},
				branchData: json.branch_info || {},
				token: json.token
			});

			if(json.user_info.permission === 'MANAGER') {
				LoginReducer.dispatch({ type: Actions.SET_IS_SUBMITTING, value: false });
				props.history.push('/admin/auth');
			}
			else if(json.user_info.permission === 'STAFF') {
				LoginReducer.dispatch({ type: Actions.SET_IS_SUBMITTING, value: false });
				props.history.push('/sales/auth');
			}
			else if(json.user_info.permission === 'RXMALL') {
				LoginReducer.dispatch({ type: Actions.SET_IS_SUBMITTING, value: false });
				props.history.push('/lab/auth');
			}
			else {
				swal({
					title: 'Permission Denied',
					text: 'Please contact dev team',
					icon: 'error'
				});
			}
		}
		else {
			LoginReducer.dispatch({ type: Actions.SET_IS_SUBMITTING, value: false });
			swal({
				title: 'Something went wrong!',
				text: 'Your username or password are incorrect',
				icon: 'error'
			});
		}
	}

	return (
		<Layout>
			<Layout.Content>
				<LoginScreen
					{ ...LoginReducer.state }

					onChangeUsername={ onChangeUsername }
					onChangePassword={ onChangePassword }
					onSubmit={ onSubmit }

					globalReducer={{ state, dispatch }}
				/>
			</Layout.Content>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
