/**
 *    Order To PDF component
 */

import React from 'react';
import {
    Row,
    Col
} from 'antd';
import moment from 'moment';

import styles from './to-pdf-order.module.css';

const HTMLRadio = (props) => {
    return (
        <span>
			<input
                className={`${props.className}`}
                defaultChecked={props.checked}
                type={'radio'}
                value={props.value}
            />&nbsp;
            <label className={styles.labelSize}>{props.label}</label>
		</span>
    );
}

const HTMLCheckbox = (props) => {
    return (
        <span>
			<input
                className={`${props.className}`}
                defaultChecked={props.checked}
                type={'checkbox'}
                value={props.value}
            />&nbsp;
            <label className={styles.labelSize}>{props.label}</label>
		</span>
    );
}

class OrderToPDF extends React.Component {

    customDateDiffToday = (toCompare, comparator) => {
        let _date = moment(new Date(toCompare));
        let _today = moment();
        return _date.isBefore(_today, 'day');
    }

    renderTopInfoSection = () => {
        return (
            <div>
                <Row gutter={24}>
                    <Col span={14}>
                        <div className={styles.logoContainer}>
                            <img
                                src={process.env.PUBLIC_URL + '/static/images/iWearLogo.svg'}
                                alt={'iWear Logo'}
                            />
                        </div>
                        <div className={styles.rxmallRemarkBox}>
                            <p className={styles.dotFiller}>
								<span className={styles.dotContent}>
									<strong>หมายเหตุ / เพื่อ Rx Mall</strong>
								</span>
                            </p>
                            <p className={styles.dotFiller}>
                                <span className={styles.dotContent}></span>
                            </p>
                            <p className={styles.dotFiller}>
                                <span className={styles.dotContent}></span>
                            </p>
                            <p className={styles.dotFiller}>
                                <span className={styles.dotContent}></span>
                            </p>
                        </div>
                    </Col>
                    <Col span={10}>
                        <Row>
                            <Col>
                                <div className={styles.orderDateBox}>
									<span>
										<strong>เลขที่สั่งซื้อ</strong>&nbsp;{(this.props.data && this.props.data.order_code) ? this.props.data.order_code : ''}
									</span>
                                </div>
                            </Col>
                        </Row>
                        <div className={styles.spacing}></div>
                        <Row gutter={12} type={'flex'}>
                            <Col>
								<span>
									<strong>วัน/เดือน/ปี</strong>&nbsp;{(this.props.data && this.props.data.create_at) ? moment(this.props.data.create_at).format('DD/MM/') : ''}{(this.props.data && this.props.data.create_at) ? Number.parseInt(moment(this.props.data.create_at).format('YYYY'), 10) + 543 : ''}
								</span>
                            </Col>
                            <Col>
								<span>
									<strong>วันนัด</strong>&nbsp;{(this.props.data && this.props.data.receive_date) ? moment(this.props.data.receive_date).format('DD/MM/') : ''}{(this.props.data && this.props.data.receive_date) ? Number.parseInt(moment(this.props.data.receive_date).format('YYYY'), 10) + 543 : ''}
								</span>
                            </Col>
                        </Row>
                        <div className={styles.spacing}></div>
                        <Row>
                            <Col span={24}>
                                <span>เงื่อนไขการรับประกัน </span>
                            </Col>
                            <Col span={24}>
                                <span style={{fontSize: '8px'}}>1. รับประกัน เลนส์คุณภาพการ Coating 90 วัน (นับจากวันที่ ลูกค้ารับสินค้า)</span>
                            </Col>
                            <Col span={24}>
                                <span style={{fontSize: '8px'}}>2. รับประกัน ค่าสายตาถูกต้อง 30 วัน (นับจากวันที่ ลูกค้ารับสินค้า)</span>
                            </Col>
                            <Col span={24}>
                                <span style={{fontSize: '8px'}}>3. รับประกัน กรอบ สีลอก หลุดรอยเชื่อม 90 วัน (นับจากวันที่ ลูกค้ารับสินค้า)</span>
                            </Col>
                            <Col span={24}>
                                <span
                                    style={{fontSize: '8px'}}>4. รับประกัน แป้นจมูก และน๊อตต่าง ๆ ตลอดอายุการใช้งาน</span>
                            </Col>
                            <Col span={24}>
                                <span style={{fontSize: '8px'}}>5. กรณีลูกค้านำกรอบมาเอง ทางบริษัทฯ จะไม่รับผิดชอบความเสียหายที่เกิดขึ้นจากการประกอบ</span>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className={styles.spacing}></div>
                <Row gutter={12} type={'flex'} align={'top'}>
                    <Col span={10}>
                        <Row gutter={8}>
                            <Col span={8}>
                                <HTMLRadio
                                    checked={(this.props.data && this.props.data.customer_info) ? this.customDateDiffToday(this.props.data.customer_info.create_at) : false}
                                    value={'old-customer'}
                                    label={'ลูกค้าสมาชิก'}
                                />
                            </Col>
                            <Col span={12}>
                                <HTMLRadio
                                    checked={(this.props.data && this.props.data.customer_info) ? !this.customDateDiffToday(this.props.data.customer_info.create_at) : false}
                                    value={'new-customer'}
                                    label={'ลูกค้าใหม่'}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={14}>
                        <Row gutter={8}>
                            <Col span={8}>
                                <HTMLCheckbox
                                    checked={(this.props.data && this.props.data.customer_info && this.props.data.customer_info.customer_channel.includes('ช่องทางออนไลน์')) ? true : false}
                                    value={'online-channel'}
                                    label={'ช่องทางออนไลน์'}
                                />
                            </Col>
                            <Col span={8}>
                                <HTMLCheckbox
                                    checked={(this.props.data && this.props.data.customer_info && this.props.data.customer_info.customer_channel.includes('ลูกค้า Walk in')) ? true : false}
                                    value={'walk-in-channel'}
                                    label={'ลูกค้า Walk in'}
                                />
                            </Col>
                            <Col span={8}>
                                <HTMLCheckbox
                                    checked={(this.props.data && this.props.data.customer_info && this.props.data.customer_info.customer_channel.includes('ลูกค้าบอกต่อ')) ? true : false}
                                    value={'gossip-channel'}
                                    label={'ลูกค้าบอกต่อ'}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className={styles.spacing}></div>
                <div className={styles.spacing}></div>
                <Row gutter={8} type={'flex'} align={'middle'}>
                    <Col span={10}>
                        {/*<p className={ styles.dotFiller }>
							<span className={ styles.dotContent }>
								ชื่อ-นามสกุล&nbsp;:
							</span>
						</p>*/}
                        <span>
							<strong>ชื่อ-นามสกุล</strong>:&nbsp;{(this.props.data && this.props.data.customer_info) ? this.props.data.customer_info.full_name : '-'}
						</span>
                    </Col>
                    <Col span={6}>
                        {/*<p className={ styles.dotFiller }>
							<span className={ styles.dotContent }>
								อายุ&nbsp;:
							</span>
						</p>*/}
                        <span>
							<strong>อายุ</strong>:&nbsp;{(this.props.data && this.props.data.customer_info) ? this.props.data.customer_info.age : '-'}
						</span>
                    </Col>
                    <Col span={6}>
                        <span>เพศ&nbsp;:</span>&nbsp;
                        <HTMLRadio
                            checked={(this.props.data && this.props.data.customer_info && this.props.data.customer_info.sex) === 'male'}
                            value={'male'}
                            label={'ชาย'}
                        />&nbsp;&nbsp;&nbsp;
                        <HTMLRadio
                            checked={(this.props.data && this.props.data.customer_info && this.props.data.customer_info.sex) === 'female'}
                            value={'female'}
                            label={'หญิง'}
                        />
                    </Col>
                </Row>
                <div className={styles.spacing}></div>
                <Row gutter={8} type={'flex'} align={'middle'}>
                    <Col span={10}>
                        {/*<p className={ styles.dotFiller }>
							<span className={ styles.dotContent }>
								เบอร์ติดต่อ&nbsp;:
							</span>
						</p>*/}
                        <span>
							<strong>เบอร์ติดต่อ</strong>:&nbsp;{(this.props.data && this.props.data.customer_info) ? this.props.data.customer_info.phone_number : '-'}
						</span>
                    </Col>
                    <Col span={14}>
                        {/*<p className={ styles.dotFiller }>
							<span className={ styles.dotContent }>
								ที่อยู่&nbsp;:
							</span>
						</p>*/}
                        <span>
							<strong>ที่อยู่</strong>:&nbsp;{(this.props.data && this.props.data.customer_info) ? this.props.data.customer_info.province : ''}&nbsp;{(this.props.data && this.props.data.customer_info) ? this.props.data.customer_info.district : ''}
						</span>
                    </Col>
                </Row>
            </div>
        );
    }

    renderEyeMeasureTableValue = () => {
        return (
            <div>
                <table className={styles.table}>
                    <thead>
                    <tr>
                        <th className={styles.tableHead} style={{borderLeft: '1px solid #2E3091'}}></th>
                        <th className={styles.tableHead}>
                            Sphere
                        </th>
                        <th className={styles.tableHead}>
                            CYL.
                        </th>
                        <th className={styles.tableHead}>
                            AXIS.
                        </th>
                        <th className={styles.tableHead}>
                            ADD.
                        </th>
                        <th className={styles.tableHead}>
                            DPD.
                        </th>
                        <th className={styles.tableHead}>
                            NPD.
                        </th>
                        <th className={styles.tableHead}>
                            SegHT.
                        </th>
                        <th className={styles.tableHead}>
                            Prism
                        </th>
                        <th className={styles.tableHead} style={{fontSize: '0.75em'}}>
                            MONO-VA
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td className={styles.tableBody}>O.D[R]</td>
                        <td className={styles.tableBody}>
                            {/* Sphere */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? (this.props.data.eye_info[this.props.data.eye_info.length - 1].sphere_right > 0)
                                        ? `+${Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].sphere_right}`).toFixed(2)}`
                                        : (this.props.data.eye_info[this.props.data.eye_info.length - 1].sphere_right < 0)
                                            ? `${Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].sphere_right}`).toFixed(2)}`
                                            : 0.00
                                    : 0.00
                            }
                        </td>
                        <td className={styles.tableBody}>
                            {/* CYL. */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? (this.props.data.eye_info[this.props.data.eye_info.length - 1].cyl_right > 0)
                                        ? `+${Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].cyl_right}`).toFixed(2)}`
                                        : (this.props.data.eye_info[this.props.data.eye_info.length - 1].cyl_right < 0)
                                            ? `${Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].cyl_right}`).toFixed(2)}`
                                            : 0.00
                                    : 0.00
                            }
                        </td>
                        <td className={styles.tableBody}>
                            {/* AXIS. */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].axis_right}`).toFixed(2)
                                    : 0.00
                            }
                        </td>
                        <td className={styles.tableBody}>
                            {/* ADD. */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].add_right}`).toFixed(2)
                                    : 0.00
                            }
                        </td>
                        <td className={styles.tableBody}>
                            {/* DPD. */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].dpd_right}`).toFixed(2)
                                    : 0.00
                            }
                        </td>
                        <td className={styles.tableBody}>
                            {/* NPD. */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].npd_right}`).toFixed(2)
                                    : 0.00
                            }
                        </td>
                        <td className={styles.tableBody}>
                            {/* SegHT. */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].seg_ht_right}`).toFixed(2)
                                    : 0.00
                            }
                        </td>
                        <td className={styles.tableBody}>
                            {/* Prism */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].prism_right}`).toFixed(2)
                                    : 0.00
                            }
                        </td>
                        <td className={styles.tableBody}>
                            {/* MONO-VA */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? this.props.data.eye_info[this.props.data.eye_info.length - 1].mono_va_right
                                    : '-'
                            }
                        </td>
                    </tr>
                    <tr>
                        <td className={styles.tableBody}>0.S[L]</td>
                        <td className={styles.tableBody}>
                            {/* Sphere */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? (this.props.data.eye_info[this.props.data.eye_info.length - 1].sphere_left > 0)
                                        ? `+${Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].sphere_left}`).toFixed(2)}`
                                        : (this.props.data.eye_info[this.props.data.eye_info.length - 1].sphere_left < 0)
                                            ? `${Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].sphere_left}`).toFixed(2)}`
                                            : 0.00
                                    : 0.00
                            }
                        </td>
                        <td className={styles.tableBody}>
                            {/* CYL. */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? (this.props.data.eye_info[this.props.data.eye_info.length - 1].cyl_left > 0)
                                        ? `+${Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].cyl_left}`).toFixed(2)}`
                                        : (this.props.data.eye_info[this.props.data.eye_info.length - 1].cyl_left < 0)
                                            ? `${Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].cyl_left}`).toFixed(2)}`
                                            : 0.00
                                    : 0.00
                            }
                        </td>
                        <td className={styles.tableBody}>
                            {/* AXIS. */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].axis_left}`).toFixed(2)
                                    : 0.00
                            }
                        </td>
                        <td className={styles.tableBody}>
                            {/* ADD. */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].add_left}`).toFixed(2)
                                    : 0.00
                            }
                        </td>
                        <td className={styles.tableBody}>
                            {/* DPD. */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].dpd_left}`).toFixed(2)
                                    : 0.00
                            }
                        </td>
                        <td className={styles.tableBody}>
                            {/* NPD. */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].npd_left}`).toFixed(2)
                                    : 0.00
                            }
                        </td>
                        <td className={styles.tableBody}>
                            {/* SegHT. */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].seg_ht_left}`).toFixed(2)
                                    : 0.00
                            }
                        </td>
                        <td className={styles.tableBody}>
                            {/* Prism */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? Number.parseFloat(`${this.props.data.eye_info[this.props.data.eye_info.length - 1].prism_left}`).toFixed(2)
                                    : 0.00
                            }
                        </td>
                        <td className={styles.tableBody}>
                            {/* MONO-VA */}
                            {
                                (this.props.data && this.props.data.eye_info && this.props.data.eye_info.length > 0)
                                    ? this.props.data.eye_info[this.props.data.eye_info.length - 1].mono_va_left
                                    : '-'
                            }
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    renderOrderTable = () => {
        if (this.props.data && this.props.data.order_detail) {
            return (
                <div>
                    <span>รายการสินค้า</span><br/>
                    <table className={styles.table}>
                        <thead>
                        <tr>
                            <th className={styles.tableHead}
                                style={{maxWidth: '20px', borderLeft: '1px solid #2E3091'}}>
                                ลำดับ
                            </th>
                            <th className={styles.tableHead}>
                                รหัสสินค้า
                            </th>
                            <th className={styles.tableHead}>
                                สินค้า
                            </th>
                            <th className={styles.tableHead}>
                                จำนวน
                            </th>
                            <th className={styles.tableHead}>
                                ราคา
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.data.order_detail.map((_e, _i) => (
                                <tr key={_i}>
                                    <td className={styles.tableBody} style={{maxWidth: '20px'}}>
                                        {_i + 1}
                                    </td>
                                    <td className={styles.tableBody}>
                                        {_e.product_info.item_code}
                                    </td>
                                    <td className={styles.tableBody}>
                                        {_e.product_info.name}
                                    </td>
                                    <td className={styles.tableBody}>
                                        {_e.quantity}
                                    </td>
                                    <td className={styles.tableBody}>
                                        {_e.product_info.price}
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            );
        } else {
            return null;
        }
    }

    renderPrimaryRxSection = () => {
        return (
            <div>
                <div className={styles.rxPanel}>
                    <span>Primary Rx.</span>
                </div>
                {this.renderOrderTable()}
                <div className={styles.spacingSmall}></div>
                <Row gutter={24}>
                    <Col span={12}>
						<span>
							<strong>Lens Type</strong>
							:&nbsp;&nbsp;
                            <span
                                className={styles.labelSize}>{(this.props.data && this.props.data.lens) ? this.props.data.lens.type : '-'}</span>
						</span>
                    </Col>
                    <Col span={12}>
                        <Row gutter={24}>
                            <Col span={16}>
                                <div className={styles.verticalLine}></div>
                                <p className={styles.dotFiller}>
									<span className={styles.dotContent}>
										<strong>ทำสี (Tint)</strong>
									</span>
                                </p>
                            </Col>
                            <Col span={8} style={{position: 'relative'}}>
                                <HTMLRadio
                                    checked={false}
                                    value={'fill-color'}
                                    label={'เต็ม'}
                                />&nbsp;&nbsp;&nbsp;
                                <HTMLRadio
                                    checked={false}
                                    value={'gradient-color'}
                                    label={'ไล่สี'}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div className={styles.spacingSmall}></div>
                <Row gutter={24}>
                    <Col span={8}>
                        <p className={styles.dotFiller}>
							<span className={styles.dotContent}>
								VA แว่นเดิม
							</span>
                        </p>
                    </Col>
                    <Col span={8}>
                        <p className={styles.dotFiller}>
							<span className={styles.dotContent}>
								เลนส์เดิม R&nbsp;:
							</span>
                        </p>
                    </Col>
                    <Col span={8}>
                        <p className={styles.dotFiller}>
							<span className={styles.dotContent}>
								เลนส์เดิม L&nbsp;:
							</span>
                        </p>
                    </Col>
                </Row>
                <div className={styles.spacingSmall}></div>
                <Row gutter={24}>
                    <Col span={4} style={{position: 'relative'}}>
                        <span>Material Index</span>
                        <br/>
                        <HTMLRadio
                            value={'1.5'}
                            label={'1.5'}
                        />
                        <br/>
                        <HTMLRadio
                            value={'1.56'}
                            label={'1.56'}
                        />
                        <br/>
                        <HTMLRadio
                            value={'1.6'}
                            label={'1.6'}
                        />
                        <br/>
                        <HTMLRadio
                            value={'1.67'}
                            label={'1.67'}
                        />
                        <br/>
                        <HTMLRadio
                            value={'1.74'}
                            label={'1.74'}
                        />
                        <br/>
                        <HTMLRadio
                            value={'Poly'}
                            label={'Poly'}
                        />
                        <br/>
                        <HTMLRadio
                            value={'Other'}
                            label={'Other'}
                        />
                    </Col>
                    <Col span={7} style={{position: 'relative'}}>
                        <span>Option</span><br/>
                        <HTMLRadio
                            value={'Clear Lens'}
                            label={'Clear Lens'}
                        /><br/>
                        <HTMLRadio
                            value={'Blue UV420'}
                            label={'Blue UV420'}
                        /><br/>
                        <HTMLRadio
                            value={'Blue'}
                            label={'Blue'}
                        /><br/>
                        <HTMLRadio
                            value={'Photochromic ..............................'}
                            label={'Photochromic ..............................'}
                        /><br/>
                        <HTMLRadio
                            value={'Transition ..............................'}
                            label={'Transition ..............................'}
                        /><br/>
                        <HTMLRadio
                            value={'Polarized ..............................'}
                            label={'Polarized ..............................'}
                        /><br/>
                        <HTMLRadio
                            value={'Other (อื่นๆ) ..............................'}
                            label={'Other (อื่นๆ) ..............................'}
                        />
                        <div className={styles.verticalLine} style={{height: '170px'}}></div>
                    </Col>
                    <Col span={4} style={{position: 'relative'}}>
                        <span>Lens Design</span><br/>
                        <HTMLRadio
                            value={'Single Vision'}
                            label={'Single Vision'}
                        /><br/>
                        <HTMLRadio
                            value={'Bifocal'}
                            label={'Bifocal'}
                        /><br/>
                        <HTMLRadio
                            value={'Progressive'}
                            label={'Progressive'}
                        />
                        <div className={styles.verticalLine} style={{height: '170px'}}></div>
                    </Col>
                    <Col span={5} style={{position: 'relative'}}>
                        <span>Treatment (เคลือบ)</span><br/>
                        <HTMLRadio
                            value={'UC'}
                            label={'UC'}
                        /><br/>
                        <HTMLRadio
                            value={'HC'}
                            label={'HC'}
                        /><br/>
                        <HTMLRadio
                            value={'HMC'}
                            label={'HMC'}
                        /><br/>
                        <HTMLRadio
                            value={'SuperHMC'}
                            label={'SuperHMC'}
                        />
                        <div className={styles.verticalLine} style={{height: '170px'}}></div>
                    </Col>
                    <Col span={4} style={{position: 'relative'}}>
                        <span>WEAR TYPE</span><br/>
                        <HTMLRadio
                            value={'ตลอดเวลา'}
                            label={'ตลอดเวลา'}
                        /><br/>
                        <HTMLRadio
                            value={'มองไกล'}
                            label={'มองไกล'}
                        /><br/>
                        <HTMLRadio
                            value={'มองใกล้'}
                            label={'มองใกล้'}
                        /><br/>
                        <HTMLRadio
                            value={'ระยะคอมพิวเตอร์'}
                            label={'ระยะคอมพิวเตอร์'}
                        /><br/>
                        <HTMLRadio
                            value={'กันแดด'}
                            label={'กันแดด'}
                        />
                        <div className={styles.verticalLine} style={{height: '170px'}}></div>
                    </Col>
                </Row>
            </div>
        );
    }

    renderGlassesInfoSection = () => {
        return (
            <div>
                <Row gutter={24}>
                    <Col span={12}>
                        {/*<p className={ styles.dotFiller }>
							<span className={`${styles.dotContent} has-text-weight-bold`}>
								ยี่ห้อแว่น
							</span>
						</p>*/}
                        <span>
							<strong>ยี่ห้อแว่น</strong>:&nbsp;{(this.props.data && this.props.data.glasses) ? this.props.data.glasses.brand : '-'}
						</span>
                    </Col>
                    <Col span={12}>
                        {/*<p className={ styles.dotFiller }>
							<span className={`${styles.dotContent} has-text-weight-bold`}>
								รุ่น
							</span>
						</p>*/}
                        <span>
							<strong>รุ่น</strong>:&nbsp;{(this.props.data && this.props.data.glasses) ? this.props.data.glasses.model : '-'}
						</span>
                    </Col>
                </Row>
                <div className={styles.spacing}></div>
                <Row gutter={24}>
                    <Col span={16}>
						<span className={'has-text-weight-bold'}>
							ขนาดเลนส์
						</span>
                        <Row gutter={24} type={'flex'} align={'middle'} justify={'center'}>
                            <Col span={16}>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <p className={styles.dotFiller}>
											<span className={styles.dotContent}>
												กว้าง
											</span>
                                        </p>
                                    </Col>
                                    <Col span={12}>
                                        <p className={styles.dotFiller}>
											<span className={styles.dotContent}>
												สูง
											</span>
                                        </p>
                                    </Col>
                                </Row>
                                <div className={styles.spacingSmall}></div>
                                <Row gutter={24}>
                                    <Col span={12}>
                                        <p className={styles.dotFiller}>
											<span className={styles.dotContent}>
												ทะแยง
											</span>
                                        </p>
                                    </Col>
                                    <Col span={12}>
                                        <p className={styles.dotFiller}>
											<span className={styles.dotContent}>
												จมูก
											</span>
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={8}>
                                <Row gutter={24}>
                                    <Col>
                                        <HTMLRadio
                                            value={'เต็ม'}
                                            label={'เต็ม'}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col>
                                        <HTMLRadio
                                            value={'เซาะ'}
                                            label={'เซาะ'}
                                        />&nbsp;&nbsp;&nbsp;
                                        <HTMLRadio
                                            value={'เอ็น'}
                                            label={'เอ็น'}
                                        />&nbsp;&nbsp;&nbsp;
                                        <HTMLRadio
                                            value={'เหล็ก'}
                                            label={'เหล็ก'}
                                        />
                                    </Col>
                                </Row>
                                <Row gutter={24}>
                                    <Col>
                                        <HTMLRadio
                                            value={'เจาะ'}
                                            label={'เจาะ ............... (จำนวนรู)'}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={8} style={{position: 'relative'}}>
						<span className={'has-text-weight-bold'} style={{fontSize: '1rem'}}>
							หมายเหตุ / อื่นๆ
						</span>
                        {
                            (this.props.data && this.props.data.note)
                                ? (
                                    <p>{this.props.data.note}</p>
                                )
                                : (
                                    <React.Fragment>
                                        <p className={styles.dotFiller}>
                                            <span className={`${styles.dotContent}`}></span>
                                        </p>
                                        <p className={styles.dotFiller}>
                                            <span className={`${styles.dotContent}`}></span>
                                        </p>
                                        <p className={styles.dotFiller}>
                                            <span className={`${styles.dotContent}`}></span>
                                        </p>
                                    </React.Fragment>
                                )
                        }
                        <div className={styles.verticalLine}></div>
                    </Col>
                </Row>
            </div>
        );
    }

    renderSummarySection = () => {
        return (
            <div>
                <Row gutter={24}>
                    <Col span={20}>
                        <Row>
                            <Col span={24}>
                                <table className={styles.table}>
                                    <thead>
                                    <tr>
                                        <th className={styles.tableHead}>
                                            ราคาแว่น
                                        </th>
                                        <th className={styles.tableHead}>
                                            ราคาเลนส์
                                        </th>
                                        <th className={styles.tableHead}>
                                            ยอดรวม
                                        </th>
                                        <th className={styles.tableHead}>
                                            ส่วนลด
                                        </th>
                                        <th className={styles.tableHead}>
                                            VAT
                                        </th>
                                        <th className={styles.tableHead}>
                                            ราคาสุทธิ
                                        </th>
                                        <th className={styles.tableHead}>
                                            ชำระ
                                        </th>
                                        <th className={styles.tableHead}>
                                            ยอดคงค้าง
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td className={styles.tableBody}>
                                            {/* ราคาแว่น */}
                                            {(this.props.data && this.props.data.summary_price) ? this.props.data.summary_price.glass || 0 : 0}
                                        </td>
                                        <td className={styles.tableBody}>
                                            {/* ราคาเลนส์ */}
                                            {(this.props.data && this.props.data.summary_price) ? this.props.data.summary_price.lens || 0 : 0}
                                        </td>
                                        <td className={styles.tableBody}>
                                            {/* ยอดรวม */}
                                            {(this.props.data && this.props.data.payment_summary) ? this.props.data.payment_summary.total || 0 : 0}
                                        </td>
                                        <td className={styles.tableBody}>
                                            {/* ส่วนลด */}
                                            {(this.props.data && this.props.data.payment_summary) ? this.props.data.payment_summary.discount || 0 : 0}
                                        </td>
                                        <td className={styles.tableBody}>
                                            {/* VAT */}
                                            {(this.props.data && this.props.data.payment_summary) ? this.props.data.payment_summary.vat || 0 : 0}
                                        </td>
                                        <td className={styles.tableBody}>
                                            {/* ราคาสุทธิ */}
                                            {
                                                (this.props.data && this.props.data.payment_summary)
                                                    ? (this.props.data.payment_summary.total || 0) - (this.props.data.payment_summary.discount || 0) + (this.props.data.payment_summary.vat || 0)
                                                    : 0
                                            }
                                        </td>
                                        <td className={styles.tableBody}>
                                            {/* ชำระ */}
                                            {(this.props.data && this.props.data.payment_summary) ? this.props.data.payment_summary.paid || 0 : 0}
                                        </td>
                                        <td className={styles.tableBody}>
                                            {/* คงค้าง */}
                                            {(this.props.data && this.props.data.payment_summary) ? this.props.data.payment_summary.remaining || 0 : 0}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Col>
                        </Row>
                        <div className={styles.spacingLarge}></div>
                        <Row gutter={24}>
                            <Col span={12}>
                                <span>ผู้ตรวจสายตา</span>
                                <div className={styles.spacing}></div>
                                {
                                    (this.props.data && this.props.data.eye_inspector)
                                        ? (
                                            <Row align={'middle'} justify={'center'}>
                                                <Col>
                                                    <span>{this.props.data.eye_inspector}</span>
                                                </Col>
                                            </Row>
                                        )
                                        : (
                                            <p className={styles.dotFiller}>
                                                <span className={styles.dotContent}></span>
                                            </p>
                                        )
                                }
                            </Col>
                            <Col span={12}>
                                <span>ลายมือผู้สั่งซื้อ</span>
                                <div className={styles.spacing}></div>
                                <p className={styles.dotFiller}>
                                    <span className={styles.dotContent}></span>
                                </p>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={4}>
                        <HTMLRadio
                            checked={(this.props.data && this.props.data.payment_info) ? this.props.data.payment_info[this.props.data.payment_info.length - 1].payment_type === 'เงินสด' : false}
                            value={'เงินสด'}
                            label={'เงินสด'}
                        /><br/>
                        <HTMLRadio
                            checked={(this.props.data && this.props.data.payment_info) ? this.props.data.payment_info[this.props.data.payment_info.length - 1].payment_type === 'โอนผ่าน Application' : false}
                            value={'โอน App'}
                            label={'โอน App'}
                        /><br/>
                        <HTMLRadio
                            checked={(this.props.data && this.props.data.payment_info) ? this.props.data.payment_info[this.props.data.payment_info.length - 1].payment_type === 'จ่ายผ่อน' : false}
                            value={'จ่ายผ่อน'}
                            label={'จ่ายผ่อน'}
                        /><br/>
                        <HTMLRadio
                            checked={(this.props.data && this.props.data.payment_info) ? this.props.data.payment_info[this.props.data.payment_info.length - 1].payment_type === 'บัตรเครดิต' : false}
                            value={'เครดิตการ์ด'}
                            label={'เครดิตการ์ด'}
                        />
                    </Col>
                </Row>
            </div>
        );
    }

    renderRemarkSection = () => {
        return (
            <div>
                <Row gutter={[8, 8]}>
                    <Col span={24}>
						<span className={styles.labelSize}>
							หมายเหตุ
						</span>
                    </Col>
                    <Col span={24}>
						<span className={styles.labelSize}>
							1. หากลูกค้าดำเนินการตามเงื่อนไขการรับประกันข้างต้นแล้ว ยังพบปัญหา กรุณาติดต่อฝ่ายลูกค้าสัมพันธ์ เบอร์โทรฯ 091-760-7513 หรือแอดไลน์ @iWear หรือสแกน QR Code
						</span>
                    </Col>
                    <Col span={24}>
                        <div className={styles.qrCodeContainer}>
                            <img
                                style={{width: '100%', height: '8%'}}
                                src={process.env.PUBLIC_URL + '/static/images/QRCodeiWear.jpg'}
                                alt={'QRCodeiWear'}
                            /><br/>
                            <span style={{paddingLeft: '5px'}} className={styles.labelSize}>
                                    QR Code: @iWear
                            </span>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }

    render() {
        return (
            <div className={styles.container}>
                {this.renderTopInfoSection()}
                <div className={styles.spacingLarge}></div>
                {this.renderEyeMeasureTableValue()}
                <div className={styles.spacingLarge}></div>
                {this.renderPrimaryRxSection()}
                <div className={styles.spacingLarge}></div>
                {this.renderGlassesInfoSection()}
                <div className={styles.spacingLarge}></div>
                {this.renderSummarySection()}
                <div className={styles.spacingLarge}></div>
                {this.renderRemarkSection()}
            </div>
        );
    }

}

OrderToPDF.propTypes = {
    //
}

export default OrderToPDF;
