/**
 *	iWear - DataProduct screen
 */

import React from 'react';
import NumberFormat from 'react-number-format';
import {
	Row,
	Col,
	Button,
	Input,
	Dropdown,
	Menu,
	Pagination
} from 'antd';
import PropTypes from 'prop-types';

import Breadcrumb from '../../../../../components/breadcrumb';
import IWearDropdown from '../../../../../components/dropdown';
import ExcelButton from '../../../../../components/excel-button';

const renderDropdownToCreate = (props) => {
	return (
		<Menu>
			<Menu.Item key={0}>
				{/* eslint-disable-next-line */}
				<a onClick={() => { props.onClickAdd('manual'); }}>
					กำหนดเอง
				</a>
			</Menu.Item>
			<Menu.Item key={1}>
				{/* eslint-disable-next-line */}
				<a onClick={() => { props.onClickAdd('file'); }}>
					จาก Excel
				</a>
			</Menu.Item>
		</Menu>
	);
}

const renderOptions = (props) => {
	return (
		<div className={'custom-iwear-global-table-option-panel'}>
			<Row>
				<Col span={12}>
					<span className={'custom-iwear-global-font-color has-text-weight-bold is-size-5'}>
						ผลลัพธ์ข้อมูล&nbsp;
						<NumberFormat
							thousandSeparator
							displayType={'text'}
							value={ props.totalData }
						/>
						&nbsp;ข้อมูล
					</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						ค้นหา
					</span>&nbsp;&nbsp;&nbsp;
					<Input.Search
						enterButton
						placeholder={'Search'}
						allowClear={true}
						style={{ maxWidth: '70%' }}
						onSearch={(value) => {
							props.setPage(1);
							props.setSearchKey(value);
						}}
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col span={12}>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						แสดง
					</span>&nbsp;&nbsp;&nbsp;
					<IWearDropdown
						styleJSX={{ minWidth: '80px', paddingRight: '20px' }}
						displayValue={ String(props.pageSize) }
						choices={[ 10, 20, 50, 100 ]}
						onSelect={(selectedVal) => {
							props.setPage(1);
							props.setPageSize(Number.parseInt(selectedVal, 10));
						}}
					/>
				</Col>
				<Col span={12}>
					<Row gutter={8} type={'flex'} align={'middle'} justify={'end'}>
						<Col>
							<Dropdown overlay={() => renderDropdownToCreate(props)} trigger={['click']}>
								<Button>
									<i className={'fas fa-plus custom-iwear-global-font-color'}></i>&nbsp;
									<span className={'custom-iwear-global-font-color'}>เพิ่ม</span>
								</Button>
							</Dropdown>
						</Col>
						<Col>
							<ExcelButton
								modalTitle={'เลือกวันที่ของสินค้า'}
								exportType={'data_product'}
								dispatcher={ props.dispatcher }
								isLoading={ props.isExporting }

								branchName={''}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
}

const renderTable = (props) => {
	return (
		<div className={'custom-iwear-global-table-container'}>
			<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ลำดับ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							รหัสสินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ชื่อสินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ราคาสินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							เพิ่มเติม
						</th>
					</tr>
				</thead>
				<tbody>
					{ renderTableData(props) }
				</tbody>
			</table>
		</div>
	);
}

const renderTableData = (props) => {
	if(props.isLoading) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* กำลังโหลด... */}
				</td>
			</tr>
		);
	}
	else if(!props.isLoading && props.tableData.length === 0) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* ไม่มีข้อมูล */}
				</td>
			</tr>
		);
	}

	return props.tableData.map((_e, _i) => {
		return (
			<tr key={_i} className={'custom-iwear-global-hover-cursor-default'}>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _i + 1 + ((props.page - 1) * props.pageSize) }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.item_code }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.name }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.price }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					<Button onClick={() => { props.onClickEdit(_e.item_id); }}>
						<i className={'fas fa-edit'}></i>
					</Button>
				</td>
			</tr>
		);
	});
}

const renderContent = (props) => {
	return (
		<React.Fragment>
			<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
				<Row>
					<Col span={24}>
						{ renderOptions(props) }
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						{ renderTable(props) }
					</Col>
				</Row>
				<Row type={'flex'} align={'middle'} justify={'end'}>
					<Col style={{ paddingBottom: '10px' }}>
						<Pagination
							className={'custom-iwear-global-table-pagination-container is-pulled-right'}
							current={ props.page || 1 }
							pageSize={ props.pageSize || 10 }
							total={ props.totalPage * props.pageSize }
							onChange={(page, pageSize) => {
								props.setPage(page);
							}}
						/>
					</Col>
				</Row>
			</div>
		</React.Fragment>
	)
}

const AdminDataProduct = (props) => {
	return (
		<React.Fragment>
			<div className={'container custom-iwear-global-container-padding'}>
				<Breadcrumb paths={[ 'การจัดการข้อมูล', 'สินค้า' ]} />
				{ renderContent(props) }
			</div>
		</React.Fragment>
	);
}

AdminDataProduct.propTypes = {
	tableData: PropTypes.array.isRequired,
	totalData: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	totalPage: PropTypes.number.isRequired,
	searchKey: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,

	setTableData: PropTypes.func.isRequired,
	setTotalData: PropTypes.func.isRequired,
	setPage: PropTypes.func.isRequired,
	setPageSize: PropTypes.func.isRequired,
	setTotalPage: PropTypes.func.isRequired,
	setSearchKey: PropTypes.func.isRequired,
	setIsLoading: PropTypes.func.isRequired,

	onClickAdd: PropTypes.func.isRequired,
	onClickEdit: PropTypes.func.isRequired,
	onClickFileExport: PropTypes.func.isRequired,
}

export default AdminDataProduct;
