/**
 *	Order list - Container
 */

import React from 'react';
import {
	Layout
} from 'antd';
import XDate from 'xdate';
import swal from 'sweetalert';
import moment from 'moment';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../environments';
import { downloadFromBuffer } from '../../../../functions';
import GlobalStore from '../../../../store';
import LocalStore from './order-list.store';
import Content from './order-list.content';
import { ContainerPropTypes, Actions } from './order-list.model';

const Container = (props) => {
	const OrderReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData({
			branchName: OrderReducer.state.branchName,
			page: OrderReducer.state.page,
			pageSize: OrderReducer.state.pageSize,
			searchKey: OrderReducer.state.searchKey
		}, OrderReducer.dispatch, state.auth.token);
	}, [ OrderReducer.state.branchName, OrderReducer.state.page, OrderReducer.state.pageSize, OrderReducer.state.searchKey, OrderReducer.dispatch, state.auth.token ]) // @update on `page` or `pageSize` state changed

	const fetchData = async (params, localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		let res_branch_list = await fetch(`${API_ENDPOINT}/branches?limit=9999`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json_branch_list = await res_branch_list.json();
		localDispatch({ type: Actions.SET_BRANCH_LIST, value: [ ...json_branch_list.list ] });

		if(params.branchName && params.branchName !== 'ทั้งหมด') {
			let res_branch = await fetch(`${API_ENDPOINT}/branches?branch_name=${encodeURIComponent(params.branchName)}`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + authToken,
		    		'Content-Type': 'application/json'
				}
			});

			let json_branch = await res_branch.json();
			let _branchID = json_branch.list[0].branch_id;

			let res = await fetch(
				`${API_ENDPOINT}/orders?branch_id=${encodeURIComponent(_branchID)}
				&search=${encodeURIComponent(params.searchKey)}
				&offset=${(params.page - 1) * params.pageSize}
				&limit=${params.pageSize}`,
				{
					method: 'GET',
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'Content-Type': 'application/json'
					}
				}
			);

			let json = await res.json();
			localDispatch({ type: Actions.SET_TABLE_DATA, value: [ ...json.list ] });
			localDispatch({ type: Actions.SET_TOTAL_DATA, value: json.count || json.list.length });
			localDispatch({ type: Actions.SET_TOTAL_PAGE, value: json.total_pages * params.pageSize });
		}
		else {
			let res = await fetch(
				`${API_ENDPOINT}/orders?search=${encodeURIComponent(params.searchKey)}
				&offset=${(params.page - 1) * params.pageSize}
				&limit=${params.pageSize}`,
				{
					method: 'GET',
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'Content-Type': 'application/json'
					}
				}
			);

			let json = await res.json();
			localDispatch({ type: Actions.SET_TABLE_DATA, value: [ ...json.list ] });
			localDispatch({ type: Actions.SET_TOTAL_DATA, value: json.count || json.list.length });
			localDispatch({ type: Actions.SET_TOTAL_PAGE, value: json.total_pages * params.pageSize });
		}

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onChangeBranch = (selectedValue) => {
		OrderReducer.dispatch({ type: Actions.SET_PAGE, value: 1 });
		OrderReducer.dispatch({ type: Actions.SET_BRANCH_NAME, value: `${selectedValue}` });
	}

	const onChangePage = (page, pageSize) => {
		OrderReducer.dispatch({ type: Actions.SET_PAGE, value: page });
	}

	const onChangePageSize = (selectedVal) => {
		OrderReducer.dispatch({ type: Actions.SET_PAGE, value: 1 });
		OrderReducer.dispatch({ type: Actions.SET_PAGE_SIZE, value: Number.parseInt(selectedVal, 10) });
	}

	const onChangeSearchKey = (value) => {
		OrderReducer.dispatch({ type: Actions.SET_PAGE, value: 1 });
		OrderReducer.dispatch({ type: Actions.SET_SEARCH_KEY, value: value });
	}

	const onClickClosePaymentModal = () => {
		OrderReducer.dispatch({ type: Actions.SET_PAY_MODAL_VISIBLE, value: false });
	}

	const onClickCreate = (new_customer) => {
		// if(new_customer) {
		// 	props.history.push('/sales/order/create/new');
		// }
		// else {
		// 	props.history.push('/sales/order/create/old');
		// }
	}

	const onClickViewDetail = (orderID, customerID, fullname, tel) => {
		props.history.push(`/admin/order/detail`, {
			order_id: Number.parseInt(orderID, 10),
			customer_id: customerID,
			fullname: fullname,
			tel
		});
	}

	const modalOnClickPaid = async (payment_date, payment_type, payment_amount) => {
		let res = await fetch(`${API_ENDPOINT}/payments`, {
			method: 'POST',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				order_id: OrderReducer.state.orderID,
				is_first: false,
				payment_type: payment_type,
				payment_date: (XDate(new Date()).toString('yyyy-MM-dd')),
				amount: payment_amount
			})
		});

		let json = await res.json();

		if(json.hasOwnProperty('order_id')) {
			swal({
				title: 'ชำระเงินสำเร็จ',
				icon: 'success'
			}).then((value) => {
				OrderReducer.dispatch({ type: Actions.SET_PAY_MODAL_VISIBLE, value: false });
				window.location.reload();
			});
		}
		else {
			swal({
				title: 'ชำระเงินล้มเหลว',
				text: 'ข้อมูลไม่ครบถ้วน หรือ มีข้อผิดพลาดบางอย่าง',
				icon: 'warning'
			});
		}
	};

	const onClickCancel = async (order_info) => {
		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		let userID = order_info.customer_id;

		let res = await fetch(`${API_ENDPOINT}/orders/${encodeURIComponent(order_info.order_id)}`, {
			method: 'PATCH',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				msg: 'Update order status',
				customer_id: userID,
				branch_id: branchID,
				promotion_id: order_info.promotion_info.promotion_id,
				order_code: order_info.order_code, // order_code
				discount: order_info.discount,
				deposit_first: order_info.payment_info[order_info.payment_info.length - 1].amount,
				payment_type: order_info.payment_info[order_info.payment_info.length - 1].payment_type,
				receive_date: order_info.payment_info[order_info.payment_info.length - 1].payment_date,
				status: 'cancelled',
				rxmall_status: order_info.rxmall_status,
				price: order_info.price,
				note: order_info.note,
				cancel_reason: order_info.cancel_reason
			})
		});

		let json = await res.json();
		console.log('Cancelled', json);

		OrderReducer.dispatch({ type: Actions.SET_ORDER_ID, value: 0 });
		OrderReducer.dispatch({ type: Actions.SET_CANCEL_TYPE, value: '' });
		OrderReducer.dispatch({ type: Actions.SET_CANCEL_REASON, value: '' });
		OrderReducer.dispatch({ type: Actions.SET_CANCEL_MODAL_VISIBLE, value: false });
		window.location.reload();
	}

	const cancelModalOnClickOK = () => {
		onClickCancel(OrderReducer.state.orderID);
	}

	const cancelModalOnClickCancel = () => {
		OrderReducer.dispatch({ type: Actions.SET_ORDER_ID, value: 0 });
		OrderReducer.dispatch({ type: Actions.SET_CANCEL_TYPE, value: '' });
		OrderReducer.dispatch({ type: Actions.SET_CANCEL_REASON, value: '' });
		OrderReducer.dispatch({ type: Actions.SET_CANCEL_MODAL_VISIBLE, value: false });
	}

	const cancelModalOnChangeCancelReason = (evt) => {
		OrderReducer.dispatch({ type: Actions.SET_CANCEL_REASON, value: evt.target.value });
	}

	const onClickFileExport = async (dates) => {
		if(dates.length !== 2) { console.error('Date Range invalid', dates); return; }

		OrderReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: true });

		let [ _branch ] = OrderReducer.state.branchList.filter((_f) => { return _f.branch_name === OrderReducer.state.branchName; });

		let _url = '';

		if(_branch && _branch.branch_id) {
			_url = `
				${API_ENDPOINT}/orders
				?branch_id=${encodeURIComponent(_branch.branch_id)}
				&search=${encodeURIComponent(OrderReducer.state.searchKey)}
				&receive_date_start=${encodeURIComponent(dates[0])}
				&receive_date_end=${encodeURIComponent(dates[1])}
				&is_report=true
			`;
		}
		else {
			_url = `
				${API_ENDPOINT}/orders
				?search=${encodeURIComponent(OrderReducer.state.searchKey)}
				&receive_date_start=${encodeURIComponent(dates[0])}
				&receive_date_end=${encodeURIComponent(dates[1])}
				&is_report=true
			`;
		}

		let res = await fetch(`${_url}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let data = await res.json();

		if(res.status === 200) {
			let _timer = 5000 + (Number.parseInt(OrderReducer.state.totalData / 5000, 10) * 1000);
			setTimeout(() => {
				downloadFromBuffer(data.report_path, `order_report_${moment().format('DD-MM-YYYY_HHmmss')}.xlsx`);
				OrderReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: false });
			}, _timer);
		}
		else {
			OrderReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: false });
			swal({
				title: 'เกิดข้อผิดพลาด',
				text: 'ไม่สามารถ export ได้ในขณะนี้',
				icon: 'error'
			});
		}
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/order'} history={ props.history } />
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/order'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...OrderReducer.state }
						dispatcher={ OrderReducer.dispatch }

						onChangeBranch={ onChangeBranch }
						onChangePage={ onChangePage }
						onChangePageSize={ onChangePageSize }
						onChangeSearchKey={ onChangeSearchKey }
						onClickClosePaymentModal={ onClickClosePaymentModal }

						onClickCreate={ onClickCreate }
						onClickViewDetail={ onClickViewDetail }
						modalOnClickPaid={ modalOnClickPaid }
						onClickCancel={ onClickCancel }
						cancelModalOnClickOK={ cancelModalOnClickOK }
						cancelModalOnClickCancel={ cancelModalOnClickCancel }
						cancelModalOnChangeCancelReason={ cancelModalOnChangeCancelReason }

						onClickFileExport={ onClickFileExport }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
