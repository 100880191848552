/**
 *	Stock Import Bill - Model
 */

import PropTypes from 'prop-types';

export const ContainerPropTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export const ContentPropTypes = {
	barcode: PropTypes.string.isRequired,
	tableData: PropTypes.array.isRequired,
	itemsStatus: PropTypes.array.isRequired,
	isLoading: PropTypes.bool.isRequired,

	setBarcode: PropTypes.func.isRequired,
	setTableData: PropTypes.func.isRequired,
	setItemsStatus: PropTypes.func.isRequired,
	setIsLoading: PropTypes.func.isRequired,

	onScan: PropTypes.func.isRequired,
	onClickConfirm: PropTypes.func.isRequired,
	onClickGoBack: PropTypes.func.isRequired
}

export const Actions = {
	SET_BARCODE: "SET_BARCODE",
	SET_TABLE_DATA: "SET_TABLE_DATA",
	SET_SRC_BRANCH: "SET_SRC_BRANCH",
	SET_ITEM_STATUS: "SET_ITEM_STATUS",
	SET_IS_LOADING: "SET_IS_LOADING"
}
