/**
 *	Login - Content
 */

import React from 'react';
import {
	Input,
	Form,
	Button,
	Row,
	Col
} from 'antd';

import { SITE_VERSION } from '../../../environments';

import { ContentPropTypes } from './login.model';
import styles from './login.module.css';

const Content = (props) => {
	return (
		<React.Fragment>
			<div className={`${styles.loginBackground} is-hidden-touch`} style={{ width: '100vw', height: '100vh' }}>
				<Row gutter={8} type={'flex'} align={'middle'} justify={'center'} style={{ height: '100%' }}>
					<Col span={2}></Col>
					<Col span={8}>
						{ renderAbout(props) }
					</Col>
					<Col span={4}></Col>
					<Col span={8}>
						{ renderForm(props) }
					</Col>
					<Col span={2}></Col>
				</Row>
			</div>

			<div className={`${styles.loginBackground} is-hidden-desktop`} style={{ width: '100vw', height: '100vh' }}>
				<Row gutter={8} type={'flex'} align={'middle'} justify={'center'} style={{ height: '100%', overflow: 'auto' }}>
					<div style={{ margin: '10px' }}></div>
					<Col span={24}>
						<Row gutter={8} type={'flex'} align={'middle'} justify={'center'}>
							<Col span={4}></Col>
							<Col span={16}>{ renderAbout(props) }</Col>
							<Col span={4}></Col>
						</Row>
					</Col>
					<div style={{ margin: '20px' }}></div>
					<Col span={24}>
						<Row gutter={8} type={'flex'} align={'middle'} justify={'center'}>
							<Col span={4}></Col>
							<Col span={16}>{ renderForm(props) }</Col>
							<Col span={4}></Col>
						</Row>
					</Col>
					<div style={{ padding: '10px' }}></div>
				</Row>
			</div>
		</React.Fragment>
	);
}

const renderAbout = (props) => {
	return (
		<React.Fragment>
			<span className={`is-size-2 ${styles.loginFontColor}`}>
				iWear<br/>The Optical Outlet
			</span><br/><br/>
			<span className={`is-size-4 ${styles.loginFontColor}`}>
				Glasses, also known as eyeglasses or spectacles, are devices
				consisting of glass or hard plastic lenses mounted in a frame
				that holds them in front of person's eyes, typically using a
				bridge over the nose and arms which rest over the ears.
			</span>
		</React.Fragment>
	);
}

const renderForm = (props) => {
	return (
		<div className={`card ${styles.loginCard}`} style={{ paddingTop: '40px', paddingBottom: '40px' }}>
			<div className={'card-image'} style={{ paddingTop: '10px', display: 'flex', justifyContent: 'center' }}>
				<figure className={'image'}>
					<img
						src={process.env.PUBLIC_URL + '/static/images/iWearLogo.svg'}
						alt={'iWear Logo'}
					/>
				</figure>
			</div>
			<div className={'card-content'}>
				<Form onSubmit={ props.onSubmit }>
					<Form.Item
						required hasFeedback
						validateStatus={(props.errorUsername) ? 'error' : ''}
						help={(props.errorUsername) ? 'Username ควรมีความยาว 4 ตัวอักษรขึ้นไป' : ''}
					>
						<Input
							size={'large'}
							placeholder={'Username'}
							prefix={<i className={'far fa-user'}></i>}
							onChange={ props.onChangeUsername }
						/>
					</Form.Item>
					<Form.Item
						required hasFeedback
						validateStatus={(props.errorPassword) ? 'error' : ''}
						help={(props.errorPassword) ? 'Password ควรมีความยาว 8 ตัวอักษรขึ้นไป' : ''}
					>
						<Input.Password
							size={'large'}
							placeholder={'Password'}
							prefix={<i className={'fas fa-lock'}></i>}
							onChange={ props.onChangePassword }
						/>
					</Form.Item>
					<Form.Item>
						<Button
							block
							loading={ props.isSubmitting }
							className={`custom-iwear-login-access-button`}
							disabled={ props.isSubmitting }
							type={'primary'}
							htmlType={'submit'}
						>
							SIGN IN
						</Button>
					</Form.Item>
				</Form>
				<Row type={'flex'} align={'middle'} justify={'center'}>
					<Col>
						<span>Site version: {SITE_VERSION}</span>
					</Col>
				</Row>
			</div>
		</div>
	);
}

Content.propTypes = ContentPropTypes;

export default Content;
