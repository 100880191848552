/**
 *	Pages - All pages path
 */

import { ROLE } from '../environments';

import RxMall from '../features/rxmall';
import RxMallOrder from '../features/rxmall/order/list';
import RxMallOrderDetail from '../features/rxmall/order/detail';
import RxMallAppointment from '../features/rxmall/appointment';
import RxMallStock from '../features/rxmall/stock/list';
import RxMallExportList from '../features/rxmall/stock/export-list';
import RxMallExportListDetail from '../features/rxmall/stock/export-detail';
import RxMallExportInventory from '../features/rxmall/stock/export-inventory';
// import RxMallExportUpload from '../features/rxmall/stock/export-upload';
import RxMallImportList from '../features/rxmall/stock/import-list';
import RxMallImportListDetail from '../features/rxmall/stock/import-detail';
import RxMallImportInventoryBill from '../features/rxmall/stock/import-inventory-bill';
import RxMallImportInventoryManual from '../features/rxmall/stock/import-inventory-manual';
// import RxMallImportUpload from '../features/rxmall/stock/import-upload';
import RxMallDataBranchList from '../features/rxmall/data-management/branch/list';
import RxMallDataBranchAdd from '../features/rxmall/data-management/branch/add';
import RxMallDataBranchEdit from '../features/rxmall/data-management/branch/edit';
import RxMallDataBrand from '../features/rxmall/data-management/brand';
import RxMallDataStaffList from '../features/rxmall/data-management/staff/list';
import RxMallDataStaffAdd from '../features/rxmall/data-management/staff/add';
import RxMallDataStaffEdit from '../features/rxmall/data-management/staff/edit';
import RxMallDataProductList from '../features/rxmall/data-management/product/list';
import RxMallDataProductAdd from '../features/rxmall/data-management/product/add';
import RxMallDataProductEdit from '../features/rxmall/data-management/product/edit';
import RxMallDataProductUpload from '../features/rxmall/data-management/product/upload';
import RxMallDataProductCategoryList from '../features/rxmall/data-management/product/category';
import RxMallDataPrmotionList from '../features/rxmall/data-management/promotion';

const labRoutes = [
	{ path: '/lab/auth', component: RxMall, role: ROLE.rxmall },
	{ path: '/lab/order', component: RxMallOrder, role: ROLE.rxmall },
	{ path: '/lab/order/detail', component: RxMallOrderDetail, role: ROLE.rxmall },
	{ path: '/lab/appointment', component: RxMallAppointment, role: ROLE.rxmall },
	{ path: '/lab/stock', component: RxMallStock, role: ROLE.rxmall },
	{ path: '/lab/stock/export', component: RxMallExportList, role: ROLE.rxmall },
	{ path: '/lab/stock/export/detail', component: RxMallExportListDetail, role: ROLE.rxmall },
	{ path: '/lab/stock/export/inventory', component: RxMallExportInventory, role: ROLE.rxmall },
	// { path: '/lab/stock/export/upload', component: RxMallExportUpload, role: ROLE.rxmall },
	{ path: '/lab/stock/import', component: RxMallImportList, role: ROLE.rxmall },
	{ path: '/lab/stock/import/detail', component: RxMallImportListDetail, role: ROLE.rxmall },
	{ path: '/lab/stock/import/bill', component: RxMallImportInventoryBill, role: ROLE.rxmall },
	{ path: '/lab/stock/import/manual', component: RxMallImportInventoryManual, role: ROLE.rxmall },
	// { path: '/lab/stock/import/upload', component: RxMallImportUpload, role: ROLE.rxmall },
	{ path: '/lab/data/branch', component: RxMallDataBranchList, role: ROLE.rxmall },
	{ path: '/lab/data/branch/add', component: RxMallDataBranchAdd, role: ROLE.rxmall },
	{ path: '/lab/data/branch/edit', component: RxMallDataBranchEdit, role: ROLE.rxmall },
	{ path: '/lab/data/brand', component: RxMallDataBrand, role: ROLE.rxmall },
	{ path: '/lab/data/staff', component: RxMallDataStaffList, role: ROLE.rxmall },
	{ path: '/lab/data/staff/add', component: RxMallDataStaffAdd, role: ROLE.rxmall },
	{ path: '/lab/data/staff/edit', component: RxMallDataStaffEdit, role: ROLE.rxmall },
	{ path: '/lab/data/product', component: RxMallDataProductList, role: ROLE.rxmall },
	{ path: '/lab/data/product/add', component: RxMallDataProductAdd, role: ROLE.rxmall },
	{ path: '/lab/data/product/edit', component: RxMallDataProductEdit, role: ROLE.rxmall },
	{ path: '/lab/data/product/upload', component: RxMallDataProductUpload, role: ROLE.rxmall },
	{ path: '/lab/data/product/category', component: RxMallDataProductCategoryList, role: ROLE.rxmall },
	{ path: '/lab/data/promotion', component: RxMallDataPrmotionList, role: ROLE.rxmall }
];

export default labRoutes;
