/**
 *	iWear - Data Branch Add screen
 */

import React from 'react';
import {
	Row,
	Col,
	Button,
	Input
} from 'antd';
import PropTypes from 'prop-types';

import Breadcrumb from '../../../../../components/breadcrumb';
import IWearDropdown from '../../../../../components/dropdown';
import ThaiAddress from '../../../../../lib/thai-address';

const EditDataBranch = (props) => {
	return (
		<React.Fragment>
			<div className={'container custom-iwear-global-container-padding'}>
				<Breadcrumb paths={[ 'การจัดการข้อมูล', 'สาขา', 'แก้ไขข้อมูล' ]} withGoBack onClickGoBack={ props.onClickGoBack } />
				{ renderContent(props) }
			</div>
		</React.Fragment>
	);
}

const renderContent = (props) => {
	return (
		<React.Fragment>
			<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
				<div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>
					<Row>
						<Col span={24}>
							{ renderOptions(props) }
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							{ renderFields(props) }
						</Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row type={'flex'} align={'middle'} justify={'end'}>
						<Col>
							<Button onClick={ props.onClickSave }>
								<span className={'custom-iwear-global-font-color'}>
									บันทึกข้อมูล
								</span>
							</Button>
						</Col>
					</Row>
				</div>
			</div>
		</React.Fragment>
	);
}

const renderOptions = (props) => {
	return (
		<div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
			<Row type={'flex'} align={'middle'} justify={'space-between'}>
				<Col span={12}>
					<span
						className={'custom-iwear-global-font-color is-size-5'}
						style={{ textDecorationLine: 'underline' }}
					>
						แก้ไขข้อมูลสาขา
					</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<Button onClick={ props.onClickSave }>
						<span className={'custom-iwear-global-font-color'}>
							บันทึกข้อมูล
						</span>
					</Button>
				</Col>
			</Row>
		</div>
	);
}

const renderFields = (props) => {
	return (
		<React.Fragment>
			<Row>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						* ชื่อสาขา
					</label>
					<Input
						value={ props.branchName }
						onChange={(evt) => {
							props.setBranchName(evt.target.value);
						}}
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						* จังหวัด
					</label>
					<IWearDropdown
						isFull
						displayValue={ props.branchProvince.text }
						choices={ ThaiAddress.provinceList() }
						onSelect={(selectedVal, selectedIndex) => {
							props.setBranchProvince({ index: selectedIndex, text: selectedVal });
						}}
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						* เขต
					</label>
					<IWearDropdown
						isFull
						dropdownStyle={{ disabled: (props.branchProvince.text === '') }}
						displayValue={ props.branchDistrict }
						choices={ (props.branchProvince.index === -1) ? [] : ThaiAddress.districtList(props.branchProvince.index)  }
						onSelect={(selectedVal) => { props.setBranchDistrict(selectedVal); }}
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						รหัสไปรษณีย์
					</label>
					<Input
						value={ props.branchPostCode }
						onChange={(evt) => {
							props.setBranchPostCode(evt.target.value);
						}}
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						* ที่ตั้ง
					</label>
					<Input.TextArea
						value={ props.branchAddr }
						onChange={(evt) => {
							props.setBranchAddr(evt.target.value);
						}}
					/>
				</Col>
			</Row>
		</React.Fragment>
	);
}

EditDataBranch.propTypes = {
	branchName: PropTypes.string.isRequired,
	branchProvince: PropTypes.object.isRequired,
	branchDistrict: PropTypes.string.isRequired,
	branchAddr: PropTypes.string.isRequired,
	branchPostCode: PropTypes.string.isRequired,

	setBranchName: PropTypes.func.isRequired,
	setBranchProvince: PropTypes.func.isRequired,
	setBranchDistrict: PropTypes.func.isRequired,
	setBranchAddr: PropTypes.func.isRequired,
	setBranchPostCode: PropTypes.func.isRequired,

	onClickGoBack: PropTypes.func.isRequired,
	onClickSave: PropTypes.func.isRequired
}

export default EditDataBranch;
