/**
 *	iWear - Data Staff Add container
 */

import React from 'react';
import { Layout } from 'antd';
import swal from 'sweetalert';
import PropTypes from 'prop-types';

import TopBar from '../../../../../components/layout/header';
import Sidebar from '../../../../../components/layout/sider';
import BottomBar from '../../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../../environments';
import WebCookie from '../../../../../lib/web-cookie';
import AddDataStaff from './screen';

const AddDataStaffContainer = (props) => {
	const [ username, setUsername ] = React.useState('');
	const [ password, setPassword ] = React.useState('');
	const [ fullname, setFullname ] = React.useState('');
	const [ branch, setBranch ] = React.useState('');
	const [ permissionLevel, setPermissionLevel ] = React.useState('');

	const [ branchList, setBranchList ] = React.useState([]);

	React.useEffect(() => {
		fetchData();
	}, []);

	let fetchData = async () => {
		let res_branch = await fetch(`${API_ENDPOINT}/branches?limit=9999`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		let json_branch = await res_branch.json();

		let _arr = [];
		json_branch.list.forEach((_e) => {
			_arr.push({ id: _e.branch_id, text: _e.branch_name });
		});

		setBranchList([ ..._arr ]);
	}

	const onClickGoBack = () => {
		props.history.goBack();
	}

	const onClickSave = async () => {
		if(username === '') {
			swal({
				title: 'เพิ่มพนักงานล้มเหลว',
				text: 'ไม่ระบุ username',
				icon: 'error'
			});
		}
		else if(password === '') {
			swal({
				title: 'เพิ่มพนักงานล้มเหลว',
				text: 'ไม่ระบุ password',
				icon: 'error'
			});
		}
		else if(fullname === '') {
			swal({
				title: 'เพิ่มพนักงานล้มเหลว',
				text: 'ไม่ระบุชื่อ-นามสกุล',
				icon: 'error'
			});
		}
		else if(permissionLevel === '') {
			swal({
				title: 'เพิ่มพนักงานล้มเหลว',
				text: 'ไม่ระบุสิทธิ์การเข้าถึง',
				icon: 'error'
			});
		}
		else if(branch === '') {
			swal({
				title: 'เพิ่มพนักงานล้มเหลว',
				text: 'ไม่ระบุสาขา',
				icon: 'error'
			});
		}
		else {
			let _branch = [ ...branchList ].find((_f) => { return _f.text === branch; }).id;

			let res = await fetch(`${API_ENDPOINT}/staffs`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					username: username,
					password: password,
					full_name: fullname,
					branch_id: _branch,
					permission: permissionLevel.toUpperCase()
				})
			});

			let json = await res.json();
			console.log('CreateStaff', json);

			if(res.status === 200) {
				swal({
					title: 'เพิ่มพนักงานสำเร็จ',
					icon: 'success'
				}).then((val) => {
					props.history.goBack();
				});
			}
			else {
				swal({
					title: 'เพิ่มพนักงานล้มเหลว',
					text: (json.msg) ? json.msg : 'ไม่ทราบสาเหตุ',
					icon: 'error'
				});
			}
		}
	}

	return (
		<Layout>
			<Sidebar mode={'rxmall'} currentRoute={'/lab/data/staff'} history={ props.history } />
			<Layout>
				<TopBar mode={'rxmall'} currentRoute={'/lab/data/staff'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<AddDataStaff
						username={ username }
						password={ password }
						fullname={ fullname }
						branch={ branch }
						permissionLevel={ permissionLevel }

						branchList={ branchList }

						setUsername={ setUsername }
						setPassword={ setPassword }
						setFullname={ setFullname }
						setBranch={ setBranch }
						setPermissionLevel={ setPermissionLevel }

						setBranchList={ setBranchList }

						onClickGoBack={ onClickGoBack }
						onClickSave={ onClickSave }
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

AddDataStaffContainer.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default AddDataStaffContainer;
