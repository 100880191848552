/**
 *	Stock Export Inventory - Model
 */

import PropTypes from 'prop-types';

export const ContainerPropTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export const ContentPropTypes = {
	productID: PropTypes.string.isRequired,
	productName: PropTypes.string.isRequired,
	productCode: PropTypes.string.isRequired,
	productAmount: PropTypes.number.isRequired,
	productPrice: PropTypes.number.isRequired,
	suggestionDataProductCode: PropTypes.array.isRequired,
	suggestionDataProductName: PropTypes.array.isRequired,

	exportInfo: PropTypes.object.isRequired,
	exportRef: PropTypes.any.isRequired,
	printRef: PropTypes.any.isRequired,

	branch: PropTypes.string.isRequired,
	branchList: PropTypes.array.isRequired,
	tableData: PropTypes.array.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	editIdx: PropTypes.number.isRequired,
	isEditing: PropTypes.bool.isRequired,
	isLoading: PropTypes.bool.isRequired,

	setProductID: PropTypes.func.isRequired,
	setProductName: PropTypes.func.isRequired,
	setProductCode: PropTypes.func.isRequired,
	setProductAmount: PropTypes.func.isRequired,
	setProductPrice: PropTypes.func.isRequired,
	setSuggestionDataProductCode: PropTypes.func.isRequired,
	setSuggestionDataProductName: PropTypes.func.isRequired,

	setExportInfo: PropTypes.func.isRequired,

	setBranch: PropTypes.func.isRequired,
	setBranchList: PropTypes.func.isRequired,
	setTableData: PropTypes.func.isRequired,
	setPage: PropTypes.func.isRequired,
	setPageSize: PropTypes.func.isRequired,
	setEditIdx: PropTypes.func.isRequired,
	setIsEditing: PropTypes.func.isRequired,
	setIsLoading: PropTypes.func.isRequired,

	onScan: PropTypes.func.isRequired,
	onAddData: PropTypes.func.isRequired,
	onClickConfirm: PropTypes.func.isRequired,
	onClickGoBack: PropTypes.func.isRequired
}
