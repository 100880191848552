/**
 *	iWear - Data Staff Edit screen
 */

import React from 'react';
import {
	Row,
	Col,
	Button,
	Input
} from 'antd';
import PropTypes from 'prop-types';

import Breadcrumb from '../../../../../components/breadcrumb';
import IWearDropdown from '../../../../../components/dropdown';

const renderOptions = (props) => {
	return (
		<div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
			<Row type={'flex'} align={'middle'} justify={'space-between'}>
				<Col>
					<span
						className={'custom-iwear-global-font-color is-size-5'}
						style={{ textDecorationLine: 'underline' }}
					>
						แก้ไขข้อมูลพนักงาน
					</span>
				</Col>
				<Col>
					<Button onClick={ props.onClickSave }>
						<span className={'custom-iwear-global-font-color'}>
							บันทึกข้อมูล
						</span>
					</Button>
				</Col>
			</Row>
		</div>
	);
}

const renderFields = (props) => {
	let _branches = [];
	props.branchList.forEach((_e) => { _branches.push(_e.text); })

	return (
		<React.Fragment>
			<Row>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						* Username
					</label>
					<Input
						disabled
						value={ props.username }
						className={'custom-iwear-global-font-color'}
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						Password
					</label>
					<Input.Password
						// disabled
						value={ props.password }
						className={'custom-iwear-global-font-color'}
						onChange={(evt) => { props.setPassword(evt.target.value); }}
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						* ชื่อ - นามสกุล
					</label>
					<Input
						value={ props.fullname }
						onChange={(evt) => { props.setFullname(evt.target.value); }}
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						* สิทธิ์การเข้าถึง
					</label>
					<IWearDropdown
						isFull
						// dropdownStyle={{ disabled: true }}
						displayValue={ props.permissionLevel.toUpperCase() }
						choices={[ 'STAFF', 'RXMALL', 'MANAGER' ]}
						onSelect={(selectedVal) => {
							props.setPermissionLevel(selectedVal);
							if(selectedVal === 'MANAGER') {
								props.setBranch('สาขาใหญ่');
								props.setBranchDisabled(true);
							}
							else { props.setBranchDisabled(false); }
						}}
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						* สาขา
					</label>
					<IWearDropdown
						isFull
						dropdownStyle={{ disabled: props.branchDisabled }}
						displayValue={ props.branch }
						choices={ _branches }
						onSelect={(selectedVal) => { props.setBranch(selectedVal); }}
					/>
				</Col>
			</Row>
		</React.Fragment>
	);
}

const renderContent = (props) => {
	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
			<div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>
				<Row>
					<Col span={24}>
						{ renderOptions(props) }
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						{ renderFields(props) }
					</Col>
				</Row>
				<div style={{ margin: '10px' }}></div>
				<Row type={'flex'} align={'middle'} justify={'end'}>
					<Col>
						<Button onClick={ props.onClickSave }>
							<span className={'custom-iwear-global-font-color'}>
								บันทึกข้อมูล
							</span>
						</Button>
					</Col>
				</Row>
			</div>
		</div>
	);
}

const EditDataStaff = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'การจัดการข้อมูล', 'พนักงาน', 'แก้ไขข้อมูล' ]} withGoBack onClickGoBack={ props.onClickGoBack } />
			{ renderContent(props) }
		</div>
	);
}

EditDataStaff.propTypes = {
	username: PropTypes.string.isRequired,
	password: PropTypes.string.isRequired,
	fullname: PropTypes.string.isRequired,
	branch: PropTypes.string.isRequired,
	permissionLevel: PropTypes.string.isRequired,

	setUsername: PropTypes.func.isRequired,
	setPassword: PropTypes.func.isRequired,
	setFullname: PropTypes.func.isRequired,
	setBranch: PropTypes.func.isRequired,
	setPermissionLevel: PropTypes.func.isRequired,

	onClickGoBack: PropTypes.func.isRequired,
	onClickSave: PropTypes.func.isRequired
}

export default EditDataStaff;
