/**
 *	Pages - Admin pages path
 */

import { ROLE } from '../environments';

import Admin from '../features/admin';
import AdminDashboard from '../features/admin/dashboard';
import AdminAppointment from '../features/admin/appointment';
import AdminCustomerList from '../features/admin/customers/list';
import AdminCustomerDetail from '../features/admin/customers/detail';
import AdminOrderList from '../features/admin/order/list';
import AdminOrderDetail from '../features/admin/order/detail';
import AdminStock from '../features/admin/stock/list';
import AdminExportList from '../features/admin/stock/export-list';
import AdminExportListDetail from '../features/admin/stock/export-detail';
import AdminExportInventory from '../features/admin/stock/export-inventory';
// import AdminExportUpload from '../features/admin/stock/export-upload';
import AdminImportList from '../features/admin/stock/import-list';
import AdminImportListDetail from '../features/admin/stock/import-detail';
import AdminImportInventoryBill from '../features/admin/stock/import-inventory-bill';
import AdminImportInventoryManual from '../features/admin/stock/import-inventory-manual';
// import AdminImportUpload from '../features/admin/stock/import-upload';
import AdminDataBranchList from '../features/admin/data-management/branch/list';
import AdminDataBranchAdd from '../features/admin/data-management/branch/add';
import AdminDataBranchEdit from '../features/admin/data-management/branch/edit';
import AdminDataBrand from '../features/admin/data-management/brand';
import AdminDataStaffList from '../features/admin/data-management/staff/list';
import AdminDataStaffAdd from '../features/admin/data-management/staff/add';
import AdminDataStaffEdit from '../features/admin/data-management/staff/edit';
import AdminDataProductList from '../features/admin/data-management/product/list';
import AdminDataProductAdd from '../features/admin/data-management/product/add';
import AdminDataProductEdit from '../features/admin/data-management/product/edit';
import AdminDataProductUpload from '../features/admin/data-management/product/upload';
import AdminDataProductCategoryList from '../features/admin/data-management/product/category';
import AdminDataPrmotionList from '../features/admin/data-management/promotion';
import AdminFinance from '../features/admin/finance';

const adminRoutes = [
	{ path: '/admin/auth', component: Admin, role: ROLE.admin },
	{ path: '/admin/dashboard', component: AdminDashboard, role: ROLE.admin },
	{ path: '/admin/appointment', component: AdminAppointment, role: ROLE.admin },
	{ path: '/admin/customer', component: AdminCustomerList, role: ROLE.admin },
	{ path: '/admin/customer/detail', component: AdminCustomerDetail, role: ROLE.admin },
	{ path: '/admin/order', component: AdminOrderList, role: ROLE.admin },
	{ path: '/admin/order/detail', component: AdminOrderDetail, role: ROLE.admin },
	{ path: '/admin/stock', component: AdminStock, role: ROLE.admin },
	{ path: '/admin/stock/export', component: AdminExportList, role: ROLE.admin },
	{ path: '/admin/stock/export/detail', component: AdminExportListDetail, role: ROLE.admin },
	{ path: '/admin/stock/export/inventory', component: AdminExportInventory, role: ROLE.admin },
	// { path: '/admin/stock/export/upload', component: AdminExportUpload, role: ROLE.admin },
	{ path: '/admin/stock/import', component: AdminImportList, role: ROLE.admin },
	{ path: '/admin/stock/import/detail', component: AdminImportListDetail, role: ROLE.admin },
	{ path: '/admin/stock/import/bill', component: AdminImportInventoryBill, role: ROLE.admin },
	{ path: '/admin/stock/import/manual', component: AdminImportInventoryManual, role: ROLE.admin },
	// { path: '/admin/stock/import/upload', component: AdminImportUpload, role: ROLE.admin },
	{ path: '/admin/data/branch', component: AdminDataBranchList, role: ROLE.admin },
	{ path: '/admin/data/branch/add', component: AdminDataBranchAdd, role: ROLE.admin },
	{ path: '/admin/data/branch/edit', component: AdminDataBranchEdit, role: ROLE.admin },
	{ path: '/admin/data/brand', component: AdminDataBrand, role: ROLE.admin },
	{ path: '/admin/data/staff', component: AdminDataStaffList, role: ROLE.admin },
	{ path: '/admin/data/staff/add', component: AdminDataStaffAdd, role: ROLE.admin },
	{ path: '/admin/data/staff/edit', component: AdminDataStaffEdit, role: ROLE.admin },
	{ path: '/admin/data/product', component: AdminDataProductList, role: ROLE.admin },
	{ path: '/admin/data/product/add', component: AdminDataProductAdd, role: ROLE.admin },
	{ path: '/admin/data/product/edit', component: AdminDataProductEdit, role: ROLE.admin },
	{ path: '/admin/data/product/upload', component: AdminDataProductUpload, role: ROLE.admin },
	{ path: '/admin/data/product/category', component: AdminDataProductCategoryList, role: ROLE.admin },
	{ path: '/admin/data/promotion', component: AdminDataPrmotionList, role: ROLE.admin },
	{ path: '/admin/finance', component: AdminFinance, role: ROLE.admin }
];

export default adminRoutes;
