/**
 *	iWear - Stock Import Manual state management
 */

import React from 'react';
import { Actions } from './stock-import-manual.model';

const initState = {
	productID: '',
	productName: '',
	productCode: '',
	productAmount: 0,
	suggestionDataProductCode: [],
	suggestionDataProductName: [],
	branch: 'สาขาใหญ่',
	branchList: [ 'สาขาย่อย', 'สาขาใหญ่' ],
	tableData: [],
	editIdx: -1,
	isEditing: false,
	isLoading: false
}

const reducer = (state, action) => {
	switch(action.type) {
		case Actions.SET_PRODUCT_ID:
			return {
				...state,
				productID: action.value
			}
		case Actions.SET_PRODUCT_NAME:
			return {
				...state,
				productName: action.value
			}
		case Actions.SET_PRODUCT_CODE:
			return {
				...state,
				productCode: action.value
			}
		case Actions.SET_PRODUCT_AMOUNT:
			return {
				...state,
				productAmount: action.value
			}
		case Actions.SET_PRODUCT_CODE_SUGGESTION:
			return {
				...state,
				suggestionDataProductCode: action.value
			}
		case Actions.SET_PRODUCT_NAME_SUGGESTION:
			return {
				...state,
				suggestionDataProductName: action.value
			}
		case Actions.SET_BRANCH:
			return {
				...state,
				branch: action.value
			}
		case Actions.SET_BRANCH_LIST:
			return {
				...state,
				branchList: action.value
			}
		case Actions.SET_TABLE_DATA:
			return {
				...state,
				tableData: action.value
			}
		case Actions.SET_EDIT_IDX:
			return {
				...state,
				editIdx: action.value
			}
		case Actions.SET_IS_EDITING:
			return {
				...state,
				isEditing: action.value
			}
		case Actions.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.value
			}
		default:
			return { ...state }
	}
}

const Store = () => {
	const [  state, dispatch ] = React.useReducer(reducer, initState);

	React.useEffect(() => {}, []);

	return {
		state,
		dispatch
	}
}

export default Store;
