/**
 *	iWear - Dashboard container
 *
 *		@using React Hook
 */

import React from 'react';
import {
	Layout
} from 'antd';
import moment from 'moment';

import TopBar from '../../../components/layout/header';
import Sidebar from '../../../components/layout/sider';
import BottomBar from '../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../environments';
import WebCookie from '../../../lib/web-cookie';
import GlobalStore from '../../../store';
import LocalStore from './dashboard.store';
import Content from './dashboard.content';
import { ContainerPropTypes, Actions } from './dashboard.model';

const startMonthDate = moment().startOf('month');
const currentDate = moment();
const startMonthDateString = startMonthDate.format('YYYY-MM-DD');
const currentDateString = currentDate.format('YYYY-MM-DD');

const Container = (props) => {
	const DashboardReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData({ branch: DashboardReducer.state.branch, filterDate: DashboardReducer.state.filterDate }, DashboardReducer.dispatch, state.auth.token);
	}, [ DashboardReducer.state.branch, DashboardReducer.state.filterDate, DashboardReducer.dispatch, state.auth.token ]);

	const fetchData = async (state, localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		let res_branch = await fetch(`${API_ENDPOINT}/branches?limit=9999`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		let json_branch = await res_branch.json();
		localDispatch({ type: Actions.SET_BRANCH_LIST, value: [ ...json_branch.list ] });

		let res = await fetch(`${API_ENDPOINT}/stats/super-user?branch_name=${encodeURIComponent(state.branch)}&start_date=${(state.filterDate.text[0] !== '') ? encodeURIComponent(state.filterDate.text[0]) : encodeURIComponent(startMonthDateString)}&end_date=${(state.filterDate.text[1] !== '') ? encodeURIComponent(state.filterDate.text[1]) : encodeURIComponent(currentDateString)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json = await res.json();
		if(!json.statistic) {
			localDispatch({ type: Actions.SET_IS_LOADING, value: false });
			return;
		}

		let _stats = json.statistic[0];
		localDispatch({ type: Actions.SET_TOTAL_SALE, value: _stats.order_amount || 0 });
		localDispatch({ type: Actions.SET_MONTH_SALE, value: _stats.order_amount_month || 0 });
		localDispatch({ type: Actions.SET_TODAY_SALE, value: _stats.order_amount_today || 0 });
		localDispatch({ type: Actions.SET_TODAY_ORDER, value: _stats.order_quantity || 0 });
		localDispatch({ type: Actions.SET_FRAME_SALE, value: { count: _stats.frame_quantity || 0, value: _stats.frame_amount || 0 } });
		localDispatch({ type: Actions.SET_LENS_SALE, value: { count: _stats.lens_quantity || 0, value: _stats.lens_amount || 0 } });
		localDispatch({ type: Actions.SET_AVG_AGE, value: (_stats.avg_age_customer) ? Number.parseFloat(_stats.avg_age_customer.toFixed(2)) : 0 });
		localDispatch({ type: Actions.SET_AVG_EXPENSE, value: (_stats.avg_spend_per_customer) ? Number.parseFloat(_stats.avg_spend_per_customer.toFixed(2)) : 0 });
		localDispatch({ type: Actions.SET_CUSTOMER_RATIO, value: { newCustomer: _stats.new_customer || 0, oldCustomer: _stats.old_customer || 0 } });
		localDispatch({ type: Actions.SET_CHART_DATA, value: { branch: json.branches_income, payment: json.payment_percentage, promo: json.promotion_percentage } });

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onChangeBranch = (branchName) => {
		DashboardReducer.dispatch({ type: Actions.SET_BRANCH, value: `${branchName}` });
	}

	const onChangeFilterDateRange = (date, dateString) => {
		let _dates = [];
		date.forEach((_e) => { _dates.push(_e.toDate()); });

		let _startDate = moment(date[0]).format('YYYY-MM-DD');
		let _endDate = moment(date[1]).format('YYYY-MM-DD');

		DashboardReducer.dispatch({
			type: Actions.SET_FILTER_DATE,
			value: {
				value: _dates,
				text: [ _startDate, _endDate ]
			}
		});
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/dashboard'} history={ props.history } />
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/dashboard'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...DashboardReducer.state }

						onChangeBranch={ onChangeBranch }
						onChangeFilterDateRange={ onChangeFilterDateRange }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	)
}

Container.propTypes = ContainerPropTypes;

export default Container;
