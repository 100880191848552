/**
 *	Dashboard - Container
 */

import React from 'react';
import {
	Layout
} from 'antd';
import moment from 'moment';

import TopBar from '../../../components/layout/header';
import Sidebar from '../../../components/layout/sider';
import BottomBar from '../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../environments';
import GlobalStore from '../../../store';
import LocalStore from './dashboard.store';
import Content from './dashboard.content';
import { ContainerPropTypes, Actions } from './dashboard.model';

const startMonthDate = moment().startOf('month');
const currentDate = moment();
const startMonthDateString = startMonthDate.format('YYYY-MM-DD');
const currentDateString = currentDate.format('YYYY-MM-DD');

const Container = (props) => {
	const DashboardReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData(DashboardReducer.state.filterDate, DashboardReducer.dispatch, state.auth.token);
	}, [ DashboardReducer.state.filterDate, DashboardReducer.dispatch, state.auth.token ]);

	const fetchData = async (filterDate, localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		let res = await fetch(`${API_ENDPOINT}/stats/sales?branch_id=${encodeURIComponent(branchID)}&start_date=${(filterDate.text[0] !== '') ? encodeURIComponent(filterDate.text[0]) : encodeURIComponent(startMonthDateString)}&end_date=${(filterDate.text[1] !== '') ? encodeURIComponent(filterDate.text[1]) : encodeURIComponent(currentDateString)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json = await res.json();

		localDispatch({ type: Actions.SET_TODAY_ORDER, value: json.orders_today || 0 });
		localDispatch({ type: Actions.SET_PERIOD_ORDER, value: json.orders_period || 0 });
		localDispatch({ type: Actions.SET_FRAME_PERIOD_SALE, value: json.sales_glass_period || 0 });
		localDispatch({ type: Actions.SET_LENS_PERIOD_SALE, value: json.sales_lens_period || 0 });
		localDispatch({ type: Actions.SET_TODAY_SALE, value: json.sales_today || 0 });
		localDispatch({ type: Actions.SET_MONTH_SALE, value: json.sales_month || 0 });
		localDispatch({ type: Actions.SET_PERIOD_SALE, value: json.sales_period || 0 });
		localDispatch({ type: Actions.SET_TOTAL_SALE, value: json.sales_all_time || 0 });

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const setFilterDate = (data) => {
		DashboardReducer.dispatch({
			type: Actions.SET_FILTER_DATE,
			value: data
		});
	}

	return (
		<Layout>
			<Sidebar mode={'sales'} currentRoute={'/sales/dashboard'} history={ props.history } />
			<Layout>
				<TopBar mode={'sales'} currentRoute={'/sales/dashboard'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...DashboardReducer.state }

						setFilterDate={ setFilterDate }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	)
}

Container.propTypes = ContainerPropTypes;

export default Container;
