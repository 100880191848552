/**
 *	iWear - DataBrand operation modal
 */

import React from 'react';
import {
	Modal,
	Input,
} from 'antd';
import PropTypes from 'prop-types';

const AdminDataBrandModal = (props) => {
	return (
		<Modal
			title={ props.title }
			visible={ props.visible }
			onOk={ props.onClickConfirm }
			onCancel={ props.onClickCancel }
		>
			<div className={'field'}>
				<label className={'label'}>ชื่อแบรนด์</label>
				<div className={'control'}>
					<Input
						disabled={ (props.modalType === 'REMOVE') }
						allowClear
						value={ props.name }
						onChange={ props.onChangeName }
					/>
				</div>
			</div>
		</Modal>
	);
}

AdminDataBrandModal.propTypes = {
	title: PropTypes.string.isRequired,
	visible: PropTypes.bool.isRequired,
	modalType: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	onClickConfirm: PropTypes.func.isRequired,
	onClickCancel: PropTypes.func.isRequired,
	onChangeName: PropTypes.func.isRequired
}

export default AdminDataBrandModal;
