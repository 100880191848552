/**
 *	iWear - Customer Detail screen
 */

import React from 'react';
import XDate from 'xdate';
import {
	Radio,
	Row,
	Col,
	Input,
	InputNumber
} from 'antd';

import Breadcrumb from '../../../../components/breadcrumb';
import IWearDropdown from '../../../../components/dropdown';
import CustomerChannelSelect from '../../../../components/select-customer-channel';
import EyeMeasureTableData from '../../../../components/eye-measure-table';
import { ContentPropTypes } from './customers-detail.model';

const CustomerDetail = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'การจัดการลูกค้า', 'รายละเอียดลูกค้า' ]} withGoBack onClickGoBack={ props.onClickGoBack } />
			{ renderContent(props) }
		</div>
	);
}

const renderContent = (props) => {
	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
			<div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>
				<Row>
					<Col span={24}>
						{ renderOptions(props) }
					</Col>
				</Row>
				<div style={{ margin: '10px' }}></div>
				<Row>
					<Col span={24}>
						{ renderSectors(props) }
					</Col>
				</Row>
			</div>
		</div>
	)
}

const renderOptions = (props) => {
	return (
		<div className={'custom-iwear-global-table-option-panel'}>
			<Row>
				<Col span={24}>
					<span
						className={'custom-iwear-global-font-color is-size-5'}
						style={{ textDecorationLine: 'underline' }}
					>
						รายละเอียดลูกค้า
					</span>
				</Col>
			</Row>
		</div>
	);
}

const renderOldEyeMeasureTable = (props) => {
	return (
		<EyeMeasureTableData
			type={'old'}
			tableName={'ค่าสายตาเก่า'}
			data={ props.oldMeasureData }
			setData={(value) => {}}
			disabled={true}
		/>
	);
}

const renderNewEyeMeasureTable = (props) => {
	return (
		<EyeMeasureTableData
			type={'new'}
			tableName={'ค่าสายตาใหม่'}
			data={ props.newMeasureData }
			setData={(value) => {}}
			disabled={true}
		/>
	);
}

const renderLatestOrderTable = (props) => {
	return (
		<div className={'custom-iwear-global-table-container'}>
			<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-left-th'}>
							ลำดับที่
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							วันที่
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							เลขที่สั่งซื้อ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							จำนวนเงิน
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							วันนัดหมาย
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							สถานะ
						</th>
					</tr>
				</thead>
				<tbody>
					{ renderLatestOrderTableData(props) }
				</tbody>
			</table>
		</div>
	);
}

const renderLatestOrderTableData = (props) => {
	if(props.isLoading) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* กำลังโหลด... */}
				</td>
			</tr>
		);
	}
	else if(!props.isLoading && props.latestOrderData.length === 0) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* ไม่มีข้อมูล */}
				</td>
			</tr>
		);
	}

	return props.latestOrderData.map((_e, _i) => {
		return (
			<tr
				key={_i}
				className={'custom-iwear-global-hover-cursor-pointer'}
				onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
			>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ _i + 1 }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ XDate(_e.create_at).toString('dd/MM/yyyy')  }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.order_code }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.price }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ XDate(_e.receive_date).toString('dd/MM/yyyy') }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ itemStatusResolver(_e.status) }
				</td>
				<td
					className={'has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
				</td>
			</tr>
		);
	});
}

const itemStatusResolver = (status) => {
	if(status === 'cancelled') {
		return 'ยกเลิกแล้ว';
	}
	else if(status === 'paid') {
		return 'ชำระเงินแล้ว';
	}
	else {
		return 'มัดจำแล้ว';
	}
}

const renderFirstSector = (props) => {
	return (
		<React.Fragment>
			<Row>
				<Col span={24}>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						1. ข้อมูลลูกค้า
					</span>
				</Col>
			</Row>
			<div style={{ margin: '15px' }}></div>

			<Row>
				<Col span={12}>

					<Row type={'flex'} align={'bottom'} justify={'center'}>
						<Col span={12}>
							<label className={'custom-iwear-global-font-color'}>
								* เบอร์โทรศัพท์
							</label>
							<Input
								disabled
								className={'custom-iwear-global-font-color'}
								value={ props.tel }
								maxLength={13}
								onChange={(evt) => {}}
							/>
						</Col>
						<Col span={2}></Col>
						<Col span={10}></Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row>
						<Col span={12}>
							<label className={'custom-iwear-global-font-color'}>
								* ชื่อ-นามสกุล
							</label>
							<Input
								disabled
								className={'custom-iwear-global-font-color'}
								value={ props.name }
								onChange={(evt) => {}}
							/>
						</Col>
						<Col span={2}></Col>
						<Col span={10}>
							<label className={'custom-iwear-global-font-color'}>
								* เพศ
							</label><br />
							<Radio.Group
								value={ props.gender }
								buttonStyle={'solid'}
								onChange={(evt) => {}}
							>
								<Radio.Button disabled value={'male'}>
									<span className={'custom-iwear-global-table-text-color'}>
										ชาย
									</span>
								</Radio.Button>
								<Radio.Button disabled value={'female'}>
									<span className={'custom-iwear-global-table-text-color'}>
										หญิง
									</span>
								</Radio.Button>
							</Radio.Group>
						</Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row>
						<Col span={12}>
							<label className={'custom-iwear-global-font-color'}>
								วันเกิด
							</label>
							<Input
								disabled
								className={'custom-iwear-global-font-color'}
								value={ props.birthdate }
							/>
						</Col>
						<Col span={2}></Col>
						<Col span={10}>
							<label className={'custom-iwear-global-font-color'}>
								* อายุ
							</label><br />
							<InputNumber
								disabled
								className={'custom-iwear-global-font-color'}
								value={ props.age }
								min={0}
								max={300}
							/>
						</Col>
					</Row>

				</Col>
				<Col span={12}>

					<Row gutter={8}>
						<Col span={8}>
							<label className={'custom-iwear-global-font-color'}>
								* ช่องทางลูกค้า
							</label>
							<CustomerChannelSelect
								disabled isFull
								selected={ props.customerChannel }
								onChange={ props.setCustomerChannel }
							/>
						</Col>
						<Col span={8}>
							<label className={'custom-iwear-global-font-color'}>
								จังหวัด
							</label>
							<IWearDropdown
								isFull
								dropdownStyle={{ disabled: true }}
								displayValue={ props.province }
								choices={[]}
								onSelect={(selectedVal) => {}}
							/>
						</Col>
						<Col span={8}>
							<label className={'custom-iwear-global-font-color'}>
								เขต
							</label>
							<IWearDropdown
								isFull
								dropdownStyle={{ disabled: true }}
								displayValue={ props.addrZone }
								choices={[]}
								onSelect={(selectedVal) => {}}
							/>
						</Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row>
						<Col>
							<label className={'custom-iwear-global-font-color'}>
								ข้อมูลเพิ่มเติม
							</label>
							<Input.TextArea
								disabled
							/>
						</Col>
					</Row>

				</Col>
			</Row>
		</React.Fragment>
	);
}

const renderSecondSector = (props) => {
	return (
		<React.Fragment>
			<Row>
				<Col span={24}>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						2. การตรวจวัดสายตา
					</span>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>

			<Row>
				<Col span={8}>
					<div className={'custom-iwear-customer-create-inspector-wrapper'}>
						<span style={{ minWidth: '100px'}}>ผู้ตรวจวัดสายตา</span>
						<IWearDropdown
							isFull
							dropdownStyle={{ disabled: true }}
							displayValue={ props.inspector }
							choices={[ ...props.inspectorData ]}
							onSelect={(selectedVal) => { props.setInspector(selectedVal); }}
						/>
					</div>
				</Col>
				<Col span={16}></Col>
			</Row>
			<div style={{ margin: '10px' }}></div>

			<Row>
				<Col span={12}>
					{ (!props.isLoading) && renderNewEyeMeasureTable(props) }
				</Col>
				<Col span={12}>
					{ (!props.isLoading) && renderOldEyeMeasureTable(props) }
				</Col>
			</Row>
		</React.Fragment>
	);
}

const renderThirdSector = (props) => {
	return (
		<React.Fragment>
			<Row>
				<Col span={24}>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						3. คำสั่งซื้อล่าสุด
					</span>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>

			<Row>
				<Col span={24}>
					{ renderLatestOrderTable(props) }
				</Col>
			</Row>
		</React.Fragment>
	);
}

const renderSectors = (props) => {
	return (
		<div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
			<Row>
				<Col span={24}>
					{ renderFirstSector(props) }
				</Col>
			</Row>
			<div style={{ marginBottom: '30px' }}></div>
			<Row>
				<Col span={24}>
					{ renderSecondSector(props) }
				</Col>
			</Row>
			<div style={{ marginBottom: '30px' }}></div>
			<Row>
				<Col span={24}>
					{ renderThirdSector(props) }
				</Col>
			</Row>
		</div>
	);
}

CustomerDetail.propTypes = ContentPropTypes;

export default CustomerDetail;
