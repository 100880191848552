/**
 *	Combined Reducers
 */

import AuthenticationReducer from './Authentication';

const reducers = (initialState, action) => ({
	auth: AuthenticationReducer(initialState.auth, action)
});

export default reducers;
