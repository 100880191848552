/**
 *	Reducer initialization - All reducer
 */

import WebCookie from '../lib/web-cookie';

const authentication = {
	userInfo: (localStorage.getItem('iwear_accessor'))
		? JSON.parse(localStorage.getItem('iwear_accessor')).user_info
		: {
			full_name: '',
			userId: 0,
			permission: ''
		},
	branchInfo: (localStorage.getItem('iwear_accessor'))
		? JSON.parse(localStorage.getItem('iwear_accessor')).branch_info
		: {
			branch_id: 0,
			branch_name: '',
			district: '',
			location: '',
			branch_code: ''
		},
	token: (WebCookie.get('iwear_credential')) ? WebCookie.get('iwear_credential') : ''
}

const initializer = {
	auth: authentication
}

export default initializer;
