/**
 *	Order list - Content
 */

import React from 'react';
import NumberFormat from 'react-number-format';
import XDate from 'xdate';
import {
	Empty,
	Spin,
	Row,
	Col,
	Modal,
	Input,
	Pagination
} from 'antd';

import Breadcrumb from '../../../../components/breadcrumb';
import IWearDropdown from '../../../../components/dropdown';
import ExcelButton from '../../../../components/excel-button';
import { phoneMaskText } from '../../../../functions';
import RemainingPaymentModal from '../components/ramaining-payment-modal';
import { ContentPropTypes } from './order-list.model';

const Content = (props) => {
	return (
		<React.Fragment>
			<div className={'container custom-iwear-global-container-padding'}>
				<Breadcrumb paths={[ 'การสั่งซื้อ', 'รายการ' ]} />
				{ renderContent(props) }
			</div>
			<Modal
				title={'เหตุผลที่ยกเลิก'}
				visible={ props.cancelModalVisible }
				onOk={ props.cancelModalOnClickOK }
				onCancel={ props.cancelModalOnClickCancel }
			>
				<IWearDropdown
					isFull
					displayValue={ props.cancelType }
					choices={[ 'คืนสินค้า', 'อื่นๆ' ]}
					onSelect={(selectedVal) => {
						props.setCancelType(selectedVal);
					}}
				/>
				{
					(props.cancelType === 'อื่นๆ') && (
						<React.Fragment>
							<br/>
							<Input.TextArea
								autosize={{ minRows: 3 }}
								placeholder={'เหตุผล'}
								onChange={ props.cancelModalOnChangeCancelReason }
							/>
						</React.Fragment>
					)
				}
			</Modal>
		</React.Fragment>
	);
}

const renderContent = (props) => {
	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
			<Row>
				<Col span={24}>
					{ renderOptions(props) }
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					{ renderTable(props) }
					<RemainingPaymentModal
						fullPrice={ props.modalFullPrice }
						payment={ props.modalPayment }
						isVisible={ props.payModalVisible }
						onClickPaid={ props.modalOnClickPaid }
						onClickClose={ props.onClickClosePaymentModal }
					/>
				</Col>
			</Row>
			<Row type={'flex'} align={'middle'} justify={'end'}>
				<Col style={{ paddingBottom: '10px' }}>
					<Pagination
						className={'custom-iwear-global-table-pagination-container'}
						current={ props.page || 1 }
						pageSize={ props.pageSize || 10 }
						total={ props.totalPage }
						onChange={ props.onChangePage }
					/>
				</Col>
			</Row>
		</div>
	)
}

const renderOptions = (props) => {
	let _branches = [];
	props.branchList.forEach((_e) => {
		_branches.push(_e.branch_name);
	});

	return (
		<div className={'custom-iwear-global-table-option-panel'}>
			<Row gutter={16} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={12}>
					<span className={'custom-iwear-global-font-color has-text-weight-bold is-size-5'}>
						ผลลัพธ์ข้อมูล&nbsp;
						<NumberFormat
							thousandSeparator
							displayType={'text'}
							value={ props.totalData }
						/>
						&nbsp;ข้อมูล
					</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						ค้นหา
					</span>&nbsp;
					<Input.Search
						enterButton
						placeholder={'Search'}
						allowClear={true}
						style={{ maxWidth: '70%' }}
						onSearch={ props.onChangeSearchKey }
					/>
				</Col>
				<Col span={10}>
					<Row type={'flex'} align={'middle'} justify={'start'}>
					</Row>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row gutter={16} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={12}>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						แสดง
					</span>&nbsp;&nbsp;&nbsp;
					<IWearDropdown
						styleJSX={{ minWidth: '80px', paddingRight: '20px' }}
						displayValue={ String(props.pageSize) }
						choices={[ 10, 20, 50, 100 ]}
						onSelect={ props.onChangePageSize }
					/>
				</Col>
				<Col span={12}>
					<Row gutter={8} type={'flex'} align={'middle'} justify={'end'}>
						<Col>
							<span className={'custom-iwear-global-font-color is-size-5'}>
								สาขา
							</span>&nbsp;
							<IWearDropdown
								styleJSX={{ minWidth: '100px', paddingRight: '25px' }}
								displayValue={ props.branchName || 'ทั้งหมด' }
								choices={[ 'ทั้งหมด', ..._branches ]}
								onSelect={ props.onChangeBranch }
							/>
						</Col>
						<Col>
							<ExcelButton
								modalTitle={'เลือกช่วงของวันที่สั่งซื้อ'}
								exportType={'order'}
								dispatcher={ props.dispatcher }
								isLoading={ props.isExporting }

								branchName={ (props.branchName !== 'ทั้งหมด') ? props.branchName : '' }
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
}

const renderTable = (props) => {
	return (
		<div className={'custom-iwear-global-table-container'}>
			<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ลำดับ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							เลขที่สั่งซื้อ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ชื่อ-นามสกุลลูกค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							โทร
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							วันที่นัดหมาย
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ยอดเงินที่จ่ายแล้ว/ยอดเงินคงค้าง
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							สถานะของการสั่งซื้อ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							สถานะของการจัดส่ง
						</th>
						{/*<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							เพิ่มเติม
						</th>*/}
					</tr>
				</thead>
				<tbody>
					{ renderTableData(props) }
				</tbody>
			</table>
		</div>
	);
}

const renderTableData = (props) => {
	let _payment = 0;

	if(props.isLoading) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					<Spin />
				</td>
			</tr>
		);
	}
	else if(!props.isLoading && props.tableData.length === 0) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>กรุณาเลือกสาขา</span>} />
				</td>
			</tr>
		);
	}

	return props.tableData.map((_e, _i) => {
		_payment = 0;
		_e.payment_info.forEach((_p) => { _payment += _p.amount; })

		return (
			<tr key={_i} className={'custom-iwear-global-hover-cursor-pointer'}>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _i + 1 + ((props.page - 1) * props.pageSize) }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.order_code }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.customer_info.full_name }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ phoneMaskText(_e.customer_info.phone_number) }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ XDate(new Date(_e.receive_date)).toString('dd/MM/yyyy') }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					<NumberFormat thousandSeparator displayType={'text'} value={ Number.parseFloat(_payment.toFixed(2)) } />
					&nbsp;/&nbsp;
					<NumberFormat
            thousandSeparator
            displayType={'text'}
            value={ (_e.price - _e.discount - _payment >= 0)
              ? Number.parseFloat(`${ (_e.price - _e.discount - _payment).toFixed(2) }`)
              : 0
            }
          />
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ itemStatusResolver(_e.status) }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ preparationStatusResolver(_e.status) }
				</td>
				{/*<td
					className={'has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{
						(_e.status !== 'cancelled') && (
							<Dropdown overlay={() => renderDropdownMenu(props, _e, _i)} trigger={['click']}>
								<button className={'button '}>
									<i className={'fas fa-ellipsis-v custom-iwear-global-table-text-color'}></i>
								</button>
							</Dropdown>
						)
					}
				</td>*/}
			</tr>
		);
	});
}

const itemStatusResolver = (status) => {
	if(status === 'cancelled') {
		return 'ยกเลิกแล้ว';
	}
	else if(status === 'paid') {
		return 'ชำระเงินแล้ว';
	}
	else {
		return 'มัดจำแล้ว';
	}
}

const preparationStatusResolver = (status) => {
	if(status === 'IN_PROGRESS') { return 'กำลังดำเนินการ'; }
	else if(status === 'SHIPPING') { return 'กำลังส่งสินค้า'; }
	else if(status === 'DONE') { return 'ส่งสินค้าเรียนร้อยแล้ว'; }
	else if(status === 'REVISE') { return 'กำลังแก้ไข'; }
	else if(status === 'RETURNING') { return 'กำลังส่งกลับไปแก้ไข'; }
	else { /* ORDERED */ return 'สั่งซื้อแล้ว'; }
}

Content.propTypes = ContentPropTypes;

export default Content;
