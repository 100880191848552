/**
 *	iWear - Order Detail state management
 */

import React from 'react';
import Actions from './actions.json';

const initState = {
	tel: '',
	name: '',
	age: 1,
	gender: '',
	birthdate: '',
	customerChannel: [],
	addrZone: '',
	province: '',
	extInfo: '',
	tableData: [],
	productData: [],
	oldMeasureData: [],
	newMeasureData: [],
	eyeHistoryData: [],
	inspector: '',
	includeVat: false,
  vatValue: 0,
	reqAssemble: false,
	claimModalVisible: false,
	measureModalVisible: false,
	promoData: [],
	promoType: '',
	promoValue: 0,
	priceSum: 0,
	createdDate: '',
	deliverDate: '',
	remark: '',
	payment: 0,
	paymentType: '',
	moneyReceive: 0,
	isLoading: false
}

const reducer = (state, action) => {
	switch(action.type) {
		case Actions.SET_TEL:
			return {
				...state,
				tel: action.value
			}
		case Actions.SET_NAME:
			return {
				...state,
				name: action.value
			}
		case Actions.SET_AGE:
			return {
				...state,
				age: action.value
			}
		case Actions.SET_GENDER:
			return {
				...state,
				gender: action.value
			}
		case Actions.SET_BIRTHDATE:
			return {
				...state,
				birthdate: action.value
			}
		case Actions.SET_CUSTOMER_CHANNEL:
			return {
				...state,
				customerChannel: action.value
			}
		case Actions.SET_ADDR_ZONE:
			return {
				...state,
				addrZone: action.value
			}
		case Actions.SET_PROVINCE:
			return {
				...state,
				province: action.value
			}
		case Actions.SET_EXT_INFO:
			return {
				...state,
				extInfo: action.value
			}
		case Actions.SET_TABLE_DATA:
			return {
				...state,
				tableData: action.value
			}
		case Actions.SET_PRODUCT_DATA:
			return {
				...state,
				productData: action.value
			}
		case Actions.SET_OLD_MEASURE_DATA:
			return {
				...state,
				oldMeasureData: action.value
			}
		case Actions.SET_NEW_MEASURE_DATA:
			return {
				...state,
				newMeasureData: action.value
			}
		case Actions.SET_EYE_HISTORY_DATA:
			return {
				...state,
				eyeHistoryData: action.value
			}
		case Actions.SET_INSPECTOR:
			return {
				...state,
				inspector: action.value
			}
		case Actions.SET_INCLUDE_VAT: return ({ ...state, includeVat: action.value })
		case Actions.SET_VAT_VALUE: return ({ ...state, vatValue: action.value })
		case Actions.SET_REQ_ASSEMBLE: return ({ ...state, reqAssemble: action.value })
		case Actions.SET_CLAIM_MODAL_VISIBLE:
			return {
				...state,
				claimModalVisible: action.value
			}
		case Actions.SET_MEASURE_MODAL_VISIBLE:
			return {
				...state,
				measureModalVisible: action.value
			}
		case Actions.SET_PROMO_DATA:
			return {
				...state,
				promoData: action.value
			}
		case Actions.SET_PROMO_TYPE:
			return {
				...state,
				promoType: action.value
			}
		case Actions.SET_PROMO_VALUE:
			return {
				...state,
				promoValue: action.value
			}
		case Actions.SET_PRICE_SUM:
			return {
				...state,
				priceSum: action.value
			}
		case Actions.SET_CREATED_DATE:
			return {
				...state,
				createdDate: action.value
			}
		case Actions.SET_DELIVER_DATE:
			return {
				...state,
				deliverDate: action.value
			}
		case Actions.SET_REMARK:
			return {
				...state,
				remark: action.value
			}
		case Actions.SET_PAYMENT:
			return {
				...state,
				payment: action.value
			}
		case Actions.SET_PAYMENT_TYPE:
			return {
				...state,
				paymentType: action.value
			}
		case Actions.SET_MONEY_RECEIVE:
			return {
				...state,
				moneyReceive: action.value
			}
		case Actions.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.value
			}
		default:
			return { ...state }
	}
}

const OrderDetailReducer = () => {
	const [ state, dispatch ] = React.useReducer(reducer, initState);

	React.useEffect(() => {}, []);

	return {
		state,
		dispatch
	}
}

export default OrderDetailReducer;
