/**
 *	iWear - Data Product Upload state management
 */

import React from 'react';
import Actions from './actions.json';

const initState = {
	branch: '',
	branchList: [],
	file: { value: null, text: '' },
	productType: '',
	productTypeList: [],
	frameType: '',
	frameTypeList: [],
	lensType: '',
	lensTypeList: [],
	isLoading: false
}

const reducer = (state, action) => {
	switch(action.type) {
		case Actions.SET_BRANCH:
			return {
				...state,
				branch: action.value
			}
		case Actions.SET_BRANCH_LIST:
			return {
				...state,
				branchList: action.value
			}
		case Actions.SET_FILE:
			return {
				...state,
				file: action.value
			}
		case Actions.SET_PRODUCT_TYPE:
			return {
				...state,
				productType: action.value
			}
		case Actions.SET_PRODUCT_TYPE_LIST:
			return {
				...state,
				productTypeList: action.value
			}
		case Actions.SET_FRAME_TYPE:
			return {
				...state,
				frameType: action.value
			}
		case Actions.SET_FRAME_TYPE_LIST:
			return {
				...state,
				frameTypeList: action.value
			}
		case Actions.SET_LENS_TYPE:
			return {
				...state,
				lensType: action.value
			}
		case Actions.SET_LENS_TYPE_LIST:
			return {
				...state,
				lensTypeList: action.value
			}
		case Actions.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.value
			}
		default:
			return { ...state }
	}
}

const DataProductUploadReducer = () => {
	const [ state, dispatch ] = React.useReducer(reducer, initState);

	React.useEffect(() => {}, []);

	return {
		state,
		dispatch
	}
}

export default DataProductUploadReducer;
