/**
 *	Stock Export Detail - Model
 */

import PropTypes from 'prop-types';

export const ContainerPropTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export const ContentPropTypes = {
	tableData: PropTypes.array.isRequired,
	onClickGoBack: PropTypes.func.isRequired
}
