/**
 *	Appointment component - Contact Panel
 */

import React from 'react';
import {
	DatePicker,
	Modal,
	Row,
	Col,
	Button
} from 'antd';
import moment from 'moment';
import swal from 'sweetalert';
import PropTypes from 'prop-types';

import { phoneMaskText } from '../../functions';
import styles from './appointment-contact-panel.module.css';

const ContactPanel = (props) => {
	const [ appointedDate, setAppointedDate ] = React.useState('');

	const [ telModalVisible, setTelModalVisible ] = React.useState(false);
	const [ appointModalVisible, setAppointModalVisible ] = React.useState(false);

	React.useEffect(() => {
		//
	}, []);

	const onPanelClick = () => {
		// console.log('props', props)
		props.onPanelClick(props.orderID, props.customerID, props.name, props.tel);
	}

	const onClickTel = () => {
		setTelModalVisible(true)
	}

	const onClickReAppointed = () => {
		setAppointModalVisible(true);
	}

	return (
		<React.Fragment>
			<Row className={ styles.contactPanelContainer } type={'flex'} align={'middle'} justify={'center'}>
				<Col
					span={18}
					className={`${styles.contactPanelNameContainer} custom-iwear-global-hover-cursor-pointer`}
					onClick={ onPanelClick }
				>
					<span>
						{ props.name }
					</span>
				</Col>
				<Col
					span={3}
					className={`custom-iwear-appointment-contact-panel-icon-container custom-iwear-global-hover-cursor-pointer custom-iwear-appointment-contact-panel-item-separator`}
					onClick={ onClickTel }
				>
					<i className={'fas fa-phone-alt'}></i>
				</Col>
				<Col
					span={3}
					className={`custom-iwear-appointment-contact-panel-icon-container custom-iwear-global-hover-cursor-pointer custom-iwear-appointment-contact-panel-item-separator`}
					onClick={ onClickReAppointed }
				>
					<i className={'fas fa-redo-alt'}></i>
				</Col>
			</Row>
			{ renderTelModal({ telModalVisible, setTelModalVisible }, props) }
			{ renderAppointModal({ appointedDate, setAppointedDate, appointModalVisible, setAppointModalVisible }, props) }
		</React.Fragment>
	);
}

const renderTelModal = (state, props) => {
	return (
		<Modal
			title={'เบอร์ติดต่อ'}
			visible={ state.telModalVisible }
			footer={[
				<Button onClick={() => { state.setTelModalVisible(false); }}>
					ปิด
				</Button>
			]}
			onCancel={() => { state.setTelModalVisible(false); }}
		>
			<div className={'is-flex'} style={{ alignItems: 'center', justifyContent: 'center' }}>
				<span className={'is-size-3'}>
					{ phoneMaskText(props.tel) }
				</span>
			</div>
		</Modal>
	);
}

const renderAppointModal = (state, props) => {
	return (
		<Modal
			title={'วันนัดหมาย'}
			visible={ state.appointModalVisible }
			onCancel={() => { state.setAppointModalVisible(false); }}
			footer={[
				<Button onClick={() => { state.setAppointModalVisible(false); }}>
					ปิด
				</Button>,
				<Button
					type={'primary'}
					onClick={() => {
						if(state.appointedDate === '') {
							swal({
								title: 'นัดหมายล้มเหลว',
								text: 'กรุณาเลือกวันนัดหมายใหม่',
								icon: 'warning'
							});
						}
						else {
							props.onSubmitNewAppointmentDate(state.appointedDate, props.orderID, props.customerID, props.appointmentType);
							state.setAppointModalVisible(false);
						}
					}}
				>
					ยืนยัน
				</Button>
			]}
		>
			<div className={'is-flex'} style={{ alignItems: 'center', justifyContent: 'center' }}>
				{
					(props.appointedDate === '')
					? (
						<DatePicker
							defaultValue={moment()}
							showToday={false}
							format={'DD/MM/YYYY'}
							allowClear={false}
							placeholder={'Date'}
							style={{ width: '100%' }}
							onChange={(dateObj, dateString) => {
								state.setAppointedDate(JSON.stringify(dateObj._d));
							}}
						/>
					)
					: (
						<DatePicker
							defaultValue={moment(props.appointedDate)}
							showToday={false}
							format={'DD/MM/YYYY'}
							allowClear={false}
							placeholder={'Date'}
							style={{ width: '100%' }}
							onChange={(dateObj, dateString) => {
								state.setAppointedDate(JSON.stringify(dateObj._d));
							}}
						/>
					)
				}
			</div>
		</Modal>
	);
}

ContactPanel.defaultProps = {
	name: '',
	tel: '',
	appointedDate: ''
}

ContactPanel.propTypes = {
	name: PropTypes.string,
	tel: PropTypes.string,
	appointedDate: PropTypes.string,
	orderDate: PropTypes.string.isRequired,
	orderID: PropTypes.number.isRequired,
	customerID: PropTypes.number.isRequired,
	onPanelClick: PropTypes.func.isRequired,
	onSubmitNewAppointmentDate: PropTypes.func.isRequired,
	appointmentType: PropTypes.string.isRequired,
}

export default ContactPanel;
