/**
 *	iWear - Restricted Route
 */

import React from 'react';
import {
	Route,
	Redirect
} from 'react-router';
import PropTypes from 'prop-types';
import WebCookie from '../../lib/web-cookie';

const RestrictedRoute = (props) => {
	if(WebCookie.get('iwear_credential')) {
		let permit = (localStorage.getItem('iwear_accessor') !== null)
						? JSON.parse(localStorage.getItem('iwear_accessor')).user_info.permission
						: '';

		if(permit === props.rolePermission) {
			return (
				<React.Fragment>
					<Route {...props} />
				</React.Fragment>
			);
		}
		else if(props.rolePermission === 'any') {
			// For common pages or use for test to access
			return (
				<React.Fragment>
					<Route {...props} />
				</React.Fragment>
			);
		}

		return (
			<React.Fragment>
				<Redirect to={'/'} />
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<Redirect to={'/'} />
		</React.Fragment>
	);
}

RestrictedRoute.propTypes = {
	exact: PropTypes.bool,
	path: PropTypes.string.isRequired,
	component: PropTypes.elementType.isRequired,
	rolePermission: PropTypes.string.isRequired, // 'manager' || 'staff' || 'any'
}

export default RestrictedRoute;
