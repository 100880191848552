/**
 *	Customer Edit - Content
 */

import React from 'react';
import moment from 'moment';
import {
	DatePicker,
	Radio,
	Input,
	InputNumber,
	Row,
	Col,
	Button
} from 'antd';

import Breadcrumb from '../../../../components/breadcrumb';
import IWearDropdown from '../../../../components/dropdown';
import CustomerChannelSelect from '../../../../components/select-customer-channel';
import EyeMeasureTableData from '../../../../components/eye-measure-table';
import ThaiAddress from '../../../../lib/thai-address';
import { ContentPropTypes } from './customers-edit.model';

const Content = (props) => {
	return (
		<React.Fragment>
			<div className={'container custom-iwear-global-container-padding'}>
				<Breadcrumb paths={[ 'การจัดการลูกค้า', 'แก้ไขข้อมูลลูกค้า' ]} withGoBack onClickGoBack={ props.onClickGoBack } />
				{ renderContent(props) }
			</div>
		</React.Fragment>
	);
}

const renderContent = (props) => {
	return (
		<React.Fragment>
			<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
				<div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>
					<Row>
						<Col span={24}>
							{ renderOptions(props) }
						</Col>
					</Row>
					<Row>
						<Col span={24}>
							{ renderSectors(props) }
						</Col>
					</Row>
					<Row>
						<Col span={24} style={{ textAlign: 'right' }}>
							<Button onClick={ props.onClickSave }>
								<span className={'custom-iwear-global-font-color'}>
									บันทึกข้อมูล
								</span>
							</Button>
						</Col>
					</Row>
				</div>
			</div>
		</React.Fragment>
	)
}

const renderOptions = (props) => {
	return (
		<div className={'custom-iwear-global-table-option-panel'}>
			<Row>
				<Col span={12}>
					<span
						className={'custom-iwear-global-font-color is-size-5'}
						style={{ textDecorationLine: 'underline' }}
					>
						แก้ไขข้อมูลลูกค้า
					</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<Button onClick={ props.onClickSave }>
						<span className={'custom-iwear-global-font-color'}>
							บันทึกข้อมูล
						</span>
					</Button>
				</Col>
			</Row>
		</div>
	);
}

const renderOldEyeMeasureTable = (props) => {
	return (
		<EyeMeasureTableData
			type={'old'}
			tableName={'ค่าสายตาเก่า'}
			data={ props.oldMeasureData }
			setData={ props.onChangeOldMeasureData }
			disabled={true}
		/>
	);
}

const renderNewEyeMeasureTable = (props) => {
	return (
		<EyeMeasureTableData
			type={'new'}
			tableName={'ค่าสายตาใหม่'}
			data={ props.newMeasureData }
			setData={ props.onChangeNewMeasureData }
		/>
	);
}

const renderFirstSector = (props) => {
	return (
		<React.Fragment>
			<Row>
				<Col>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						1. ข้อมูลลูกค้า
					</span>
				</Col>
			</Row>
			<div style={{ margin: '20px' }}></div>

			<Row>
				<Col span={12}>

					<Row type={'flex'} align={'bottom'} justify={'center'}>
						<Col span={12}>
							<label className={'custom-iwear-global-font-color'}>
								* เบอร์โทรศัพท์
							</label>
							<Input
								required
								value={ props.tel }
								maxLength={13}
								onChange={ props.onChangeTel }
							/>
						</Col>
						<Col span={2}></Col>
						<Col span={10}></Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row>
						<Col span={12}>
							<label className={'custom-iwear-global-font-color'}>
								* ชื่อ-นามสกุล
							</label>
							<Input
								value={ props.name }
								onChange={ props.onChangeName }
							/>
						</Col>
						<Col span={2}></Col>
						<Col span={10}>
							<label className={'custom-iwear-global-font-color'}>
								* เพศ
							</label><br />
							<Radio.Group
								value={ props.gender }
								buttonStyle={'solid'}
								onChange={ props.onChangeGender }
							>
								<Radio.Button value={'male'}>
									<span>
										ชาย
									</span>
								</Radio.Button>
								<Radio.Button value={'female'}>
									<span>
										หญิง
									</span>
								</Radio.Button>
							</Radio.Group>
						</Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row>
						<Col span={12}>
							<label className={'custom-iwear-global-font-color'}>
								วันเกิด
							</label>
							{
								(props.oldBirthdate)
								? (
									<DatePicker
										defaultValue={moment(props.oldBirthdate)}
										showToday={false}
										format={'DD/MM/YYYY'}
										allowClear={false}
										placeholder={'Date'}
										style={{ width: '100%' }}
										onChange={ props.onChangeBirthdate }
									/>
								)
								: (
									<DatePicker
										showToday={false}
										format={'DD/MM/YYYY'}
										allowClear={false}
										placeholder={'Date'}
										style={{ width: '100%' }}
										onChange={ props.onChangeBirthdate }
									/>
								)
							}
						</Col>
						<Col span={2}></Col>
						<Col span={10}>
							<label className={'custom-iwear-global-font-color'}>
								* อายุ
							</label><br />
							<InputNumber
								value={ props.age }
								min={1}
								max={100}
								onChange={ props.onChangeAge }
							/>
						</Col>
					</Row>

				</Col>
				<Col span={12}>

					<Row gutter={8}>
						<Col span={8}>
							<label className={'custom-iwear-global-font-color'}>
								* ช่องทางลูกค้า
							</label>
							<CustomerChannelSelect
								isFull
								selected={ props.customerChannel }
								onChange={ props.onChangeCustomerChannel }
							/>
						</Col>
						<Col span={8}>
							<label className={'custom-iwear-global-font-color'}>
								จังหวัด
							</label>
							<IWearDropdown
								isFull
								displayValue={ props.province.text }
								choices={ ThaiAddress.provinceList() }
								onSelect={ props.onChangeProvince }
							/>
						</Col>
						<Col span={8}>
							<label className={'custom-iwear-global-font-color'}>
								เขต
							</label>
							<IWearDropdown
								isFull
								dropdownStyle={{ disabled: (props.province.index === -1) }}
								displayValue={ props.addrZone }
								choices={ (props.province.index !== -1) ? ThaiAddress.districtList(props.province.index) : [] }
								onSelect={ props.onChangeAddrZone }
							/>
						</Col>
					</Row>
					<div style={{ margin: '10px' }}></div>
					<Row>
						<Col>
							<label className={'custom-iwear-global-font-color'}>
								ข้อมูลเพิ่มเติม
							</label>
							<Input.TextArea
								value={ props.extInfo }
								onChange={ props.onChangeExtInfo }
							/>
						</Col>
					</Row>

				</Col>
			</Row>
		</React.Fragment>
	);
}

const renderSecondSector = (props) => {
	let _inspector = ['ไม่มีการตรวจวัดสายตา'];
	props.inspectorData.forEach((_e) => { _inspector.push(_e.name); });

	return (
		<React.Fragment>
			<Row>
				<Col span={24}>
					<span className={'is-size-5 custom-iwear-global-font-color'}>
						2. การตรวจวัดสายตา
					</span>
				</Col>
			</Row>
			<div style={{ margin: '20px' }}></div>

			<Row>
				<Col span={8}>
					<div className={'custom-iwear-customer-create-inspector-wrapper'}>
						<span style={{ minWidth: '100px'}}>ผู้ตรวจวัดสายตา</span>
						<IWearDropdown
							isFull
							displayValue={ props.inspector.name }
							choices={[ ..._inspector ]}
							onSelect={(selectedVal) => {
								if(selectedVal === 'ไม่มีการตรวจวัดสายตา') {
									props.onChangeInspector({ id: -1, name: '' });
								}
								else {
									let _arr = [ ...props.inspectorData ].filter((_f) => { return _f.name === selectedVal; });
									props.onChangeInspector({ ..._arr[0] });
								}
							}}
						/>
					</div>
				</Col>
				<Col span={16}></Col>
			</Row>
			<div style={{ margin: '10px' }}></div>

			<Row>
				<Col span={12}>
					{ renderNewEyeMeasureTable(props) }
				</Col>
				<Col span={12}>
					{ renderOldEyeMeasureTable(props) }
				</Col>
			</Row>
		</React.Fragment>
	);
}

const renderSectors = (props) => {
	return (
		<div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
			<Row>
				<Col span={24}>
					{ renderFirstSector(props) }
				</Col>
			</Row>
			<div style={{ marginBottom: '30px' }}></div>
			<Row>
				<Col span={24}>
					{ renderSecondSector(props) }
				</Col>
			</Row>
		</div>
	);
}

Content.propTypes = ContentPropTypes;

export default Content;
