/**
 *	Customer Add - State Management
 */

import React from 'react';
import { Actions } from './customers-add.model';

const initState = {
	customerID: '',
	tel: '',
	name: '',
	gender: '',
	birthdate: '',
	age: 0,
	customerChannel: [],
	addrZone: '',
	province: { index: -1, text: '' },
	extInfo: '',

	oldMeasureData: [],
	newMeasureData: [],
	inspector: { id: -1, name: '' },
	inspectorData: [],

	latestOrderData: [],
	isLoading: false,
}

const reducer = (state, action) => {
	switch(action.type) {
		case Actions.SET_CUSTOMER_ID:
			return {
				...state,
				customerID: action.value
			}
		case Actions.SET_TEL:
			return {
				...state,
				tel: action.value
			}
		case Actions.SET_NAME:
			return {
				...state,
				name: action.value
			}
		case Actions.SET_GENDER:
			return {
				...state,
				gender: action.value
			}
		case Actions.SET_BIRTHDATE:
			return {
				...state,
				birthdate: action.value
			}
		case Actions.SET_AGE:
			return {
				...state,
				age: action.value
			}
		case Actions.SET_CUSTOMER_CHANNEL:
			return {
				...state,
				customerChannel: action.value
			}
		case Actions.SET_ADDR_ZONE:
			return {
				...state,
				addrZone: action.value
			}
		case Actions.SET_PROVINCE:
			return {
				...state,
				province: action.value
			}
		case Actions.SET_EXT_INFO:
			return {
				...state,
				extInfo: action.value
			}
		case Actions.SET_OLD_MEASURE_DATA:
			return {
				...state,
				oldMeasureData: action.value
			}
		case Actions.SET_NEW_MEASURE_DATA:
			return {
				...state,
				newMeasureData: action.value
			}
		case Actions.SET_INSPECTOR:
			return {
				...state,
				inspector: action.value
			}
		case Actions.SET_INSPECTOR_DATA:
			return {
				...state,
				inspectorData: action.value
			}
		case Actions.SET_LATEST_ORDER_DATA:
			return {
				...state,
				latestOrderData: action.value
			}
		case Actions.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.value
			}
		default:
			return { ...state }
	}
}

const Store = () => {
	const [ state, dispatch ] = React.useReducer(reducer, initState);

	React.useEffect(() => {}, []);

	return {
		state,
		dispatch
	}
}

export default Store;
