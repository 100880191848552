/**
 *	iWear - Excel Button
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Modal,
	DatePicker
} from 'antd';

import { API_ENDPOINT } from '../../environments';
import GlobalStore from '../../store';
import ExportFunction from './excel-button.func';

const ExcelButton = (props) => {
	const [ branchList, setBranchList ] = React.useState([]);
	const [ modalVisible, setModalVisible ] = React.useState(false);
	const [ dateRange, setDateRange ] = React.useState([]);
	const [ state ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchBranchlist(state.auth.token);
	}, [ state.auth.token ]);

	const fetchBranchlist = async (authToken) => {
		let res_branch_list = await fetch(`${API_ENDPOINT}/branches?limit=9999`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json_branch_list = await res_branch_list.json();
		setBranchList([ ...json_branch_list.list ]);
	}

	const onChangeDateRange = (date, dateString) => {
		setDateRange(date);
	}

	const onClickCancel = () => {
		setDateRange([]);
		setModalVisible(false);
	}

	const onClickOK = () => {
		if(state.auth.userInfo.permission.toUpperCase() === 'STAFF') {
			ExportFunction({
				type: props.exportType,
				branch_id: state.auth.branchInfo.branch_id,
				branch_name: state.auth.branchInfo.branch_name,
				dates: [ ...dateRange ],
				dispatcher: props.dispatcher,
				token: state.auth.token
			});
		}
		else {
			// All Branch -> Admin & RxMall
			let [ _branch ] = branchList.filter((_f) => { return _f.branch_name === props.branchName; });
			ExportFunction({
				type: props.exportType,
				branch_id: (_branch) ? _branch.branch_id || 0 : 0,
				branch_name: (props.branchName) ? `${props.branchName}` : '',
				dates: [ ...dateRange ],
				dispatcher: props.dispatcher,
				token: state.auth.token
			});
		}

		setDateRange([]);
		setModalVisible(false);
		// window.location.reload(true);
	}

	const onClickExportButton = () => {
		setModalVisible(true);
	}

	return (
		<React.Fragment>
			<Button
				onClick={ onClickExportButton }
				loading={ props.isLoading }
			>
				<i className={'far fa-file-excel custom-iwear-global-font-color'}></i>&nbsp;Excel Export
			</Button>
			{
				renderModal({
					...props,
					modalVisible, setModalVisible,
					dateRange, setDateRange,
					onChangeDateRange,
					onClickCancel,
					onClickOK,
					onClickExportButton
				})
			}
		</React.Fragment>
	);
}

const renderModal = (props) => {
	return (
		<Modal
			title={ props.modalTitle }
			visible={ props.modalVisible }
			footer={[
				<Button key={'cancel'} onClick={ props.onClickCancel }>
					<span>ยกเลิก</span>
				</Button>,
				<Button key={'ok'} onClick={ props.onClickOK }>
					<span>ยืนยัน</span>
				</Button>
			]}
			onCancel={ props.onClickCancel }
		>
			{ renderModalContent(props) }
		</Modal>
	);
}

const renderModalContent = (props) => {
	if(props.exportType === 'data_branch' ||
		props.exportType === 'data_brand' ||
		props.exportType === 'data_staff' ||
		props.exportType === 'data_product_category' ||
		props.exportType === 'data_promotion'
	) {
		return (
			<span>กด ยืนยัน เพื่อทำรายการ</span>
		);
	}
	else {
		return (
			<DatePicker.RangePicker
				value={ props.dateRange }
				placeholder={[ 'เริ่ม', 'สิ้นสุด' ]}
				onChange={ props.onChangeDateRange }
			/>
		);
	}
}

ExcelButton.propTypes = {
	modalTitle: PropTypes.string.isRequired,
	exportType: PropTypes.string.isRequired,
	dispatcher: PropTypes.any.isRequired, // React Dispatch
	isLoading: PropTypes.bool.isRequired,

	branchName: PropTypes.string
}

export default ExcelButton
