/**
 *	Pages - All pages path
 */

import AdminRoutes from './routes.admin';
import SalesRoutes from './routes.sales';
import LabRoutes from './routes.rxmall';

const AllPages = [ ...AdminRoutes, ...SalesRoutes, ...LabRoutes ];

export default AllPages;
