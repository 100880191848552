/**
 *	Dashboard - Info Card
 */

import React from 'react';
import {
	Row,
	Col,
	Skeleton
} from 'antd';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

import styles from '../dashboard.module.css';

const DashboardInfoCard = (props) => {
	return (
		<div className={ styles.dashboardCard }>
			<Row type={'flex'} align={'middle'} justify={'center'}>
				<Col span={24}>
					<div className={ styles.dashboardCardWrapper }>
						<figure className={'image is-128x128'} style={{ display: 'flex' }}>
							<img src={ props.imgPath } alt={ props.imgAlt }/>
						</figure>
					</div>
				</Col>
			</Row>
			<Row type={'flex'} align={'middle'} justify={'center'}>
				<Col span={24} style={{ textAlign: 'center' }}>
						{
							(props.isLoading)
							? (<Skeleton active title={{ width: '100%' }} paragraph={{ rows: 0 }} />)
							: (props.valueUnit === '')
								? (
									<span className={'is-size-4'}>
										<NumberFormat
											thousandSeparator
											displayType={'text'}
											value={ props.value }
										/>
									</span>
								)
								: (props.valueUnit !== '') && (
									<span className={'is-size-4'}>
										<NumberFormat
											thousandSeparator
											displayType={'text'}
											value={ props.value }
										/>
										&nbsp;{ props.valueUnit }
									</span>
								)
						}
				</Col>
			</Row>
			<Row type={'flex'} align={'middle'} justify={'center'}>
				<Col span={24} style={{ textAlign: 'center' }}>
					<span className={'is-size-5'}>
						{ props.label }
					</span>
					{
						(props.dateLabel !== 'BLANK_SPACE') && (
							<React.Fragment>
								<br/>
								<span className={'is-size-6'}>
									{ props.dateLabel }
								</span>
							</React.Fragment>
						)
					}
					{
						(props.dateLabel === 'BLANK_SPACE') && (
							<React.Fragment>
								<br/>
								<span className={'is-size-6'} style={{ color: 'white' }}>{ props.label }</span>
							</React.Fragment>
						)
					}
				</Col>
			</Row>
		</div>
	);
}

DashboardInfoCard.defaultProps = {
	valueUnit: ''
}

DashboardInfoCard.propTypes = {
	imgPath: PropTypes.string.isRequired,
	imgAlt: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	valueUnit: PropTypes.string,
	label: PropTypes.string.isRequired
}

export default DashboardInfoCard;
