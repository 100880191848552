/**
 *	iWear - DataProductCategory screen
 */

import React from 'react';
import NumberFormat from 'react-number-format';
import {
	Row,
	Col,
	Button,
	Input,
	Pagination
} from 'antd';
import PropTypes from 'prop-types';

import Breadcrumb from '../../../../../components/breadcrumb';
import IWearDropdown from '../../../../../components/dropdown';
import ExcelButton from '../../../../../components/excel-button';
import ProductCategoryModal from './components/ProductCategoryModal';

const renderAddModal = (props) => {
	return (
		<ProductCategoryModal
			title={'เพิ่มชนิดสินค้า'}
			visible={ props.addModalVisible }
			modalType={'ADD'}
			name={ props.modalData.name }
			onClickConfirm={ props.addModalOnConfirm }
			onClickCancel={() => {
				props.setModalData({ id: 0, name: '' });
				props.setAddModalVisible(false);
			}}
			onChangeName={(evt) => {
				props.setModalData({ ...props.modalData, name: evt.target.value });
			}}
		/>
	);
}

const renderEditModal = (props) => {
	return (
		<ProductCategoryModal
			title={'แก้ไขชนิดสินค้า'}
			visible={ props.editModalVisible }
			modalType={'EDIT'}
			name={ props.modalData.name }
			onClickConfirm={ props.editModalOnConfirm }
			onClickCancel={() => {
				props.setModalData({ id: 0, name: '' });
				props.setEditModalVisible(false);
			}}
			onChangeName={(evt) => {
				props.setModalData({ ...props.modalData, name: evt.target.value });
			}}
		/>
	);
}

const renderRemoveModal = (props) => {
	return (
		<ProductCategoryModal
			title={'ลบชนิดสินค้า'}
			visible={ props.removeModalVisible }
			modalType={'REMOVE'}
			name={ props.modalData.name }
			onClickConfirm={ props.removeModalOnConfirm }
			onClickCancel={() => {
				props.setModalData({ id: 0, name: '' });
				props.setRemoveModalVisible(false);
			}}
			onChangeName={() => {}}
		/>
	);
}

const renderOptions = (props) => {
	return (
		<div className={'custom-iwear-global-table-option-panel'}>
			<Row gutter={16} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={12}>
					<span className={'custom-iwear-global-font-color has-text-weight-bold is-size-5'}>
						ผลลัพธ์ข้อมูล&nbsp;
						<NumberFormat
							thousandSeparator
							displayType={'text'}
							value={ props.totalData }
						/>
						&nbsp;ข้อมูล
					</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						ค้นหา
					</span>&nbsp;&nbsp;&nbsp;
					<Input.Search
						enterButton
						placeholder={'Search'}
						allowClear={true}
						style={{ maxWidth: '70%' }}
						onSearch={(value) => {
							props.setPage(1);
							props.setSearchKey(value);
						}}
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row gutter={16} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={12}>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						แสดง
					</span>&nbsp;&nbsp;&nbsp;
					<IWearDropdown
						styleJSX={{ minWidth: '80px', paddingRight: '20px' }}
						displayValue={ String(props.pageSize) }
						choices={[ 10, 20, 50, 100 ]}
						onSelect={(selectedVal) => {
							props.setPage(1);
							props.setPageSize(Number.parseInt(selectedVal, 10));
						}}
					/>
				</Col>
				<Col span={12}>
					<Row gutter={8} type={'flex'} align={'middle'} justify={'end'}>
						<Col>
							<Button onClick={ props.onClickAdd }>
								<i className={'fas fa-plus custom-iwear-global-font-color'}></i>&nbsp;
								<span className={'custom-iwear-global-font-color'}>เพิ่ม</span>
							</Button>
						</Col>
						<Col>
							<ExcelButton
								modalTitle={'ชนิดสินค้า'}
								exportType={'data_product_category'}
								dispatcher={ props.dispatcher }
								isLoading={ props.isExporting }

								branchName={''}
							/>
						</Col>
					</Row>
				</Col>
			</Row>
		</div>
	);
}

const renderTable = (props) => {
	return (
		<div className={'custom-iwear-global-table-container'}>
			<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ลำดับ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ชนิดสินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							เพิ่มเติม
						</th>
					</tr>
				</thead>
				<tbody>
					{ renderTableData(props) }
				</tbody>
			</table>
		</div>
	);
}

const renderTableData = (props) => {
	if(props.isLoading) { return (<tr><td colSpan={8}></td></tr>); }
	else if(!props.isLoading && props.tableData.length === 0) { return (<tr><td colSpan={8}></td></tr>); }

	return props.tableData.map((_e, _i) => {
		return (
			<tr key={_i} className={'custom-iwear-global-hover-cursor-default'}>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _i + 1 + ((props.page - 1) * props.pageSize) }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.name }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					<Button onClick={() => { props.onClickEdit(_e); }}>
						<i className={'fas fa-edit'}></i>
					</Button>&nbsp;
					<Button onClick={() => { props.onClickRemove(_e); }}>
						<i className={'fas fa-trash'}></i>
					</Button>
				</td>
			</tr>
		);
	});
}

const renderContent = (props) => {
	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
			<Row>
				<Col span={24}>
					{ renderOptions(props) }
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					{ renderTable(props) }
				</Col>
			</Row>
			<Row type={'flex'} align={'middle'} justify={'end'}>
				<Col style={{ paddingBottom: '10px' }}>
					<Pagination
						className={'custom-iwear-global-table-pagination-container is-pulled-right'}
						current={ props.page || 1 }
						pageSize={ props.pageSize || 10 }
						total={ props.totalPage * props.pageSize }
						onChange={(page, pageSize) => {
							props.setPage(page);
						}}
					/>
				</Col>
			</Row>
		</div>
	)
}

const AdminDataProductCategory = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'การจัดการข้อมูล', 'ชนิดสินค้า' ]} />
			{ renderContent(props) }
			{ renderAddModal(props) }
			{ renderEditModal(props) }
			{ renderRemoveModal(props) }
		</div>
	);
}

AdminDataProductCategory.propTypes = {
	tableData: PropTypes.array.isRequired,
	totalData: PropTypes.number.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	totalPage: PropTypes.number.isRequired,
	searchKey: PropTypes.string.isRequired,
	isLoading: PropTypes.bool.isRequired,

	modalData: PropTypes.object.isRequired,
	addModalVisible: PropTypes.bool.isRequired,
	editModalVisible: PropTypes.bool.isRequired,
	removeModalVisible: PropTypes.bool.isRequired,

	setTableData: PropTypes.func.isRequired,
	setTotalData: PropTypes.func.isRequired,
	setPage: PropTypes.func.isRequired,
	setPageSize: PropTypes.func.isRequired,
	setTotalPage: PropTypes.func.isRequired,
	setSearchKey: PropTypes.func.isRequired,
	setIsLoading: PropTypes.func.isRequired,

	setModalData: PropTypes.func.isRequired,
	setAddModalVisible: PropTypes.func.isRequired,
	setEditModalVisible: PropTypes.func.isRequired,
	setRemoveModalVisible: PropTypes.func.isRequired,

	onClickAdd: PropTypes.func.isRequired,
	onClickEdit: PropTypes.func.isRequired,
	onClickRemove: PropTypes.func.isRequired,
}

export default AdminDataProductCategory;
