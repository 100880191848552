/**
 *	Stock Export List - Container
 */

import React from 'react'
import { Layout } from 'antd';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import WebCookie from '../../../../lib/web-cookie';
import { API_ENDPOINT } from '../../../../environments';
import GlobalStore from '../../../../store';
import LocalStore from './stock-export-list.store';
import Content from './stock-export-list.content';
import { ContainerPropTypes, Actions } from './stock-export-list.model';

const Container = (props) => {
	const StockExportReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData({ page: StockExportReducer.state.page, pageSize: StockExportReducer.state.pageSize, searchKey: StockExportReducer.state.searchKey, branchName: StockExportReducer.state.branchName }, StockExportReducer.dispatch, state.auth.token);
	}, [ StockExportReducer.state.page, StockExportReducer.state.pageSize, StockExportReducer.state.searchKey, StockExportReducer.state.branchName, StockExportReducer.dispatch, state.auth.token ]);

	const fetchData = async (params, localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		// let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		// branch_id=${branchID}

		let res = await fetch(`${API_ENDPOINT}/exchanges?export_code=${encodeURIComponent(params.searchKey)}&limit=${params.pageSize}&offset=${(params.page - 1) * params.pageSize}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json = await res.json();
		localDispatch({ type: Actions.SET_TABLE_DATA, value: [ ...json.list ] });
		localDispatch({ type: Actions.SET_TOTAL_DATA, value: json.count || json.list.length });
		localDispatch({ type: Actions.SET_TOTAL_PAGE, value: json.total_pages * params.pageSize });

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onChangePage = (page, pageSize) => {
		StockExportReducer.dispatch({ type: Actions.SET_PAGE, value: page });
	}

	const onChangePageSize = (selectedVal) => {
		StockExportReducer.dispatch({ type: Actions.SET_PAGE, value: 1 });
		StockExportReducer.dispatch({ type: Actions.SET_PAGE_SIZE, value: Number.parseInt(selectedVal, 10) });
	}

	const onChangeSearchKey = (value) => {
		StockExportReducer.dispatch({ type: Actions.SET_PAGE, value: 1 });
		StockExportReducer.dispatch({ type: Actions.SET_SEARCH_KEY, value: value });
	}

	const onClickCreate = (mode) => {
		// if(mode === 'manual') {
			props.history.push('/admin/stock/export/inventory');
		// }
		// else if(mode === 'file') {
		// 	props.history.push('/admin/stock/export/upload');
		// }
	}

	const onClickViewDetail = (exchangeCode) => {
		props.history.push('/admin/stock/export/detail', { exchangeCode });
	}

	const onClickFileExport = async () => {
		//
	}

	const beforePrintHandler = (exchangeCode) => {
		StockExportReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: true });
		beforePrintFetch(exchangeCode);

		return new Promise((resolve) => {
			setTimeout(() => {
				StockExportReducer.dispatch({ type: Actions.SET_IS_EXPORTING, value: false });
				resolve();
			}, 3000);
		})
	}

	const beforePrintFetch = async (exchangeCode) => {
		const res = await fetch(`${API_ENDPOINT}/exchanges?export_code=${encodeURIComponent(exchangeCode)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		const json = await res.json();
		StockExportReducer.dispatch({ type: Actions.SET_PRINT_DATA, value: (json && json.list && json.list.length > 0) ? json.list[0] : {} });
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/stock/export'} history={ props.history } />
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/stock/export'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...StockExportReducer.state }
						dispatcher={ StockExportReducer.dispatch }

						onChangePage={ onChangePage }
						onChangePageSize={ onChangePageSize }
						onChangeSearchKey={ onChangeSearchKey }

						onClickCreate={ onClickCreate }
						onClickViewDetail={ onClickViewDetail }
						onClickFileExport={ onClickFileExport }
						beforePrintHandler={ beforePrintHandler }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
