/**
 *	Finance - Container
 */

import React from 'react';
import { Layout } from 'antd';
import moment from 'moment';

import TopBar from '../../../components/layout/header';
import Sidebar from '../../../components/layout/sider';
import BottomBar from '../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../environments';
import GlobalStore from '../../../store';
import LocalStore from './finance.store';
import Content from './finance.content';
import { ContainerPropTypes, Actions } from './finance.model';

const startMonthDateString = moment().startOf('month').format('YYYY-MM-DD');
const currentDateString = moment().format('YYYY-MM-DD');

const Container = (props) => {
	const FinanceStore = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchListData({
			searchKey: FinanceStore.state.listSearchFilter,
			selectedBranch: FinanceStore.state.listSelectedBranch,
			tableDateStartFilter: FinanceStore.state.listDateStartFilter,
			tableDateEndFilter: FinanceStore.state.listDateEndFilter,
			page: FinanceStore.state.page,
			pageSize: FinanceStore.state.pageSize,
			localDispatch: FinanceStore.dispatch,
			authToken: state.auth.token
		});
	}, [
		FinanceStore.state.listSearchFilter,
		FinanceStore.state.listSelectedBranch,
		FinanceStore.state.listDateStartFilter,
		FinanceStore.state.listDateEndFilter,
		FinanceStore.state.page,
		FinanceStore.state.pageSize,
		FinanceStore.dispatch,
		state.auth.token
	])

	React.useEffect(() => {
		fetchGraphData({
			selectedBranch: FinanceStore.state.ratioSelectedBranch,
			graphDateStartFilter: FinanceStore.state.ratioDateStartFilter,
			graphDateEndFilter: FinanceStore.state.ratioDateEndFilter,
			localDispatch: FinanceStore.dispatch,
			authToken: state.auth.token
		});
	}, [
		FinanceStore.state.ratioSelectedBranch,
		FinanceStore.state.ratioDateStartFilter,
		FinanceStore.state.ratioDateEndFilter,
		FinanceStore.dispatch,
		state.auth.token
	]);

	const fetchGraphData = async ({ selectedBranch, graphDateStartFilter, graphDateEndFilter, localDispatch, authToken }) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });
		let _paramBranch = (selectedBranch !== 'ทั้งหมด') ? `branch_name=${encodeURIComponent(selectedBranch)}` : `branch_name=${encodeURIComponent('')}`;
		let _paramStartDate = `start_date=${(graphDateStartFilter) ? encodeURIComponent(graphDateStartFilter.format('YYYY-MM-DD')) : encodeURIComponent(startMonthDateString)}`;
		let _paramEndDate = `end_date=${(graphDateEndFilter) ? encodeURIComponent(graphDateEndFilter.format('YYYY-MM-DD')) : encodeURIComponent(currentDateString)}`;
		let res = await fetch(`${API_ENDPOINT}/stats/super-user?${_paramBranch}&${_paramStartDate}&${_paramEndDate}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json = await res.json();
		if(!json.statistic) {
			localDispatch({ type: Actions.SET_IS_LOADING, value: false });
			return;
		}

		localDispatch({ type: Actions.SET_PAYMENT_RATIO, value: json.payment_percentage });
		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const fetchListData = async ({ localDispatch, searchKey, selectedBranch, tableDateStartFilter, tableDateEndFilter, page, pageSize, authToken }) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });
		let res_branch_list = await fetch(`${API_ENDPOINT}/branches?limit=9999`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json_branch_list = await res_branch_list.json();
		localDispatch({ type: Actions.SET_BRANCH_LIST, value: [ ...json_branch_list.list ] });

		if(selectedBranch && selectedBranch !== 'ทั้งหมด') {
			let res_branch = await fetch(`${API_ENDPOINT}/branches?branch_name=${encodeURIComponent(selectedBranch)}`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + authToken,
		    		'Content-Type': 'application/json'
				}
			});

			let json_branch = await res_branch.json();
			let _branchID = json_branch.list[0].branch_id;

			let res = await fetch(
				`${API_ENDPOINT}/orders
				?branch_id=${encodeURIComponent(_branchID)}
				&search=${encodeURIComponent(searchKey)}
				&filter_start_date=${(tableDateStartFilter) ? encodeURIComponent(tableDateStartFilter.format('YYYY-MM-DD')) : ''}
				&filter_end_date=${(tableDateEndFilter) ? encodeURIComponent(tableDateEndFilter.format('YYYY-MM-DD')) : ''}
				&offset=${(page - 1) * pageSize}
				&limit=${pageSize}`,
				{
					method: 'GET',
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'Content-Type': 'application/json'
					}
				}
			);

			let json = await res.json();
			localDispatch({ type: Actions.SET_TABLE_DATA, value: [ ...json.list ] });
			localDispatch({ type: Actions.SET_TOTAL_DATA, value: json.count || json.list.length });
			localDispatch({ type: Actions.SET_TOTAL_PAGE, value: json.total_pages * pageSize });
		}
		else {
			let res = await fetch(
				`${API_ENDPOINT}/orders
				?search=${encodeURIComponent(searchKey)}
				&filter_start_date=${(tableDateStartFilter) ? encodeURIComponent(tableDateStartFilter.format('YYYY-MM-DD')) : ''}
				&filter_end_date=${(tableDateEndFilter) ? encodeURIComponent(tableDateEndFilter.format('YYYY-MM-DD')) : ''}
				&offset=${(page - 1) * pageSize}
				&limit=${pageSize}`,
				{
					method: 'GET',
					headers: {
						'Authorization': 'Bearer ' + authToken,
						'Content-Type': 'application/json'
					}
				}
			);

			let json = await res.json();
			localDispatch({ type: Actions.SET_TABLE_DATA, value: [ ...json.list ] });
			localDispatch({ type: Actions.SET_TOTAL_DATA, value: json.count || json.list.length });
			localDispatch({ type: Actions.SET_TOTAL_PAGE, value: json.total_pages * pageSize });
		}

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onChangeViewMode = (evt) => {
		if(evt.target.value === 'list') {
			FinanceStore.dispatch({ type: Actions.SET_VIEW_MODE, value: 'list' });
			FinanceStore.dispatch({ type: Actions.RESET, value: null });
		}
		else if(evt.target.value === 'graph') {
			FinanceStore.dispatch({ type: Actions.SET_VIEW_MODE, value: 'graph' });
			FinanceStore.dispatch({ type: Actions.RESET, value: null });
		}
	}
	const onChangeRatioBranch = (selectedValue) => {
		FinanceStore.dispatch({ type: Actions.SET_RATIO_SELECTED_BRANCH, value: `${selectedValue}` });
	}

	const onChangeRatioDateRange = (date, dateString) => {
		if(date && date.length > 1) {
			FinanceStore.dispatch({ type: Actions.SET_RATIO_DATE_START_FILTER, value: date[0] });
			FinanceStore.dispatch({ type: Actions.SET_RATIO_DATE_END_FILTER, value: date[1] });
		}
	}

	const onChangeListDateRange = (date, dateString) => {
		if(date && date.length > 1) {
			FinanceStore.dispatch({ type: Actions.SET_LIST_DATE_START_FILTER, value: date[0] });
			FinanceStore.dispatch({ type: Actions.SET_LIST_DATE_END_FILTER, value: date[1] });
		}
	}

	const onSubmitListSearchFilter = (value) => {
		FinanceStore.dispatch({ type: Actions.SET_PAGE, value: 1 });
		FinanceStore.dispatch({ type: Actions.SET_LIST_SEARCH_FILTER, value: value });
	}

	const onChangePage = (page, pageSize) => {
		FinanceStore.dispatch({ type: Actions.SET_PAGE, value: page });
	}

	const onChangePageSize = (selectedVal) => {
		FinanceStore.dispatch({ type: Actions.SET_PAGE, value: 1 });
		FinanceStore.dispatch({ type: Actions.SET_PAGE_SIZE, value: Number.parseInt(selectedVal, 10) });
	}

	const onChangeListBranch = (selectedValue) => {
		FinanceStore.dispatch({ type: Actions.SET_PAGE, value: 1 });
		FinanceStore.dispatch({ type: Actions.SET_LIST_SELECTED_BRANCH, value: `${selectedValue}` });
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/finance'} history={props.history}/>
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/finance'} history={props.history}/>
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...FinanceStore.state }

						onChangeViewMode={ onChangeViewMode }
						onChangeRatioBranch={ onChangeRatioBranch }
						onChangeRatioDateRange={ onChangeRatioDateRange }

						onChangeListDateRange={ onChangeListDateRange }
						onSubmitListSearchFilter={ onSubmitListSearchFilter }
						onChangePage={ onChangePage }
						onChangePageSize={ onChangePageSize }
						onChangeListBranch={ onChangeListBranch }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar/>
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;