/**
 *	iWear - Stock Export Inventory Container
 *
 *		@using React Hook
 */

import React from 'react';
import PropTypes from 'prop-types';
import swal from 'sweetalert';
import { Layout } from 'antd';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../environments';
import WebCookie from '../../../../lib/web-cookie';
import { useDebounce } from '../../../../functions';
import StockExportInventory from './screen';

const StockExportInventoryContainer = (props) => {
	const [ productID, setProductID ] = React.useState('');
	const [ productName, setProductName ] = React.useState('');
	const [ productCode, setProductCode ] = React.useState('');
	const [ productAmount, setProductAmount ] = React.useState(0);
	const [ productPrice, setProductPrice ] = React.useState(0);
	const [ suggestionDataProductCode, setSuggestionDataProductCode ] = React.useState([]);
	const [ suggestionDataProductName, setSuggestionDataProductName ] = React.useState([]);
	const productCodeDebouncer = useDebounce(productCode, 1000);
	const productNameDebouncer = useDebounce(productName, 1000);

	const [ exportInfo, setExportInfo ] = React.useState({ exchange_detail: [], exchange_code: '', source_branch: { branch_name: '' }, dest_branch: { branch_name: '' }, create_at: '' });
	const exportRef = React.useRef(null);
	const printRef = React.useRef(null);

	const [ branch, setBranch ] = React.useState('');
	const [ branchList, setBranchList ] = React.useState([]);

	const [ tableData, setTableData ] = React.useState([]);
	const [ page, setPage ] = React.useState(1);
	const [ pageSize, setPageSize ] = React.useState(10);
	const [ editIdx, setEditIdx ] = React.useState(-1);
	const [ isEditing, setIsEditing ] = React.useState(false);
	const [ isLoading, setIsLoading ] = React.useState(false);

	React.useEffect(() => {
		fetchData({ page, pageSize });
	}, [ page, pageSize ]);

	React.useEffect(() => {
		if(productCodeDebouncer === productCode) {
			debounceProductCode(productCode)
		}
	}, [ productCode, productCodeDebouncer ]);

	React.useEffect(() => {
		if(productNameDebouncer === productName) {
			debounceProductName(productName)
		}
	}, [ productName, productNameDebouncer ])

	const debounceProductCode = async (qrCode) => {
		// let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		if(qrCode.length >= 2) {
			let res = await fetch(`${API_ENDPOINT}/inventory-items?only_available=true&search=${encodeURIComponent(qrCode)}&offset=0&limit=20`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
					'Content-Type': 'application/json'
				}
			});
			let json = await res.json();

			let _arr = [];
			json.list.forEach((_e, _i) => {
				_arr.push({ text: _e.product_info.item_code, value: _e.product_info.item_id });
			});
			setSuggestionDataProductCode([ ..._arr ]);
		}
		else {
			setSuggestionDataProductCode([]);
		}
	}

	const debounceProductName = async (itemName) => {
		// let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		if(itemName.length >= 2) {
			let res = await fetch(`${API_ENDPOINT}/inventory-items?only_available=true&search=${encodeURIComponent(itemName)}&offset=0&limit=20`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
					'Content-Type': 'application/json'
				}
			});
			let json = await res.json();

			let _arr = [];
			json.list.forEach((_e, _i) => {
				_arr.push({ text: _e.product_info.name, value: _e.product_info.item_id });
			});
			setSuggestionDataProductName([ ..._arr ]);
		}
		else {
			setSuggestionDataProductName([]);
		}
	}

	const fetchData = async (params) => {
		setIsLoading(true);

		let thisBranchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		let res_branch = await fetch(`${API_ENDPOINT}/branches?limit=9999`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		let json_branch = await res_branch.json();

		if(json_branch.list.length > 0) {
			let _arr = json_branch.list.filter((_f) => { return _f.branch_id !== thisBranchID; });
			// console.log('_arr', _arr);
			setBranchList([ ..._arr ]);
		}

		setIsLoading(false);
	}

	const onScan = async (qrCode) => {
		// let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		// let splitStr = qrCode.match(/.{1,3}/g);
		// let convertedQrCode = '';
		// splitStr.map((_e) => { return convertedQrCode += String.fromCharCode(_e); });

		let res = await fetch(`${API_ENDPOINT}/inventory-items?search=${encodeURIComponent(qrCode)}&offset=0&limit=20`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});
		let json = await res.json();

		if(json.list.length === 0) {
			swal({
				title: 'ไม่พบสินค้าในระบบ',
				icon: 'error'
			}).then((val) => {
				setProductCode('');
				setProductName('');
				setProductAmount(1);
				setProductPrice(0);
			});
		}
		else {
			let _arr = [ ...tableData ];
			_arr.push({
				order: tableData.length + 1,
				id: json.list[0].product_info.item_id,
				code: json.list[0].product_info.item_code,
				name: json.list[0].product_info.name,
				amount: productAmount || 1,
				price: Number.parseFloat(json.list[0].product_info.price).toFixed(2)
			});

			setProductCode('');
			setProductName('');
			setProductAmount(1);
			setProductPrice(0);
			setTableData([ ..._arr ]);
		}
	}

	const onSelectProductCode = async (qrCode) => {
		// let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		let res = await fetch(`${API_ENDPOINT}/inventory-items?inventory=${encodeURIComponent(qrCode)}&offset=0&limit=20`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});
		let json = await res.json();

		let _arr = [ ...tableData ];
		// let _sum = Number.parseFloat(priceSum + (json.list[0].price * productAmount));
		_arr.push({
			order: tableData.length + 1,
			id: json.list[0].product_info.item_id,
			code: json.list[0].product_info.item_code,
			name: json.list[0].product_info.name,
			amount: productAmount || 1,
			price: Number.parseFloat(json.list[0].product_info.price).toFixed(2)
		});

		setProductCode('');
		setProductName('');
		setProductAmount(1);
		setProductPrice(0);
		setTableData([ ..._arr ]);
		// setPriceSum(_sum);
	}

	const onSelectProductName = async (itemName) => {
		// let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		let res = await fetch(`${API_ENDPOINT}/inventory-items?inventory=${encodeURIComponent(itemName)}&offset=0&limit=20`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});
		let json = await res.json();

		let _arr = [ ...tableData ];
		// let _sum = Number.parseFloat(priceSum + (json.list[0].price * productAmount));
		_arr.push({
			order: tableData.length + 1,
			id: json.list[0].product_info.item_id,
			code: json.list[0].product_info.item_code,
			name: json.list[0].product_info.name,
			amount: productAmount || 1,
			price: Number.parseFloat(json.list[0].product_info.price).toFixed(2)
		});

		setProductCode('');
		setProductName('');
		setProductAmount(1);
		setProductPrice(0);
		setTableData([ ..._arr ]);
		// setPriceSum(_sum);
	}

	const onAddData = () => {
		if(productName === 'ค้นหาสินค้า' || productCode === '' || productAmount === 0) {
			swal('ข้อมูลไม่ครบ', 'กรุณาใส่ข้อมูลทุกช่องก่อนเพิ่ม', 'error');
			return;
		}

		setTableData([
			...tableData,
			{
				order: tableData.length + 1,
				id: productCode,
				name: productName,
				amount: productAmount || 1
			}
		]);

		setProductID('');
		setProductName('ค้นหาสินค้า');
		setProductCode('');
		setProductAmount(0);
	}

	const onEditData = (idx) => {
		if(isEditing) {
			setEditIdx(-1);
			setIsEditing(false);

			//

			setEditIdx(idx);
			setIsEditing(true);
		}
		else {
			setEditIdx(idx);
			setIsEditing(true);
		}
	}

	const onDeleteData = (idx) => {
		let _arr = [ ...tableData ];
		_arr.splice(idx, 1);
		setTableData([ ..._arr ]);
	}

	const onClickFinishEditing = () => {
		setEditIdx(-1);
		setIsEditing(false);
	}

	const onClickConfirm = async () => {
		let _arr = [];
		// tableData.forEach((_e) => {
		// 	_arr.push({
		// 		inv_item_id: `${_e.id}`,
		// 		quantity: _e.amount
		// 	});
		// });

		tableData.forEach((_e) => {
			_arr.push({
				item_id: _e.id,
				quantity: _e.amount
			});
		});

		let _destID = [...branchList].filter((_f) => { return _f.branch_name === branch });

		// console.log('ExportItems', _arr);
		// console.log('Destination', _destID);

		if(_arr.length > 0 && _destID.length > 0) {
			let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

			let res = await fetch(`${API_ENDPOINT}/exchanges`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					source_branch_id: branchID,
					dest_branch_id: _destID[0].branch_id,
					exchange_detail: [ ..._arr ]
				})
			});

			let json = await res.json();
			if(res.status !== 200) {
				swal({
					title: 'เกิดข้อผิดพลาด',
					text: json.msg,
					icon: 'error'
				});
			}
			else {
				setExportInfo(json);
				if(printRef !== null || printRef !== undefined) {
					printRef.current.handleClick(); // print
				}

				swal({
					title: 'ส่งออกสินค้าสำเร็จ',
					icon: 'success'
				}).then((value) => {
					props.history.goBack();
				});
			}
		}
		else {
			swal({
				title: 'ส่งออกสินค้าเข้าล้มเหลว',
				text: 'ไม่มีข้อมูลสินค้า หรือ ไม่ได้เลือกสาขาที่จะส่งไป',
				icon: 'error'
			});
		}
	}

	const onClickGoBack = () => {
		props.history.goBack();
	}

	return (
		<Layout>
			<Sidebar mode={'rxmall'} currentRoute={'/lab/stock/export'} history={ props.history } />
			<Layout>
				<TopBar mode={'rxmall'} currentRoute={'/lab/stock/export'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<StockExportInventory
						productID={ productID }
						productName={ productName }
						productCode={ productCode }
						productAmount={ productAmount }
						productPrice={ productPrice }
						suggestionDataProductCode={ suggestionDataProductCode }
						suggestionDataProductName={ suggestionDataProductName }

						exportInfo={ exportInfo }
						exportRef={ exportRef }
						printRef={ printRef }

						branch={ branch }
						branchList={ branchList }
						tableData={ tableData }
						page={ page }
						pageSize={ pageSize }
						editIdx={ editIdx }
						isEditing={ isEditing }
						isLoading={ isLoading }

						setProductID={ setProductID }
						setProductName={ setProductName }
						setProductCode={ setProductCode }
						setProductAmount={ setProductAmount }
						setProductPrice={ setProductPrice }
						setSuggestionDataProductCode={ setSuggestionDataProductCode }
						setSuggestionDataProductName={ setSuggestionDataProductName }

						setExportInfo={ setExportInfo }

						setBranch={ setBranch }
						setBranchList={ setBranchList }
						setTableData={ setTableData }
						setPage={ setPage }
						setPageSize={ setPageSize }
						setEditIdx={ setEditIdx }
						setIsEditing={ setIsEditing }
						setIsLoading={ setIsLoading }

						onScan={ onScan }
						onSelectProductCode={ onSelectProductCode }
						onSelectProductName={ onSelectProductName }
						onAddData={ onAddData }
						onEditData={ onEditData }
						onDeleteData={ onDeleteData }
						onClickFinishEditing={ onClickFinishEditing }
						onClickConfirm={ onClickConfirm }
						onClickGoBack={ onClickGoBack }
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

StockExportInventoryContainer.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default StockExportInventoryContainer;
