/**
 *	Thai Address - Province + District
 */

import Address from './data.json';

const formatProvinceList = () => {
	let _arr = [];

	Address.forEach((_e) => {
		_arr.push(_e.name.th);
	});

	return _arr;
}

const formatDistrictList = (province_index) => {
	let _arr = [];

	Address[province_index].district.forEach((_e) => {
		_arr.push(_e.name.th);
	});

	return _arr;
}

const ThaiAddress = {
	data: Address,
	provinceList: formatProvinceList,
	districtList: formatDistrictList
}

export default ThaiAddress;
