/**
 *	iWear - Dashboard screen -> Info Card component
 */

import React from 'react';
import { Skeleton } from 'antd';
import NumberFormat from 'react-number-format';
import PropTypes from 'prop-types';

import styles from '../dashboard.module.css';

const InfoCard = (props) => {
	return (
		<div className={ styles.card }>
			<div className={ styles.cardInfo }>
				{
					(props.isLoading)
					? (
						<Skeleton active title={{ width: '60%' }} paragraph={{ rows: 0 }} />
					)
					: (props.cardRender)
						? (props.cardRender)
						: (
							<span className={'is-size-4 has-text-weight-bold'}>
								<NumberFormat
									thousandSeparator
									displayType={'text'}
									value={ props.cardValue }
								/>
								{ props.cardUnit }
							</span>
						)
				}
				<br/>
				<span className={'is-size-6'}>
					{ props.cardLabel }
				</span>
			</div>
		</div>
	);
}

InfoCard.defaultProps = {
	cardRender: null,
	cardUnit: ''
}

InfoCard.propTypes = {
	cardValue: PropTypes.number.isRequired,
	cardLabel: PropTypes.string.isRequired,
	cardRender: PropTypes.element,
	cardUnit: PropTypes.string,
	isLoading: PropTypes.bool.isRequired
}

export default InfoCard;
