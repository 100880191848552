/**
 *	Stock Import Manual - Container
 */

import React from 'react';
import swal from 'sweetalert';
import { Layout } from 'antd';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../environments';
import { useDebounce } from '../../../../functions';
import GlobalStore from '../../../../store';

import LocalStore from './stock-import-manual.store';
import StockImportInventoryManual from './stock-import-manual.content';
import { ContainerPropTypes, Actions } from './stock-import-manual.model';

const Container = (props) => {
	const StockImportReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	const productCodeDebouncer = useDebounce(StockImportReducer.state.productCode, 1000);
	const productNameDebouncer = useDebounce(StockImportReducer.state.productName, 1000);

	// React.useEffect(() => {
	// 	//
	// }, [ branch, page, pageSize ]);

	React.useEffect(() => {
		if(productCodeDebouncer === StockImportReducer.state.productCode) {
			debounceProductCode(StockImportReducer.state.productCode, StockImportReducer.dispatch, state.auth.token)
		}
	}, [ StockImportReducer.state.productCode, StockImportReducer.dispatch, state.auth.token, productCodeDebouncer ]);

	React.useEffect(() => {
		if(productNameDebouncer === StockImportReducer.state.productName) {
			debounceProductName(StockImportReducer.state.productName, StockImportReducer.dispatch, state.auth.token)
		}
	}, [ StockImportReducer.state.productName, StockImportReducer.dispatch, state.auth.token, productNameDebouncer ]);

	const debounceProductCode = async (qrCode, localDispatch, authToken) => {
		// let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		if(qrCode.length >= 2) {
			let res = await fetch(`${API_ENDPOINT}/inventory-items?only_available=true&search=${encodeURIComponent(qrCode)}&offset=0&limit=20`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + authToken,
					'Content-Type': 'application/json'
				}
			});
			let json = await res.json();

			let _arr = [];
			json.list.forEach((_e, _i) => {
				_arr.push({ text: _e.product_info.item_code, value: _e.product_info.item_id });
			});

			localDispatch({ type: Actions.SET_PRODUCT_CODE_SUGGESTION, value: [ ..._arr ] });
		}
		else {
			localDispatch({ type: Actions.SET_PRODUCT_CODE_SUGGESTION, value: [] });
		}
	}

	const debounceProductName = async (itemName, localDispatch, authToken) => {
		// let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		if(itemName.length >= 2) {
			let res = await fetch(`${API_ENDPOINT}/inventory-items?only_available=true&search=${encodeURIComponent(itemName)}&offset=0&limit=20`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + authToken,
					'Content-Type': 'application/json'
				}
			});
			let json = await res.json();

			let _arr = [];
			json.list.forEach((_e, _i) => {
				_arr.push({ text: _e.product_info.name, value: _e.product_info.item_id });
			});

			localDispatch({ type: Actions.SET_PRODUCT_NAME_SUGGESTION, value: [ ..._arr ] });
		}
		else {

			localDispatch({ type: Actions.SET_PRODUCT_NAME_SUGGESTION, value: [] });
		}
	}

	const onChangeTableData = (value) => {
		StockImportReducer.dispatch({ type: Actions.SET_TABLE_DATA, value: value });
	}

	const onChangeQR = (value) => {
		StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: value });
	}

	const onChangeProduct = (value) => {
		StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: value });
	}

	const onChangeQuantity = (value) => {
		StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: value });
	}

	const onScan = async (qrCode) => {
		// let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		// let splitStr = qrCode.match(/.{1,3}/g);
		// let convertedQrCode = '';
		// splitStr.map((_e) => { return convertedQrCode += String.fromCharCode(_e); });

		let res = await fetch(`${API_ENDPOINT}/inventory-items?search=${encodeURIComponent(qrCode)}&offset=0&limit=20`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});
		let json = await res.json();

		if(json.list.length === 0) {
			swal({
				title: 'ไม่พบสินค้าในระบบ',
				icon: 'error'
			}).then((val) => {
				StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: '' });
				StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: '' });
				StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: 1 });
			});
		}
		else {
			let _arr = [ ...StockImportReducer.state.tableData ];
			_arr.push({
				order: StockImportReducer.state.tableData.length + 1,
				id: json.list[0].product_info.item_id,
				code: json.list[0].product_info.item_code,
				name: json.list[0].product_info.name,
				amount: StockImportReducer.state.productAmount || 1
			});

			StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: '' });
			StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: '' });
			StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: 1 });
			StockImportReducer.dispatch({ type: Actions.SET_TABLE_DATA, value: [ ..._arr ] });
		}
	}

	const onSelectProductCode = async (qrCode) => {
		// let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		let res = await fetch(`${API_ENDPOINT}/inventory-items?inventory=${encodeURIComponent(qrCode)}&offset=0&limit=20`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});
		let json = await res.json();

		let _arr = [ ...StockImportReducer.state.tableData ];
		_arr.push({
			order: StockImportReducer.state.tableData.length + 1,
			id: json.list[0].product_info.item_id,
			code: json.list[0].product_info.item_code,
			name: json.list[0].product_info.name,
			amount: StockImportReducer.state.productAmount || 1,
			price: json.list[0].product_info.price
		});

		StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: '' });
		StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: '' });
		StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: 1 });
		StockImportReducer.dispatch({ type: Actions.SET_TABLE_DATA, value: [ ..._arr ] });
	}

	const onSelectProductName = async (itemName) => {
		// let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		let res = await fetch(`${API_ENDPOINT}/inventory-items?inventory=${encodeURIComponent(itemName)}&offset=0&limit=20`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});
		let json = await res.json();

		let _arr = [ ...StockImportReducer.state.tableData ];
		_arr.push({
			order: StockImportReducer.state.tableData.length + 1,
			id: json.list[0].product_info.item_id,
			code: json.list[0].product_info.item_code,
			name: json.list[0].product_info.name,
			amount: StockImportReducer.state.productAmount || 1,
			price: json.list[0].product_info.price
		});

		StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: '' });
		StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: '' });
		StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: 1 });
		StockImportReducer.dispatch({ type: Actions.SET_TABLE_DATA, value: [ ..._arr ] });
	}

	const onAddData = () => {
		if(StockImportReducer.state.productID === '' || StockImportReducer.state.productName === 'ค้นหาสินค้า' || StockImportReducer.state.productCode === '' || StockImportReducer.state.productAmount === '') {
			swal('ข้อมูลไม่ครบ', 'กรุณาใส่ข้อมูลทุกช่องก่อนเพิ่ม', 'error');
			return;
		}

		StockImportReducer.dispatch({
			type: Actions.SET_TABLE_DATA,
			value: [
				...StockImportReducer.state.tableData,
				{
					order: StockImportReducer.state.tableData.length + 1,
					id: StockImportReducer.state.productID,
					name: StockImportReducer.state.productName,
					code: StockImportReducer.state.productCode,
					amount: Number.parseInt(StockImportReducer.state.productAmount, 10)
				}
			]
		});

		StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_ID, value: '' });
		StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: '' });
		StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: 'ค้นหาสินค้า' });
		StockImportReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: 0 });
	}

	const onEditData = (idx) => {
		if(StockImportReducer.state.isEditing) {
			StockImportReducer.dispatch({ type: Actions.SET_EDIT_IDX, value: -1 });
			StockImportReducer.dispatch({ type: Actions.SET_IS_EDITING, value: false });

			//

			StockImportReducer.dispatch({ type: Actions.SET_EDIT_IDX, value: idx });
			StockImportReducer.dispatch({ type: Actions.SET_IS_EDITING, value: true });
		}
		else {
			StockImportReducer.dispatch({ type: Actions.SET_EDIT_IDX, value: idx });
			StockImportReducer.dispatch({ type: Actions.SET_IS_EDITING, value: true });
		}
	}

	const onDeleteData = (idx) => {
		let _arr = [ ...StockImportReducer.state.tableData ];
		_arr.splice(idx, 1);
		StockImportReducer.dispatch({ type: Actions.SET_TABLE_DATA, value: [ ..._arr ] });
	}

	const onClickFinishEditing = () => {
		StockImportReducer.dispatch({ type: Actions.SET_EDIT_IDX, value: -1 });
		StockImportReducer.dispatch({ type: Actions.SET_IS_EDITING, value: false });
	}

	const onClickConfirm = async () => {
		if(StockImportReducer.state.tableData.length === 0) {
			swal({
				title: 'นำสินค้าเข้าล้มเหลว',
				text: 'ไม่มีข้อมูลสินค้า',
				icon: 'error'
			});
		}
		else {
			// let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
			// let _arr = [];
			// tableData.forEach((_e, _i) => {
				// _arr.push({
				// 	inventory_id: branchID, // this branch_id ?
				// 	src_inv_id: null, // ?
				// 	ex_item_id: _e.ex_item_id, // ?
				// 	product_item_id: _e.id,
				// 	quantity: _e.amount,
				// 	price: _e.price,
				// 	status: 'normal'
				// });
			// });
			// console.log('Branch', branchID);
			// console.log('ImportData', _arr);

			// let res = await fetch(`${API_ENDPOINT}/inventory-items`, {
			// 	method: 'POST',
			// 	headers: {
			// 		'Authorization': 'Bearer ' + state.auth.token,
			// 		'Content-Type': 'application/json'
			// 	},
			// 	body: JSON.stringify({
			// 		// code: barcode, // NOT EXISTED -> HOW TO ADD
			// 		// inventory_items: [ ..._arr ]
			// 	})
			// });
			//
			// let json = await res.json();
			// console.log('Import', json);
			// if(res.status !== 200) {
			// 	swal({
			// 		title: 'เกิดข้อผิดพลาด',
			// 		text: json.msg,
			// 		icon: 'error'
			// 	});
			// } else {
			// 	swal({
			// 		title: 'นำสินค้าเข้าสำเร็จ',
			// 		icon: 'success'
			// 	}).then((val) => {
			// 		props.history.goBack();
			// 	});
			// }
		}
	}

	const onClickGoBack = () => {
		props.history.goBack();
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/stock/import'} history={ props.history } />
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/stock/import'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<StockImportInventoryManual
						{  ...StockImportReducer.state }

						onChangeTableData={ onChangeTableData }
						onChangeQR={ onChangeQR }
						onChangeProduct={ onChangeProduct }
						onChangeQuantity={ onChangeQuantity }

						onScan={ onScan }
						onSelectProductCode={ onSelectProductCode }
						onSelectProductName={ onSelectProductName }
						onAddData={ onAddData }
						onEditData={ onEditData }
						onDeleteData={ onDeleteData }
						onClickFinishEditing={ onClickFinishEditing }
						onClickConfirm={ onClickConfirm }
						onClickGoBack={ onClickGoBack }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
