/**
 *	iWear - Dashboard state management
 */

import React from 'react';
import moment from 'moment';
import { Actions } from './dashboard.model';

const startMonthDate = moment().startOf('month');
const currentDate = moment();
const startMonthDateString = startMonthDate.format('YYYY-MM-DD');
const currentDateString = currentDate.format('YYYY-MM-DD');

const initState = {
	branch: '',
	branchList: [],
	filterDate: { value: [startMonthDate, currentDate], text: [startMonthDateString, currentDateString] },
	totalSale: 0,
	monthSale: 0,
	todaySale: 0,
	todayOrder: 0,
	frameSale: { count: 0, value: 0 },
	lensSale: { count: 0, value: 0 },
	avgAge: 0,
	avgExpense: 0,
	customerRatio: { newCustomer: 0, oldCustomer: 0 },
	chartData: { branch: null, payment: null, promo: null },
	isLoading: false
}

const reducer = (state, action) => {
	switch(action.type) {
		case Actions.SET_BRANCH:
			return {
				...state,
				branch: action.value
			}
		case Actions.SET_BRANCH_LIST:
			return {
				...state,
				branchList: action.value
			}
		case Actions.SET_FILTER_DATE:
			return {
				...state,
				filterDate: action.value
			}
		case Actions.SET_TOTAL_SALE:
			return {
				...state,
				totalSale: action.value
			}
		case Actions.SET_MONTH_SALE:
			return {
				...state,
				monthSale: action.value
			}
		case Actions.SET_TODAY_SALE:
			return {
				...state,
				todaySale: action.value
			}
		case Actions.SET_TODAY_ORDER:
			return {
				...state,
				todayOrder: action.value
			}
		case Actions.SET_FRAME_SALE:
			return {
				...state,
				frameSale: action.value
			}
		case Actions.SET_LENS_SALE:
			return {
				...state,
				lensSale: action.value
			}
		case Actions.SET_AVG_AGE:
			return {
				...state,
				avgAge: action.value
			}
		case Actions.SET_AVG_EXPENSE:
			return {
				...state,
				avgExpense: action.value
			}
		case Actions.SET_CUSTOMER_RATIO:
			return {
				...state,
				customerRatio: action.value
			}
		case Actions.SET_CHART_DATA:
			return {
				...state,
				chartData: action.value
			}
		case Actions.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.value
			}
		default:
			return { ...state }
	}
}

const Store = () => {
	const [ state, dispatch ] = React.useReducer(reducer, initState);

	React.useEffect(() => {}, []);

	return {
		state,
		dispatch
	}
}

export default Store;
