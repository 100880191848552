/**
 *	Order List component - Remaining Payment Modal
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
	DatePicker,
	InputNumber,
	Modal,
	Button,
	Row,
	Col,
	Input
} from 'antd';

import IWearDropdown from '../../../../components/dropdown';

const RemainingPaymentModal = (props) => {
	const [ paymentDate, setPaymentDate ] = React.useState('');
	const [ paymentType, setPaymentType ] = React.useState('');
	const [ paymentAmount, setPaymentAmount ] = React.useState(0);
	const [ orderNote, setOrderNote ] = React.useState('');

	React.useEffect(() => {
		setPaymentAmount(props.fullPrice - props.payment);
	}, [ props ]);

	const onChangeOrderNote = (evt) => {
		setOrderNote(evt.target.value);
	}

	return (
		<Modal
			title={'ชำระเงินที่เหลือ'}
			visible={ props.isVisible }
			footer={[
				<Button key={'close'} onClick={ props.onClickClose }>
					<span>
						ปิด
					</span>
				</Button>,
				<Button
					key={'confirm'}
					className={`${(props.fullPrice - props.payment > 0) ? '' : 'is-hidden'}`}
					type={'primary'}
					onClick={ () => { props.onClickPaid(paymentDate, paymentType, paymentAmount, orderNote); } }
				>
					<span>
						ชำระ
					</span>
				</Button>
			]}
		>
			<Row gutter={8}>
				<Col>
					<span>
						เลขที่สั่งซื้อ:&nbsp;<u><strong>{ props.orderCode }</strong></u>
					</span>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row gutter={8}>
				<Col span={9}>
					<span>ราคาสั่งซื้อ</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<span>{ props.fullPrice }</span>
				</Col>
				<Col span={3} style={{ textAlign: 'right' }}>
					<span>บาท</span>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row gutter={8}>
				<Col span={9}>
					<span>ยอดเงินชำระ</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<span>{ props.payment }</span>
				</Col>
				<Col span={3} style={{ textAlign: 'right' }}>
					<span>บาท</span>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row gutter={8}>
				<Col span={9}>
					<span>ยอดค้างชำระ</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<span>{ props.fullPrice - props.payment }</span>
				</Col>
				<Col span={3} style={{ textAlign: 'right' }}>
					<span>บาท</span>
				</Col>
			</Row>
			{
				(props.fullPrice - props.payment > 0) && (
					<React.Fragment>
						<div style={{ margin: '10px' }}></div>
						<Row gutter={8} type={'flex'} align={'middle'}>
							<Col span={9}>
								<span className={'has-text-weight-bold'}>วันที่ชำระ</span>
							</Col>
							<Col span={15}>
								<DatePicker
									format={'DD/MM/YYYY'}
									allowClear={false}
									placeholder={'Date'}
									style={{ width: '100%' }}
									onChange={(dateObj, dateString) => {
										setPaymentDate(dateObj.toDate());
									}}
								/>
							</Col>
						</Row>
						<div style={{ margin: '10px' }}></div>
						<Row gutter={8} type={'flex'} align={'middle'}>
							<Col span={9}>
								<span className={'has-text-weight-bold'}>ประเภทการชำระ</span>
							</Col>
							<Col span={15}>
								<IWearDropdown
									isFull
									displayValue={ paymentType }
									choices={[ 'บัตรเครดิต', 'จ่ายผ่อน', 'เงินสด', 'โอนผ่าน Application' ]}
									onSelect={(selectedVal) => {
										setPaymentType(selectedVal);
									}}
								/>
							</Col>
						</Row>
						<div style={{ margin: '10px' }}></div>
						<Row gutter={8} type={'flex'} align={'middle'}>
							<Col span={9}>
								<span className={'has-text-weight-bold'}>ชำระเพิ่ม</span>
							</Col>
							<Col span={12}>
								<InputNumber
									style={{ width: '100%' }}
									defaultValue={ props.fullPrice - props.payment }
									min={0}
									max={ props.fullPrice - props.payment }
									onChange={(value) => {
										setPaymentAmount(value);
									}}
								/>
							</Col>
							<Col span={3} style={{ textAlign: 'right' }}>
								<span className={'has-text-weight-bold'}>บาท</span>
							</Col>
						</Row>
					</React.Fragment>
				)
			}
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col span={24}>
					<span>หมายเหตุ</span><br/>
					<Input.TextArea
						rows={3}
						maxLength={ 200 }
						onChange={ onChangeOrderNote }
					/>
				</Col>
			</Row>
		</Modal>
	);
}

RemainingPaymentModal.propTypes = {
	fullPrice: PropTypes.number.isRequired,
	payment: PropTypes.number.isRequired,
	isVisible: PropTypes.bool.isRequired,

	onClickPaid: PropTypes.func.isRequired,
	onClickClose: PropTypes.func.isRequired
}

export default RemainingPaymentModal;
