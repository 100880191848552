/**
 *	iWear - Data Product Edit container
 */

import React from 'react';
import { Layout } from 'antd';
import swal from 'sweetalert';
import PropTypes from 'prop-types';

import TopBar from '../../../../../components/layout/header';
import Sidebar from '../../../../../components/layout/sider';
import BottomBar from '../../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../../environments';
import WebCookie from '../../../../../lib/web-cookie';
import EditDataProduct from './screen';

const EditDataProductContainer = (props) => {
	const [ origin, setOrigin ] = React.useState({});
	const [ name, setName ] = React.useState('');
	const [ picture, setPicture ] = React.useState({ file: null, preview: '' }); // FILE
	const [ price, setPrice ] = React.useState(0);
	const [ quantity, setQuantity ] = React.useState(0);

	const [ brand, setBrand ] = React.useState('');
	const [ productType, setProductType ] = React.useState('');
	const [ branch, setBranch ] = React.useState('');

	React.useEffect(() => {
		fetchData(props.location.state.item_id);
	}, [ props.location.state.item_id ]);

	const fetchData = async (itemID) => {
		let res = await fetch(`${API_ENDPOINT}/product-items/${encodeURIComponent(itemID)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		let json = await res.json();
		setOrigin(json);
		setName(json.name);
		setPicture({ file: null, preview: json.image_url });
		setPrice(json.price);
	}

	const onClickGoBack = () => {
		props.history.goBack();
	}

	const onClickSave = async () => {
		if(name === '') {
			swal({
				title: 'เพิ่มสินค้าล้มเหลว',
				text: 'กรุณาระบุชื่อสินค้า',
				icon: 'error'
			});
		}
		// else if(picture.file === null || picture.preview === '') {
		// 	swal({
		// 		title: 'เพิ่มสินค้าล้มเหลว',
		// 		text: 'กรุณาเลือกรูปภาพสินค้า',
		// 		icon: 'error'
		// 	});
		// }
		else if(price === 0) {
			swal({
				title: 'เพิ่มสินค้าล้มเหลว',
				text: 'กรุณาระบุราคาสินค้า',
				icon: 'error'
			});
		}
		else {
			let res_product = await fetch(`${API_ENDPOINT}/product-items/${encodeURIComponent(origin.item_id)}`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					image_url: origin.image_url,
					sku_id: origin.sku_id,
					name: name,
					price: price
				})
			});

			if(res_product.status === 200) {
				swal({
					title: 'แก้ไขสินค้าสำเร็จ',
					icon: 'success'
				}).then((value) => {
					props.history.goBack();
				});
			}
			else {
				swal({
					title: 'แก้ไขสินค้าล้มเหลว',
					text: 'เกิดข้อผิดพลาด ไม่ทราบสาเหตุ',
					icon: 'error'
				});
			}
		}
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/data/product'} history={ props.history } />
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/data/product'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<EditDataProduct
						name={ name }
						picture={ picture }
						price={ price }
						quantity={ quantity }

						branch={ branch }
						productType={ productType }
						brand={ brand }

						setName={ setName }
						setPicture={ setPicture }
						setPrice={ setPrice }
						setQuantity={ setQuantity }

						setBranch={ setBranch }
						setProductType={ setProductType }
						setBrand={ setBrand }

						onClickGoBack={ onClickGoBack }
						onClickSave={ onClickSave }
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

EditDataProductContainer.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default EditDataProductContainer;
