/**
 *	Order Add component - Cliam Product modal
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
	Checkbox,
	InputNumber,
	Row,
	Col,
	Button,
	Modal
} from 'antd';
import swal from 'sweetalert';

import IWearDropdown from '../dropdown';

const onAdd = (state) => {
	let _arr = [ ...state.replacementData ];
	_arr.push({
		order: _arr.length + 1,
		code: state.productCode,
		name: state.productName,
		amount: state.productAmount,
		price: Number.parseFloat(state.productPrice).toFixed(2),
	});

	state.setReplacementData([ ..._arr ]);
}

const renderModalContent = (state, onScan) => {
	return (
		<React.Fragment>
			<Row>
				<Col>
					{ renderClaimTable(state.claimData, state.checkArray, state.setCheckArray, state.isLoading) }
				</Col>
			</Row><br/>
		{
				(state.checkArray.includes(true)) && (
					<React.Fragment>
						<Row>
							<Col>
								<span className={'is-size-4'}>
									รายการสินค้าทดแทน
								</span>
							</Col>
						</Row>
						<Row>
							<Col>
								{ renderReplacementTable(state, onScan) }
							</Col>
						</Row>
					</React.Fragment>
				)
			}
		</React.Fragment>
	);
}

const renderClaimTable = (claimData, checkArray, setCheckArray, isLoading) => {
	return (
		<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
			<thead>
				<tr>
					<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-left-th'}>
						ลำดับที่
					</th>
					<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
						รหัสสินค้า
					</th>
					<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
						สินค้า
					</th>
					<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
						จำนวน
					</th>
					<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
						ราคา
					</th>
					<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-right-th'}>
						เลือกเคลม
					</th>
				</tr>
			</thead>
			<tbody>
				{ renderClaimTableData(claimData, checkArray, setCheckArray, isLoading) }
			</tbody>
		</table>
	);
}

const renderClaimTableData = (claimData, checkArray, setCheckArray, isLoading) => {
	if(isLoading) {
		return (
			<tr>
				<td colSpan={6} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* กำลังโหลด... */}
				</td>
			</tr>
		);
	}
	else if(claimData.length === 0) {
		return (
			<tr>
				<td colSpan={6} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* ไม่มีข้อมูล */}
				</td>
			</tr>
		);
	}
	else {
		return claimData.map((_e, _i) => {
			return (
				<tr key={_i}>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered  custom-iwear-global-table-bottom-left-td'}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.order }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.code }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.name }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.amount }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.price }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered  custom-iwear-global-table-bottom-right-td'}
						style={{ verticalAlign: 'middle' }}
					>
						<Checkbox
							defaultChecked={ false }
							checked={ checkArray[_i] }
							onChange={(evt) => {
								let _arr = [...checkArray];
								_arr[_i] = evt.target.checked;
								setCheckArray([ ..._arr ]);
							}}
						>
						</Checkbox>
					</td>
				</tr>
			);
		});
	}
}

const renderReplacementTable = (state, onScan) => {
	return (
		<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
			<thead>
				<tr>
					<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-left-th'}>
						ลำดับที่
					</th>
					<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
						รหัสสินค้า
					</th>
					<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
						สินค้า
					</th>
					<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
						จำนวน
					</th>
					<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
						ราคา
					</th>
					<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-right-th'}>
						เพิ่มเติม
					</th>
				</tr>
			</thead>
			<tbody>
				{ renderReplacementTableData(state.replacementData, state.isLoading) }
				{ renderReplacementTableFooter(state, onScan) }
			</tbody>
		</table>
	);
}

const renderReplacementTableData = (replacementData, isLoading) => {
	if(isLoading) {
		return null;
		// return (
		// 	<tr>
		// 		<td colSpan={6} className={'custom-iwear-global-font-color has-text-centered'}>
		// 			{/* กำลังโหลด... */}
		// 		</td>
		// 	</tr>
		// );
	}
	else if(replacementData.length === 0) {
		return null;
		// return (
		// 	<tr>
		// 		<td colSpan={6} className={'custom-iwear-global-font-color has-text-centered'}>
		// 			{/* ไม่มีข้อมูล */}
		// 		</td>
		// 	</tr>
		// );
	}
	else {
		return replacementData.map((_e, _i) => {
			return (
				<tr key={_i}>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered  custom-iwear-global-table-bottom-left-td'}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.order }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.code }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.name }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.amount }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.price }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered  custom-iwear-global-table-bottom-right-td'}
						style={{ verticalAlign: 'middle' }}
					>
						<Button>
							<i className={'fas fa-ellipsis-v custom-iwear-global-table-text-color'}></i>
						</Button>
					</td>
				</tr>
			);
		});
	}
}

const renderReplacementTableFooter = (state, onScan) => {
	let _productData = [];
	state.productData.forEach((_e) => { _productData.push(_e.name); });

	return (
		<tr>
			<td
				className={'custom-iwear-global-table-text-color has-text-centered  custom-iwear-global-table-bottom-left-td'}
				style={{ verticalAlign: 'middle' }}
			>
				{ state.replacementData.length + 1 }
			</td>
			<td
				className={'custom-iwear-global-table-text-color has-text-centered '}
				style={{ verticalAlign: 'middle' }}
			>
				<input
					style={{ width: '150px' }}
					value={ state.productCode }
					className={'input'}
					placeholder={'สแกน QR กดที่นี่'}
					onChange={(evt) => {
						evt.preventDefault();
						state.setProductCode(evt.target.value);
						// props.onScan(evt.target.value);
					}}
					onKeyDown={(evt) => {
						// console.log('KeyPress', evt.key);
						if(evt.key === 'Enter') {
							onScan(state.productCode);
						}
					}}
				/>
			</td>
			<td
				className={'custom-iwear-global-table-text-color has-text-centered '}
				style={{ verticalAlign: 'middle' }}
			>
				<IWearDropdown
					isFilterable
					styleJSX={{ minWidth: '95px', paddingRight: '20px' }}
					displayValue={ state.productName }
					choices={[ ..._productData ]}
					onSelect={(selectedVal) => {
						state.setProductName(selectedVal);
						state.setProductAmount(1);
						state.setProductPrice(state.productData[state.productData.findIndex((_f) => { return _f.name === selectedVal; })].price);
					}}
				/>
			</td>
			<td
				className={'custom-iwear-global-table-text-color has-text-centered '}
				style={{ verticalAlign: 'middle' }}
			>
				<InputNumber
					value={ state.productAmount }
					min={0}
					onChange={(val) => {
						if(!Number.isNaN(Number.parseInt(val, 10))) {
							state.setProductAmount(Number.parseInt(val, 10));
						}
					}}
				/>
			</td>
			<td
				className={'custom-iwear-global-table-text-color has-text-centered '}
				style={{ verticalAlign: 'middle' }}
			>
				<InputNumber
					value={ state.productPrice }
					min={0}
					onChange={(val) => {
						if(!Number.isNaN(Number.parseInt(val, 10))) {
							state.setProductPrice(Number.parseInt(val, 10));
						}
					}}
				/>
			</td>
			<td
				className={'has-text-centered  custom-iwear-global-table-bottom-right-td'}
				style={{ verticalAlign: 'middle' }}
			>
				<Button
					onClick={() => { onAdd(state); }}
				>
					<span
						className={'custom-iwear-global-table-text-color'}
						style={{ textDecorationLine: 'underline' }}
					>
						เพิ่ม
					</span>
				</Button>
			</td>
		</tr>
	);
}

const ClaimProductModal = (props) => {
	const [ claimData, setClaimData ] = React.useState([]);
	const [ checkArray, setCheckArray ] = React.useState([]);

	const [ replacementData, setReplacementData ] = React.useState([]);
	const [ productData, setProductData ] = React.useState([]);
	const [ productCode, setProductCode ] = React.useState('');
	const [ productName, setProductName ] = React.useState('ค้นหาสินค้า');
	const [ productAmount, setProductAmount ] = React.useState(0);
	const [ productPrice, setProductPrice ] = React.useState(0);
	const [ isLoading, setIsLoading ] = React.useState(false);

	React.useEffect(() => {
		setIsLoading(true);

		setProductData([ ...props.productData ]);
		setClaimData([ ...props.originData ]);

		let _check = [];
		props.originData.forEach((_e) => { _check.push(false); });
		setCheckArray([ ..._check ]);

		setIsLoading(false);
	}, [ props, props.isVisible ]);

	const onScan = (item_id) => {
		let _arr = [ ...replacementData ];
		let _data = [ ...productData ].filter((_e) => { return _e.id === Number.parseInt(item_id, 10); });

		if(_data.length === 0) {
			setProductCode('');
			setProductName('เลือกสินค้า');
			setProductAmount(1);
			setProductPrice(0);

			swal({
				text: 'ไม่พบสินค้า',
				icon: 'error'
			});
		}
		else {
			_data.forEach((_e) => {
				_arr.push({
					order: replacementData.length + 1,
					code: `${_e.id}`,
					name: _e.name,
					amount: 1,
					price: Number.parseFloat(_e.price).toFixed(2)
				});
			});

			setReplacementData([  ..._arr ]);
			setProductCode('');
			setProductName('เลือกสินค้า');
			setProductAmount(1);
			setProductPrice(0);
		}
	}

	const onCloseModal = () => {
		let _check = [];
		props.originData.forEach((_e) => { _check.push(false); });
		setClaimData([]);
		setReplacementData([]);
		setCheckArray([ ..._check ]);
		props.setIsVisible(false);
	}

	const onSubmitModal = () => {
		// Do something...
		let _check = [];
		props.originData.forEach((_e) => { _check.push(false); });
		setCheckArray([ ..._check ]);
		props.setIsVisible(false);
	}

	return (
		<Modal
			title={'รายการเคลมสินค้า'}
			visible={ props.isVisible }
			onCancel={ onCloseModal }
			footer={[
				<Button onClick={ onCloseModal }>
					ยกเลิกการเคลม
				</Button>,
				<Button onClick={ onSubmitModal }>
					ยืนยันการเคลม
				</Button>
			]}
		>
			{
				renderModalContent(
					{
						claimData,
						checkArray,
						setCheckArray,
						replacementData,
						productData,
						productCode,
						productName,
						productAmount,
						productPrice,
						setReplacementData,
						setProductData,
						setProductCode,
						setProductName,
						setProductAmount,
						setProductPrice,
						isLoading
					},
					onScan
				)
			}
		</Modal>
	);
}

ClaimProductModal.propTypes = {
	originData: PropTypes.array.isRequired,
	productData: PropTypes.array.isRequired,

	isVisible: PropTypes.bool.isRequired,
	setIsVisible: PropTypes.func.isRequired
}

export default ClaimProductModal;
