/**
 *	Stock Export Inventory - Content
 */

import React from 'react';
import {
	Row,
	Col,
	Button,
	InputNumber
} from 'antd';
import ReactToPrint from 'react-to-print';

import Breadcrumb from '../../../../components/breadcrumb';
import ToPDF from '../../../../components/to-pdf';
import TableFooter from '../../../../components/table-footer'
import IWearDropdown from '../../../../components/dropdown';
import { ContentPropTypes } from './stock-export-inventory.model';

const Content = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'คลังสินค้า', 'ส่งออกสินค้า', 'กำหนดเอง' ]} withGoBack onClickGoBack={ props.onClickGoBack } />
			{ renderContent(props) }
		</div>
	);
}

const renderContent = (props) => {
	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
			<Row>
				<Col span={24}>
					{ renderOptions(props) }
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					{ renderTable(props) }
				</Col>
			</Row>
			<Row type={'flex'} align={'middle'} justify={'end'}>
				<Col style={{ paddingRight: '20px', paddingBottom: '20px' }}>
					<Button onClick={ props.onClickConfirm }>
						<span className={'custom-iwear-global-font-color'}>
							ยืนยันการส่งออกสินค้า
						</span>
					</Button>
					<div style={{ display: 'none' }}>
						<ToPDF.Stock ref={ props.exportRef } inventoryInfo={ props.exportInfo } />
					</div>
					<ReactToPrint
						ref={ props.printRef }
						trigger={() => (
							<button className={'button'} style={{ display: 'none' }}>
								<i className={'fas fa-file-excel custom-iwear-global-font-color'}/>
							</button>
						)}
						content={() => props.exportRef.current}
					/>
				</Col>
			</Row>
		</div>
	)
}

const renderOptions = (props) => {
	let _arr = [];
	props.branchList.forEach((_e) => { _arr.push(_e.branch_name); });

	return (
		<div className={'custom-iwear-global-table-option-panel'}>
			<Row>
				<Col span={12}>
					<span
						className={'custom-iwear-global-font-color is-size-5'}
						style={{ textDecorationLine: 'underline' }}
					>
						ส่งออกสินค้า
					</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						ไปยังสาขา:&nbsp;
					</span>
					<IWearDropdown
						styleJSX={{ minWidth: '120px', paddingRight: '40px' }}
						displayValue={ props.branch }
						choices={[ ..._arr ]}
						onSelect={(selectedVal) => {
							console.log('selectedVal', selectedVal, props.branch);
							props.setBranch(selectedVal);
						}}
					/>
				</Col>
			</Row>
		</div>
	);
}

const renderTable = (props) => {
	return (
		<div className={'custom-iwear-global-table-container'}>
			<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-left-th'}>
							ลำดับ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							รหัสสินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							สินค้า
						</th>
						{/*<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							Barcode
						</th>*/}
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							จำนวน
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-right-th'}>
							เพิ่มเติม
						</th>
					</tr>
				</thead>
				<tbody>
					{ renderTableData(props) }
					{ renderTableFooter(props) }
				</tbody>
			</table>
		</div>
	);
}

const renderTableData = (props) => {
	if(props.isLoading) {
		return null;
		// return (
		// 	<tr>
		// 		<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
		// 			{/* กำลังโหลด... */}
		// 		</td>
		// 	</tr>
		// );
	}
	else if(!props.isLoading && props.tableData.length === 0) {
		return null;
		// return (
		// 	<tr>
		// 		<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
		// 			{/* ไม่มีข้อมูล */}
		// 		</td>
		// 	</tr>
		// );
	}

	return props.tableData.map((_e, _i) => {
		if(_i === props.editIdx) {
			return (
				<tr key={_i}>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.order }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.code }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.name }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						<InputNumber
							value={ props.tableData[_i].amount }
							min={1}
							onChange={(val) => {
								let _arr = [ ...props.tableData ];
								_arr[_i].amount = val;
								props.setTableData([ ..._arr ]);
							}}
						/>
					</td>
					<td
						className={'has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						<Button onClick={ props.onClickFinishEditing }>
							<span>
								เสร็จ
							</span>
						</Button>
					</td>
				</tr>
			);
		}
		else {
			return (
				<tr key={_i}>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.order }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.code }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.name }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.amount }
					</td>
					<td
						className={'has-text-centered '}
						style={{ verticalAlign: 'middle' }}
					>
						<Button onClick={() => { props.onEditData(_i); }}>
							<span>
								แก้ไข
							</span>
						</Button>
						&nbsp;
						<Button onClick={() => { props.onDeleteData(_i); }}>
							<span>
								ลบ
							</span>
						</Button>
					</td>
				</tr>
			);
		}
	});
}

const renderTableFooter = (props) => {
	return (
		<TableFooter.Stock
			seq={ props.tableData.length + 1 }

			qrValue={ props.productCode }
			qrSuggestion={[ ...props.suggestionDataProductCode ]}
			onChangeQR={(val) => { props.setProductCode(val); }}
			onKeyEnterQR={ props.onScan }
			onSelectQR={(valueId, valueObj) => { props.onSelectProductCode(valueId); }}

			productValue={ props.productName }
			productSuggestion={[ ...props.suggestionDataProductName ]}
			onChangeProduct={(val) => { props.setProductName(val); }}
			onSelectProduct={(valueId, valueObj) => { props.onSelectProductName(valueId); }}

			quantityValue={ props.productAmount }
			onChangeQuantity={(val) => { props.setProductAmount(val); }}

			priceValue={ props.productPrice }
			onChangePrice={(val) => { props.setProductPrice(val); }}

			onAddData={ props.onAddData }
		/>
	);
}

Content.propTypes = ContentPropTypes;

export default Content;
