/**
 *	iWear - DataPromotion Container
 *
 *		@using React Hook
 */

import React from 'react';
import { Layout } from 'antd';
import swal from 'sweetalert';
import PropTypes from 'prop-types';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../environments';
import { downloadFromBuffer } from '../../../../functions';
import WebCookie from '../../../../lib/web-cookie';
import AdminDataPromotion from './screen';

const AdminDataPromotionContainer = (props) => {
	const [ tableData, setTableData ] = React.useState([]);
	const [ totalData, setTotalData ] = React.useState(0);
	const [ page, setPage ] = React.useState(1);
	const [ pageSize, setPageSize ] = React.useState(10);
	const [ totalPage, setTotalPage ] = React.useState(1);
	const [ searchKey, setSearchKey ] = React.useState('');
	const [ isLoading, setIsLoading ] = React.useState(false);
	const [ isExporting, setIsExporting ] = React.useState(false);

	const [ modalCode, setModalCode ] = React.useState('');
	const [ modalName, setModalName ] = React.useState('');
	const [ modalExpDate, setModalExpDate ] = React.useState({ value: null, dateString: '' });
	const [ addModalVisible, setAddModalVisible ] = React.useState(false);
	const [ editModalVisible, setEditModalVisible ] = React.useState(false);
	const [ removeModalVisible, setRemoveModalVisible ] = React.useState(false);
	const [ editIdx, setEditIdx ] = React.useState(-1);

	const [ fetchTrigger, setFetchTrigger ] = React.useState(0);

	React.useEffect(() => {
		fetchData({ page, pageSize, searchKey });
	}, [ page, pageSize, searchKey, fetchTrigger ]);

	const fetchData = async (state) => {
		setIsLoading(true);

		let res = await fetch(`${API_ENDPOINT}/promotions?name=${encodeURIComponent(state.searchKey)}&offset=${(state.page - 1) * state.pageSize}&limit=${state.pageSize}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		let json = await res.json();
		setTableData([ ...json.list ]);
		setTotalData(json.count || json.list.length);
		setTotalPage(json.total_pages);

		setIsLoading(false);
	}

	const reFetch = async () => {
		if(page > 1) { setPage(1); }
		else { setFetchTrigger(fetchTrigger + 1); }
	}

	const onClickAdd = async () => {
		if(modalCode === '') {
			swal({
				title: 'เพิ่มโปรโมชั่นล้มเหลว',
				text: 'กรุณาระบุรหัสโปรโมชั่น',
				icon: 'error'
			});
		}
		else if(modalName === '') {
			swal({
				title: 'เพิ่มโปรโมชั่นล้มเหลว',
				text: 'กรุณาระบุชื่อโปรโมชั่น',
				icon: 'error'
			});
		}
		else if(modalExpDate.dateString === '') {
			swal({
				title: 'เพิ่มโปรโมชั่นล้มเหลว',
				text: 'กรุณาระบุวันที่สิ้นสุดโปรโมชั่น',
				icon: 'error'
			});
		}
		else {
			let res = await fetch(`${API_ENDPOINT}/promotions`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					code: modalCode,
					name: modalName,
					expire_date: modalExpDate.dateString,
					type: 'price',
					value: totalData + 1
				})
			});

			let json = await res.json();
			console.log('Add Promotion', json);
			if(res.status === 200) {
				swal({
					title: 'เพิ่มโปรโมชั่นสำเร็จ',
					icon: 'success'
				}).then((value) => {
					setAddModalVisible(false);
					reFetch();
				});
			}
			else {
				swal({
					title: 'เพิ่มโปรโมชั่นล้มเหลว',
					text: 'เกิดข้อผิดพลาดบางอย่าง',
					icon: 'error'
				});
			}
		}
	}

	const onClickEdit = async () => {
		if(modalCode === '') {
			swal({
				title: 'เพิ่มโปรโมชั่นล้มเหลว',
				text: 'กรุณาระบุโค้ดโปรโมชั่น',
				icon: 'error'
			});
		}
		else if(modalName === '') {
			swal({
				title: 'เพิ่มโปรโมชั่นล้มเหลว',
				text: 'กรุณาระบุชื่อโปรโมชั่น',
				icon: 'error'
			});
		}
		else {
			let _data = tableData.find((_f) => { return _f.promotion_id === editIdx; });

			let res = await fetch(`${API_ENDPOINT}/promotions/${encodeURIComponent(_data.promotion_id)}`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					code: modalCode,
					name: modalName,
					expire_date: (modalExpDate.dateString === '') ? _data.expire_date : modalExpDate.dateString,
					type: 'inv',
					value: _data.value
				})
			});

			let json = await res.json();
			console.log('Edit Promotion', json);
			if(res.status === 200) {
				swal({
					title: 'แก้ไขโปรโมชั่นสำเร็จ',
					icon: 'success'
				}).then((value) => {
					setEditModalVisible(false);
					reFetch();
				});
			}
			else {
				swal({
					title: 'แก้ไขโปรโมชั่นล้มเหลว',
					text: 'เกิดข้อผิดพลาดบางอย่าง',
					icon: 'error'
				});
			}
		}
	}

	const onClickRemove = async () => {
		let _data = tableData.find((_f) => { return _f.promotion_id === editIdx; });
		let res = await fetch(`${API_ENDPOINT}/promotions/${encodeURIComponent(_data.promotion_id)}`, {
			method: 'DELETE',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		let json = await res.json();
		console.log('Delete Promotion', json);
		if(res.status === 200) {
			swal({
				title: 'ลบโปรโมชั่นสำเร็จ',
				icon: 'success'
			}).then((value) => {
				setRemoveModalVisible(false);
				reFetch();
			});
		}
		else {
			swal({
				title: 'ลบโปรโมชั่นล้มเหลว',
				text: 'เกิดข้อผิดพลาดบางอย่าง',
				icon: 'error'
			});
		}
	}

	const onChangePromotionStatus = async (checked, idx) => {
		const _data = [ ...tableData ];
		_data[idx].is_active = (checked) ? 1 : 0;
		setTableData(_data);

		let res = await fetch(`${API_ENDPOINT}/promotions/${encodeURIComponent(_data[idx].promotion_id)}`, {
			method: 'PATCH',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				code: _data[idx].code,
				name: _data[idx].name,
				expire_date: _data[idx].expire_date,
				type: _data[idx].type,
				value: _data[idx].value,
				is_active: _data[idx].is_active
			})
		});

		let json = await res.json();
		console.log('Active', json);

		if(res.status === 200) {
			swal({
				title: 'แก้ไขโปรโมชั่นสำเร็จ',
				icon: 'success'
			}).then((value) => {
				setEditModalVisible(false);
				reFetch();
			});
		}
		else {
			swal({
				title: 'แก้ไขโปรโมชั่นล้มเหลว',
				text: 'เกิดข้อผิดพลาดบางอย่าง',
				icon: 'error'
			});
		}
	}

	const onClickFileExport = async () => {
		setIsExporting(true);

		let _url = '';
		if(searchKey && searchKey.length > 0) {
			_url = `${API_ENDPOINT}/promotions
				?name=${encodeURIComponent(searchKey)}
				&is_report=true
			`;
		}
		else {
			_url = `${API_ENDPOINT}/promotions?is_report=true`;
		}

		let res = await fetch(`${_url}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});

		let data = await res.json();

		if(res.status === 200) {
			let _timer = 5000 + (Number.parseInt(totalData / 5000, 10) * 1000);
			setTimeout(() => {
				downloadFromBuffer(data.report_path, 'promotion_report.xlsx');
				setIsExporting(false);
			}, _timer);
		}
		else {
			setIsExporting(false);
			swal({
				title: 'เกิดข้อผิดพลาด',
				text: 'ไม่สามารถ export ได้ในขณะนี้',
				icon: 'error'
			});
		}
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/data/promotion'} history={ props.history } />
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/data/promotion'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<AdminDataPromotion
						dispatcher={({ type, value }) => { setIsExporting(value); }}
						tableData={ tableData }
						totalData={ totalData }
						page={ page }
						pageSize={ pageSize }
						totalPage={ totalPage }
						searchKey={ searchKey }
						isLoading={ isLoading }
						isExporting={ isExporting }

						modalCode={ modalCode }
						modalName={ modalName }
						modalExpDate={ modalExpDate }
						addModalVisible={ addModalVisible }
						editModalVisible={ editModalVisible }
						removeModalVisible={ removeModalVisible }
						editIdx={ editIdx }

						setTableData={ setTableData }
						setTotalData={ setTotalData }
						setPage={ setPage }
						setPageSize={ setPageSize }
						setTotalPage={ setTotalPage }
						setSearchKey={ setSearchKey }
						setIsLoading={ setIsLoading }
						setIsExporting={ setIsExporting }

						setModalCode={ setModalCode }
						setModalName={ setModalName }
						setModalExpDate={ setModalExpDate }
						setAddModalVisible={ setAddModalVisible }
						setEditModalVisible={ setEditModalVisible }
						setRemoveModalVisible={ setRemoveModalVisible }
						setEditIdx={ setEditIdx }

						onClickAdd={ onClickAdd }
						onClickEdit={ onClickEdit }
						onClickRemove={ onClickRemove }
						onChangePromotionStatus={ onChangePromotionStatus }
						onClickFileExport={ onClickFileExport }
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

AdminDataPromotionContainer.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default AdminDataPromotionContainer;
