/**
 *	Order List - Container
 */

import React from 'react';
import {
	Layout
} from 'antd';
import XDate from 'xdate';
import swal from 'sweetalert';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../environments';
import GlobalStore from '../../../../store';
import LocalStore from './order-list.store';
import Content from './order-list.content';
import Method from './order-list.method';
import { ContainerPropTypes, Actions } from './order-list.model';

const Container = (props) => {
	const OrderReducer = LocalStore();
	const [ printData, setPrintData ] = React.useState({});
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData({
			page: OrderReducer.state.page,
			pageSize: OrderReducer.state.pageSize,
			searchKey: OrderReducer.state.searchKey
		}, OrderReducer.dispatch, state.auth.token);
	}, [ OrderReducer.state.page, OrderReducer.state.pageSize, OrderReducer.state.searchKey, OrderReducer.dispatch, state.auth.token ]);

	const fetchData = async (params, localDispatch, authToken) => {
		await Method.fetchData(params, localDispatch, authToken);
	}

	const onChangePage = (page, pageSize) => {
		OrderReducer.dispatch({ type: Actions.SET_PAGE, value: Number.parseInt(page, 10) });
	}

	const onChangePageSize = (selectedVal) => {
		OrderReducer.dispatch({ type: Actions.SET_PAGE, value: 1 });
		OrderReducer.dispatch({ type: Actions.SET_PAGE_SIZE, value: Number.parseInt(selectedVal, 10) });
	}

	const onSearch = (value) => {
		OrderReducer.dispatch({ type: Actions.SET_PAGE, value: 1 });
		OrderReducer.dispatch({ type: Actions.SET_SEARCH_KEY, value: value });
	}

	const onClickRemainingPayment = (order_id, full_price, payment, order_code, remark, visible) => {
		OrderReducer.dispatch({ type: Actions.SET_ORDER_ID, value: order_id });
		OrderReducer.dispatch({ type: Actions.SET_MODAL_FULL_PRICE, value: full_price });
		OrderReducer.dispatch({ type: Actions.SET_MODAL_PAYMENT, value: payment });
		OrderReducer.dispatch({ type: Actions.SET_MODAL_ORDER_CODE, value: order_code });
		OrderReducer.dispatch({ type: Actions.SET_MODAL_REMARK, value: remark });
		OrderReducer.dispatch({ type: Actions.SET_PAY_MODAL_VISIBLE, value: visible });
	}

	const onClickCancelOrder = (idx) => {
		OrderReducer.dispatch({ type: Actions.SET_ORDER_ID, value: idx });
		OrderReducer.dispatch({ type: Actions.SET_CANCEL_MODAL_VISIBLE, value: true });
	}

	const onChangeCancelType = (selectedVal) => {
		OrderReducer.dispatch({ type: Actions.SET_CANCEL_TYPE, value: selectedVal });
	}

	const onClickCreate = (new_customer) => {
		if(new_customer) {
			props.history.push('/sales/order/create/new');
		}
		else {
			props.history.push('/sales/order/create/old');
		}
	}

	const onClickViewDetail = (orderID, customerID, fullname, tel) => {
		props.history.push(`/sales/order/detail`, {
			order_id: Number.parseInt(orderID, 10),
			customer_id: customerID,
			fullname: fullname,
			tel
		});
	}

	const modalOnClickPaid = async (payment_date, payment_type, payment_amount, orderNote) => {
		let res = await fetch(`${API_ENDPOINT}/payments`, {
			method: 'POST',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				order_id: OrderReducer.state.orderID,
				is_first: false,
				payment_type: payment_type,
				payment_date: (payment_date) ? XDate(payment_date).toString('yyyy-MM-dd') : XDate(new Date()).toString('yyyy-MM-dd'),
				amount: payment_amount,
				remark: orderNote
			})
		});

		let json = await res.json();

		if(json.hasOwnProperty('order_id')) {
			swal({
				title: 'ชำระเงินสำเร็จ',
				icon: 'success'
			}).then((value) => {
				OrderReducer.dispatch({ type: Actions.SET_PAY_MODAL_VISIBLE, value: false });
				window.location.reload();
			});
		}
		else {
			swal({
				title: 'ชำระเงินล้มเหลว',
				text: 'ข้อมูลไม่ครบถ้วน หรือ มีข้อผิดพลาดบางอย่าง',
				icon: 'warning'
			});
		}
	};

	const onClickCancel = async (order_info) => {
		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		let userID = order_info.customer_id;

		let res = await fetch(`${API_ENDPOINT}/orders/${encodeURIComponent(order_info.order_id)}`, {
			method: 'PATCH',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				msg: 'Update order status',
				customer_id: userID,
				branch_id: branchID,
				promotion_id: order_info.promotion_info.promotion_id,
				order_code: order_info.order_code, // order_code
				discount: order_info.discount,
				deposit_first: order_info.payment_info[order_info.payment_info.length - 1].amount,
				payment_type: order_info.payment_info[order_info.payment_info.length - 1].payment_type,
        receive_date: order_info.payment_info[order_info.payment_info.length - 1].payment_date,
				status: 'cancelled',
				rxmall_status: order_info.rxmall_status,
        price: order_info.price,
        vat: order_info.vat,
				note: order_info.note,
				cancel_reason: OrderReducer.state.cancelReason
			})
		});

		let json = await res.json();

		console.log('Cancelled', json);
		OrderReducer.dispatch({ type: Actions.SET_ORDER_ID, value: 0 });
		OrderReducer.dispatch({ type: Actions.SET_CANCEL_TYPE, value: '' });
		OrderReducer.dispatch({ type: Actions.SET_CANCEL_REASON, value: '' });
		OrderReducer.dispatch({ type: Actions.SET_CANCEL_MODAL_VISIBLE, value: false });
		window.location.reload();
	}

	const onClosePayModal = () => {
		OrderReducer.dispatch({ type: Actions.SET_PAY_MODAL_VISIBLE, value: false });
	}

	const onClickPickup = (info) => {
		OrderReducer.dispatch({ type: Actions.SET_ORDER_INFO, value: info });
		OrderReducer.dispatch({ type: Actions.SET_PICK_UP_MODAL_VISIBLE, value: true });
	}

	const onClosePickupModal = () => {
		OrderReducer.dispatch({ type: Actions.SET_PICK_UP_MODAL_VISIBLE, value: false });
	}

	const cancelModalOnClickOK = () => {
		onClickCancel(OrderReducer.state.orderID);
	}

	const cancelModalOnClickCancel = () => {
		OrderReducer.dispatch({ type: Actions.SET_ORDER_ID, value: 0 });
		OrderReducer.dispatch({ type: Actions.SET_CANCEL_TYPE, value: '' });
		OrderReducer.dispatch({ type: Actions.SET_CANCEL_REASON, value: '' });
		OrderReducer.dispatch({ type: Actions.SET_CANCEL_MODAL_VISIBLE, value: false });
	}

	const cancelModalOnChangeCancelReason = (evt) => {
		OrderReducer.dispatch({ type: Actions.SET_CANCEL_REASON, value: evt.target.value });
	}

	const onTickPickUpStatus = async (orderInfo, orderID, status, date) => {
		let _flag = 0;
		if(status === 1) { _flag = 1; }

		let res = await fetch(`${API_ENDPOINT}/orders/${encodeURIComponent(orderID)}`, {
			method: 'PATCH',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				msg: 'Update order pick up status',
				customer_id: orderInfo.customer_id,
				branch_id: orderInfo.branch_id,
				promotion_id: orderInfo.promotion_info.promotion_id,
				order_code: orderInfo.order_code, // order_code
				discount: orderInfo.discount,
				deposit_first: orderInfo.payment_info[orderInfo.payment_info.length - 1].amount,
				payment_type: orderInfo.payment_info[orderInfo.payment_info.length - 1].payment_type,
				receive_date: orderInfo.payment_info[orderInfo.payment_info.length - 1].payment_date,
        status: orderInfo.status,
				rxmall_status: orderInfo.rxmall_status || 'ORDERED',
        price: orderInfo.price,
        vat: orderInfo.vat,
				note: orderInfo.note,
				cancel_reason: orderInfo.cancel_reason,
				pick_up_status: _flag,
				pick_up_date: date || XDate(new Date()).toString('yyyy-MM-dd')
			})
		});

    let json = await res.json();
    console.log('PickUpStatus', json);

		OrderReducer.dispatch({ type: Actions.SET_ORDER_ID, value: 0 });
		OrderReducer.dispatch({ type: Actions.SET_PICK_UP_STATUS, value: 0 });
		OrderReducer.dispatch({ type: Actions.SET_PICK_UP_MODAL_VISIBLE, value: false });
		fetchData({
			page: OrderReducer.state.page,
			pageSize: OrderReducer.state.pageSize,
			searchKey: OrderReducer.state.searchKey
		}, OrderReducer.dispatch, state.auth.token)
	}

	const onCancelPickup = async (orderInfo) => {
		let res = await fetch(`${API_ENDPOINT}/orders/${encodeURIComponent(orderInfo.order_id)}`, {
			method: 'PATCH',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				msg: 'Cancel order pick up status',
				customer_id: orderInfo.customer_id,
				branch_id: orderInfo.branch_id,
				promotion_id: orderInfo.promotion_info.promotion_id,
				order_code: orderInfo.order_code, // order_code
				discount: orderInfo.discount,
				deposit_first: orderInfo.payment_info[orderInfo.payment_info.length - 1].amount,
				payment_type: orderInfo.payment_info[orderInfo.payment_info.length - 1].payment_type,
				receive_date: orderInfo.payment_info[orderInfo.payment_info.length - 1].payment_date,
				status: orderInfo.status,
				rxmall_status: orderInfo.rxmall_status || 'ORDERED',
        price: orderInfo.price,
        vat: orderInfo.vat,
				note: orderInfo.note,
				cancel_reason: orderInfo.cancel_reason,
				pick_up_status: 0,
				pick_up_date: null
			})
		});

		let json = await res.json();

		console.log('CancelPickUpStatus', json);
		OrderReducer.dispatch({ type: Actions.SET_ORDER_ID, value: 0 });
		OrderReducer.dispatch({ type: Actions.SET_PICK_UP_STATUS, value: 0 });
		OrderReducer.dispatch({ type: Actions.SET_PICK_UP_MODAL_VISIBLE, value: false });
		fetchData({
			page: OrderReducer.state.page,
			pageSize: OrderReducer.state.pageSize,
			searchKey: OrderReducer.state.searchKey
		}, OrderReducer.dispatch, state.auth.token);
	}

	const beforePrintHandler = (order_id, customer_name, customer_tel) => {
		OrderReducer.dispatch({ type: Actions.SET_IS_PRINTING, value: true });
		beforePrintFetch(order_id, customer_name, customer_tel);

		return new Promise((resolve) => {
			console.log('InPromise');
			setTimeout(() => {
				OrderReducer.dispatch({ type: Actions.SET_IS_PRINTING, value: false });
				console.log('Resolve');
				resolve();
			}, 3000);
		})
	}

	const beforePrintFetch = async (order_id, customer_name, customer_tel) => {
		let res_order = await fetch(`${API_ENDPOINT}/orders?order_id=${encodeURIComponent(order_id)}&to_print=true`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let json_order = await res_order.json();

		let res_order_detail = await fetch(`${API_ENDPOINT}/orders/${encodeURIComponent(order_id)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let json_order_detail = await res_order_detail.json();

		let _customerID = json_order.result[0].customer_id;

		let res_eye = await fetch(`${API_ENDPOINT}/customer-with-eye?customer_id=${encodeURIComponent(_customerID)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let json_eye = await res_eye.json();

		let res_inspector = await fetch(`${API_ENDPOINT}/eye-inspector?customer_id=${encodeURIComponent(_customerID)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let json_inspector = await res_inspector.json();

		let res_product = await fetch(`${API_ENDPOINT}/products?to_print=true&order_id=${encodeURIComponent(order_id)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let json_product = await res_product.json();

		let result = {
			...json_order.result[0],
			eye_info: json_eye.list[json_eye.list.length - 1].eye_info,
			eye_inspector: (json_inspector.list.length > 0 && json_inspector.list[json_inspector.list.length - 1] && json_inspector.list[json_inspector.list.length - 1].staff_info)
				? json_inspector.list[json_inspector.list.length - 1].staff_info.full_name : '',
			glasses: {
				brand: (json_product && json_product.result) ? json_product.result.brand : '-',
				model: (json_product && json_product.result) ? json_product.result.model : '-',
			},
			lens: {
				type: json_product.lens_type
			},
			summary_price: {
				glass: (json_product && json_product.price) ? json_product.price.glass : 0,
				lens: (json_product && json_product.price) ? json_product.price.lens : 0
			},
			order_detail: [
				...json_order_detail.order_detail
			]
		}
		let _paid = 0;
		result.payment_info.forEach((_e) => { _paid += _e.amount; });

		const _remaining = result.price - result.discount + result.vat - _paid;
		console.log({ result, _remaining })
		result.payment_summary = {
			total: result.price,
			discount: result.discount,
			paid: _paid,
			vat: result.vat,
			remaining: (_remaining) ? _remaining : 0
		}

		console.log('BeforePrintGetContent', result);
		setPrintData({ ...result });
	}

	return (
		<Layout>
			<Sidebar mode={'sales'} currentRoute={'/sales/order'} history={ props.history } />
			<Layout>
				<TopBar mode={'sales'} currentRoute={'/sales/order'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...OrderReducer.state }
						dispatcher={ OrderReducer.dispatch }

						onChangePage={ onChangePage }
						onChangePageSize={ onChangePageSize }
						onSearch={ onSearch }
						onClickRemainingPayment={ onClickRemainingPayment }
						onClickCancelOrder={ onClickCancelOrder }
						onChangeCancelType={ onChangeCancelType }

						onClickCreate={ onClickCreate }
						onClickViewDetail={ onClickViewDetail }
						modalOnClickPaid={ modalOnClickPaid }
						onClickPickup={ onClickPickup }
						onClickCancel={ onClickCancel }
						onClosePayModal={ onClosePayModal }
						cancelModalOnClickOK={ cancelModalOnClickOK }
						cancelModalOnClickCancel={ cancelModalOnClickCancel }
						cancelModalOnChangeCancelReason={ cancelModalOnChangeCancelReason }
						onTickPickUpStatus={ onTickPickUpStatus }
						onCancelPickup={ onCancelPickup }
						onClosePickupModal={ onClosePickupModal }

						printData={ printData }
						beforePrintHandler={ beforePrintHandler }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
