/**
 *	iWear - Data Branch Add container
 */

import React from 'react';
import { Layout } from 'antd';
import swal from 'sweetalert';
import PropTypes from 'prop-types';

import TopBar from '../../../../../components/layout/header';
import Sidebar from '../../../../../components/layout/sider';
import BottomBar from '../../../../../components/bottom-bar';
import ThaiAddress from '../../../../../lib/thai-address';
import { API_ENDPOINT } from '../../../../../environments';
import WebCookie from '../../../../../lib/web-cookie';
import EditDataBranch from './screen';

const EditDataBranchContainer = (props) => {
	const [ branchName, setBranchName ] = React.useState('');
	const [ branchProvince, setBranchProvince ] = React.useState({ index: -1, text: '' });
	const [ branchDistrict, setBranchDistrict ] = React.useState('');
	const [ branchAddr, setBranchAddr ] = React.useState('');
	const [ branchPostCode, setBranchPostCode ] = React.useState('');

	React.useEffect(() => {
		fetchData(
			props.location.state.branchID,
			setBranchName,
			setBranchProvince,
			setBranchDistrict,
			setBranchAddr,
			setBranchPostCode
		);
	}, [
		props.location.state.branchID,
		setBranchName,
		setBranchProvince,
		setBranchDistrict,
		setBranchAddr,
		setBranchPostCode
	]);

	const fetchData = async (branchID, _setBranchName, _setBranchProvince, _setBranchDistrict, _setBranchAddr, _setBranchPostCode) => {
		let res = await fetch(`${API_ENDPOINT}/branches/${encodeURIComponent(branchID)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			},
		});

		let json = await res.json();

		let _provinceIdx = ThaiAddress.provinceList().findIndex((_f) => _f === json.province);

		_setBranchName(json.branch_name);
		_setBranchProvince({ index: _provinceIdx, text: json.province });
		_setBranchDistrict(json.district);
		_setBranchAddr(json.location);
		_setBranchPostCode(json.zip_code);

		// console.log('props.branchProvince', props.branchProvince)
		// console.log('props.branchDistrict', props.branchDistrict)
	}

	const onClickGoBack = () => {
		props.history.goBack();
	}

	const onClickSave = async () => {
		if(branchName === '') {
			swal({
				title: 'แก้ไขข้อมูลสาขาล้มเหลว',
				text: 'ไม่มีชื่อสาขา',
				icon: 'error'
			});
		}
		else if(branchProvince.text === '') {
			swal({
				title: 'แก้ไขข้อมูลสาขาล้มเหลว',
				text: 'ไม่เลือกจังหวัด',
				icon: 'error'
			});
		}
		else if(branchDistrict === '') {
			swal({
				title: 'แก้ไขข้อมูลสาขาล้มเหลว',
				text: 'ไม่เลือกเขต',
				icon: 'error'
			});
		}
		else if(branchAddr === '') {
			swal({
				title: 'แก้ไขข้อมูลสาขาล้มเหลว',
				text: 'ไม่ระบุที่ตั้ง',
				icon: 'error'
			});
		}
		else {
			let res = await fetch(`${API_ENDPOINT}/branches/${encodeURIComponent(props.location.state.branchID)}`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					branch_name: branchName,
					province: branchProvince.text,
					district: branchDistrict,
					location: branchAddr,
					zip_code: Number.isNaN(Number.parseInt(branchPostCode)) ? 0 : Number.parseInt(branchPostCode)
				})
			});

			let json = await res.json();
			console.log('UpdateBranch', json);

			if(res.status === 200) {
				swal({
					title: 'แก้ไขข้อมูลสาขาสำเร็จ',
					icon: 'success'
				}).then((val) => {
					props.history.goBack();
				});
			}
			else {
				swal({
					title: 'แก้ไขข้อมูลสาขาล้มเหลว',
					text: 'ไม่ทราบสาเหตุ',
					icon: 'error'
				});
			}
		}
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/data/branch'} history={ props.history } />
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/data/branch'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<EditDataBranch
						branchName={ branchName }
						branchProvince={ branchProvince }
						branchDistrict={ branchDistrict }
						branchAddr={ branchAddr }
						branchPostCode={ branchPostCode }

						setBranchName={ setBranchName }
						setBranchProvince={ setBranchProvince }
						setBranchDistrict={ setBranchDistrict }
						setBranchAddr={ setBranchAddr }
						setBranchPostCode={ setBranchPostCode }

						onClickGoBack={ onClickGoBack }
						onClickSave={ onClickSave }
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

EditDataBranchContainer.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default EditDataBranchContainer;
