/**
 *	Stock Export Inventory - Container
 */

import React from 'react';
import swal from 'sweetalert';
import { Layout } from 'antd';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../environments';
import { useDebounce } from '../../../../functions';
import GlobalStore from '../../../../store';
import Content from './stock-export-inventory.content';
import LocalStore from './stock-export-inventory.store';
import { ContainerPropTypes, Actions } from './stock-export-inventory.model';

const Container = (props) => {
	const StockExportReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	const productCodeDebouncer = useDebounce(StockExportReducer.state.productCode, 1000);
	const productNameDebouncer = useDebounce(StockExportReducer.state.productName, 1000);
	const exportRef = React.useRef(null);
	const printRef = React.useRef(null);

	React.useEffect(() => {
		fetchData({ page: StockExportReducer.state.page, pageSize: StockExportReducer.state.pageSize }, StockExportReducer.dispatch, state.auth.token);
	}, [ StockExportReducer.state.page, StockExportReducer.state.pageSize, StockExportReducer.dispatch, state.auth.token ]);

	React.useEffect(() => {
		if(productCodeDebouncer === StockExportReducer.state.productCode) {
			debounceProductCode(StockExportReducer.state.productCode, StockExportReducer.dispatch, state.auth.token)
		}
	}, [ StockExportReducer.state.productCode, productCodeDebouncer, StockExportReducer.dispatch, state.auth.token ]);

	React.useEffect(() => {
		if(productNameDebouncer === StockExportReducer.state.productName) {
			debounceProductName(StockExportReducer.state.productName, StockExportReducer.dispatch, state.auth.token)
		}
	}, [ StockExportReducer.state.productName, productNameDebouncer, StockExportReducer.dispatch, state.auth.token ]);

	const debounceProductCode = async (qrCode, localDispatch, authToken) => {
		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		if(qrCode.length >= 2) {
			let res = await fetch(`${API_ENDPOINT}/inventory-items?only_available=true&search=${encodeURIComponent(qrCode)}&branch_id=${encodeURIComponent(branchID)}&offset=0&limit=20`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + authToken,
					'Content-Type': 'application/json'
				}
			});
			let json = await res.json();

			let _arr = [];
			json.list.forEach((_e, _i) => {
				_arr.push({ text: _e.product_info.item_code, value: _e.product_info.item_id });
			});

			localDispatch({ type: Actions.SET_PRODUCT_CODE_SUGGESTION, value: [ ..._arr ] });
		}
		else {
			localDispatch({ type: Actions.SET_PRODUCT_CODE_SUGGESTION, value: [] });
		}
	}

	const debounceProductName = async (itemName, localDispatch, authToken) => {
		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		if(itemName.length >= 2) {
			let res = await fetch(`${API_ENDPOINT}/inventory-items?only_available=true&search=${encodeURIComponent(itemName)}&branch_id=${encodeURIComponent(branchID)}&offset=0&limit=20`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + authToken,
					'Content-Type': 'application/json'
				}
			});
			let json = await res.json();

			let _arr = [];
			json.list.forEach((_e, _i) => {
				_arr.push({ text: _e.product_info.name, value: _e.product_info.item_id });
			});

			localDispatch({ type: Actions.SET_PRODUCT_NAME_SUGGESTION, value: [ ..._arr ] });
		}
		else {
			localDispatch({ type: Actions.SET_PRODUCT_NAME_SUGGESTION, value: [] });
		}
	}

	const fetchData = async (params, localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		let thisBranchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		let res_branch = await fetch(`${API_ENDPOINT}/branches?limit=9999`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json_branch = await res_branch.json();

		if(json_branch.list.length > 0) {
			let _arr = json_branch.list.filter((_f) => { return _f.branch_id !== thisBranchID; });
			localDispatch({ type: Actions.SET_BRANCH_LIST, value: [ ..._arr ] });
		}

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onChangeBranch = (selectedVal) => { StockExportReducer.dispatch({ type: Actions.SET_BRANCH, value: selectedVal }); }
	const onChangeTableData = (value) => { StockExportReducer.dispatch({ type: Actions.SET_TABLE_DATA, value: value }); }
	const onChangeQR = (val) => { StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: val }); }
	const onChangeProduct = (val) => { StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: val }); }
	const onChangeQuantity = (val) => { StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: val }); }
	const onChangePrice = (val) => { StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_PRICE, value: val }); }

	const onScan = async (qrCode) => {
		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		// let splitStr = qrCode.match(/.{1,3}/g);
		// let convertedQrCode = '';
		// splitStr.map((_e) => { return convertedQrCode += String.fromCharCode(_e); });

		let res = await fetch(`${API_ENDPOINT}/inventory-items?search=${encodeURIComponent(qrCode)}&branch_id=${encodeURIComponent(branchID)}&offset=0&limit=20`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});
		let json = await res.json();

		if(json.list.length === 0) {
			swal({
				title: 'ไม่พบสินค้าในระบบ',
				icon: 'error'
			}).then((val) => {
				StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: '' });
				StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: '' });
				StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: 1 });
				StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_PRICE, value: 0 });
			});
		}
		else {
			let _arr = [ ...StockExportReducer.state.tableData ];
			_arr.push({
				order: StockExportReducer.state.tableData.length + 1,
				id: json.list[0].product_info.item_id,
				code: json.list[0].product_info.item_code,
				name: json.list[0].product_info.name,
				amount: StockExportReducer.state.productAmount || 1,
				price: Number.parseFloat(json.list[0].product_info.price).toFixed(2)
			});

			StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: '' });
			StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: '' });
			StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: 1 });
			StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_PRICE, value: 0 });
			StockExportReducer.dispatch({ type: Actions.SET_TABLE_DATA, value: [ ..._arr ] });
		}
	}

	const onSelectProductCode = async (qrCode) => {
		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		let res = await fetch(`${API_ENDPOINT}/inventory-items?only_available=true&inventory=${encodeURIComponent(qrCode)}&branch_id=${encodeURIComponent(branchID)}&offset=0&limit=20`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});
		let json = await res.json();

		let _arr = [ ...StockExportReducer.state.tableData ];
		// let _sum = Number.parseFloat(priceSum + (json.list[0].price * productAmount));
		_arr.push({
			order: StockExportReducer.state.tableData.length + 1,
			id: json.list[0].product_info.item_id,
			code: json.list[0].product_info.item_code,
			name: json.list[0].product_info.name,
			amount: StockExportReducer.state.productAmount || 1,
			price: Number.parseFloat(json.list[0].product_info.price).toFixed(2)
		});

		StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: '' });
		StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: '' });
		StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: 1 });
		StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_PRICE, value: 0 });
		StockExportReducer.dispatch({ type: Actions.SET_TABLE_DATA, value: [ ..._arr ] });
	}

	const onSelectProductName = async (itemName) => {
		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		let res = await fetch(`${API_ENDPOINT}/inventory-items?only_available=true&inventory=${encodeURIComponent(itemName)}&branch_id=${encodeURIComponent(branchID)}&offset=0&limit=20`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});
		let json = await res.json();

		let _arr = [ ...StockExportReducer.state.tableData ];
		// let _sum = Number.parseFloat(priceSum + (json.list[0].price * productAmount));
		_arr.push({
			order: StockExportReducer.state.tableData.length + 1,
			id: json.list[0].product_info.item_id,
			code: json.list[0].product_info.item_code,
			name: json.list[0].product_info.name,
			amount: StockExportReducer.state.productAmount || 1,
			price: Number.parseFloat(json.list[0].product_info.price).toFixed(2)
		});

		StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: '' });
		StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: '' });
		StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: 1 });
		StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_PRICE, value: 0 });
		StockExportReducer.dispatch({ type: Actions.SET_TABLE_DATA, value: [ ..._arr ] });
	}

	const onAddData = () => {
		if(StockExportReducer.state.productName === 'ค้นหาสินค้า' || StockExportReducer.state.productCode === '' || StockExportReducer.state.productAmount === 0) {
			swal('ข้อมูลไม่ครบ', 'กรุณาใส่ข้อมูลทุกช่องก่อนเพิ่ม', 'error');
			return;
		}

		StockExportReducer.dispatch({
			type: Actions.SET_TABLE_DATA,
			value: [
				...StockExportReducer.state.tableData,
				{
					order: StockExportReducer.state.tableData.length + 1,
					id: StockExportReducer.state.productCode,
					name: StockExportReducer.state.productName,
					amount: StockExportReducer.state.productAmount || 1
				}
			]
		});

		StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: '' });
		StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: 'ค้นหาสินค้า' });
		StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: 0 });
		StockExportReducer.dispatch({ type: Actions.SET_PRODUCT_ID, value: '' });
	}

	const onEditData = (idx) => {
		if(StockExportReducer.state.isEditing) {
			StockExportReducer.dispatch({ type: Actions.SET_EDIT_IDX, value: -1 });
			StockExportReducer.dispatch({ type: Actions.SET_IS_EDITING, value: false });

			//
			StockExportReducer.dispatch({ type: Actions.SET_EDIT_IDX, value: idx });
			StockExportReducer.dispatch({ type: Actions.SET_IS_EDITING, value: true });
		}
		else {
			StockExportReducer.dispatch({ type: Actions.SET_EDIT_IDX, value: idx });
			StockExportReducer.dispatch({ type: Actions.SET_IS_EDITING, value: true });
		}
	}

	const onDeleteData = (idx) => {
		let _arr = [ ...StockExportReducer.state.tableData ];
		_arr.splice(idx, 1);

		StockExportReducer.dispatch({ type: Actions.SET_TABLE_DATA, value: [ ..._arr ] });
	}

	const onClickFinishEditing = () => {
		StockExportReducer.dispatch({ type: Actions.SET_EDIT_IDX, value: -1 });
		StockExportReducer.dispatch({ type: Actions.SET_IS_EDITING, value: false });
	}

	const onClickConfirm = async () => {
		let _arr = [];
		// tableData.forEach((_e) => {
		// 	_arr.push({
		// 		inv_item_id: `${_e.id}`,
		// 		quantity: _e.amount
		// 	});
		// });

		StockExportReducer.state.tableData.forEach((_e) => {
			_arr.push({
				item_id: _e.id,
				quantity: _e.amount
			});
		});

		let _destID = [...StockExportReducer.state.branchList].filter((_f) => { return _f.branch_name === StockExportReducer.state.branch });

		// console.log('ExportItems', _arr);
		// console.log('Destination', _destID);

		if(_arr.length > 0 && _destID.length > 0) {
			let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

			let res = await fetch(`${API_ENDPOINT}/exchanges`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + state.auth.token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					source_branch_id: branchID,
					dest_branch_id: _destID[0].branch_id,
					exchange_detail: [ ..._arr ]
				})
			});

			let json = await res.json();
			if(res.status !== 200) {
				swal({
					title: 'เกิดข้อผิดพลาด',
					text: json.msg,
					icon: 'error'
				});
			}
			else {
				StockExportReducer.dispatch({ type: Actions.SET_EXPORT_INFO, value: json });
				if(printRef !== null || printRef !== undefined) {
					printRef.current.handleClick(); // print
				}

				swal({
					title: 'ส่งออกสินค้าสำเร็จ',
					icon: 'success'
				}).then((value) => {
					props.history.goBack();
				});
			}
		}
		else {
			swal({
				title: 'ส่งออกสินค้าเข้าล้มเหลว',
				text: 'ไม่มีข้อมูลสินค้า หรือ ไม่ได้เลือกสาขาที่จะส่งไป',
				icon: 'error'
			});
		}
	}

	const onClickGoBack = () => {
		props.history.goBack();
	}

	return (
		<Layout>
			<Sidebar mode={'sales'} currentRoute={'/sales/stock/export'} history={ props.history } />
			<Layout>
				<TopBar mode={'sales'} currentRoute={'/sales/stock/export'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...StockExportReducer.state }
						exportRef={ exportRef }
						printRef={ printRef }

						onChangeBranch={ onChangeBranch }
						onChangeTableData={ onChangeTableData }
						onChangeQR={ onChangeQR }
						onChangeProduct={ onChangeProduct }
						onChangeQuantity={ onChangeQuantity }
						onChangePrice={ onChangePrice }

						onScan={ onScan }
						onSelectProductCode={ onSelectProductCode }
						onSelectProductName={ onSelectProductName }
						onAddData={ onAddData }
						onEditData={ onEditData }
						onDeleteData={ onDeleteData }
						onClickFinishEditing={ onClickFinishEditing }
						onClickConfirm={ onClickConfirm }
						onClickGoBack={ onClickGoBack }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
