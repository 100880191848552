/**
 *	Export Button - Excel Functions
 */

import swal from 'sweetalert';
import moment from 'moment';
import { API_ENDPOINT } from '../../environments';
import { downloadFromRawBuffer } from '../../functions';
import axios from 'axios'

const excelExport = async ({ type, branch_id, branch_name, dates, dispatcher, token }) => {
	if(type !== 'data_branch' &&
		type !== 'data_brand' &&
		type !== 'data_staff' &&
		type !== 'data_product_category' &&
		type !== 'data_promotion'
	) {
		if(dates.length !== 2) { console.error('Date Range invalid', dates); return; }
		dispatcher({ type: 'SET_IS_EXPORTING', value: true });

		let [ url, filename ] = typeResolver({
			type,
			branch_id,
			branch_name,
			dates: [ dates[0].format('YYYY-MM-DD'), dates[1].format('YYYY-MM-DD') ]
		});
		if(!url || !filename) { console.error('URL or Filename are missing', url, filename); dispatcher({ type: 'SET_IS_EXPORTING', value: false }); return; }

		let ms = Date.now();
		let res = await axios({
			url: `${url}&autogenerateddummycounter=${ ms }`,
			method: 'GET',
			headers: {
				'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'Authorization': 'Bearer ' + token,
				'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'Cache-Control': 'no-cache'
			},
			responseType: 'arraybuffer'
		});
		if(res.status === 200) {
			downloadFromRawBuffer(res.data, `${filename}_${moment().format('DD-MM-YYYY_HHmmss')}.xlsx`);
			dispatcher({ type: 'SET_IS_EXPORTING', value: false });
			// clearBrowserCache();
			// window.location.reload();
		}
		else {
			dispatcher({ type: 'SET_IS_EXPORTING', value: false });
			swal({
				title: 'เกิดข้อผิดพลาด',
				text: 'ไม่สามารถ export ได้ในขณะนี้',
				icon: 'error'
			}).then(() => {
				// clearBrowserCache();
				window.location.reload();
			});
		}
	}
	else {
		dispatcher({ type: 'SET_IS_EXPORTING', value: true });

		let [ url, filename ] = typeResolver({
			type,
			branch_id,
			branch_name,
			dates: [ '', '' ]
		});
		if(!url || !filename) { console.error('URL or Filename are missing', url, filename); dispatcher({ type: 'SET_IS_EXPORTING', value: false }); return; }

		// console.log('URL', url)
		// console.log('filename', filename)

		let ms = Date.now();
		let res = await axios({
			url: `${url}&autogenerateddummycounter=${ ms }`,
			method: 'GET',
			headers: {
				'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'Authorization': 'Bearer ' + token,
				'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
				'Cache-Control': 'no-cache'
			},
			responseType: 'arraybuffer'
		});

		if(res.status === 200) {
			downloadFromRawBuffer(res.data, `${filename}_${moment().format('DD-MM-YYYY_HHmmss')}.xlsx`);
			dispatcher({ type: 'SET_IS_EXPORTING', value: false });
			// clearBrowserCache();
			// window.location.reload();
		}
		else {
			dispatcher({ type: 'SET_IS_EXPORTING', value: false });
			swal({
				title: 'เกิดข้อผิดพลาด',
				text: 'ไม่สามารถ export ได้ในขณะนี้',
				icon: 'error'
			}).then(() => {
				// clearBrowserCache();
				window.location.reload();
			});
		}
	}
}

const typeResolver = ({ type, branch_id, branch_name, dates }) => {
	if(type === 'order') {
		return ([
			`${API_ENDPOINT}/orders
				?branch_id=${encodeURIComponent(branch_id)}
				&branch_name=${encodeURIComponent(branch_name)}
				&receive_date_start=${encodeURIComponent(dates[0])}
				&receive_date_end=${encodeURIComponent(dates[1])}
				&is_report=true
			`,
			'order_report'
		]);
	}
	else if(type === 'appointment') {
		return ([
			`${API_ENDPOINT}/appointments
				?branch_id=${encodeURIComponent(branch_id)}
				&branch_name=${encodeURIComponent(branch_name)}
				&report_start_date=${encodeURIComponent(dates[0])}
				&report_end_date=${encodeURIComponent(dates[1])}
				&is_report=true
			`,
			'appointment_report'
		]);
	}
	else if(type === 'customer') {
		return ([
			`${API_ENDPOINT}/customers
				?branch_id=${encodeURIComponent(branch_id)}
				&branch_name=${encodeURIComponent(branch_name)}
				&report_start_date=${encodeURIComponent(dates[0])}
				&report_end_date=${encodeURIComponent(dates[1])}
				&is_report=true
			`,
			'customer_report'
		]);
	}
	else if(type === 'inventory') {
		return ([
			`${API_ENDPOINT}/inventory-items
				?branch_id=${encodeURIComponent(branch_id)}
				&branch_name=${encodeURIComponent(branch_name)}
				&report_start_date=${encodeURIComponent(dates[0])}
				&report_end_date=${encodeURIComponent(dates[1])}
				&is_report=true
			`,
			'inventory_report'
		]);
	}
	else if(type === 'import') {
		return ([
			`${API_ENDPOINT}/exchanges
				?branch_id=${encodeURIComponent(branch_id)}
				&branch_name=${encodeURIComponent(branch_name)}
				&report_start_date=${encodeURIComponent(dates[0])}
				&report_end_date=${encodeURIComponent(dates[1])}
				&is_report=true
				&mode=import
			`,
			'import_report'
		]);
	}
	else if(type === 'export') {
		return ([
			`${API_ENDPOINT}/exchanges
				?branch_id=${encodeURIComponent(branch_id)}
				&branch_name=${encodeURIComponent(branch_name)}
				&report_start_date=${encodeURIComponent(dates[0])}
				&report_end_date=${encodeURIComponent(dates[1])}
				&is_report=true
				&mode=export
			`,
			'export_report'
		]);
	}
	else if(type === 'data_branch') {
		return ([
			`${API_ENDPOINT}/branches?is_report=true`,
			'branch_report'
		]);
	}
	else if(type === 'data_brand') {
		return ([
			`${API_ENDPOINT}/product-attribute-values
				?is_report=true
				&mode=brand
				&attribute_id=1
			`,
			'brand_report'
		]);
	}
	else if(type === 'data_staff') {
		return ([
			`${API_ENDPOINT}/staffs
				?branch_id=${encodeURIComponent(branch_id)}
				&branch_name=${encodeURIComponent(branch_name)}
				&is_report=true
			`,
			'staff_report'
		]);
	}
	else if(type === 'data_product') {
		return ([
			`${API_ENDPOINT}/product-items
				?report_start_date=${encodeURIComponent(dates[0])}
				&report_end_date=${encodeURIComponent(dates[1])}
				&is_report=true
			`,
			'product_report'
		]);
	}
	else if(type === 'data_product_category') {
		return ([
			`${API_ENDPOINT}/product-attribute-values
				?is_report=true
				&mode=category
				&attribute_id=1
			`,
			'product_category_report'
		]);
	}
	else if(type === 'data_promotion') {
		return ([
			`${API_ENDPOINT}/promotions?is_report=true`,
			'promotion_report'
		]);
	}
	else {
		return ([undefined, undefined]);
	}
}

// const clearBrowserCache = async () => {
// 	console.log('clearBrowserCache')
// 	if(caches) {
// 		let _keys = await caches.keys();
// 		console.log('_keys', _keys)
// 	}
// }

export default excelExport;
