/**
 *	Finance - Model
 */
import PropTypes from 'prop-types';

export const ContainerPropTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export const ContentPropTypes = {
	viewMode: PropTypes.string.isRequired,
	branchList: PropTypes.array.isRequired,

	paymentRatio: PropTypes.array.isRequired,
	ratioSelectedBranch: PropTypes.string.isRequired,
	ratioDateStartFilter: PropTypes.any.isRequired,
	ratioDateEndFilter: PropTypes.any.isRequired,

	tableData: PropTypes.array.isRequired,
	totalData: PropTypes.number.isRequired,
	totalRemaining: PropTypes.number.isRequired,
	listDateStartFilter: PropTypes.any.isRequired,
	listDateEndFilter: PropTypes.any.isRequired,
	listSearchFilter: PropTypes.string.isRequired,
	listSelectedBranch: PropTypes.string.isRequired,
	page: PropTypes.number.isRequired,
	pageSize: PropTypes.number.isRequired,
	totalPage: PropTypes.number.isRequired,
	isLoading: PropTypes.bool.isRequired,

	onChangeViewMode: PropTypes.func.isRequired,
	onChangeRatioBranch: PropTypes.func.isRequired,
	onChangeRatioDateRange: PropTypes.func.isRequired,

	onChangeListDateRange: PropTypes.func.isRequired,
	onSubmitListSearchFilter: PropTypes.func.isRequired,
	onChangePage: PropTypes.func.isRequired,
	onChangePageSize: PropTypes.func.isRequired,
	onChangeListBranch: PropTypes.func.isRequired,

	globalReducer: PropTypes.object.isRequired
}

export const Actions = {
	SET_VIEW_MODE: 'SET_VIEW_MODE',
	SET_BRANCH_LIST: 'SET_BRANCH_LIST',

	SET_PAYMENT_RATIO: 'SET_PAYMENT_RATIO',
	SET_RATIO_SELECTED_BRANCH: 'SET_RATIO_SELECTED_BRANCH',
	SET_RATIO_DATE_START_FILTER: 'SET_RATIO_DATE_START_FILTER',
	SET_RATIO_DATE_END_FILTER: 'SET_RATIO_DATE_END_FILTER',

	SET_TABLE_DATA: 'SET_TABLE_DATA',
	SET_TOTAL_DATA: 'SET_TOTAL_DATA',
	SET_TOTAL_REMAINING: 'SET_TOTAL_REMAINING',
	SET_LIST_DATE_START_FILTER: 'SET_LIST_DATE_START_FILTER',
	SET_LIST_DATE_END_FILTER: 'SET_LIST_DATE_END_FILTER',
	SET_LIST_SEARCH_FILTER: 'SET_LIST_SEARCH_FILTER',
	SET_LIST_SELECTED_BRANCH: 'SET_LIST_SELECTED_BRANCH',
	SET_PAGE: 'SET_PAGE',
	SET_PAGE_SIZE: 'SET_PAGE_SIZE',
	SET_TOTAL_PAGE: 'SET_TOTAL_PAGE',
	SET_IS_LOADING: 'SET_IS_LOADING',

	RESET: 'RESET'
}
