/**
 *	Export Inventory component - Export Footer
 */

import React from 'react';
import {
	AutoComplete,
	Input,
	InputNumber,
	Tooltip,
	Switch
} from 'antd';
import PropTypes from 'prop-types';

const ExportFooter = (props) => {
	const [ isTypingMode, setIsTypingMode ] = React.useState(false);

	React.useEffect(() => {
		//
	}, []);

	return (
		<tr>
			<td
				className={'custom-iwear-global-table-text-color has-text-centered  custom-iwear-global-table-bottom-left-td'}
				style={{ verticalAlign: 'middle' }}
			>
				{ props.seq }
			</td>
			<td
				className={'custom-iwear-global-table-text-color has-text-centered '}
				style={{ verticalAlign: 'middle' }}
			>
				{
					(!isTypingMode)
					? (
						<Input
							autoFocus
							style={{ width: '450px' }}
							value={ props.qrValue }
							placeholder={'สแกน QR ที่นี่'}
							onChange={(evt) => { props.onChangeQR(evt.target.value); }}
							onPressEnter={(evt) => {
								props.onKeyEnterQR(props.qrValue);
							}}
						/>
					)
					: (
						<AutoComplete
							autoFocus
							style={{ width: '450px' }}
							placeholder={'พิมพ์รหัสสินค้าที่นี่'}
							value={ props.qrValue }
							dataSource={[ ...props.qrSuggestion ]}
							onChange={ props.onChangeQR }
							onSelect={ props.onSelectQR }
						/>
					)
				}
				&nbsp;
				<Tooltip title={'ใส่ข้อมูลด้วยการพิมพ์'}>
					<Switch
						checkedChildren={<i className={'far fa-edit'}></i>}
						unCheckedChildren={<i className={'fas fa-qrcode'}></i>}
						onChange={(value) => {
							setIsTypingMode(value);
						}}
					/>
				</Tooltip>
			</td>
			<td
				className={'custom-iwear-global-table-text-color has-text-centered '}
				style={{ verticalAlign: 'middle' }}
			>
				{/*<IWearDropdown
					isFull isFilterable
					displayValue={ props.productName }
					choices={[ ..._arr ]}
					onSelect={(selectedVal) => {
						props.setProductName(selectedVal);
						props.setProductPrice(props.productData[props.productData.findIndex((_f) => { return _f.name === selectedVal; })].price);
					}}
				/>*/}
				<AutoComplete
					style={{ width: '100%' }}
					placeholder={'เลือกสินค้า'}
					value={ props.productValue }
					dataSource={[ ...props.productSuggestion ]}
					onChange={ props.onChangeProduct }
					onSelect={ props.onSelectProduct }
				/>
			</td>
			<td
				className={'custom-iwear-global-table-text-color has-text-centered '}
				style={{ verticalAlign: 'middle' }}
			>
				<InputNumber
					value={ props.quantityValue }
					min={1}
					onChange={(val) => {
						if(!Number.isNaN(Number.parseInt(val, 10))) {
							props.onChangeQuantity(Number.parseInt(val, 10));
						}
					}}
				/>
			</td>
			{/*<td
				className={'has-text-centered  custom-iwear-global-table-bottom-right-td'}
				style={{ verticalAlign: 'middle' }}
			>
				<button
					className={'button '}
					onClick={ props.onAddData }
				>
					<span
						className={'custom-iwear-global-table-text-color'}
						style={{ textDecorationLine: 'underline' }}
					>
						เพิ่ม
					</span>
				</button>\
			</td>*/}
		</tr>
	);
}

ExportFooter.propsTypes = {
	seq: PropTypes.number.isRequired,

	qrValue: PropTypes.string.isRequired,
	qrSuggestion: PropTypes.array.isRequired,
	onChangeQR: PropTypes.func.isRequired,
	onKeyEnterQR: PropTypes.func.isRequired,

	productValue: PropTypes.string.isRequired,
	productSuggestion: PropTypes.array.isRequired,
	onChangeProduct: PropTypes.func.isRequired,
	onKeyEnterProduct: PropTypes.func.isRequired,

	quantityValue: PropTypes.number.isRequired,
	onChangeQuantity: PropTypes.func.isRequired,

	priceValue: PropTypes.number.isRequired,
	onChangePrice: PropTypes.func.isRequired,

	onAddData: PropTypes.func,
}

export default ExportFooter;
