/**
 *	Order List - Content
 */

import React from 'react';
import NumberFormat from 'react-number-format';
import XDate from 'xdate';
import {
	Row,
	Col,
	Button,
	Dropdown,
	Menu,
	Modal,
	Input,
	Pagination,
	Spin,
	Empty,
	Tooltip
} from 'antd';
import ReactToPrint from 'react-to-print';

import Breadcrumb from '../../../../components/breadcrumb';
import IWearDropdown from '../../../../components/dropdown';
// import ExcelButton from '../../../../components/excel-button';
import OrderPickUpModal from '../../../../components/order-pick-up-modal';
import ToPDF from '../../../../components/to-pdf';
import { phoneMaskText } from '../../../../functions';
import RemainingPaymentModal from '../components/ramaining-payment-modal';
import { ContentPropTypes } from './order-list.model';

const Content = (props) => {
	return (
		<React.Fragment>
			<div className={'container custom-iwear-global-container-padding'}>
				<Breadcrumb paths={[ 'การสั่งซื้อ', 'รายการ' ]} />
				{ renderContent(props) }
			</div>
			<Modal
				title={'เหตุผลที่ยกเลิก'}
				visible={ props.cancelModalVisible }
				onOk={ props.cancelModalOnClickOK }
				onCancel={ props.cancelModalOnClickCancel }
			>
				<IWearDropdown
					isFull
					displayValue={ props.cancelType }
					choices={[ 'คืนสินค้า', 'อื่นๆ' ]}
					onSelect={ props.onChangeCancelType }
				/>
				{
					(props.cancelType === 'อื่นๆ') && (
						<React.Fragment>
							<br/>
							<Input.TextArea
								autosize={{ minRows: 3 }}
								placeholder={'เหตุผล'}
								onChange={ props.cancelModalOnChangeCancelReason }
							/>
						</React.Fragment>
					)
				}
			</Modal>
		</React.Fragment>
	);
}

const renderContent = (props) => {
	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
			<Row>
				<Col span={24}>
					{ renderOptions(props) }
				</Col>
			</Row>
			<Row>
				<Col span={24}>
					{ renderTable(props) }
					<RemainingPaymentModal
						fullPrice={ props.modalFullPrice }
						payment={ props.modalPayment }
						orderCode={ props.modalOrderCode }
						note={ props.modalRemark }
						isVisible={ props.payModalVisible }
						onClickPaid={ props.modalOnClickPaid }
						onClickClose={ props.onClosePayModal }
					/>
				</Col>
			</Row>
			<Row type={'flex'} align={'middle'} justify={'end'} style={{ paddingBottom: '10px' }}>
				<Col>
					<Pagination
						className={'custom-iwear-global-table-pagination-container is-pulled-right'}
						current={ props.page || 1 }
						pageSize={ props.pageSize || 10 }
						total={ props.totalPage }
						onChange={ props.onChangePage }
					/>
				</Col>
			</Row>
			<OrderPickUpModal
				orderInfo={ props.orderInfo }
				isVisible={ props.pickupModalVisible }
				onCloseModal={ props.onClosePickupModal }
				onSubmitModal={ props.onTickPickUpStatus }
			/>
		</div>
	)
}

const renderDropdownMenu = (props, data, idx) => {
	return (
		<Menu>
			{
				(data.status === 'pending') && (
					<Menu.Item key={0}>
						{/* eslint-disable-next-line */}
						<a
							onClick={() => {
								console.log('onClickRemainingPayment', data)
								let _pay = 0;
								data.payment_info.forEach((_e) => { _pay +=  (_e.amount); });
								props.onClickRemainingPayment(
									data.order_id,
									data.price - data.discount + data.vat,
									_pay,
									data.order_code,
									data.note,
									true
								);
							}}
						>
							ชำระเงินที่เหลือ
						</a>
					</Menu.Item>
				)
			}
			{
				(data.pick_up_status === 0) && (
					<Menu.Item key={2}>
						{/* eslint-disable-next-line */}
						<a
							onClick={() => {
								props.onClickPickup(data);
							}}
						>
							ทำการรับสินค้า
						</a>
					</Menu.Item>
				)
			}
			{
				(data.pick_up_status === 1) && (
					<Menu.Item key={3}>
						{/* eslint-disable-next-line */}
						<a
							onClick={() => {
								props.onClickPickup(data);
							}}
						>
							เปลี่ยนวันรับสินค้า
						</a>
					</Menu.Item>
				)
			}
			{
				(data.pick_up_status === 1) && (
					<Menu.Item key={4}>
						{/* eslint-disable-next-line */}
						<a
							onClick={() => {
								props.onCancelPickup(data);
							}}
						>
							ยกเลิกการรับสินค้า
						</a>
					</Menu.Item>
				)
			}
			<Menu.Item key={5}>
				{/* eslint-disable-next-line */}
				<a
					onClick={() => {
						props.onClickCancelOrder(data);
					}}
				>
					ยกเลิกรายการ
				</a>
			</Menu.Item>
		</Menu>
	);
}

const renderDropdownToCreateMode = (props) => {
	return (
		<Menu>
			<Menu.Item key={0}>
				{/* eslint-disable-next-line */}
				<a onClick={() => { props.onClickCreate(true); }}>
					ลูกค้าใหม่
				</a>
			</Menu.Item>
			<Menu.Item key={1}>
				{/* eslint-disable-next-line */}
				<a onClick={() => { props.onClickCreate(false); }}>
					ลูกค้าเก่า
				</a>
			</Menu.Item>
		</Menu>
	);
}

const renderOptions = (props) => {
	return (
		<div className={'custom-iwear-global-table-option-panel'}>
			<Row gutter={16} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={12}>
					<span className={'custom-iwear-global-font-color has-text-weight-bold is-size-5'}>
						ผลลัพธ์ข้อมูล&nbsp;
						<NumberFormat
							thousandSeparator
							displayType={'text'}
							value={ props.totalData }
						/>
						&nbsp;ข้อมูล
					</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						ค้นหา
					</span>&nbsp;&nbsp;&nbsp;
					<Input.Search
						enterButton
						placeholder={'Search'}
						allowClear={true}
						style={{ maxWidth: '70%' }}
						onSearch={ props.onSearch }
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row gutter={16} type={'flex'} align={'middle'} justify={'center'}>
				<Col span={12}>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						แสดง
					</span>&nbsp;&nbsp;&nbsp;
					<IWearDropdown
						styleJSX={{ minWidth: '80px', paddingRight: '20px' }}
						displayValue={ String(props.pageSize) }
						choices={[ 10, 20, 50, 100 ]}
						onSelect={ props.onChangePageSize }
					/>
				</Col>
				<Col span={12}>
					<Row gutter={8} type={'flex'} align={'middle'} justify={'end'}>
						<Col>
							<Dropdown overlay={() => renderDropdownToCreateMode(props)} trigger={['click']}>
								<Button>
									<i className={'fas fa-plus custom-iwear-global-font-color'}></i>&nbsp;
									<span className={'custom-iwear-global-font-color'}>เพิ่มรายการสั่งซื้อ</span>
								</Button>
							</Dropdown>
						</Col>
						{/* <Col>
							<ExcelButton
								modalTitle={'เลือกช่วงของวันที่สั่งซื้อ'}
								exportType={'order'}
								dispatcher={ props.dispatcher }
								isLoading={ props.isExporting }
							/>
						</Col> */}
					</Row>
				</Col>
			</Row>
		</div>
	);
}

const renderTable = (props) => {
	return (
		<div className={'custom-iwear-global-table-container'}>
			<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-left-th'}>
							ลำดับ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							เลขที่สั่งซื้อ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ชื่อ-นามสกุลลูกค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							โทร
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							วันที่นัดหมาย
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ยอดเงินที่จ่ายแล้ว/<br/>ยอดเงินคงค้าง
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							สถานะของการสั่งซื้อ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							การรับสินค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							สถานะของการจัดส่ง
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-right-th'}>
							เพิ่มเติม
						</th>
					</tr>
				</thead>
				<tbody>
					{ renderTableData(props) }
				</tbody>
			</table>
		</div>
	);
}

const renderTableData = (props) => {
	let _payment = 0;

	if(props.isLoading) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* กำลังโหลด... */}
					<Spin />
				</td>
			</tr>
		);
	}
	else if(!props.isLoading && props.tableData.length === 0) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* ไม่มีข้อมูล */}
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>ไม่มีข้อมูล</span>} />
				</td>
			</tr>
		);
	}

	return props.tableData.map((_e, _i) => {
		let exportRef = null;
		_payment = 0;
		_e.payment_info.forEach((_p) => { _payment += _p.amount; });
		_e.total_payment = _payment;

		return (
			<tr key={_i} className={'custom-iwear-global-hover-cursor-pointer'}>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{/* ลำดับ */}
					{ _i + 1 + ((props.page - 1) * props.pageSize) }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{/* เลขที่สั่งซื้อ */}
					{ _e.order_code }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{/* ชื่อ-นามสกุลลูกค้า */}
					{ _e.customer_info.full_name }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{/* โทร */}
					{ phoneMaskText(_e.customer_info.phone_number) }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{/* วันที่นัดหมาย */}
					{ XDate(new Date(_e.receive_date)).toString('dd/MM/yyyy') }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{/* ยอดเงินที่จ่ายแล้ว / ยอดเงินคงค้าง */}
					<NumberFormat thousandSeparator displayType={'text'} value={ Number.parseFloat(_payment.toFixed(2)) } />
					&nbsp;/&nbsp;
          <NumberFormat thousandSeparator
            displayType={'text'}
            value={ (_e.price - _e.discount + _e.vat - _payment >= 0)
              ? Number.parseFloat((_e.price - _e.discount + _e.vat - _payment).toFixed(2))
              : 0
            }
          />
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{/* สถานะของการสั่งซื้อ */}
					{ itemStatusResolver(_e.status) }
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{/* การรับสินค้า */}
					{
						(_e.pick_up_status === 0) && (
							<Tooltip title={'ยังไม่รับสินค้า'}>
								<i className={'fas fa-times'} style={{ color: '#FF5C5C' }}></i>
							</Tooltip>
						)
					}
					{
						(_e.pick_up_status === 1) && (
							<Tooltip title={'รับสินค้าแล้ว'}>
								<i className={'fas fa-check'} style={{ color: '#66FF00' }}></i>
							</Tooltip>
						)
					}
				</td>
				<td
					onClick={() => { props.onClickViewDetail(_e.order_id, _e.customer_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{/* สถานะของการจัดส่ง */}
					{
						(_e.status !== 'cancelled')
						? preparationStatusResolver(_e.rxmall_status)
						: '-'
					}
				</td>
				<td
					className={'has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{/* เพิ่มเติม */}
					<Row gutter={8}>
						{
							(_e.status !== 'cancelled') && (
								<Col span={12}>
									<Dropdown overlay={() => renderDropdownMenu(props, _e, _i)} trigger={['click']}>
										<Button>
											<i className={'fas fa-ellipsis-v custom-iwear-global-table-text-color'}></i>
										</Button>
									</Dropdown>
								</Col>
							)
						}
						{
							(_e.status !== 'cancelled') && (
								<Col span={12}>
									<ReactToPrint
										trigger={() =>
											<Button loading={ props.isPrinting }>
												<i className={'far fa-file-pdf is-size-5 custom-iwear-global-font-color'}/>
											</Button>
										}
										content={() => exportRef}
										onBeforeGetContent={() => { return props.beforePrintHandler(_e.order_id, _e.customer_info.full_name, _e.customer_info.phone_number); }}
										removeAfterPrint={ true }
									/>
								</Col>
							)
						}
						<div style={{ display: 'none', width: '100%', height: '100%' }}>
							<ToPDF.Order ref={el => (exportRef = el)} data={ props.printData } />
						</div>
					</Row>
				</td>
			</tr>
		);
	});
}

const itemStatusResolver = (status) => {
	if(status === 'cancelled') {
		return 'ยกเลิกแล้ว';
	}
	else if(status === 'paid') {
		return 'ชำระเงินแล้ว';
	}
	else {
		return 'มัดจำแล้ว';
	}
}

const preparationStatusResolver = (status) => {
	if(status === 'IN_PROGRESS') { return 'กำลังดำเนินการ'; }
	else if(status === 'SHIPPING') { return 'กำลังส่งสินค้า'; }
	else if(status === 'DONE') { return 'ส่งสินค้าเรียนร้อยแล้ว'; }
	else if(status === 'REVISE') { return 'กำลังแก้ไข'; }
	else if(status === 'RETURNING') { return 'กำลังส่งกลับไปแก้ไข'; }
	else { /* ORDERED */ return 'สั่งซื้อแล้ว'; }
}

Content.propTypes = ContentPropTypes;

export default Content;
