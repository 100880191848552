/**
 *	Stock Export List - Container
 */

import React from 'react';
import { Layout } from 'antd';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../environments';
import GlobalStore from '../../../../store';

import LocalStore from './stock-import-list.store';
import StockImportList from './stock-import-list.content';
import { ContainerPropTypes, Actions } from './stock-import-list.model';

const Container = (props) => {
	const StockImportReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData({ page: StockImportReducer.state.page, pageSize: StockImportReducer.state.pageSize, searchKey: StockImportReducer.state.searchKey, branchName: StockImportReducer.state.branchName }, StockImportReducer.dispatch, state.auth.token);
	}, [ StockImportReducer.state.page, StockImportReducer.state.pageSize, StockImportReducer.state.searchKey, StockImportReducer.state.branchName, StockImportReducer.dispatch, state.auth.token ]);

	const fetchData = async (params, localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		// let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id
		// dest_branch_id=${branchID}

		let res = await fetch(`${API_ENDPOINT}/exchanges?export_code=${encodeURIComponent(params.searchKey)}&limit=${params.pageSize}&offset=${(params.page - 1) * params.pageSize}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json = await res.json();
		localDispatch({ type: Actions.SET_TABLE_DATA, value: [ ...json.list ] });
		localDispatch({ type: Actions.SET_TOTAL_DATA, value: json.count || json.list.length });
		localDispatch({ type: Actions.SET_TOTAL_PAGE, value: json.total_pages * params.pageSize });

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onChangePage = (page, pageSize) => {
		StockImportReducer.dispatch({ type: Actions.SET_PAGE, value: page });
	}

	const onChangePageSize = (selectedVal) => {
		StockImportReducer.dispatch({ type: Actions.SET_PAGE, value: 1 });
		StockImportReducer.dispatch({ type: Actions.SET_PAGE_SIZE, value: Number.parseInt(selectedVal, 10) });
	}

	const onChangeSearchKey = (value) => {
		StockImportReducer.dispatch({ type: Actions.SET_PAGE, value: 1 });
		StockImportReducer.dispatch({ type: Actions.SET_SEARCH_KEY, value: value });
	}

	const onClickCreate = (type) => {
		if(type === 'scan') {
			props.history.push('/admin/stock/import/bill');
		}
		else if(type === 'manual') {
			props.history.push('/admin/stock/import/manual');
		}
		else if(type === 'file') {
			// props.history.push('/admin/stock/import/upload');
		}
	}

	const onClickViewDetail = (exchange_code) => {
		props.history.push('/admin/stock/import/detail', { exchange_code });
	}

	const onClickFileExport = async () => {
		//
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/stock/import'} history={ props.history } />
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/stock/import'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<StockImportList
						{ ...StockImportReducer.state }
						dispatcher={ StockImportReducer.dispatch }

						onChangePage={ onChangePage }
						onChangePageSize={ onChangePageSize }
						onChangeSearchKey={ onChangeSearchKey }

						onClickCreate={ onClickCreate }
						onClickViewDetail={ onClickViewDetail }
						onClickFileExport={ onClickFileExport }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
