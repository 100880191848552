/**
 *	Order Add New - State Management
 */

import React from 'react';
import { Actions } from './order-add-new.model';

const initState = {
	createdOrderID: 0,
	tel: '',
	name: '',
	age: 0,
	gender: '',
	birthdate: '',
	customerChannel: [],
	addrZone: '',
	province: { index: -1, text: '' },
	extInfo: '',
	suggestionDataProductCode: [],
	suggestionDataProductName: [],
	tableData: [],
	productData: [],
	productCode: '',
	productName: '',
	productAmount: 1,
	productPrice: 0,
  claimModalVisible: false,
  includeVat: false,
  vatValue: 0,
	reqAssemble: false,
	oldMeasureData: [],
	newMeasureData: [],
	inspector: { id: -1, name: '' },
	inspectorData: [],
	measureModalVisible: false,
	promoData: [],
	promoType: '',
	promoValue: 0,
	pastDate: '',
	isPastOrder: false,
	pickUpDate: '',
	isPickUp: false,
	priceSum: 0,
	deliverDate: '',
	paymentType: '',
	remark: '',
	payment: 0,
	moneyReceive: 0,
	editIdx: -1,
	isEditing: false,
	isLoading: false,
	isSubmitting: false
}

const reducer = (state, action) => {
	switch(action.type) {
		case Actions.SET_CREATED_ORDER_ID: return ({ ...state, createdOrderID: action.value })
		case Actions.SET_TEL: return ({ ...state, tel: action.value })
		case Actions.SET_NAME: return ({ ...state, name: action.value })
		case Actions.SET_AGE: return ({ ...state, age: action.value })
		case Actions.SET_GENDER: return ({ ...state, gender: action.value })
		case Actions.SET_BIRTHDATE: return ({ ...state, birthdate: action.value })
		case Actions.SET_CUSTOMER_CHANNEL: return ({ ...state, customerChannel: action.value })
		case Actions.SET_ADDR_ZONE: return ({ ...state, addrZone: action.value })
		case Actions.SET_PROVINCE: return ({ ...state, province: action.value })
		case Actions.SET_EXT_INFO: return ({ ...state, extInfo: action.value })
		case Actions.SET_PRODUCT_CODE_SUGGESTION: return ({ ...state, suggestionDataProductCode: action.value })
		case Actions.SET_PRODUCT_NAME_SUGGESTION: return ({ ...state, suggestionDataProductName: action.value })
		case Actions.SET_TABLE_DATA: return ({ ...state, tableData: action.value })
		case Actions.SET_PRODUCT_DATA: return ({ ...state, productData: action.value })
		case Actions.SET_PRODUCT_CODE: return ({ ...state, productCode: action.value })
		case Actions.SET_PRODUCT_NAME: return ({ ...state, productName: action.value })
		case Actions.SET_PRODUCT_AMOUNT: return ({ ...state, productAmount: action.value })
		case Actions.SET_PRODUCT_PRICE: return ({ ...state, productPrice: action.value })
    case Actions.SET_CLAIM_MODAL_VISIBLE: return ({ ...state, claimModalVisible: action.value })
    case Actions.SET_INCLUDE_VAT: return ({ ...state, includeVat: action.value })
    case Actions.SET_VAT_VALUE: return ({ ...state, vatValue: action.value })
		case Actions.SET_REQ_ASSEMBLE: return ({ ...state, reqAssemble: action.value })
		case Actions.SET_OLD_MEASURE_DATA: return ({ ...state, oldMeasureData: action.value })
		case Actions.SET_NEW_MEASURE_DATA: return ({ ...state, newMeasureData: action.value })
		case Actions.SET_INSPECTOR: return ({ ...state, inspector: action.value })
		case Actions.SET_INSPECTOR_DATA: return ({ ...state, inspectorData: action.value })
		case Actions.SET_MEASURE_MODAL_VISIBLE: return ({ ...state, measureModalVisible: action.value })
		case Actions.SET_PROMO_DATA: return ({ ...state, promoData: action.value })
		case Actions.SET_PROMO_TYPE: return ({ ...state, promoType: action.value })
		case Actions.SET_PROMO_VALUE: return ({ ...state, promoValue: action.value })
		case Actions.SET_PAST_DATE: return ({ ...state, pastDate: action.value })
		case Actions.SET_IS_PAST_ORDER: return ({ ...state, isPastOrder: action.value })
		case Actions.SET_PICK_UP_DATE: return ({ ...state, pickUpDate: action.value })
		case Actions.SET_IS_PICK_UP: return ({ ...state, isPickUp: action.value })
		case Actions.SET_PRICE_SUM: return ({ ...state, priceSum: action.value })
		case Actions.SET_DELIVER_DATE: return ({ ...state, deliverDate: action.value })
		case Actions.SET_PAYMENT_TYPE: return ({ ...state, paymentType: action.value })
		case Actions.SET_REMARK: return ({ ...state, remark: action.value })
		case Actions.SET_PAYMENT: return ({ ...state, payment: action.value })
		case Actions.SET_MONEY_RECEIVE: return ({ ...state, moneyReceive: action.value })
		case Actions.SET_EDIT_IDX: return ({ ...state, editIdx: action.value })
		case Actions.SET_IS_EDITING: return ({ ...state, isEditing: action.value })
		case Actions.SET_IS_LOADING: return ({ ...state, isLoading: action.value })
		case Actions.SET_IS_SUBMITTING: return ({ ...state, isSubmitting: action.value })
		default:
			return { ...state }
	}
}

const OrderCreateReducer = () => {
	const [ state, dispatch ] = React.useReducer(reducer, initState);

	React.useEffect(() => {}, []);

	return {
		state,
		dispatch
	}
}

export default OrderCreateReducer;
