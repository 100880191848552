/**
 *	Finance - Content
 */

import React from 'react';
import {
	Row,
	Col,
	Input,
	Radio,
	DatePicker,
	Spin,
	Empty,
	Pagination
} from 'antd';
import NumberFormat from 'react-number-format';
import moment from 'moment';

import Breadcrumb from '../../../components/breadcrumb';
import IWearDropdown from '../../../components/dropdown';
import { phoneMaskText } from '../../../functions';

import PaymentChannelChart from './components/payment-channel-chart';

import { ContentPropTypes } from './finance.model';
import styles from './finance.module.css';

const Content = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'การเงิน', 'ยอดคงค้าง' ]}/>
			{ renderContent(props) }
		</div>
	);
}

const renderContent = (props) => {
	return (
		<React.Fragment>
			<div style={{ marginTop: '40px' }}></div>
  			<Row>
				<Col>
					{ renderOptions(props) }
				</Col>
			</Row>
			<div style={{ margin: '20px' }}></div>
  			<Row>
				<Col>
					{ (props.viewMode === 'list') && renderList(props) }
					{ (props.viewMode === 'graph') && renderGraph(props) }
				</Col>
			</Row>
		</React.Fragment>
	);
}

const renderOptions = (props) => {
	return (
		<Row gutter={24} type={'flex'} align={'top'}>
			<Col span={6}>
				<Radio.Group
					buttonStyle={'solid'}
					value={ props.viewMode }
					onChange={ props.onChangeViewMode }
				>
					<Radio.Button value={'list'}>ยอดคงค้าง</Radio.Button>
					<Radio.Button value={'graph'}>อัตราส่วนวิธีการชำระเงิน</Radio.Button>
				</Radio.Group>
			</Col>
			<Col span={18}>
				{ (props.viewMode === 'list') && (renderListOption(props)) }
				{ (props.viewMode === 'graph') && (renderGraphOption(props)) }
			</Col>
		</Row>
	);
}

const renderListOption = (props) => {
	return (
		<Row gutter={24} type={'flex'} align={'top'}>
			<Col span={14}>
				<span className={'custom-iwear-global-font-color is-size-5'}>
					เลือกวันที่
				</span>&nbsp;
				<DatePicker.RangePicker
					allowClear={false}
					format={[ 'DD/MM/YYYY' ]}
					onChange={ props.onChangeListDateRange }
				/>
			</Col>
			<Col span={10}>
				<div className={ styles.ramainingContainer }>
					<Row>
						<Col span={24}>
							<span className={'is-size-3'}>
								<NumberFormat
									thousandSeparator
									displayType={'text'}
									value={ props.totalRemaining || 30000 }
								/>&nbsp;บาท
							</span>
						</Col>
						<Col span={24}>
							<span className={'is-size-5'}>
								ยอดคงค้าง
							</span>
						</Col>
					</Row>
				</div>
			</Col>
		</Row>
	);
}

const renderGraphOption = (props) => {
	let _branches = [];
	props.branchList.forEach((_e) => {
		_branches.push(_e.branch_name);
	});

	return (
		<Row gutter={24} type={'flex'} align={'middle'} justify={'end'}>
			<Col>
				<span className={'custom-iwear-global-font-color is-size-5'}>
					สาขา
				</span>&nbsp;
				<IWearDropdown
					styleJSX={{ minWidth: '100px', paddingRight: '25px' }}
					displayValue={ props.ratioSelectedBranch || 'ทั้งหมด' }
					choices={[ 'ทั้งหมด', ..._branches ]}
					onSelect={ props.onChangeRatioBranch }
				/>
			</Col>
			<Col>
				<span className={'custom-iwear-global-font-color is-size-5'}>
					เลือกวันที่
				</span>&nbsp;
				<DatePicker.RangePicker
					allowClear={false}
					format={[ 'DD/MM/YYYY' ]}
					onChange={ props.onChangeRatioDateRange }
				/>
			</Col>
		</Row>
	);
}

const renderList = (props) => {
	let _branches = [];
	props.branchList.forEach((_e) => {
		_branches.push(_e.branch_name);
	});

	return (
		<div className={ styles.listModeContainer }>
			<Row type={'flex'} align={'middle'} justify={'space-between'}>
				<Col>
					<span className={'custom-iwear-global-font-color has-text-weight-bold is-size-5'}>
						ผลลัพธ์ข้อมูล&nbsp;
						<NumberFormat
							thousandSeparator
							displayType={'text'}
							value={ props.totalData }
						/>
						&nbsp;ข้อมูล
					</span>
				</Col>
				<Col style={{ textAlign: 'right' }}>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						ค้นหา
					</span>&nbsp;
					<Input.Search
						enterButton
						placeholder={'Search'}
						allowClear={true}
						style={{ maxWidth: '70%' }}
						onSearch={ props.onSubmitListSearchFilter }
					/>
				</Col>
			</Row>
			<div style={{ margin: '5px' }}></div>
			<Row type={'flex'} align={'middle'} justify={'space-between'}>
				<Col>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						แสดง
					</span>&nbsp;&nbsp;&nbsp;
					<IWearDropdown
						styleJSX={{ minWidth: '80px', paddingRight: '20px' }}
						displayValue={ String(props.pageSize) }
						choices={[ 10, 20, 50, 100 ]}
						onSelect={ props.onChangePageSize }
					/>
				</Col>
				<Col>
					<span className={'custom-iwear-global-font-color is-size-5'}>
						สาขา
					</span>&nbsp;
					<IWearDropdown
						styleJSX={{ minWidth: '100px', paddingRight: '25px' }}
						displayValue={ props.listSelectedBranch || 'ทั้งหมด' }
						choices={[ 'ทั้งหมด', ..._branches ]}
						onSelect={ props.onChangeListBranch }
					/>
				</Col>
			</Row>
			<Row>
				<Col>
					{ renderTable(props) }
				</Col>
			</Row>
			<Row type={'flex'} align={'middle'} justify={'end'}>
				<Col style={{ paddingBottom: '10px' }}>
					<Pagination
						className={'custom-iwear-global-table-pagination-container'}
						current={ props.page || 1 }
						pageSize={ props.pageSize || 10 }
						total={ props.totalPage }
						onChange={ props.onChangePage }
					/>
				</Col>
			</Row>
		</div>
	);
}

const renderTable = (props) => {
	return (
		<div style={{ overflow: 'auto' }}>
			<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head custom-iwear-global-table-top-left-th'}>
							ลำดับที่
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							เลขที่สั่งซื้อ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ชื่อ-นามสกุลลูกค้า
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							โทร
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							วันที่นัดหมาย
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ยอดเงินคงค้าง
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							สถานะของการสั่งซื้อ
						</th>
					</tr>
				</thead>
				<tbody>
					{ renderTableData(props) }
				</tbody>
			</table>
		</div>
	);
}

const renderTableData = (props) => {
	let _payment = 0;

	if(props.isLoading) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					{/* กำลังโหลด... */}
					<Spin />
				</td>
			</tr>
		);
	}
	else if(!props.isLoading && props.tableData.length === 0) {
		return (
			<tr>
				<td colSpan={8} className={'custom-iwear-global-font-color has-text-centered'}>
					<Empty description={<span>กรุณาเลือกสาขา</span>} image={ Empty.PRESENTED_IMAGE_SIMPLE } />
				</td>
			</tr>
		);
	}

	return props.tableData.map((_e, _i) => {
		_payment = 0;
		_e.payment_info.forEach((_p) => { _payment += _p.amount; });

		return (
			<tr key={_i} className={'custom-iwear-global-hover-cursor-default'}>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ (_i + 1) + ((props.page - 1) * props.pageSize) }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.order_code }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.customer_info.full_name }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ phoneMaskText(_e.customer_info.phone_number) }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ moment(_e.receive_date).format('DD/MM/YYYY') }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					<NumberFormat thousandSeparator displayType={'text'} value={ (_e.price - _e.discount - _payment >= 0) ? _e.price - _e.discount - _payment : 0 } />
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					{ itemStatusResolver(_e.status) }
				</td>
			</tr>
		);
	});
}

const renderGraph = (props) => {
	return (
		<PaymentChannelChart
			data={ props.paymentRatio }
		/>
	);
}

const itemStatusResolver = (status) => {
	if(status === 'cancelled') {
		return 'ยกเลิกแล้ว';
	}
	else if(status === 'paid') {
		return 'ชำระเงินแล้ว';
	}
	else {
		return 'มัดจำแล้ว';
	}
}

Content.propTypes = ContentPropTypes;

export default Content;