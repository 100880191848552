/**
 *	iWear - Dashboard screen -> Payment Channel Chart component
 */

import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Skeleton } from 'antd';
import PropTypes from 'prop-types';

import styles from '../dashboard.module.css';

const _chartOption = {
	dataLabels: {
		enabled: true,
		formatter: (val) => { return `${Number.parseFloat(val).toFixed(2)} %`; },
		style: {
			fontFamily: 'Prompt'
		},
		background: {
			enabled: false,
			foreColor: '#FFFFFF',
			padding: 4,
			borderRadius: 2,
			borderWidth: 1,
			borderColor: '#000000',
			opacity: 0.9,
			dropShadow: {
				enabled: false,
				top: 1,
				left: 1,
				blur: 0,
				color: '#000',
				opacity: 0.95
			}
		},
		dropShadow: {
			enabled: false,
			top: 1,
			left: 1,
			blur: 0,
			color: '#000',
			opacity: 0.95
		}
	},
	labels: [],
	fill: {
		type: 'solid',
		// colors: ['#0084F8', '#001B36', '#52616B', '#C9D6DF', '#D9DEE2', '#DD2233', '#77E266']
		colors: [ '#FA541C', '#FAAD14', '#2F54EB', '#EB2F96', '#13C2C2' ]
	},
	// colors: [ '#0084F8', '#001B36', '#52616B', '#C9D6DF', '#D9DEE2', '#DD2233', '#77E266' ],
	colors: [ '#FA541C', '#FAAD14', '#2F54EB', '#EB2F96', '#13C2C2' ],
	legend: {
		position: 'right',
		show: true,
		showForSingleSeries: false,
		showForNullSeries: true,
		showForZeroSeries: true,
		horizontalAlign: 'center',
		floating: false,
		fontSize: '18px',
		fontFamily: 'Prompt',
		labels: {
			useSeriesColors: false
		},
		markers: {
			width: 10,
			height: 10,
			strokeWidth: 0,
			radius: 0,
			offsetX: 0,
        	offsetY: 0,
			shape: 'square'
		},
		itemMargin: {
			horizontal: 10,
			vertical: 20
		},
		onItemClick: {
  			toggleDataSeries: true
		},
		onItemHover: {
  			highlightDataSeries: true
		},
	},
	plotOptions: {
		pie: {
			expandOnClick: false,
			donut: {
				size: '70%',
				background: 'transparent',
				labels: {
					show: true,
					name: {
						show: true,
						color: '#000000',
					},
					value: {
						show: true,
						color: '#000000',
					},
					total: {
						show: false,
						label: 'รวม',
						color: '#000000',
					}
				}
			}
		}
	},
	responsive: [
		{
		 	breakpoint: 1400,
		 	options: {
		   		chart: {
					type: 'donut',
		 			width: '100%',
					height: 460
		   		},
				dataLabels: {
					enabled: true
				},
		   		legend: {
					show: true,
			 		position: 'bottom'
		   		},
				plotOptions: {
					pie: {
						expandOnClick: false,
						donut: {
							size: '70%',
							background: 'transparent',
							labels: {
								show: true,
								name: {
									show: true,
									color: '#000000',
								},
								value: {
									show: true,
									color: '#000000',
								},
								total: {
									show: false,
									label: 'รวม',
									color: '#000000',
								}
							}
						}
					}
				}
		 	}
		},
		{
		 	breakpoint: 1200,
		 	options: {
		   		chart: {
					type: 'donut',
		 			width: '100%',
					height: 400
		   		},
				dataLabels: {
					enabled: true
				},
		   		legend: {
					show: false,
			 		position: 'bottom'
		   		},
				plotOptions: {
					pie: {
						expandOnClick: false,
						donut: {
							size: '70%',
							background: 'transparent',
							labels: {
								show: true,
								name: {
									show: true,
									color: '#000000',
								},
								value: {
									show: true,
									color: '#000000',
								},
								total: {
									show: false,
									label: 'รวม',
									color: '#000000',
								}
							}
						}
					}
				}
		 	}
		},
		{
		 	breakpoint: 1025,
		 	options: {
		   		chart: {
					type: 'donut',
		 			width: '100%',
					height: 640
		   		},
				dataLabels: {
					enabled: true
				},
		   		legend: {
					show: true,
			 		position: 'right'
		   		},
				plotOptions: {
					pie: {
						expandOnClick: false,
						donut: {
							size: '70%',
							background: 'transparent',
							labels: {
								show: true,
								name: {
									show: true,
									color: '#000000',
								},
								value: {
									show: true,
									color: '#000000',
								},
								total: {
									show: false,
									label: 'รวม',
									color: '#000000',
								}
							}
						}
					}
				}
		 	}
		},
		{
		 	breakpoint: 930,
		 	options: {
		   		chart: {
					type: 'donut',
		 			width: '100%',
					height: 480
		   		},
				dataLabels: {
					enabled: true
				},
		   		legend: {
					show: true,
			 		position: 'right'
		   		},
				plotOptions: {
					pie: {
						expandOnClick: false,
						donut: {
							size: '70%',
							background: 'transparent',
							labels: {
								show: true,
								name: {
									show: true,
									color: '#000000',
								},
								value: {
									show: true,
									color: '#000000',
								},
								total: {
									show: false,
									label: 'รวม',
									color: '#000000',
								}
							}
						}
					}
				}
		 	}
		},
		{
		 	breakpoint: 765,
		 	options: {
		   		chart: {
					type: 'donut',
		 			width: '100%',
					height: 360
		   		},
				dataLabels: {
					enabled: true
				},
		   		legend: {
					show: true,
			 		position: 'right'
		   		},
				plotOptions: {
					pie: {
						expandOnClick: false,
						donut: {
							size: '70%',
							background: 'transparent',
							labels: {
								show: true,
								name: {
									show: true,
									color: '#000000',
								},
								value: {
									show: true,
									color: '#000000',
								},
								total: {
									show: false,
									label: 'รวม',
									color: '#000000',
								}
							}
						}
					}
				}
		 	}
		},
		{
		 	breakpoint: 650,
		 	options: {
		   		chart: {
					type: 'donut',
		 			width: '100%',
					height: 280
		   		},
				dataLabels: {
					enabled: false
				},
		   		legend: {
					show: true,
			 		position: 'right'
		   		},
				plotOptions: {
					pie: {
						expandOnClick: false,
						donut: {
							size: '70%',
							background: 'transparent',
							labels: {
								show: true,
								name: {
									show: true,
									color: '#000000',
								},
								value: {
									show: true,
									color: '#000000',
								},
								total: {
									show: false,
									label: 'รวม',
									color: '#000000',
								}
							}
						}
					}
				}
		 	}
		},
		{
		 	breakpoint: 550,
		 	options: {
		   		chart: {
					type: 'donut',
		 			width: '100%',
					height: 380
		   		},
				dataLabels: {
					enabled: false
				},
		   		legend: {
					show: false,
			 		position: 'right'
		   		},
				plotOptions: {
					pie: {
						expandOnClick: false,
						donut: {
							size: '70%',
							background: 'transparent',
							labels: {
								show: true,
								name: {
									show: true,
									color: '#000000',
								},
								value: {
									show: true,
									color: '#000000',
								},
								total: {
									show: false,
									label: 'รวม',
									color: '#000000',
								}
							}
						}
					}
				}
		 	}
		},
		{
		 	breakpoint: 450,
		 	options: {
		   		chart: {
					type: 'donut',
		 			width: '100%',
					height: 280
		   		},
				dataLabels: {
					enabled: false
				},
		   		legend: {
					show: false,
			 		position: 'right'
		   		},
				plotOptions: {
					pie: {
						expandOnClick: false,
						donut: {
							size: '70%',
							background: 'transparent',
							labels: {
								show: true,
								name: {
									show: true,
									color: '#000000',
								},
								value: {
									show: true,
									color: '#000000',
								},
								total: {
									show: false,
									label: 'รวม',
									color: '#000000',
								}
							}
						}
					}
				}
		 	}
		}
	],
	chart: {
		toolbar: {
	      	show: false,
	      	tools: {
	        	download: false,
	            selection: false,
	            zoom: false,
	            zoomin: false,
	            zoomout: false,
	            pan: false,
	            reset: false,
	            customIcons: []
	      	}
	    },
	},
}

const DashboardTotalSaleChart = (props) => {
	const [ chartSeries, setChartSeries ] = React.useState([]);
	const [ chartOption, setChartOption ] = React.useState(_chartOption);
	const [ isLoading, setIsLoading ] = React.useState(false);

	React.useEffect(() => {
		init(props.data);
	}, [ props.data ]);

	const init = async (data) => {
		setIsLoading(true);

		if(data !== null) {
			let _series = [];
			let _labels = [];
			data.forEach((_e) => {
				_labels.push(_e.branch_name);
				_series.push(Number.parseInt(_e.income, 10));
			});

			setChartOption({ ..._chartOption, labels: _labels });
			setChartSeries(_series);

			setIsLoading(false);
		}
	}

	return (
		<div className={`${styles.card} ${styles.cardChart}`}>
			<div className={ styles.chartTitleContainer }>
				<span className={`is-size-4 ${styles.chartTitle}`}>
					สรุปยอดขายแต่ละสาขา
				</span>
			</div>
			{
				(isLoading)
				? (<Skeleton  active title={{ width: '100%' }} paragraph={{ rows: 8, width: '100%' }} />)
				: (chartSeries.length > 0)
					? (
						<ReactApexChart
							type={'donut'}
							height={'350'}
							width={'100%'}
							options={ chartOption }
							series={ chartSeries }
						/>
					)
					: (
						<div style={{ display: 'flex', height: '350px', alignItems: 'center', justifyContent: 'center' }}>
							<i className={'fas fa-exclamation has-text-black'}></i>&nbsp;
							<span className={'is-size-5'}>
								ไม่สามารถทำการสรุปยอดขาย
							</span>
							&nbsp;<i className={'fas fa-exclamation has-text-black'}></i>
						</div>
					)
			}
		</div>
	)
}

DashboardTotalSaleChart.propTypes = {
	data: PropTypes.array
}

export default DashboardTotalSaleChart
