/**
 *	iWear - Customer Detail container
 *
 *		@using React Hook
 */

import React from 'react';
import {
	Layout
} from 'antd';
import XDate from 'xdate';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../environments';
import GlobalStore from '../../../../store';
import LocalStore from './customers-detail.store';
import Content from './customers-detail.content';
import { ContainerPropTypes, Actions } from './customers-detail.model';

const Container = (props) => {
	const CustomerDetailReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData(
			props.location.state.branch_id,
			props.location.state.customer_id,
			props.location.state.customer_name,
			props.location.state.customer_phone_number,
			CustomerDetailReducer.dispatch, state.auth.token
		);
	}, [ props.location.state.branch_id, props.location.state.customer_id, props.location.state.customer_name, props.location.state.customer_phone_number, CustomerDetailReducer.dispatch, state.auth.token ]);

	const fetchData = async (branchID, customerID, customerName, customerTel, localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		let res_customer = await fetch(`${API_ENDPOINT}/customer-with-eye?search=${encodeURIComponent(customerTel)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let res_eye = await fetch(`${API_ENDPOINT}/eye-inspector?customer_id=${encodeURIComponent(customerID)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json_eye = await res_eye.json();

		let res_staff = await fetch(`${API_ENDPOINT}/staffs?branch_id=${encodeURIComponent(branchID)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json_staff = await res_staff.json();
		let _inspectors = [];
		json_staff.list.forEach((_e) => { _inspectors.push({ id: _e.staff_info.staff_id, name: _e.staff_info.full_name }); });

		let res_order = await fetch(`${API_ENDPOINT}/orders?branch_id=${encodeURIComponent(branchID)}&offset=0&limit=5&customer_tel=${encodeURIComponent(customerTel)}`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + authToken,
					'Content-Type': 'application/json'
				}
			}
		);

		let json_customer = await res_customer.json();
		let json_order = await res_order.json();

		localDispatch({ type: Actions.SET_LATEST_ORDER_DATA, value: json_order.list });
		localDispatch({ type: Actions.SET_TEL, value: json_customer.list[0].phone_number });
		localDispatch({ type: Actions.SET_NAME, value: json_customer.list[0].full_name });
		localDispatch({ type: Actions.SET_GENDER, value: json_customer.list[0].sex });
		localDispatch({ type: Actions.SET_BIRTHDATE, value: (json_customer.list[0].birth_date) ? XDate(new Date(json_customer.list[0].birth_date)).toString('dd/MM/yyyy') : '' });
		localDispatch({ type: Actions.SET_AGE, value: json_customer.list[0].age });
		localDispatch({ type: Actions.SET_CUSTOMER_CHANNEL, value: (json_customer.list[0].customer_channel.split(',')[0]) ? json_customer.list[0].customer_channel.split(',') : [] });
		localDispatch({ type: Actions.SET_ADDR_ZONE, value: json_customer.list[0].district });
		localDispatch({ type: Actions.SET_PROVINCE, value: json_customer.list[0].province });
		localDispatch({ type: Actions.SET_EXT_INFO, value: json_customer.list[0].description });

		// let _inspectorInfo = [..._inspectors].filter((_filt) => { return _filt.id === json_customer.list[0].eye_info[0].inspector_id });
		// if(_inspectorInfo.length > 0) { setInspector(_inspectorInfo[0].name); }
		if (json_eye.list.length > 0 && json_eye.list[json_eye.list.length - 1] !== undefined) {
			localDispatch({ type: Actions.SET_INSPECTOR, value: json_eye.list[json_eye.list.length - 1].staff_info.full_name });
		} else {
			localDispatch({ type: Actions.SET_INSPECTOR, value: '' });
		}

		if(json_customer.list[0].eye_info.length >= 2) {
			let lastIdx = json_customer.list[0].eye_info.length - 1;

			localDispatch({
				type: Actions.SET_OLD_MEASURE_DATA,
				value: [
					{ type: 'Sphere', leftValue: json_customer.list[0].eye_info[lastIdx - 1].sphere_left, rightValue: json_customer.list[0].eye_info[lastIdx - 1].sphere_right },
					{ type: 'CYL', leftValue: json_customer.list[0].eye_info[lastIdx - 1].cyl_left, rightValue: json_customer.list[0].eye_info[lastIdx - 1].cyl_right },
					{ type: 'AXIS', leftValue: json_customer.list[0].eye_info[lastIdx - 1].axis_left, rightValue: json_customer.list[0].eye_info[lastIdx - 1].axis_right },
					{ type: 'ADD', leftValue: json_customer.list[0].eye_info[lastIdx - 1].add_left, rightValue: json_customer.list[0].eye_info[lastIdx - 1].add_right },
					{ type: 'DPD', leftValue: json_customer.list[0].eye_info[lastIdx - 1].dpd_left, rightValue: json_customer.list[0].eye_info[lastIdx - 1].dpd_right },
					{ type: 'NPD', leftValue: json_customer.list[0].eye_info[lastIdx - 1].npd_left, rightValue: json_customer.list[0].eye_info[lastIdx - 1].npd_right },
					{ type: 'SegHT', leftValue: json_customer.list[0].eye_info[lastIdx - 1].seg_ht_left, rightValue: json_customer.list[0].eye_info[lastIdx - 1].seg_ht_right },
					{ type: 'Prism', leftValue: json_customer.list[0].eye_info[lastIdx - 1].prism_left, rightValue: json_customer.list[0].eye_info[lastIdx - 1].prism_right },
					{ type: 'Mono-VA', leftValue: json_customer.list[0].eye_info[lastIdx - 1].mono_va_left, rightValue: json_customer.list[0].eye_info[lastIdx - 1].mono_va_right },
				]
			});

			localDispatch({
				type: Actions.SET_NEW_MEASURE_DATA,
				value: [
					{ type: 'Sphere', leftValue: json_customer.list[0].eye_info[lastIdx].sphere_left, rightValue: json_customer.list[0].eye_info[lastIdx].sphere_right },
					{ type: 'CYL', leftValue: json_customer.list[0].eye_info[lastIdx].cyl_left, rightValue: json_customer.list[0].eye_info[lastIdx].cyl_right },
					{ type: 'AXIS', leftValue: json_customer.list[0].eye_info[lastIdx].axis_left, rightValue: json_customer.list[0].eye_info[lastIdx].axis_right },
					{ type: 'ADD', leftValue: json_customer.list[0].eye_info[lastIdx].add_left, rightValue: json_customer.list[0].eye_info[lastIdx].add_right },
					{ type: 'DPD', leftValue: json_customer.list[0].eye_info[lastIdx].dpd_left, rightValue: json_customer.list[0].eye_info[lastIdx].dpd_right },
					{ type: 'NPD', leftValue: json_customer.list[0].eye_info[lastIdx].npd_left, rightValue: json_customer.list[0].eye_info[lastIdx].npd_right },
					{ type: 'SegHT', leftValue: json_customer.list[0].eye_info[lastIdx].seg_ht_left, rightValue: json_customer.list[0].eye_info[lastIdx].seg_ht_right },
					{ type: 'Prism', leftValue: json_customer.list[0].eye_info[lastIdx].prism_left, rightValue: json_customer.list[0].eye_info[lastIdx].prism_right },
					{ type: 'Mono-VA', leftValue: json_customer.list[0].eye_info[lastIdx].mono_va_left, rightValue: json_customer.list[0].eye_info[lastIdx].mono_va_right },
				]
			});
		}
		else if(json_customer.list[0].eye_info.length === 1) {
			if(Object.values(json_customer.list[0].eye_info[0]).includes(null)) {
				localDispatch({
					type: Actions.SET_OLD_MEASURE_DATA,
					value: [
						{ type: 'Sphere', leftValue: 0, rightValue: 0 },
						{ type: 'CYL', leftValue: 0, rightValue: 0 },
						{ type: 'ADD', leftValue: 0, rightValue: 0 },
						{ type: 'AXIS', leftValue: 0, rightValue: 0 },
						{ type: 'Mono-VA', leftValue: 0, rightValue: 0 },
						{ type: 'DPD', leftValue: 0, rightValue: 0 },
						{ type: 'NPD', leftValue: 0, rightValue: 0 },
						{ type: 'SegHT', leftValue: 0, rightValue: 0 },
						{ type: 'Prism', leftValue: 0, rightValue: 0 }
					]
				});

				localDispatch({
					type: Actions.SET_NEW_MEASURE_DATA,
					value: [
						{ type: 'Sphere', leftValue: 0, rightValue: 0 },
						{ type: 'CYL', leftValue: 0, rightValue: 0 },
						{ type: 'ADD', leftValue: 0, rightValue: 0 },
						{ type: 'AXIS', leftValue: 0, rightValue: 0 },
						{ type: 'Mono-VA', leftValue: 0, rightValue: 0 },
						{ type: 'DPD', leftValue: 0, rightValue: 0 },
						{ type: 'NPD', leftValue: 0, rightValue: 0 },
						{ type: 'SegHT', leftValue: 0, rightValue: 0 },
						{ type: 'Prism', leftValue: 0, rightValue: 0 }
					]
				});
			}
			else {
				localDispatch({
					type: Actions.SET_OLD_MEASURE_DATA,
					value: [
						{ type: 'Sphere', leftValue: 0, rightValue: 0 },
						{ type: 'CYL', leftValue: 0, rightValue: 0 },
						{ type: 'ADD', leftValue: 0, rightValue: 0 },
						{ type: 'AXIS', leftValue: 0, rightValue: 0 },
						{ type: 'Mono-VA', leftValue: 0, rightValue: 0 },
						{ type: 'DPD', leftValue: 0, rightValue: 0 },
						{ type: 'NPD', leftValue: 0, rightValue: 0 },
						{ type: 'SegHT', leftValue: 0, rightValue: 0 },
						{ type: 'Prism', leftValue: 0, rightValue: 0 }
					]
				});

				localDispatch({
					type: Actions.SET_NEW_MEASURE_DATA,
					value: [
						{ type: 'Sphere', leftValue: json_customer.list[0].eye_info[0].sphere_left, rightValue: json_customer.list[0].eye_info[0].sphere_right },
						{ type: 'CYL', leftValue: json_customer.list[0].eye_info[0].cyl_left, rightValue: json_customer.list[0].eye_info[0].cyl_right },
						{ type: 'ADD', leftValue: json_customer.list[0].eye_info[0].add_left, rightValue: json_customer.list[0].eye_info[0].add_right },
						{ type: 'AXIS', leftValue: json_customer.list[0].eye_info[0].axis_left, rightValue: json_customer.list[0].eye_info[0].axis_right },
						{ type: 'Mono-VA', leftValue: json_customer.list[0].eye_info[0].mono_va_left, rightValue: json_customer.list[0].eye_info[0].mono_va_right },
						{ type: 'DPD', leftValue: json_customer.list[0].eye_info[0].dpd_left, rightValue: json_customer.list[0].eye_info[0].dpd_right },
						{ type: 'NPD', leftValue: json_customer.list[0].eye_info[0].npd_left, rightValue: json_customer.list[0].eye_info[0].npd_right },
						{ type: 'SegHT', leftValue: json_customer.list[0].eye_info[0].seg_ht_left, rightValue: json_customer.list[0].eye_info[0].seg_ht_right },
						{ type: 'Prism', leftValue: json_customer.list[0].eye_info[0].prism_left, rightValue: json_customer.list[0].eye_info[0].prism_right }
					]
				});
			}
		}
		else {
			localDispatch({
				type: Actions.SET_OLD_MEASURE_DATA,
				value: [
					{ type: 'Sphere', leftValue: 0, rightValue: 0 },
					{ type: 'CYL', leftValue: 0, rightValue: 0 },
					{ type: 'ADD', leftValue: 0, rightValue: 0 },
					{ type: 'AXIS', leftValue: 0, rightValue: 0 },
					{ type: 'Mono-VA', leftValue: 0, rightValue: 0 },
					{ type: 'DPD', leftValue: 0, rightValue: 0 },
					{ type: 'NPD', leftValue: 0, rightValue: 0 },
					{ type: 'SegHT', leftValue: 0, rightValue: 0 },
					{ type: 'Prism', leftValue: 0, rightValue: 0 }
				]
			});

			localDispatch({
				type: Actions.SET_NEW_MEASURE_DATA,
				value: [
					{ type: 'Sphere', leftValue: 0, rightValue: 0 },
					{ type: 'CYL', leftValue: 0, rightValue: 0 },
					{ type: 'ADD', leftValue: 0, rightValue: 0 },
					{ type: 'AXIS', leftValue: 0, rightValue: 0 },
					{ type: 'Mono-VA', leftValue: 0, rightValue: 0 },
					{ type: 'DPD', leftValue: 0, rightValue: 0 },
					{ type: 'NPD', leftValue: 0, rightValue: 0 },
					{ type: 'SegHT', leftValue: 0, rightValue: 0 },
					{ type: 'Prism', leftValue: 0, rightValue: 0 }
				]
			});
		}

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onClickGoBack = () => {
		props.history.goBack();
	}

	const onClickViewDetail = (orderID, customerID, fullname, tel) => {
		props.history.push(`/admin/order/detail`, {
			order_id: Number.parseInt(orderID, 10),
			customer_id: customerID,
			fullname: fullname,
			tel
		});
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/customer'} history={ props.history } />
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/customer'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...CustomerDetailReducer.state }

						onClickGoBack={ onClickGoBack }
						onClickViewDetail={ onClickViewDetail }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes

export default Container;
