/**
 *	Dashboard - State Management
 */

import React from 'react';
import moment from 'moment';
import { Actions } from './dashboard.model';

const startMonthDate = moment().startOf('month');
const currentDate = moment();
const startMonthDateString = startMonthDate.format('YYYY-MM-DD');
const currentDateString = currentDate.format('YYYY-MM-DD');

const initState = {
	todayOrder: 0,
	periodOrder: 0,
	framePeriodSale: 0,
	lensPeriodSale: 0,
	todaySale: 0,
	monthSale: 0,
	periodSale: 0,
	totalSale: 0,
	filterDate: {
		value: [startMonthDate, currentDate],
		text: [startMonthDateString, currentDateString]
	},
	isLoading: false
}

const reducer = (state, action) => {
	switch(action.type) {
		case Actions.SET_TODAY_ORDER:
			return {
				...state,
				todayOrder: action.value
			}
		case Actions.SET_PERIOD_ORDER:
			return {
				...state,
				periodOrder: action.value
			}
		case Actions.SET_FRAME_PERIOD_SALE:
			return {
				...state,
				framePeriodSale: action.value
			}
		case Actions.SET_LENS_PERIOD_SALE:
			return {
				...state,
				lensPeriodSale: action.value
			}
		case Actions.SET_TODAY_SALE:
			return {
				...state,
				todaySale: action.value
			}
		case Actions.SET_MONTH_SALE:
			return {
				...state,
				monthSale: action.value
			}
		case Actions.SET_PERIOD_SALE:
			return {
				...state,
				periodSale: action.value
			}
		case Actions.SET_TOTAL_SALE:
			return {
				...state,
				totalSale: action.value
			}
		case Actions.SET_FILTER_DATE:
			return {
				...state,
				filterDate: action.value
			}
		case Actions.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.value
			}
		default:
			return { ...state }
	}
}

const DashboardReducer = () => {
	const [ state, dispatch ] = React.useReducer(reducer, initState);

	React.useEffect(() => {}, []);

	return {
		state,
		dispatch
	}
}

export default DashboardReducer;
