/**
 *	iWear - Eye Measurement Table
 */

import React from 'react';
import { Input, InputNumber } from 'antd';
import PropTypes from 'prop-types';

const NO_EYE_DATA = 0;

const EyeMeasureTableData = (props) => {
	let type = (props.type === 'new') ? 'has-text-centered custom-iwear-customer-create-new-inspect-table-head' : 'has-text-centered custom-iwear-customer-create-old-inspect-table-head'

	return (
		<div className={'custom-iwear-global-table-container'}>
			<table className={'table is-striped is-fullwidth is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={`custom-iwear-global-table-text-color has-text-centered ${type} custom-iwear-global-table-top-left-th`}>
							{ props.tableName }
						</th>
						<th className={`custom-iwear-global-table-text-color has-text-centered ${type}`}>
							R
						</th>
						<th className={`custom-iwear-global-table-text-color has-text-centered ${type} custom-iwear-global-table-top-right-th`}>
							L
						</th>
					</tr>
				</thead>
				<tbody>{ renderData(props) }</tbody>
			</table>
		</div>
	);
}

const inputFormatter = (value) => {
	// Just show, not the actual callback value
	if(value > 0) {
		return `+${value}`;
	}
	else {
		return value;
	}
}

const renderData = (props) => {
	let _inputProps = (props.disabled) ? { className: 'custom-iwear-global-font-color-disabled' } : {};
	let _rightValueProps = { precision: 2 };
	let _leftValueProps = { precision: 2 };

	return props.data.map((_e, _i) => {
		_rightValueProps.formatter = undefined;
		_rightValueProps.value = (props.type === 'new' || props.type === 'old') ? (_e.rightValue === 0) ? NO_EYE_DATA : _e.rightValue : NO_EYE_DATA;
		_rightValueProps.disabled = (props.type === 'old') ? true : props.disabled;
		_rightValueProps.placeholder = (props.type === 'old' && _e.rightValue === 0) ? undefined : '0.00';

		_leftValueProps.formatter = undefined;
		_leftValueProps.value = (props.type === 'new' || props.type === 'old') ? (_e.leftValue === 0) ? NO_EYE_DATA : _e.leftValue : NO_EYE_DATA
		_leftValueProps.disabled = (props.type === 'old') ? true : props.disabled;
		_leftValueProps.placeholder = (props.type === 'old' && _e.leftValue === 0) ? undefined : '0.00';

		if(_i < 2) { /* Sphere | CYL */
			_rightValueProps.step = 0.25; _rightValueProps.min = -2000; _rightValueProps.max = 2000;
			_leftValueProps.step = 0.25; _leftValueProps.min = -2000; _leftValueProps.max = 2000;
			_rightValueProps.formatter = inputFormatter;
			_leftValueProps.formatter = inputFormatter;
		}
		else if(_i === 2) { /* AXIS */
			_rightValueProps.step = 1; _rightValueProps.min = 0; _rightValueProps.max = 180;
			_rightValueProps.disabled = (props.type === 'old')
				? true
				: props.disabled || (props.data[_i - 1].leftValue === 0 && props.data[_i - 1].rightValue === 0);
			_rightValueProps.placeholder = (props.type === 'old' && _e.rightValue === 0) ? undefined : '0.00';

			_leftValueProps.step = 1; _leftValueProps.min = 0; _leftValueProps.max = 180;
			_leftValueProps.disabled = (props.type === 'old')
				? true
				: props.disabled || (props.data[_i - 1].leftValue === 0 && props.data[_i - 1].rightValue === 0);
			_leftValueProps.placeholder = (props.type === 'old' && _e.leftValue === 0) ? undefined : '0.00';
		}
		else if(_i === 3 || _i === 7) { /* ADD | PRISM */
			_rightValueProps.step = 0.25; _rightValueProps.min = 0; _rightValueProps.max = 2000;
			_leftValueProps.step = 0.25; _leftValueProps.min = 0; _leftValueProps.max = 2000;
		}
		else if(_i === 4 || _i === 5 || _i === 6) { /* DPD | NPD | SegHT */
			_rightValueProps.step = 0.5; _rightValueProps.min = 0; _rightValueProps.max = 100;
			_leftValueProps.step = 0.5; _leftValueProps.min = 0; _leftValueProps.max = 100;
		}
		else if(_i === 8) { /* Mono-VA */
			// console.log('_e.rightValue', _e.rightValue);
			// console.log('_e.leftValue', _e.leftValue);
			return (
				<tr key={_i}>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered'}
						style={{ verticalAlign: 'middle' }}
					>
						{ _e.type }
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered'}
						style={{ verticalAlign: 'middle' }}
					>
						<Input
							{ ..._inputProps }
							value={ (props.type === 'new' || props.type === 'old') ? (_e.rightValue) : undefined }
							disabled={ (props.type === 'old') ? true : props.disabled }
							onChange={(evt) => {
								let _arr = [...props.data];
								_arr[_i] = {
									type: _e.type,
									leftValue: `${_e.leftValue}`,
									rightValue: `${evt.target.value}`
								};

								props.setData(_arr); // Raplacing whole array
							}}
						/>
					</td>
					<td
						className={'custom-iwear-global-table-text-color has-text-centered'}
						style={{ verticalAlign: 'middle' }}
					>
						<Input
							{ ..._inputProps }
							value={ (props.type === 'new' || props.type === 'old') ? (_e.leftValue) : undefined }
							disabled={ (props.type === 'old') ? true : props.disabled }
							onChange={(evt) => {
								let _arr = [...props.data];
								_arr[_i] = {
									type: _e.type,
									leftValue: `${evt.target.value}`,
									rightValue: `${_e.rightValue}`
								};

								props.setData(_arr); // Raplacing whole array
							}}
						/>
					</td>
				</tr>
			);
		}

		return (
			<tr key={_i}>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.type }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					<InputNumber
						{ ..._inputProps }
						{ ..._rightValueProps }
						onChange={(val) => {
							if(props.type !== 'old') {
								if(!Number.isNaN(Number.parseFloat(val))) {
									let _arr = [...props.data];
									_arr[_i] = {
										type: _e.type,
										leftValue: _e.leftValue,
										rightValue: Number.parseFloat(val)
									};

									props.setData(_arr); // Raplacing whole array
								}
							}
						}}
					/>
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered '}
					style={{ verticalAlign: 'middle' }}
				>
					<InputNumber
						{ ..._inputProps }
						{ ..._leftValueProps }
						onChange={(val) => {
							if(props.type !== 'old') {
								if(!Number.isNaN(Number.parseFloat(val))) {
									let _arr = [...props.data];
									_arr[_i] = {
										type: _e.type,
										leftValue: Number.parseFloat(val),
										rightValue: _e.rightValue
									};

									props.setData(_arr); // Raplacing whole array
								}
							}
						}}
					/>
				</td>
			</tr>
		);
	});
}


EyeMeasureTableData.defaultProps = {
	disabled: false
}

EyeMeasureTableData.propTypes = {
	type: PropTypes.string.isRequired, // 'new' || 'old'
	tableName: PropTypes.string.isRequired,
	data: PropTypes.array.isRequired,
	setData: PropTypes.func.isRequired,
	disabled: PropTypes.bool
}

export default EyeMeasureTableData;
