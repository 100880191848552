/**
 *	iWear - Data Product Add screen
 */

import React from 'react';
import {
	Row,
	Col,
	Button,
	Input,
	InputNumber
} from 'antd';
import PropTypes from 'prop-types';

import Breadcrumb from '../../../../../components/breadcrumb';
import IWearDropdown from '../../../../../components/dropdown';
import AttributeModal from './components/AttributeModal';

const AddDataProduct = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'การจัดการข้อมูล', 'สินค้า', 'เพิ่ม' ]} withGoBack onClickGoBack={ props.onClickGoBack } />
			{ renderContent(props) }
		</div>
	);
}

const renderContent = (props) => {
	return (
		<div className={'custom-iwear-global-content-under-breadcrumb-container'}>
			<div style={{ paddingLeft: '20px', paddingRight: '20px', paddingBottom: '20px' }}>
				<Row>
					<Col span={24}>
						{ renderOptions(props) }
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						{ renderFields(props) }
						<div style={{ width: '100%', borderTop: '1px solid #DBDBDB', marginBottom: '20px' }}></div>
						<Row type={'flex'} align={'middle'} justify={'space-between'}>
							<Col>
								<span className={'custom-iwear-global-font-color'}>
									* คุณสมบัติ
								</span>
							</Col>
							<Col>
								{
									(props.attrTableData.length === 0) && (
										<Button onClick={() => { props.setAttrModalVisible(true); }}>
											<i className={'fas fa-plus custom-iwear-global-font-color'}></i>&nbsp;
											<span className={'custom-iwear-global-font-color'}>เพิ่ม</span>
										</Button>
									)
								}
								<AttributeModal
									title={'เพิ่มคุณสมบัติ'}
									attr={ props.attrModalName }
									choices={[ ...props.attrModalChoices ]}
									visible={ props.attrModalVisible }
									submitModal={(value) => {
										props.setAttrModalVisible(false);

										if(props.attrModalIdx > -1) {
											let _arr = [ ...props.attrTableData ];
											_arr[props.attrModalIdx] = value;
											props.setAttrTableData([ ..._arr ]);

											props.setAttrModalName('');
											props.setAttrModalChoices([]);
											props.setAttrModalIdx(-1);
										}
										else {
											let _arr = [ ...props.attrTableData ];
											_arr.push(value);
											props.setAttrTableData([ ..._arr ]);

											props.setAttrModalName('');
											props.setAttrModalChoices([]);
											props.setAttrModalIdx(-1);
										}
									}}
									closeModal={() => {
										props.setAttrModalName('');
										props.setAttrModalChoices([]);
										props.setEditAttrIdx(-1);
										props.setAttrModalVisible(false);
									}}
								/>
							</Col>
						</Row>
					</Col>
				</Row>
				<Row>
					<Col span={24}>
						{ renderAttributeTable(props) }
					</Col>
				</Row>
				<Row type={'flex'} align={'middle'} justify={'end'}>
					<Col>
						<Button onClick={ props.onClickSave }>
							<span className={'custom-iwear-global-font-color'}>
								บันทึกข้อมูล
							</span>
						</Button>
					</Col>
				</Row>
			</div>
		</div>
	);
}

const renderOptions = (props) => {
	return (
		<div style={{ paddingTop: '20px', paddingBottom: '20px' }}>
			<Row type={'flex'} align={'middle'} justify={'space-between'}>
				<Col>
					<span
						className={'custom-iwear-global-font-color is-size-5'}
						style={{ textDecorationLine: 'underline' }}
					>
						เพิ่มสินค้า
					</span>
				</Col>
				<Col>
					<Button onClick={ props.onClickSave }>
						<span className={'custom-iwear-global-font-color'}>
							บันทึกข้อมูล
						</span>
					</Button>
				</Col>
			</Row>
		</div>
	);
}

const renderFields = (props) => {
	let _typeArr = []; let _brandArr = []; let _frameArr = []; let _lensArr = [];
	props.productTypeList.forEach((_e) => { _typeArr.push(_e.name) });
	props.frameTypeList.forEach((_e) => { _frameArr.push(_e.name); });
	props.lensTypeList.forEach((_e) => { _lensArr.push(_e.name); });
	props.brandList.forEach((_e) => { _brandArr.push(_e.name); });

	return (
		<React.Fragment>
			<Row>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						* ชื่อสินค้า
					</label>
					<Input
						onChange={(evt) => {
							props.setName(evt.target.value);
						}}
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						* รหัสสินค้า
					</label>
					<Input
						onChange={(evt) => {
							props.setCode(evt.target.value);
						}}
					/>
				</Col>
			</Row>
			{/* <div style={{ margin: '10px' }}></div>
			<Row  gutter={16}>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						* รูปภาพสินค้า
					</label>
					<div className={'file is-centered is-boxed is-success has-name is-fullwidth'}>
						<label className={'file-label'}>
							<input
								className={'file-input'}
								type={'file'}
								accept={'image/*'}
								onChange={(evt) => {
									let _file = evt.target.files[0];
									if(_file) {
										let _reader = new FileReader();
										_reader.readAsDataURL(_file);
										_reader.onload = (_e) => {
											props.setPicture({
												preview: _e.target.result,
												file: _file
											})
										}
									}
								}}
							/>
							<span className={'file-cta'}>
								<span className={'file-icon'} style={{ width: '100%' }}>
									<i className={'fas fa-upload'}></i>
								</span>
								<span className={'file-label has-text-centered'}>
									อัพโหลด (ขนาดที่แนะนำ 4:3)
								</span>
							</span>
						</label>
					</div>
				</Col>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						ตัวอย่างภาพ
					</label>
					{
						(props.picture.preview !== '') && (
							<div className={'custom-iwear-admin-data-image-preview-box'}>
								<figure className={'image'}>
									<img
										style={{ maxWidh: '100%' }}
										src={ props.picture.preview }
										alt={''}
									/>
								</figure>
							</div>
						)
					}
				</Col>
			</Row> */}
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						* ชนิดสินค้า
					</label>
					<IWearDropdown
						isFull
						displayValue={ props.productType }
						choices={[ ..._typeArr ]}
						onSelect={(selectedVal) => {
							props.setFrameType('');
							props.setLensType('');
							props.setProductType(selectedVal);
						}}
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			<Row>
				<Col span={12}>
					<label className={'custom-iwear-global-font-color'}>
						* ราคาสินค้า
					</label>
					<InputNumber
						style={{ width: '100%' }}
						min={0}
						onChange={(value) => {
							props.setPrice(value);
						}}
					/>
				</Col>
			</Row>
			<div style={{ margin: '10px' }}></div>
			{
				(props.productType !== '' && (props.productType === 'กรอบแว่น' || props.productType === 'เลนส์')) && (
					<React.Fragment>
						<Row gutter={8}>
							<Col span={12}>
								<label className={'custom-iwear-global-font-color'}>
									* แบรนด์
								</label>
								<IWearDropdown
									isFilterable isFull
									displayValue={ props.brand }
									choices={[ ..._brandArr ]}
									onSelect={(selectedVal, selectedIndex) => {
										props.setBrand(selectedVal);
									}}
								/>
							</Col>
							<Col span={12}>
								<label className={'custom-iwear-global-font-color'}>
									* { (props.productType === 'กรอบแว่น') ? 'ชนิดกรอบ' : (props.productType === 'เลนส์') ? 'ชนิดเลนส์' : '' }
								</label>
								{
									(props.productType === 'กรอบแว่น') && (
										<IWearDropdown
											isFilterable isFull
											displayValue={ props.frameType }
											choices={[ ..._frameArr ]}
											onSelect={(selectedVal, selectedIndex) => {
												props.setFrameType(selectedVal);
											}}
										/>
									)
								}
								{
									(props.productType === 'เลนส์') && (
										<IWearDropdown
											isFilterable isFull
											displayValue={ props.lensType }
											choices={[ ..._lensArr ]}
											onSelect={(selectedVal, selectedIndex) => {
												props.setLensType(selectedVal);
											}}
										/>
									)
								}
							</Col>
						</Row>
						<div style={{ margin: '10px' }}></div>
					</React.Fragment>
				)
			}
		</React.Fragment>
	);
}

const renderAttributeTable = (props) => {
	return (
		<div className={'custom-iwear-global-table-container'}>
			<table className={'table is-fullwidth is-striped is-hoverable custom-iwear-global-table-head-root'}>
				<thead>
					<tr>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							คุณสมบัติ
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							ตัวเลือก
						</th>
						<th className={'custom-iwear-global-table-text-color has-text-centered custom-iwear-global-table-head'}>
							เพิ่มเติม
						</th>
					</tr>
				</thead>
				<tbody>
					{ renderAttributeTableData(props) }
				</tbody>
			</table>
		</div>
	)
}

const renderAttributeTableData = (props) => {
	return props.attrTableData.map((_e, _i) => {
		return (
			<tr key={_i}>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.name }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					{ _e.choices.join(', ') }
				</td>
				<td
					className={'custom-iwear-global-table-text-color has-text-centered'}
					style={{ verticalAlign: 'middle' }}
				>
					<Button onClick={() => { props.onEditAttrData(_i); }}>
						<i className={'fas fa-edit'}></i>
					</Button>
					&nbsp;
					<Button onClick={() => { props.onDelAttrData(_i); }}>
						<i className={'fas fa-trash-alt'}></i>
					</Button>
				</td>
			</tr>
		);
	});
}

AddDataProduct.propTypes = {
	name: PropTypes.string.isRequired,
	picture: PropTypes.object.isRequired,
	price: PropTypes.number.isRequired,
	editAttrIdx: PropTypes.number.isRequired,

	branch: PropTypes.string.isRequired,
	branchList: PropTypes.array.isRequired,
	brand: PropTypes.string.isRequired,
	brandList: PropTypes.array.isRequired,
	productType: PropTypes.string.isRequired,
	frameType: PropTypes.string.isRequired,
	lensType: PropTypes.string.isRequired,
	productTypeList: PropTypes.array.isRequired,
	frameTypeList: PropTypes.array.isRequired,
	lensTypeList: PropTypes.array.isRequired,
	attrTableData: PropTypes.array.isRequired,

	attrModalIdx: PropTypes.number.isRequired,
	attrModalName: PropTypes.string.isRequired,
	attrModalChoices: PropTypes.array.isRequired,
	attrModalVisible: PropTypes.bool.isRequired,

	setName: PropTypes.func.isRequired,
	setPicture: PropTypes.func.isRequired,
	setPrice: PropTypes.func.isRequired,
	setEditAttrIdx: PropTypes.func.isRequired,

	setBranch: PropTypes.func.isRequired,
	setBranchList: PropTypes.func.isRequired,
	setBrand: PropTypes.func.isRequired,
	setBrandList: PropTypes.func.isRequired,
	setProductType: PropTypes.func.isRequired,
	setFrameType: PropTypes.func.isRequired,
	setLensType: PropTypes.func.isRequired,
	setProductTypeList: PropTypes.func.isRequired,
	setFrameTypeList: PropTypes.func.isRequired,
	setLensTypeList: PropTypes.func.isRequired,
	setAttrTableData: PropTypes.func.isRequired,

	setAttrModalIdx: PropTypes.func.isRequired,
	setAttrModalName: PropTypes.func.isRequired,
	setAttrModalChoices: PropTypes.func.isRequired,
	setAttrModalVisible: PropTypes.func.isRequired,

	onDelAttrData: PropTypes.func.isRequired,
	onClickGoBack: PropTypes.func.isRequired,
	onClickSave: PropTypes.func.isRequired
}

export default AddDataProduct;
