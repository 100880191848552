/**
 *	Dashboard - Content
 */

import React from 'react';
import {
	Row,
	Col,
	DatePicker
} from 'antd';
import moment from 'moment';

import Breadcrumb from '../../../components/breadcrumb';

import InfoCard from './components/info-card';
import { ContentPropTypes } from './dashboard.model';
import styles from './dashboard.module.css';

const renderInfoCards = (props) => {
	return (
		<React.Fragment>
			<div className={`${styles.dashboardCardContainer} is-hidden-touch`}>
				<Row gutter={16} type={'flex'} align={'middle'} justify={'center'}>
					<Col span={6}>
						<InfoCard
							isLoading={ props.isLoading }
							imgPath={ process.env.PUBLIC_URL + '/static/images/order_thumbnail.svg' }
							imgAlt={'list'}
							value={ props.periodOrder }
							label={'จำนวนคำสั่งซื้อ'}
							dateLabel={`(${moment(props.filterDate.text[0]).format('DD/MM/YYYY')} - ${moment(props.filterDate.text[1]).format('DD/MM/YYYY')})`}
						/>
					</Col>
					<Col span={6}>
						<InfoCard
							isLoading={ props.isLoading }
							imgPath={ process.env.PUBLIC_URL + '/static/images/glasses_thumbnail.svg' }
							imgAlt={'eye glasses'}
							value={ props.framePeriodSale }
							label={'จำนวนกรอบที่ขาย'}
							dateLabel={`(${moment(props.filterDate.text[0]).format('DD/MM/YYYY')} - ${moment(props.filterDate.text[1]).format('DD/MM/YYYY')})`}
						/>
					</Col>
					<Col span={6}>
						<InfoCard
							isLoading={ props.isLoading }
							imgPath={ process.env.PUBLIC_URL + '/static/images/lens_thumbnail.svg' }
							imgAlt={'lens'}
							value={ props.lensPeriodSale }
							label={'จำนวนเลนส์ที่ขาย'}
							dateLabel={`(${moment(props.filterDate.text[0]).format('DD/MM/YYYY')} - ${moment(props.filterDate.text[1]).format('DD/MM/YYYY')})`}
						/>
					</Col>
					<Col span={6}>
						<InfoCard
							isLoading={ props.isLoading }
							imgPath={ process.env.PUBLIC_URL + '/static/images/growth_chart_thumbnail.svg' }
							imgAlt={'chart'}
							value={ props.periodSale }
							valueUnit={'บาท'}
							label={'ยอดขาย'}
							dateLabel={`(${moment(props.filterDate.text[0]).format('DD/MM/YYYY')} - ${moment(props.filterDate.text[1]).format('DD/MM/YYYY')})`}
						/>
					</Col>
				</Row>
				<div style={{ margin: '20px' }}></div>
				<Row gutter={16} type={'flex'} align={'middle'} justify={'center'}>
					<Col span={6}>
						<InfoCard
							isLoading={ props.isLoading }
							imgPath={ process.env.PUBLIC_URL + '/static/images/order_thumbnail.svg' }
							imgAlt={'list'}
							value={ props.todayOrder }
							label={'จำนวนคำสั่งซื้อวันนี้'}
							dateLabel={'BLANK_SPACE'}
						/>
					</Col>
					<Col span={6}>
						<InfoCard
							isLoading={ props.isLoading }
							imgPath={ process.env.PUBLIC_URL + '/static/images/growth_chart_thumbnail.svg' }
							imgAlt={'chart'}
							value={ props.todaySale }
							valueUnit={'บาท'}
							label={'ยอดขายวันนี้'}
							dateLabel={'BLANK_SPACE'}
						/>
					</Col>
					<Col span={6}>
						<InfoCard
							isLoading={ props.isLoading }
							imgPath={ process.env.PUBLIC_URL + '/static/images/growth_chart_thumbnail.svg' }
							imgAlt={'chart'}
							value={ props.monthSale }
							valueUnit={'บาท'}
							label={'ยอดขายเดือนนี้'}
							dateLabel={'BLANK_SPACE'}
						/>
					</Col>
					<Col span={6}>
						<InfoCard
							isLoading={ props.isLoading }
							imgPath={ process.env.PUBLIC_URL + '/static/images/growth_chart_thumbnail.svg' }
							imgAlt={'chart'}
							value={ props.totalSale }
							valueUnit={'บาท'}
							label={'ยอดขายรวม'}
							dateLabel={'BLANK_SPACE'}
						/>
					</Col>
				</Row>
			</div>
			<div className={`${styles.dashboardCardContainer} is-hidden-desktop`}>
				<Row type={'flex'} align={'middle'} justify={'center'}>
					<Col span={24}>
						<InfoCard
							isLoading={ props.isLoading }
							imgPath={ process.env.PUBLIC_URL + '/static/images/order_thumbnail.svg' }
							imgAlt={'list'}
							value={ props.periodOrder }
							label={'จำนวนคำสั่งซื้อ'}
							dateLabel={`(${moment(props.filterDate.text[0]).format('DD/MM/YYYY')} - ${moment(props.filterDate.text[1]).format('DD/MM/YYYY')})`}
						/>
					</Col>
				</Row>
				<div style={{ margin: '10px' }}></div>
				<Row type={'flex'} align={'middle'} justify={'center'}>
					<Col span={24}>
						<InfoCard
							isLoading={ props.isLoading }
							imgPath={ process.env.PUBLIC_URL + '/static/images/glasses_thumbnail.svg' }
							imgAlt={'eye glasses'}
							value={ props.framePeriodSale }
							label={'จำนวนกรอบที่ขาย'}
							dateLabel={`(${moment(props.filterDate.text[0]).format('DD/MM/YYYY')} - ${moment(props.filterDate.text[1]).format('DD/MM/YYYY')})`}
						/>
					</Col>
				</Row>
				<div style={{ margin: '10px' }}></div>
				<Row type={'flex'} align={'middle'} justify={'center'}>
					<Col span={24}>
						<InfoCard
							isLoading={ props.isLoading }
							imgPath={ process.env.PUBLIC_URL + '/static/images/lens_thumbnail.svg' }
							imgAlt={'lens'}
							value={ props.lensPeriodSale }
							label={'จำนวนเลนส์ที่ขาย'}
							dateLabel={`(${moment(props.filterDate.text[0]).format('DD/MM/YYYY')} - ${moment(props.filterDate.text[1]).format('DD/MM/YYYY')})`}
						/>
					</Col>
				</Row>
				<div style={{ margin: '10px' }}></div>
				<Row type={'flex'} align={'middle'} justify={'center'}>
					<Col span={24}>
						<InfoCard
							isLoading={ props.isLoading }
							imgPath={ process.env.PUBLIC_URL + '/static/images/growth_chart_thumbnail.svg' }
							imgAlt={'chart'}
							value={ props.periodSale }
							valueUnit={'บาท'}
							label={'ยอดขาย'}
							dateLabel={`(${moment(props.filterDate.text[0]).format('DD/MM/YYYY')} - ${moment(props.filterDate.text[1]).format('DD/MM/YYYY')})`}
						/>
					</Col>
				</Row>
				<div style={{ margin: '10px' }}></div>
				<Row type={'flex'} align={'middle'} justify={'center'}>
					<Col span={24}>
						<InfoCard
							isLoading={ props.isLoading }
							imgPath={ process.env.PUBLIC_URL + '/static/images/order_thumbnail.svg' }
							imgAlt={'list'}
							value={ props.todayOrder }
							label={'จำนวนคำสั่งซื้อวันนี้'}
							dateLabel={'BLANK_SPACE'}
						/>
					</Col>
				</Row>
				<div style={{ margin: '10px' }}></div>
				<Row type={'flex'} align={'middle'} justify={'center'}>
					<Col span={24}>
						<InfoCard
							isLoading={ props.isLoading }
							imgPath={ process.env.PUBLIC_URL + '/static/images/growth_chart_thumbnail.svg' }
							imgAlt={'chart'}
							value={ props.todaySale }
							valueUnit={'บาท'}
							label={'ยอดขายวันนี้'}
							dateLabel={'BLANK_SPACE'}
						/>
					</Col>
				</Row>
				<div style={{ margin: '10px' }}></div>
				<Row type={'flex'} align={'middle'} justify={'center'}>
					<Col span={24}>
						<InfoCard
							isLoading={ props.isLoading }
							imgPath={ process.env.PUBLIC_URL + '/static/images/growth_chart_thumbnail.svg' }
							imgAlt={'chart'}
							value={ props.monthSale }
							valueUnit={'บาท'}
							label={'ยอดขายเดือนนี้'}
							dateLabel={'BLANK_SPACE'}
						/>
					</Col>
				</Row>
				<div style={{ margin: '10px' }}></div>
				<Row type={'flex'} align={'middle'} justify={'center'}>
					<Col span={24}>
						<InfoCard
							isLoading={ props.isLoading }
							imgPath={ process.env.PUBLIC_URL + '/static/images/growth_chart_thumbnail.svg' }
							imgAlt={'chart'}
							value={ props.totalSale }
							valueUnit={'บาท'}
							label={'ยอดขายรวม'}
							dateLabel={'BLANK_SPACE'}
						/>
					</Col>
				</Row>
			</div>
		</React.Fragment>
	);
}

const Dashboard = (props) => {
	return (
		<div className={'container custom-iwear-global-container-padding'}>
			<Breadcrumb paths={[ 'Dashboard', 'Statistic' ]} />
			<div style={{ margin: '10px' }}></div>
			<Row type={'flex'} align={'middle'} justify={'end'}>
				<Col>
					<span className={'has-text-black'}>
						เลือกวันที่&nbsp;
						<DatePicker.RangePicker
							format={'DD/MM/YYYY'}
							disabledDate={(current) => { return current > moment().endOf('day'); }}
							defaultValue={props.filterDate.value}
							onChange={(date, dateString) => {
								// RANGE
								let _dates = [];
								date.forEach((_e) => { _dates.push(_e.toDate()); });

								console.log('_dates', _dates);
								console.log('moment', [moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD')]);

								props.setFilterDate({
									value: _dates,
									text: [ moment(date[0]).format('YYYY-MM-DD'), moment(date[1]).format('YYYY-MM-DD') ]
								});
							}}
						/>
					</span>
				</Col>
			</Row>
			{ renderInfoCards(props) }
		</div>
	);
}

Dashboard.propTypes = ContentPropTypes;

export default Dashboard;
