/**
 *	Stock Import Bill - Container
 */

import React from 'react';
import swal from 'sweetalert';
import { Layout } from 'antd';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../environments';
import GlobalStore from '../../../../store';
import LocalStore from './stock-import-bill.store';
import Content from './stock-import-bill.content';
import { ContainerPropTypes, Actions } from './stock-import-bill.model';

const Container = (props) => {
	const StockImportReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		//
	});

	const onChangeBarcode = (evt) => {
		evt.preventDefault();
		StockImportReducer.dispatch({ type: Actions.SET_BARCODE, value: evt.target.value });
	}

	const onChangeItemStatus = (value) => {
		StockImportReducer.dispatch({ type: Actions.SET_ITEM_STATUS, value: value });
	}

	const onScan = async (qr_code) => {
		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		let res = await fetch(`${API_ENDPOINT}/exchanges?limit=100&offset=0&export_code=${encodeURIComponent(qr_code)}&dest_branch_id=${encodeURIComponent(branchID)}&with_product_detail=true`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let json = await res.json();

		if(json.list.length > 0) {
			StockImportReducer.dispatch({ type: Actions.SET_TABLE_DATA, value: json.list[0].exchange_detail });
			StockImportReducer.dispatch({ type: Actions.SET_SRC_BRANCH, value: json.list[0].source_branch.branch_id });

			let _arr = [];
			json.list[0].exchange_detail.forEach((_e) => { _arr.push('สินค้าปกติดี'); });
			StockImportReducer.dispatch({ type: Actions.SET_ITEM_STATUS, value: [ ..._arr ] });
		}
		else {
			swal({
				title: 'ไม่พบรหัสส่งออก',
				text: 'ไม่มีข้อมูลการส่งออกด้วยรหัสนี้',
				icon: 'error'
			});
		}
	}

	const onClickConfirm = async () => {
		if(StockImportReducer.state.tableData.length === 0) {
			swal({
				title: 'นำสินค้าเข้าล้มเหลว',
				text: 'ไม่มีข้อมูลสินค้า',
				icon: 'error'
			});
		}
		else {
			let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
			let _arr = [];
			StockImportReducer.state.tableData.forEach((_e, _i) => {
				_arr.push({
					inventory_id: branchID, // this branch_id
					src_inv_id: StockImportReducer.state.srcBranch, // imported from
					ex_item_id: _e.ex_item_id,
					product_item_id: _e.product_info.item_id,
					quantity: _e.quantity,
					price: _e.product_info.price,
					status: (StockImportReducer.state.itemsStatus[_i] === 'สินค้าปกติดี') ? 'normal' : 'broken'
				});
			});
			// console.log('Branch', branchID);
			// console.log('ImportData', _arr);

			let res = await fetch(`${API_ENDPOINT}/inventory-items`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + state.auth.token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					code: StockImportReducer.state.barcode,
					inventory_items: [ ..._arr ]
				})
			});

			let json = await res.json();
			console.log('Import', json);
			if(res.status !== 200) {
				swal({
					title: 'เกิดข้อผิดพลาด',
					text: json.msg,
					icon: 'error'
				});
			} else {
				props.history.goBack();
			}
		}
	}

	const onClickGoBack = () => {
		props.history.goBack();
	}

	return (
		<Layout>
			<Sidebar mode={'sales'} currentRoute={'/sales/stock/import'} history={ props.history } />
			<Layout>
				<TopBar mode={'sales'} currentRoute={'/sales/stock/import'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...StockImportReducer.state }

						onChangeBarcode={ onChangeBarcode }
						onChangeItemStatus={ onChangeItemStatus }

						onScan={ onScan }
						onClickConfirm={ onClickConfirm }
						onClickGoBack={ onClickGoBack }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
