/**
 *	Select Customer Channel - Container
 */

import React from 'react';
import { Select } from 'antd';

const Container = ({
  isFull, // boolean
  disabled, // boolean
  styleJSX, // object
  placeholder, // ReactNode
  selected, // string,
  onChange // () => void
}) => {
	const choices = [
		'Facebook',
		'Google Ads',
		'Google Maps',
		'IG',
		'Line OA',
		'TikTok',
		'Website',
		'Youtube',
		'ป้ายโฆษณา หน้าร้าน / ปั๊ม / ห้าง',
		'ลูกค้าเก่า แนะนำมา',
		'ลูกค้าเก่า มาใช้บริการซ้ำ',
		'ช่องทางออนไลน์',
		'ลูกค้า Walk in',
		'ลูกค้าบอกต่อ',
	];

  const _style = (isFull) ? { width: '100%' } : {};

	React.useEffect(() => {
		//
	}, []);

	const onChangeSelection = (selectedValue) => { onChange([selectedValue]); }

	const renderOptions = () => {
		return choices.map((_e, _i) => {
			return (
				<Select.Option key={ _i } value={ _e }>
					{ _e }
				</Select.Option>
			);
		});
	}

	return (
		<Select
			// mode={'tags'}
			disabled={ disabled }
			style={{ ..._style, ...styleJSX }}
			placeholder={ placeholder }
			value={ selected }
			onChange={ onChangeSelection }
		>
			{ renderOptions() }
		</Select>
	);
}

export default Container;