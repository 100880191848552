/**
 *	Order List component - Remaining Payment Modal
 */

import React from 'react';
import PropTypes from 'prop-types';
import {
	DatePicker,
	InputNumber,
	Modal,
	Button,
	Row,
	Col
} from 'antd';

import IWearDropdown from '../../../../components/dropdown';

const renderModalContent = (state, props) => {
	return (
		<React.Fragment>
			<Row gutter={8}>
				<Col span={9}>
					<span className={'is-size-4'}>ราคาสั่งซื้อ</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<span className={'is-size-4'}>{ props.fullPrice }</span>
				</Col>
				<Col span={3}>
					<span className={'is-size-4'}>บาท</span>
				</Col>
			</Row>
			<Row gutter={8}>
				<Col span={9}>
					<span className={'is-size-4'}>ยอดเงินชำระ</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<span className={'is-size-4'}>{ props.payment }</span>
				</Col>
				<Col span={3}>
					<span className={'is-size-4'}>บาท</span>
				</Col>
			</Row>
			<Row gutter={8}>
				<Col span={9}>
					<span className={'is-size-4'}>ยอดค้างชำระ</span>
				</Col>
				<Col span={12} style={{ textAlign: 'right' }}>
					<span className={'is-size-4'}>{ props.fullPrice - props.payment }</span>
				</Col>
				<Col span={3}>
					<span className={'is-size-4'}>บาท</span>
				</Col>
			</Row>
			{
				(props.fullPrice - props.payment > 0) && (
					<React.Fragment>
						<Row gutter={8}>
							<Col span={9}>
								<span className={'is-size-4 has-text-weight-bold'}>วันที่ชำระ</span>
							</Col>
							<Col span={15}>
								<DatePicker
									format={'DD/MM/YYYY'}
									allowClear={false}
									placeholder={'Date'}
									style={{ width: '100%' }}
									onChange={(dateObj, dateString) => {
										state.setPaymentDate(JSON.stringify(dateObj._d));
									}}
								/>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={9}>
								<span className={'is-size-4 has-text-weight-bold'}>ประเภทการชำระ</span>
							</Col>
							<Col span={15}>
								<IWearDropdown
									isFull
									displayValue={ state.paymentType }
									choices={[ 'บัตรเครดิต', 'จ่ายผ่อน', 'เงินสด', 'โอนผ่าน Application' ]}
									onSelect={(selectedVal) => {
										state.setPaymentType(selectedVal);
									}}
								/>
							</Col>
						</Row>
						<Row gutter={8}>
							<Col span={9}>
								<span className={'is-size-4 has-text-weight-bold'}>ชำระเพิ่ม</span>
							</Col>
							<Col span={12}>
								<InputNumber
									style={{ width: '100%' }}
									defaultValue={ props.fullPrice - props.payment }
									min={0}
									max={ props.fullPrice - props.payment }
									onChange={(value) => {
										state.setPaymentAmount(value);
									}}
								/>
							</Col>
							<Col span={3}>
								<span className={'is-size-4 has-text-weight-bold'}>บาท</span>
							</Col>
						</Row>
					</React.Fragment>
				)
			}
		</React.Fragment>
	);
}

const RemainingPaymentModal = (props) => {
	const [ paymentDate, setPaymentDate ] = React.useState('');
	const [ paymentType, setPaymentType ] = React.useState('');
	const [ paymentAmount, setPaymentAmount ] = React.useState(0);

	React.useEffect(() => {
		setPaymentAmount(props.fullPrice - props.payment);
	}, [ props ]);

	return (
		<Modal
			title={'ชำระเงินที่เหลือ'}
			visible={ props.isVisible }
			footer={[
				<Button onClick={ props.onClickClose }>
					<span>
						ปิด
					</span>
				</Button>,
				<Button
					className={`${(props.fullPrice - props.payment > 0) ? '' : 'is-hidden'}`}
					type={'primary'}
					onClick={ () => { props.onClickPaid(paymentDate, paymentType, paymentAmount); } }
				>
					<span>
						ชำระ
					</span>
				</Button>
			]}
		>
			{ renderModalContent({ paymentDate, paymentType, paymentAmount, setPaymentDate, setPaymentType, setPaymentAmount }, props) }
		</Modal>
	);
}

RemainingPaymentModal.propTypes = {
	fullPrice: PropTypes.number.isRequired,
	payment: PropTypes.number.isRequired,
	isVisible: PropTypes.bool.isRequired,

	onClickPaid: PropTypes.func.isRequired,
	onClickClose: PropTypes.func.isRequired
}

export default RemainingPaymentModal;
