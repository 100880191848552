/**
 *  Appointment - Container
 */

import React from 'react';
import { Layout } from 'antd';
import XDate from 'xdate';
import swal from 'sweetalert';

import TopBar from '../../../components/layout/header';
import Sidebar from '../../../components/layout/sider';
import BottomBar from '../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../environments';
import GlobalStore from '../../../store';
import LocalStore from './appointment.store';
import Content from './appointment.content';
import { ContainerPropTypes, Actions} from './appointment.model';

const Container = (props) => {
	const AppointmentReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData({
			selectedDate: AppointmentReducer.state.selectedDate,
			branchName: AppointmentReducer.state.branchName
		}, AppointmentReducer.dispatch, state.auth.token);
	}, [ AppointmentReducer.state.selectedDate, AppointmentReducer.state.branchName, AppointmentReducer.dispatch, state.auth.token ]);

	const fetchData = async (state, localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		let res_branch_list = await fetch(`${API_ENDPOINT}/branches?limit=9999`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json_branch_list = await res_branch_list.json();
		localDispatch({ type: Actions.SET_BRANCH_LIST, value: [ ...json_branch_list.list ] })

		if(state.branchName && state.branchName !== 'ทั้งหมด') {
			let _date = XDate(state.selectedDate).toString('yyyy-MM-dd');

			let res_branch = await fetch(`${API_ENDPOINT}/branches?branch_name=${encodeURIComponent(state.branchName)}`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + authToken,
		    		'Content-Type': 'application/json'
				}
			});

			let json_branch = await res_branch.json();
			let _branchID = json_branch.list[0].branch_id;

			let res = await fetch(`${API_ENDPOINT}/appointments?date=${encodeURIComponent(_date)}&branch_id=${_branchID}`, {
				method: 'GET',
				headers: {
		    		'Authorization': 'Bearer ' + authToken,
		    		'Content-Type': 'application/json'
				}
			});

			let json = await res.json();
			localDispatch({ type: Actions.SET_APPOINTMENT_DATA, value: json });
		}
		else {
			let _date = XDate(state.selectedDate).toString('yyyy-MM-dd');

			let res = await fetch(`${API_ENDPOINT}/appointments?date=${encodeURIComponent(_date)}`, {
				method: 'GET',
				headers: {
		    		'Authorization': 'Bearer ' + authToken,
		    		'Content-Type': 'application/json'
				}
			});

			let json = await res.json();
			localDispatch({ type: Actions.SET_APPOINTMENT_DATA, value: json });

			// localDispatch({ type: Actions.SET_APPOINTMENT_DATA, value: { current_date: [], next_date: [], double_date: [], not_receive: [] } });
		}

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onChangeBranch = (selectedValue) => {
		AppointmentReducer.dispatch({ type: Actions.SET_BRANCH_NAME, value: `${selectedValue}` })
	}

	const onChangeDate = (dateObj) => {
		AppointmentReducer.dispatch({ type: Actions.SET_SELECTED_DATE, value: dateObj._d });
	}

	const onPanelClick = (orderID, customerID, customerName, customerTel) => {
		// console.log('PanelClick', { orderID, customerID, customerName, customerTel });
		props.history.push(`/admin/order/detail`, {
			order_id: Number.parseInt(orderID, 10),
			customer_id: Number.parseInt(customerID, 10),
			fullname: customerName,
			tel: customerTel
		});
	}

	const onSubmitNewAppointmentDate = async (newAppointedDate, orderID, customerID, type) => {
		let _data = { ...AppointmentReducer.state.appointmentData };
		let _arr = _data[type].filter((_filt) => {
			return _filt.customer_info.customer_id === customerID && _filt.order_info.order_id === orderID
		});

		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		let res = await fetch(`${API_ENDPOINT}/orders/${encodeURIComponent(orderID)}`, {
			method: 'PATCH',
			headers: {
	    		'Authorization': 'Bearer ' + state.auth.token,
	    		'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				msg: 'Update Appointment Date',
				customer_id: customerID,
				branch_id: branchID,
				promotion_id: _arr[0].order_info.promotion_id,
				// payment_code: 0,
				// payment_type: '',
				discount: _arr[0].order_info.discount,
				receive_date: JSON.parse(newAppointedDate),
				status: _arr[0].order_info.status,
				price: _arr[0].order_info.price,
				note: _arr[0].order_info.note
			})
		});

		let json = await res.json();
		console.log('Update Appointment', json);

		swal({
			title: 'เลื่อนนัดหมายสำเร็จ',
			icon: 'success'
		}).then((value) => {
			window.location.reload();
		});
	}

	return (
		<Layout>
			<Sidebar mode={'admin'} currentRoute={'/admin/appointment'} history={props.history}/>
			<Layout>
				<TopBar mode={'admin'} currentRoute={'/admin/appointment'} history={props.history}/>
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...AppointmentReducer.state }
						dispatcher={ AppointmentReducer.dispatch }

						onChangeBranch={ onChangeBranch }
						onChangeDate={ onChangeDate }
						onPanelClick={ onPanelClick }
						onSubmitNewAppointmentDate={ onSubmitNewAppointmentDate }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar/>
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
