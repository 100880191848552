/**
 *	Application DOM replacement 
 */

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import GlobalStore from './store';
import Application from './navigator';

ReactDOM.render(
	<GlobalStore.Provider>
		<BrowserRouter>
			<Application />
		</BrowserRouter>
	</GlobalStore.Provider>,
	document.getElementById('root')
);