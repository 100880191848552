/**
 *	Stock Export List - State Management
 */

import React from 'react';
import { Actions } from './stock-import-list.model';

const initState = {
	branchName: '',
	tableData: [],
	totalData: 0,
	page: 1,
	pageSize: 10,
	totalPage: 1,
	searchKey: '',
	isLoading: false,
	isExporting: false
}

const reducer = (state, action) => {
	switch(action.type) {
		case Actions.SET_BRANCH_NAME:
			return {
				...state,
				branchName: action.value
			}
		case Actions.SET_TABLE_DATA:
			return {
				...state,
				tableData: action.value
			}
		case Actions.SET_TOTAL_DATA:
			return {
				...state,
				totalData: action.value
			}
		case Actions.SET_PAGE:
			return {
				...state,
				page: action.value
			}
		case Actions.SET_PAGE_SIZE:
			return {
				...state,
				pageSize: action.value
			}
		case Actions.SET_TOTAL_PAGE:
			return {
				...state,
				totalPage: action.value
			}
		case Actions.SET_SEARCH_KEY:
			return {
				...state,
				searchKey: action.value
			}
		case Actions.SET_IS_LOADING:
			return {
				...state,
				isLoading: action.value
			}
		case Actions.SET_IS_EXPORTING:
			return {
				...state,
				isExporting: action.value
			}
		default:
			return { ...state }
	}
}

const Store = () => {
	const [ state, dispatch ] = React.useReducer(reducer, initState);

	React.useEffect(() => {}, []);

	return {
		state,
		dispatch
	}
}

export default Store;
