/**
 *  iWear - Appointment Container
 *
 *    @using React Hook
 */

import React from 'react';
import { Layout } from 'antd';
import XDate from 'xdate';
import swal from 'sweetalert';

import TopBar from '../../../components/layout/header';
import Sidebar from '../../../components/layout/sider';
import BottomBar from '../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../environments';
import GlobalStore from '../../../store';
import LocalStore from './appointment.store';
import Content from './appointment.content';
import { ContainerPropTypes, Actions } from './appointment.model';

const Container = (props) => {
	const AppointmentReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData(AppointmentReducer.state.selectedDate, AppointmentReducer.dispatch, state.auth.token);
	}, [ AppointmentReducer.state.selectedDate, AppointmentReducer.dispatch, state.auth.token ]);

	const fetchData = async (selectedDate, localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		let _date = XDate(selectedDate).toString('yyyy-MM-dd');
		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		let res = await fetch(`${API_ENDPOINT}/appointments?date=${encodeURIComponent(_date)}&branch_id=${encodeURIComponent(branchID)}`, {
			method: 'GET',
			headers: {
	    		'Authorization': 'Bearer ' + authToken,
	    		'Content-Type': 'application/json'
			}
		});

		let json = await res.json();
		localDispatch({ type: Actions.SET_APPOINTMENT_DATA, value: json });

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onChangeDatePicker = (dateObj) => {
		AppointmentReducer.dispatch({
			type: Actions.SET_SELECTED_DATE,
			value: dateObj._d
		});
	}

	const onPanelClick = (orderID, customerID, customerName, customerTel) => {
		// console.log('PanelClick', { orderID, customerID, customerName, customerTel });
		props.history.push(`/sales/order/detail`, {
			order_id: Number.parseInt(orderID, 10),
			customer_id: Number.parseInt(customerID, 10),
			fullname: customerName,
			tel: customerTel
		});
	}

	const onSubmitNewAppointmentDate = async (newAppointedDate, orderID, customerID, type) => {
		let _data = { ...AppointmentReducer.state.appointmentData };
		let _arr = _data[type].filter((_filt) => {
			return _filt.customer_info.customer_id === customerID && _filt.order_info.order_id === orderID
		});

		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		let res = await fetch(`${API_ENDPOINT}/orders/${orderID}`, {
			method: 'PATCH',
			headers: {
	    		'Authorization': 'Bearer ' + state.auth.token,
	    		'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				msg: 'Update Appointment Date',
				customer_id: customerID,
				branch_id: branchID,
				promotion_id: _arr[0].order_info.promotion_id,
				// payment_code: 0,
				// payment_type: '',
				discount: _arr[0].order_info.discount,
				receive_date: JSON.parse(newAppointedDate),
				status: _arr[0].order_info.status,
				price: _arr[0].order_info.price,
				note: _arr[0].order_info.note
			})
		});

		let json = await res.json();
		console.log('Update Appointment', json);

		swal({
			title: 'เลื่อนนัดหมายสำเร็จ',
			icon: 'success'
		}).then((value) => {
			window.location.reload();
		});
	}

	return (
		<React.Fragment>
  			<Layout>
    			<Sidebar mode={'sales'} currentRoute={'/sales/appointment'} history={props.history}/>
    			<Layout>
      				<TopBar mode={'sales'} currentRoute={'/sales/appointment'} history={props.history}/>
  					<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
        				<Content
							  { ...AppointmentReducer.state }
							  dispatcher={ AppointmentReducer.dispatch }

							onChangeDatePicker={ onChangeDatePicker }
							onPanelClick={ onPanelClick }
				          	onSubmitNewAppointmentDate={ onSubmitNewAppointmentDate }

							globalReducer={{ state, dispatch }}
    					/>
      				</Layout.Content>
	      			<BottomBar/>
				</Layout>
			</Layout>
		</React.Fragment>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
