/**
 *	iWear - Data Branch Add container
 */

import React from 'react';
import { Layout } from 'antd';
import swal from 'sweetalert';
import PropTypes from 'prop-types';

import TopBar from '../../../../../components/layout/header';
import Sidebar from '../../../../../components/layout/sider';
import BottomBar from '../../../../../components/bottom-bar';
import { API_ENDPOINT } from '../../../../../environments';
import WebCookie from '../../../../../lib/web-cookie';
import AddDataBranch from './screen';

const AddDataBranchContainer = (props) => {
	const [ branchName, setBranchName ] = React.useState('');
	const [ branchProvince, setBranchProvince ] = React.useState({ index: -1, text: '' });
	const [ branchDistrict, setBranchDistrict ] = React.useState('');
	const [ branchAddr, setBranchAddr ] = React.useState('');
	const [ branchPostCode, setBranchPostCode ] = React.useState('');

	React.useEffect(() => {
		//
	}, []);

	const onClickGoBack = () => {
		props.history.goBack();
	}

	const onClickSave = async () => {
		if(branchName === '') {
			swal({
				title: 'เพิ่มสาขาล้มเหลว',
				text: 'ไม่มีชื่อสาขา',
				icon: 'error'
			});
		}
		else if(branchProvince.text === '') {
			swal({
				title: 'เพิ่มสาขาล้มเหลว',
				text: 'ไม่เลือกจังหวัด',
				icon: 'error'
			});
		}
		else if(branchDistrict === '') {
			swal({
				title: 'เพิ่มสาขาล้มเหลว',
				text: 'ไม่เลือกเขต',
				icon: 'error'
			});
		}
		else if(branchAddr === '') {
			swal({
				title: 'เพิ่มสาขาล้มเหลว',
				text: 'ไม่ระบุที่ตั้ง',
				icon: 'error'
			});
		}
		else {
			let res = await fetch(`${API_ENDPOINT}/branches`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					branch_name: branchName,
					province: branchProvince.text,
					district: branchDistrict,
					location: branchAddr,
					zip_code: Number.isNaN(Number.parseInt(branchPostCode)) ? 0 : Number.parseInt(branchPostCode)
				})
			});

			let json = await res.json();
			console.log('CreateBranch', json);

			if(res.status === 200) {
				swal({
					title: 'เพิ่มสาขาสำเร็จ',
					icon: 'success'
				}).tjem((val) => {
					props.history.goBack();
				});
			}
			else {
				swal({
					title: 'เพิ่มสาขาล้มเหลว',
					text: 'ไม่ทราบสาเหตุ',
					icon: 'error'
				});
			}
		}
	}

	return (
		<Layout>
			<Sidebar mode={'rxmall'} currentRoute={'/lab/data/branch'} history={ props.history } />
			<Layout>
				<TopBar mode={'rxmall'} currentRoute={'/lab/data/branch'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<AddDataBranch
						branchName={ branchName }
						branchProvince={ branchProvince }
						branchDistrict={ branchDistrict }
						branchAddr={ branchAddr }
						branchPostCode={ branchPostCode }

						setBranchName={ setBranchName }
						setBranchProvince={ setBranchProvince }
						setBranchDistrict={ setBranchDistrict }
						setBranchAddr={ setBranchAddr }
						setBranchPostCode={ setBranchPostCode }

						onClickGoBack={ onClickGoBack }
						onClickSave={ onClickSave }
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

AddDataBranchContainer.propTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export default AddDataBranchContainer;
