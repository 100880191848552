/**
 *  Customer Edit - Container
 */

import React from 'react';
import {Layout} from 'antd';
import swal from 'sweetalert';
import XDate from 'xdate';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import {API_ENDPOINT} from '../../../../environments';
import GlobalStore from '../../../../store';
import LocalStore from './customers-edit.store';
import Content from './customers-edit.content';
import { ContainerPropTypes, Actions } from './customers-edit.model';

const Container = (props) => {
	const CustomerUpdateReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	React.useEffect(() => {
		fetchData(props.location.state.customer_id, props.location.state.customer_name, props.location.state.customer_phone_number, CustomerUpdateReducer.dispatch, state.auth.token);
	}, [ props.location.state.customer_id, props.location.state.customer_name, props.location.state.customer_phone_number, CustomerUpdateReducer.dispatch, state.auth.token ]);

	const fetchData = async (customerID, customerName, customerTel, localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		let res = await fetch(`${API_ENDPOINT}/customer-with-eye?search=${encodeURIComponent(customerTel)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json = await res.json();

		let res_eye = await fetch(`${API_ENDPOINT}/eye-inspector?customer_id=${encodeURIComponent(customerID)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json_eye = await res_eye.json();

		let res_staff = await fetch(`${API_ENDPOINT}/staffs?branch_id=${encodeURIComponent(branchID)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
  			}
		});

		let json_staff = await res_staff.json();

		let _inspectors = [];
		json_staff.list.forEach((_e) => {
			_inspectors.push({id: _e.staff_info.staff_id, name: _e.staff_info.full_name});
		});
		localDispatch({ type: Actions.SET_INSPECTOR_DATA, value: [..._inspectors] });

		if (json_eye.length > 0) {
			localDispatch({
				type: Actions.SET_INSPECTOR,
				value: { id: json_eye.list[json_eye.list.length - 1].staff_info.id, name: json_eye.list[json_eye.list.length - 1].staff_info.full_name }
			});
		}

		localDispatch({ type: Actions.SET_CUSTOMER_ID, value: json.list[0].customer_id });
		localDispatch({ type: Actions.SET_TEL, value: json.list[0].phone_number });
		localDispatch({ type: Actions.SET_NAME, value: json.list[0].full_name });
		localDispatch({ type: Actions.SET_GENDER, value: json.list[0].sex });
		localDispatch({ type: Actions.SET_BIRTHDATE, value: (json.list[0].birth_date) ? XDate(new Date(json.list[0].birth_date)).toString('dd/MM/yyyy') : '' });
		localDispatch({ type: Actions.SET_AGE, value: json.list[0].age });
		localDispatch({ type: Actions.SET_CUSTOMER_CHANNEL, value: json.list[0].customer_channel.split('|') });
		localDispatch({ type: Actions.SET_ADDR_ZONE, value: json.list[0].district });
		localDispatch({ type: Actions.SET_PROVINCE, value: (json.list[0].province !== null) ? { index: -1, text: json.list[0].province } : { index: -1, text: '' } });
		localDispatch({ type: Actions.SET_EXT_INFO, value: json.list[0].description });

		localDispatch({
			type: Actions.SET_NEW_MEASURE_DATA,
			value: [
				{type: 'Sphere', leftValue: 0, rightValue: 0},
				{type: 'CYL', leftValue: 0, rightValue: 0},
				{type: 'AXIS', leftValue: 0, rightValue: 0},
				{type: 'ADD', leftValue: 0, rightValue: 0},
				{type: 'DPD', leftValue: 0, rightValue: 0},
				{type: 'NPD', leftValue: 0, rightValue: 0},
				{type: 'SegHT', leftValue: 0, rightValue: 0},
				{type: 'Prism', leftValue: 0, rightValue: 0},
				{type: 'Mono-VA', leftValue: 0, rightValue: 0},
			]
		});

		if (json.list[0].eye_info.length > 0) {
			let lastIdx = json.list[0].eye_info.length - 1;
			if (Object.values(json.list[0].eye_info[lastIdx]).includes(null)) {
				localDispatch({
					type: Actions.SET_OLD_MEASURE_DATA,
					value: [
						{type: 'Sphere', leftValue: 0, rightValue: 0},
						{type: 'CYL', leftValue: 0, rightValue: 0},
						{type: 'AXIS', leftValue: 0, rightValue: 0},
						{type: 'ADD', leftValue: 0, rightValue: 0},
						{type: 'DPD', leftValue: 0, rightValue: 0},
						{type: 'NPD', leftValue: 0, rightValue: 0},
						{type: 'SegHT', leftValue: 0, rightValue: 0},
						{type: 'Prism', leftValue: 0, rightValue: 0},
						{type: 'Mono-VA', leftValue: 0, rightValue: 0},
					]
				});
			}
			else {
				localDispatch({
					type: Actions.SET_OLD_MEASURE_DATA,
					value: [
						{ type: 'Sphere', leftValue: json.list[0].eye_info[lastIdx].sphere_left, rightValue: json.list[0].eye_info[lastIdx].sphere_right },
						{ type: 'CYL', leftValue: json.list[0].eye_info[lastIdx].cyl_left, rightValue: json.list[0].eye_info[lastIdx].cyl_right },
						{ type: 'AXIS', leftValue: json.list[0].eye_info[lastIdx].axis_left, rightValue: json.list[0].eye_info[lastIdx].axis_right },
						{ type: 'ADD', leftValue: json.list[0].eye_info[lastIdx].add_left, rightValue: json.list[0].eye_info[lastIdx].add_right },
						{ type: 'DPD', leftValue: json.list[0].eye_info[lastIdx].dpd_left, rightValue: json.list[0].eye_info[lastIdx].dpd_right },
						{ type: 'NPD', leftValue: json.list[0].eye_info[lastIdx].npd_left, rightValue: json.list[0].eye_info[lastIdx].npd_right },
						{ type: 'SegHT', leftValue: json.list[0].eye_info[lastIdx].seg_ht_left, rightValue: json.list[0].eye_info[lastIdx].seg_ht_right },
						{ type: 'Prism', leftValue: json.list[0].eye_info[lastIdx].prism_left, rightValue: json.list[0].eye_info[lastIdx].prism_right },
						{ type: 'Mono-VA', leftValue: json.list[0].eye_info[lastIdx].mono_va_left, rightValue: json.list[0].eye_info[lastIdx].mono_va_right }
					]
				});
			}
	    }
		else {
			localDispatch({ type: Actions.SET_IS_NEW_EYE_MEASURE, value: true });
			localDispatch({
				type: Actions.SET_OLD_MEASURE_DATA,
				value: [
					{type: 'Sphere', leftValue: 0, rightValue: 0},
					{type: 'CYL', leftValue: 0, rightValue: 0},
					{type: 'AXIS', leftValue: 0, rightValue: 0},
					{type: 'ADD', leftValue: 0, rightValue: 0},
					{type: 'DPD', leftValue: 0, rightValue: 0},
					{type: 'NPD', leftValue: 0, rightValue: 0},
					{type: 'SegHT', leftValue: 0, rightValue: 0},
					{type: 'Prism', leftValue: 0, rightValue: 0},
					{type: 'Mono-VA', leftValue: 0, rightValue: 0},
				]
			});
	    }

    	localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const onChangeTel = (evt) => {
		CustomerUpdateReducer.dispatch({ type: Actions.SET_TEL, value: evt.target.value });
	}

	const onChangeName = (evt) => {
		CustomerUpdateReducer.dispatch({ type: Actions.SET_NAME, value: evt.target.value });
	}

	const onChangeGender = (evt) => {
		CustomerUpdateReducer.dispatch({ type: Actions.SET_GENDER, value: evt.target.value });
	}

	const onChangeBirthdate = (dateObj, dateString) => {
		CustomerUpdateReducer.dispatch({ type: Actions.SET_BIRTHDATE, value: dateObj._d });

		let _age = Number.parseInt(XDate(new Date()).toString('yyyy'), 10) - Number.parseInt(XDate(dateObj._d).toString('yyyy'), 10)
		CustomerUpdateReducer.dispatch({ type: Actions.SET_AGE, value: _age });
	}

	const onChangeAge = (val) => {
		if(!Number.isNaN(Number.parseInt(val, 10))) {
			CustomerUpdateReducer.dispatch({ type: Actions.SET_AGE, value: Number.parseInt(val, 10) });
		}
	}

	const onChangeExtInfo = (evt) => {
		CustomerUpdateReducer.dispatch({ type: Actions.SET_EXT_INFO, value: evt.target.value });
	}

	const onChangeCustomerChannel = (value) => {
		CustomerUpdateReducer.dispatch({ type: Actions.SET_CUSTOMER_CHANNEL, value: value });
	}

	const onChangeProvince = (selectedVal, selectedIndex) => {
		CustomerUpdateReducer.dispatch({
			type: Actions.SET_PROVINCE,
			value: { index: selectedIndex, text: selectedVal }
		});
	}

	const onChangeAddrZone = (selectedVal) => {
		CustomerUpdateReducer.dispatch({ type: Actions.SET_ADDR_ZONE, value: selectedVal });
	}

	const onChangeInspector = (data) => {
		CustomerUpdateReducer.dispatch({ type: Actions.SET_INSPECTOR, value: data });
	}

	const onChangeOldMeasureData = (value) => {
		CustomerUpdateReducer.dispatch({
			type: Actions.SET_OLD_MEASURE_DATA,
			value: value
		});
	}

	const onChangeNewMeasureData = (value) => {
		CustomerUpdateReducer.dispatch({
			type: Actions.SET_NEW_MEASURE_DATA,
			value: value
		});
	}

	const onClickSave = async () => {
		if (CustomerUpdateReducer.state.tel === '' || CustomerUpdateReducer.state.tel.length < 10) {
			swal({title: 'เพิ่มลูกค้าล้มเหลว', text: 'ข้อมูลเบอร์โทรศัพท์ไม่ถูกต้อง', icon: 'warning'});
		}
		else if (CustomerUpdateReducer.state.name === '') {
			swal({title: 'เพิ่มลูกค้าล้มเหลว', text: 'ไม่มีชื่อลูกค้า', icon: 'warning'});
		}
		else if (CustomerUpdateReducer.state.gender === '') {
			swal({title: 'เพิ่มลูกค้าล้มเหลว', text: 'ไม่เลือกเพศของลูกค้า', icon: 'warning'});
		}
		else if (CustomerUpdateReducer.state.age === 0) {
			swal({title: 'เพิ่มลูกค้าล้มเหลว', text: 'ไม่ระบุอายุของลูกค้า', icon: 'warning'});
		}
		else {
			let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
			let staffID = [...CustomerUpdateReducer.state.inspectorData].filter((_filt) => {
				return _filt.name === CustomerUpdateReducer.state.inspector.name;
			});

			let res_customer = await fetch(`${API_ENDPOINT}/customers/${encodeURIComponent(CustomerUpdateReducer.state.customerID)}`, {
				method: 'PATCH',
				headers: {
					'Authorization': 'Bearer ' + state.auth.token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify({
					branch_id: branchID,
					full_name: CustomerUpdateReducer.state.name,
					phone_number: CustomerUpdateReducer.state.tel,
					district: (CustomerUpdateReducer.state.addrZone !== '') ? CustomerUpdateReducer.state.addrZone : '',
					province: (CustomerUpdateReducer.state.province.text !== '') ? CustomerUpdateReducer.state.province.text : '',
					age: CustomerUpdateReducer.state.age,
					sex: CustomerUpdateReducer.state.gender,
					description: CustomerUpdateReducer.state.extInfo,
					customer_channel: CustomerUpdateReducer.state.customerChannel.join('|')
				})
			});

			if(CustomerUpdateReducer.state.birthdate !== '') {
				XDate(CustomerUpdateReducer.state.birthdate).toString('yyyy-MM-dd');
			}

			let json_customer = await res_customer.json();

			if (res_customer.status === 200) {
				if (CustomerUpdateReducer.state.inspector.id === -1 && CustomerUpdateReducer.state.inspector.name === '') {
					swal({title: 'แก้ไขข้อมูลลูกค้าสำเร็จ', icon: 'success'}).then((value) => {
						props.history.push('/sales/customer');
					});
				}
				else {
					if(CustomerUpdateReducer.state.isNewEyeMeasure) {
						let res_eye = await fetch(`${API_ENDPOINT}/eye-inspector`, {
							method: 'POST',
							headers: {
								'Authorization': 'Bearer ' + state.auth.token,
								'Content-Type': 'application/json'
							},
							body: JSON.stringify({
								staff_id: staffID[0].id,
								customer_id: CustomerUpdateReducer.state.customerID,
								is_latest: true,
								sphere_left: CustomerUpdateReducer.state.newMeasureData[0].leftValue,
								sphere_right: CustomerUpdateReducer.state.newMeasureData[0].rightValue,
								cyl_left: CustomerUpdateReducer.state.newMeasureData[1].leftValue,
								cyl_right: CustomerUpdateReducer.state.newMeasureData[1].rightValue,
								axis_right: CustomerUpdateReducer.state.newMeasureData[2].leftValue,
								axis_left: CustomerUpdateReducer.state.newMeasureData[2].rightValue,
								add_left: CustomerUpdateReducer.state.newMeasureData[3].leftValue,
								add_right: CustomerUpdateReducer.state.newMeasureData[3].rightValue,
								dpd_left: CustomerUpdateReducer.state.newMeasureData[4].leftValue,
								dpd_right: CustomerUpdateReducer.state.newMeasureData[4].rightValue,
								npd_left: CustomerUpdateReducer.state.newMeasureData[5].leftValue,
								npd_right: CustomerUpdateReducer.state.newMeasureData[5].rightValue,
								seg_ht_left: CustomerUpdateReducer.state.newMeasureData[6].leftValue,
								seg_ht_right: CustomerUpdateReducer.state.newMeasureData[6].rightValue,
								prism_left: CustomerUpdateReducer.state.newMeasureData[7].leftValue,
								prism_right: CustomerUpdateReducer.state.newMeasureData[7].rightValue,
								mono_va_left: CustomerUpdateReducer.state.newMeasureData[8].leftValue,
								mono_va_right: CustomerUpdateReducer.state.newMeasureData[8].rightValue
							})
						});

						let json_eye = await res_eye.json();
						console.log(json_eye);

						if (res_eye.status === 200) {
							swal({title: 'แก้ไขข้อมูลลูกค้าสำเร็จ', icon: 'success'}).then((value) => {
								props.history.push('/sales/customer');
							});
						}
						else {
							swal({title: 'แก้ไขข้อมูลลูกค้าสำเร็จ แต่ไม่สามารถเพิ่มข้อมูลค่าสายตา', icon: 'success'}).then((value) => {
								props.history.push('/sales/customer');
							});
						}
					}
					else {
						let res_eye = await fetch(`${API_ENDPOINT}/eye-inspector/${encodeURIComponent(CustomerUpdateReducer.state.customerID)}`, {
							method: 'PATCH',
							headers: {
								'Authorization': 'Bearer ' + state.auth.token,
								'Content-Type': 'application/json'
							},
							body: JSON.stringify({
								staff_id: staffID[0].id,
								customer_id: CustomerUpdateReducer.state.customerID,
								is_latest: true,
								sphere_left: CustomerUpdateReducer.state.newMeasureData[0].leftValue,
								sphere_right: CustomerUpdateReducer.state.newMeasureData[0].rightValue,
								cyl_left: CustomerUpdateReducer.state.newMeasureData[1].leftValue,
								cyl_right: CustomerUpdateReducer.state.newMeasureData[1].rightValue,
								axis_right: CustomerUpdateReducer.state.newMeasureData[2].leftValue,
								axis_left: CustomerUpdateReducer.state.newMeasureData[2].rightValue,
								add_left: CustomerUpdateReducer.state.newMeasureData[3].leftValue,
								add_right: CustomerUpdateReducer.state.newMeasureData[3].rightValue,
								dpd_left: CustomerUpdateReducer.state.newMeasureData[4].leftValue,
								dpd_right: CustomerUpdateReducer.state.newMeasureData[4].rightValue,
								npd_left: CustomerUpdateReducer.state.newMeasureData[5].leftValue,
								npd_right: CustomerUpdateReducer.state.newMeasureData[5].rightValue,
								seg_ht_left: CustomerUpdateReducer.state.newMeasureData[6].leftValue,
								seg_ht_right: CustomerUpdateReducer.state.newMeasureData[6].rightValue,
								prism_left: CustomerUpdateReducer.state.newMeasureData[7].leftValue,
								prism_right: CustomerUpdateReducer.state.newMeasureData[7].rightValue,
								mono_va_left: CustomerUpdateReducer.state.newMeasureData[8].leftValue,
								mono_va_right: CustomerUpdateReducer.state.newMeasureData[8].rightValue
							})
						});

						let json_eye = await res_eye.json();
						console.log(json_eye);

						if (res_eye.status === 200) {
							swal({title: 'แก้ไขข้อมูลลูกค้าสำเร็จ', icon: 'success'}).then((value) => {
								props.history.push('/sales/customer');
							});
						}
						else {
							swal({title: 'แก้ไขข้อมูลลูกค้าสำเร็จ แต่ไม่สามารถเพิ่มข้อมูลค่าสายตา', icon: 'success'}).then((value) => {
								props.history.push('/sales/customer');
							});
						}
					}
				}
			}
			else if (json_customer.msg === 'mobile phone is already exists') {
				swal({title: 'แก้ไขข้อมูลลูกค้าล้มเหลว', text: 'เบอร์โทรลูกค้าซ้ำกับลูกค้าท่านอื่น', icon: 'warning'});
			}
			else {
				swal({title: 'แก้ไข้ข้อมูลลูกค้าล้มเหลว', text: 'มีข้อผิดพลาดบางอย่าง', icon: 'warning'});
			}
		}
	}

	const onClickGoBack = () => {
		props.history.goBack();
	}

	return (
		<Layout>
			<Sidebar mode={'sales'} currentRoute={'/sales/customer'} history={props.history}/>
			<Layout>
				<TopBar mode={'sales'} currentRoute={'/sales/customer'} history={props.history}/>
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...CustomerUpdateReducer.state }

						onChangeTel={ onChangeTel }
						onChangeName={ onChangeName }
						onChangeGender={ onChangeGender }
						onChangeBirthdate={ onChangeBirthdate }
						onChangeAge={ onChangeAge }
						onChangeExtInfo={ onChangeExtInfo }
						onChangeCustomerChannel={ onChangeCustomerChannel }
						onChangeProvince={ onChangeProvince }
						onChangeAddrZone={ onChangeAddrZone }
						onChangeInspector={ onChangeInspector }
						onChangeOldMeasureData={ onChangeOldMeasureData }
						onChangeNewMeasureData={ onChangeNewMeasureData }
						onClickSave={onClickSave}
						onClickGoBack={onClickGoBack}

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar/>
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
