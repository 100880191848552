/**
 *	Table Footer Component
 */

import Stock from './stock';
import Order from './order-add';

const TableFooter = {
	Stock,
	Order
}

export default TableFooter;
