/**
 *	Order Add Old - Container
 */

import React from 'react';
import {
	Layout
} from 'antd';
import swal from 'sweetalert';
import XDate from 'xdate';

import TopBar from '../../../../components/layout/header';
import Sidebar from '../../../../components/layout/sider';
import BottomBar from '../../../../components/bottom-bar';
import WebCookie from '../../../../lib/web-cookie';
import { API_ENDPOINT } from '../../../../environments';
import { useDebounce } from '../../../../functions';
import GlobalStore from '../../../../store';
import LocalStore from './order-add-old.store';
import Content from './order-add-old.content';
import { ContainerPropTypes, Actions } from './order-add-old.model';

const Container = (props) => {
	const OrderCreateReducer = LocalStore();
	const [ state, dispatch ] = GlobalStore.useState();

	const exportRef = React.useRef(null);
	const printRef = React.useRef(null);
	const [ printData, setPrintData ] = React.useState({});
	const nameDebouncer = useDebounce(OrderCreateReducer.state.name, 1000); // Custom Hook
	const productNameDebouncer = useDebounce(OrderCreateReducer.state.productName, 1000); // Custom Hook
	const productCodeDebouncer = useDebounce(OrderCreateReducer.state.productCode, 1000); // Custom Hook

	React.useEffect(() => {
		fetchData(OrderCreateReducer.dispatch, state.auth.token);
	}, [ OrderCreateReducer.dispatch, state.auth.token ]);

	React.useEffect(() => {
		if(nameDebouncer === OrderCreateReducer.state.name) {
			debounceCustomerName(OrderCreateReducer.state.name, OrderCreateReducer.dispatch, state.auth.token)
		}
	}, [ OrderCreateReducer.state.name, OrderCreateReducer.dispatch, state.auth.token, nameDebouncer ]);

	React.useEffect(() => {
		if(productCodeDebouncer === OrderCreateReducer.state.productCode) {
			debounceProductCode(OrderCreateReducer.state.productCode, OrderCreateReducer.dispatch, state.auth.token);
		}
	}, [ OrderCreateReducer.state.productCode, OrderCreateReducer.dispatch, state.auth.token, productCodeDebouncer ]);

	React.useEffect(() => {
		if(productNameDebouncer === OrderCreateReducer.state.productName) {
			debounceProductName(OrderCreateReducer.state.productName, OrderCreateReducer.dispatch, state.auth.token);
		}
	}, [ OrderCreateReducer.state.productName, OrderCreateReducer.dispatch, state.auth.token, productNameDebouncer ])

	const fetchData = async (localDispatch, authToken) => {
		localDispatch({ type: Actions.SET_IS_LOADING, value: true });

		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		let res_staff = await fetch(`${API_ENDPOINT}/staffs?branch_id=${encodeURIComponent(branchID)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		// let res_product = await fetch(`${API_ENDPOINT}/product-items?branch=${branchID}&limit=1000000`, {
		// 	method: 'GET',
		// 	headers: {
		// 		'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
		// 		'Content-Type': 'application/json'
		// 	}
		// });

		let res_promo = await fetch(`${API_ENDPOINT}/promotions?is_active=true&limit=9999`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + authToken,
				'Content-Type': 'application/json'
			}
		});

		let json_staff = await res_staff.json();
		// let json_product = await res_product.json();

		let json_promo = await res_promo.json();
		localDispatch({ type: Actions.SET_PROMO_DATA, value: [ ...json_promo.list ] });

		let _inspectors = [];
		let _doctorCert = {};
		// let _products = [];
		json_staff.list.forEach((_e) => {
			if(_e.full_name !== 'ใบแพทย์') {
				_inspectors.push({ id: _e.staff_info.staff_id, name: _e.staff_info.full_name });
			}
			else {
				_doctorCert = { id: _e.staff_info.staff_id, name: _e.staff_info.full_name };
			}
		});
		_inspectors.push(_doctorCert);
		localDispatch({ type: Actions.SET_INSPECTOR_DATA, value: [ ..._inspectors ] });

		localDispatch({
			type: Actions.SET_OLD_MEASURE_DATA,
			value: [
				{ type: 'Sphere', leftValue: 0, rightValue: 0 },
				{ type: 'CYL', leftValue: 0, rightValue: 0 },
				{ type: 'AXIS', leftValue: 0, rightValue: 0 },
				{ type: 'ADD', leftValue: 0, rightValue: 0 },
				{ type: 'DPD', leftValue: 0, rightValue: 0 },
				{ type: 'NPD', leftValue: 0, rightValue: 0 },
				{ type: 'SegHT', leftValue: 0, rightValue: 0 },
				{ type: 'Prism', leftValue: 0, rightValue: 0 },
				{ type: 'Mono-VA', leftValue: '', rightValue: '' }
			]
		});

		localDispatch({
			type: Actions.SET_NEW_MEASURE_DATA,
			value: [
				{ type: 'Sphere', leftValue: 0, rightValue: 0 },
				{ type: 'CYL', leftValue: 0, rightValue: 0 },
				{ type: 'AXIS', leftValue: 0, rightValue: 0 },
				{ type: 'ADD', leftValue: 0, rightValue: 0 },
				{ type: 'DPD', leftValue: 0, rightValue: 0 },
				{ type: 'NPD', leftValue: 0, rightValue: 0 },
				{ type: 'SegHT', leftValue: 0, rightValue: 0 },
				{ type: 'Prism', leftValue: 0, rightValue: 0 },
				{ type: 'Mono-VA', leftValue: '', rightValue: '' }
			]
		});

		localDispatch({ type: Actions.SET_IS_LOADING, value: false });
	}

	const debounceCustomerName = async (customerName, localDispatch, authToken) => {
		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		if(customerName.length >= 2) {
			let res = await fetch(`${API_ENDPOINT}/customers?only_available=true&search=${encodeURIComponent(customerName)}&branch_id=${encodeURIComponent(branchID)}&offset=0&limit=20`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + authToken,
					'Content-Type': 'application/json'
				}
			});
			let json = await res.json();
			// console.log('DebounceFetch', json);
			let _arr = [];
			json.list.forEach((_e, _i) => {
				_arr.push({ text: _e.full_name, value: _e.full_name });
			});

			localDispatch({ type: Actions.SET_CUSTOMER_NAME_SUGGESTION, value: [ ..._arr ] });
		}
		else {
			localDispatch({ type: Actions.SET_CUSTOMER_NAME_SUGGESTION, value: [] });
		}
	}

	const debounceProductCode = async (qrCode, localDispatch, authToken) => {
		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		if(qrCode.length >= 2) {
			let res = await fetch(`${API_ENDPOINT}/inventory-items?only_available=true&search=${encodeURIComponent(qrCode)}&branch_id=${encodeURIComponent(branchID)}&offset=0&limit=20`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + authToken,
					'Content-Type': 'application/json'
				}
			});
			let json = await res.json();
			let _arr = [];
			json.list.forEach((_e, _i) => {
				_arr.push({ text: _e.product_info.item_code, value: _e.product_info.item_id });
			});

			localDispatch({ type: Actions.SET_PRODUCT_CODE_SUGGESTION, value: [ ..._arr ] });
		}
		else {
			localDispatch({ type: Actions.SET_PRODUCT_CODE_SUGGESTION, value: [] });
		}
	}

	const debounceProductName = async (itemName, localDispatch, authToken) => {
		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;

		if(itemName.length >= 2) {
			let res = await fetch(`${API_ENDPOINT}/inventory-items?search=${encodeURIComponent(itemName)}&branch_id=${encodeURIComponent(branchID)}&offset=0&limit=20`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
					'Content-Type': 'application/json'
				}
			});
			let json = await res.json();
			let _arr = [];
			json.list.forEach((_e, _i) => {
				_arr.push({ text: _e.product_info.name, value: _e.product_info.item_id });
			});

			localDispatch({ type: Actions.SET_PRODUCT_NAME_SUGGESTION, value: [ ..._arr ] });
		}
		else {
			localDispatch({ type: Actions.SET_PRODUCT_NAME_SUGGESTION, value: [] });
		}
	}

	const onChangeTableData = (val) => { OrderCreateReducer.dispatch({ type: Actions.SET_TABLE_DATA, value: val }); }
	const onChangeEditIdx = (idx) => { OrderCreateReducer.dispatch({ type: Actions.SET_EDIT_IDX, value: idx }); }
	const onChangeIsEditing = (val) => { OrderCreateReducer.dispatch({ type: Actions.SET_IS_EDITING, value: val }); }
	const onChangePriceSum = (val) => { OrderCreateReducer.dispatch({ type: Actions.SET_PRICE_SUM, value: val }); }
	const onChangeQR =(val) => { OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: val }); }
	const onChangeProduct = (val) => { OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: val }); }
	const onChangeQuantity = (val) => { OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: val }); }
	const onChangePrice = (val) => { OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_PRICE, value: val }); }
	const onChangeOldMeasureData = (val) => { OrderCreateReducer.dispatch({ type: Actions.SET_OLD_MEASURE_DATA, value: val }); }
	const onChangeNewMeasureData = (val) => { OrderCreateReducer.dispatch({ type: Actions.SET_NEW_MEASURE_DATA, value: val }); }
	const onChangeTel = (evt) => {
		if(evt.target.value.match(/^[\d+-]*$/g) !== null) {
			OrderCreateReducer.dispatch({ type: Actions.SET_TEL, value: evt.target.value });
		}
		else {
			// OrderCreateReducer.dispatch({ type: Actions.SET_TEL, value: '' });
			swal({
				title: 'เกิดข้อผิดพลาด',
				text: 'รูปแบบเบอร์โทรต้องเป็น ตัวเลข เครื่องหมายบวก หรือ เครื่องหมายลบ เท่านั้น',
				icon: 'warning'
			});
		}
	}
	const onChangeName = (val) => { OrderCreateReducer.dispatch({ type: Actions.SET_NAME, value: val }); }
	const onSelectName = (val) => { OrderCreateReducer.dispatch({ type: Actions.SET_CUSTOMER_NAME_SUGGESTION, value: [] }) }
	const onChangeGender = (evt) => { OrderCreateReducer.dispatch({ type: Actions.SET_GENDER, value: evt.target.value }); }
	const onChangeCustomerChannel = (val) => { OrderCreateReducer.dispatch({ type: Actions.SET_CUSTOMER_CHANNEL, value: val }); }
	const onChangeClaimModalVisible = (val) => { OrderCreateReducer.dispatch({ type: Actions.SET_CLAIM_MODAL_VISIBLE, value: val }); }

	const onSelectInspector = (selectedVal) => {
		if(selectedVal === 'ไม่มีการตรวจวัดสายตา') {
			OrderCreateReducer.dispatch({ type: Actions.SET_INSPECTOR, value: { id: -1, name: '' } });
		}
		else {
			let _arr = [ ...OrderCreateReducer.state.inspectorData ].filter((_f) => { return _f.name === selectedVal; });
			OrderCreateReducer.dispatch({ type: Actions.SET_INSPECTOR, value: { ..._arr[0] } });
		}
	}

	const onChangePromoValue = (val) => {
    const _discount = Number.parseInt(val, 10);
    const _price = OrderCreateReducer.state.priceSum || 0;
    const _isAddVat = OrderCreateReducer.state.includeVat; // bool

		if(!Number.isNaN(_discount) && val <= OrderCreateReducer.state.priceSum) {
      const _sum = Number.parseFloat(_price - _discount);
			const _rawVat = Number.parseFloat((_sum * 0.07).toFixed(2));
      const _vat = Math.floor(_rawVat);
      OrderCreateReducer.dispatch({ type: Actions.SET_VAT_VALUE, value: (_isAddVat) ? _vat : 0 });
      OrderCreateReducer.dispatch({ type: Actions.SET_PROMO_VALUE, value: _discount });
		}
		else if(val === '' || val === null) {
      const _sum = Number.parseFloat(_price - 0);
			const _rawVat = Number.parseFloat((_sum * 0.07).toFixed(2));
      const _vat = Math.floor(_rawVat);
      OrderCreateReducer.dispatch({ type: Actions.SET_VAT_VALUE, value: (_isAddVat) ? _vat : 0 });
			OrderCreateReducer.dispatch({ type: Actions.SET_PROMO_VALUE, value: 0 });
		}
	}

	const onTickPastOrderOption = (evt) => {
		OrderCreateReducer.dispatch({ type: Actions.SET_IS_PAST_ORDER, value: evt.target.checked });
	}

	const onChangePastOrderDate = (dateObj, dateString) => {
		OrderCreateReducer.dispatch({ type: Actions.SET_IS_PICK_UP, value: false });
		OrderCreateReducer.dispatch({ type: Actions.SET_PICK_UP_DATE, value: '' });
		OrderCreateReducer.dispatch({ type: Actions.SET_PAST_DATE, value: dateObj.toDate().toISOString() });
	}

	const onTickPickUpOption = (evt) => {
		OrderCreateReducer.dispatch({ type: Actions.SET_IS_PICK_UP, value: evt.target.checked });
	}

	const onChangePickUpDate = (dateObj, dateString) => {
		OrderCreateReducer.dispatch({ type: Actions.SET_PICK_UP_DATE, value: dateObj.toDate().toISOString() });
	}

	const onChangePromoType = (val) => { OrderCreateReducer.dispatch({ type: Actions.SET_PROMO_TYPE, value: val }); }

	const onChangeDeliverDate = (dateObj, dateString) => {
		OrderCreateReducer.dispatch({ type: Actions.SET_IS_PICK_UP, value: false });
		OrderCreateReducer.dispatch({ type: Actions.SET_PICK_UP_DATE, value: '' });
		OrderCreateReducer.dispatch({ type: Actions.SET_DELIVER_DATE, value: dateObj.toDate().toISOString() });
	}

	const onChangePayment = (val) => {
		const { priceSum, promoValue, vatValue } = OrderCreateReducer.state;
		const _cost = priceSum - promoValue + vatValue
		if(!Number.isNaN(Number.parseInt(val)) && val >= 0 && val <= _cost) {
			OrderCreateReducer.dispatch({ type: Actions.SET_PAYMENT, value: Number.parseInt(val) });
		}
	}

	const onChangePaymentType = (selectedVal) => { OrderCreateReducer.dispatch({ type: Actions.SET_PAYMENT_TYPE, value: selectedVal }); }

	const onChangeMoneyReceive = (val) => {
		if(!Number.isNaN(Number.parseInt(val)) && val >= OrderCreateReducer.state.payment) {
			OrderCreateReducer.dispatch({ type: Actions.SET_MONEY_RECEIVE, value: Number.parseInt(val) });
		}
	}

	const onChangeRemark = (evt) => { OrderCreateReducer.dispatch({ type: Actions.SET_REMARK, value: evt.target.value }); }

	const onClickSearch = async () => {
		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id; 

		if(OrderCreateReducer.state.name !== '') {
			let res = await fetch(`${API_ENDPOINT}/customer-with-eye?branch_id=${encodeURIComponent(branchID)}&search=${encodeURIComponent(OrderCreateReducer.state.name)}`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + state.auth.token,
					'Content-Type': 'application/json'
				}
			});

			let json = await res.json();
			
			if(json.list.length !== 0) {
				let _eyesData = [];
				if (json.list[0].eye_info.length > 0) {
					_eyesData = [ ...json.list[0].eye_info ];
				}
				else {
					_eyesData = [{
						add_left: 0,
						add_right: 0,
						axis_left: 0,
						axis_right: 0,
						cyl_left: 0,
						cyl_right: 0,
						dpd_left: 0,
						dpd_right: 0,
						inspector_id: 0,
						is_lastest:0,
						mono_va_left: 0,
						mono_va_right: 0,
						npd_left: 0,
						npd_right: 0,
						prism_left:0,
						prism_right: 0,
						seg_ht_left: 0,
						seg_ht_right: 0,
						sphere_left: 0,
						sphere_right: 0
					}];
				}

				OrderCreateReducer.dispatch({ type: Actions.SET_USER_ID, value: json.list[0].customer_id });
				OrderCreateReducer.dispatch({ type: Actions.SET_TEL, value: json.list[0].phone_number });
				OrderCreateReducer.dispatch({ type: Actions.SET_NAME, value: json.list[0].full_name });
				OrderCreateReducer.dispatch({ type: Actions.SET_AGE, value: json.list[0].age });
				OrderCreateReducer.dispatch({ type: Actions.SET_GENDER, value: json.list[0].sex });
				OrderCreateReducer.dispatch({ type: Actions.SET_BIRTHDATE, value: (json.list[0].birth_date !== null) ? XDate(new Date(json.list[0].birth_date)).toString('dd/MM/yyyy') : '' });
				OrderCreateReducer.dispatch({ type: Actions.SET_CUSTOMER_CHANNEL, value: json.list[0].customer_channel.split(',') });
				OrderCreateReducer.dispatch({ type: Actions.SET_ADDR_ZONE, value: json.list[0].district });
				OrderCreateReducer.dispatch({ type: Actions.SET_PROVINCE, value: (json.list[0].province !== null) ? json.list[0].province : '' });
				OrderCreateReducer.dispatch({ type: Actions.SET_EXT_INFO, value: json.list[0].description });
				OrderCreateReducer.dispatch({ type: Actions.SET_EYE_HISTORY_DATA, value: [ ..._eyesData ] });
				OrderCreateReducer.dispatch({
					type: Actions.SET_OLD_MEASURE_DATA,
					value: [
						{ type: 'Sphere', leftValue: _eyesData[_eyesData.length - 1].sphere_left, rightValue: _eyesData[_eyesData.length - 1].sphere_right },
						{ type: 'CYL', leftValue: _eyesData[_eyesData.length - 1].cyl_left, rightValue: _eyesData[_eyesData.length - 1].cyl_right },
						{ type: 'AXIS', leftValue: _eyesData[_eyesData.length - 1].axis_left, rightValue: _eyesData[_eyesData.length - 1].axis_right },
						{ type: 'ADD', leftValue: _eyesData[_eyesData.length - 1].add_left, rightValue: _eyesData[_eyesData.length - 1].add_right },
						{ type: 'DPD', leftValue: _eyesData[_eyesData.length - 1].dpd_left, rightValue: _eyesData[_eyesData.length - 1].dpd_right },
						{ type: 'NPD', leftValue: _eyesData[_eyesData.length - 1].npd_left, rightValue: _eyesData[_eyesData.length - 1].npd_right },
						{ type: 'SegHT', leftValue: _eyesData[_eyesData.length - 1].seg_ht_left, rightValue: _eyesData[_eyesData.length - 1].seg_ht_right },
						{ type: 'Prism', leftValue: _eyesData[_eyesData.length - 1].prism_left, rightValue: _eyesData[_eyesData.length - 1].prism_right },
						{ type: 'Mono-VA', leftValue: _eyesData[_eyesData.length - 1].mono_va_left, rightValue: _eyesData[_eyesData.length - 1].mono_va_right },
					]
				});
			}
			else {
				swal({
					title: 'ค้นหาล้มเหลว',
					text: 'ไม่มีข้อมูลของลูกค้า',
					icon: 'warning'
				});
			}
		}
		else if(OrderCreateReducer.state.tel !== '') {
			let res = await fetch(`${API_ENDPOINT}/customer-with-eye?branch_id=${encodeURIComponent(branchID)}&search=${encodeURIComponent(OrderCreateReducer.state.tel)}`, {
				method: 'GET',
				headers: {
					'Authorization': 'Bearer ' + state.auth.token,
					'Content-Type': 'application/json'
				}
			});

			let json = await res.json();

			if(json.list.length > 0) {
				let _eyesData = [];
				if (json.list.length > 0 && json.list[0].eye_info.length > 0) {
					_eyesData = [ ...json.list[0].eye_info ];
				}
				else {
					_eyesData = [{
						add_left: 0,
						add_right: 0,
						axis_left: 0,
						axis_right: 0,
						cyl_left: 0,
						cyl_right: 0,
						dpd_left: 0,
						dpd_right: 0,
						inspector_id: 0,
						is_lastest:0,
						mono_va_left: 0,
						mono_va_right: 0,
						npd_left: 0,
						npd_right: 0,
						prism_left:0,
						prism_right: 0,
						seg_ht_left: 0,
						seg_ht_right: 0,
						sphere_left: 0,
						sphere_right: 0
					}];
				}

				OrderCreateReducer.dispatch({ type: Actions.SET_USER_ID, value: json.list[0].customer_id });
				OrderCreateReducer.dispatch({ type: Actions.SET_TEL, value: json.list[0].phone_number });
				OrderCreateReducer.dispatch({ type: Actions.SET_NAME, value: json.list[0].full_name });
				OrderCreateReducer.dispatch({ type: Actions.SET_AGE, value: json.list[0].age });
				OrderCreateReducer.dispatch({ type: Actions.SET_GENDER, value: json.list[0].sex });
				OrderCreateReducer.dispatch({ type: Actions.SET_BIRTHDATE, value: (json.list[0].birth_date !== null) ? XDate(new Date(json.list[0].birth_date)).toString('dd/MM/yyyy') : '' });
				OrderCreateReducer.dispatch({ type: Actions.SET_CUSTOMER_CHANNEL, value: json.list[0].customer_channel.split(',') });
				OrderCreateReducer.dispatch({ type: Actions.SET_ADDR_ZONE, value: json.list[0].district });
				OrderCreateReducer.dispatch({ type: Actions.SET_PROVINCE, value: json.list[0].province });
				OrderCreateReducer.dispatch({ type: Actions.SET_EXT_INFO, value: json.list[0].description });
				OrderCreateReducer.dispatch({ type: Actions.SET_EYE_HISTORY_DATA, value: [ ..._eyesData ] });
				OrderCreateReducer.dispatch({
					type: Actions.SET_OLD_MEASURE_DATA,
					value: [
						{ type: 'Sphere', leftValue: _eyesData[_eyesData.length - 1].sphere_left, rightValue: _eyesData[_eyesData.length - 1].sphere_right },
						{ type: 'CYL', leftValue: _eyesData[_eyesData.length - 1].cyl_left, rightValue: _eyesData[_eyesData.length - 1].cyl_right },
						{ type: 'AXIS', leftValue: _eyesData[_eyesData.length - 1].axis_left, rightValue: _eyesData[_eyesData.length - 1].axis_right },
						{ type: 'ADD', leftValue: _eyesData[_eyesData.length - 1].add_left, rightValue: _eyesData[_eyesData.length - 1].add_right },
						{ type: 'DPD', leftValue: _eyesData[_eyesData.length - 1].dpd_left, rightValue: _eyesData[_eyesData.length - 1].dpd_right },
						{ type: 'NPD', leftValue: _eyesData[_eyesData.length - 1].npd_left, rightValue: _eyesData[_eyesData.length - 1].npd_right },
						{ type: 'SegHT', leftValue: _eyesData[_eyesData.length - 1].seg_ht_left, rightValue: _eyesData[_eyesData.length - 1].seg_ht_right },
						{ type: 'Prism', leftValue: _eyesData[_eyesData.length - 1].prism_left, rightValue: _eyesData[_eyesData.length - 1].prism_right },
						{ type: 'Mono-VA', leftValue: _eyesData[_eyesData.length - 1].mono_va_left, rightValue: _eyesData[_eyesData.length - 1].mono_va_right },
					]
				});
			}
			else {
				swal({
					title: 'ค้นหาล้มเหลว',
					text: 'ไม่มีข้อมูลของลูกค้า',
					icon: 'warning'
				});
			}
		}
		else {
			swal({
				title: 'ข้อมูลที่ไม่ครบถ้วน',
				text: 'จำเป็นต้องให้ข้อมูลเบอร์โทร หรือ ชื่อลูกค้า',
				icon: 'error'
			})
		}
	}

	const onScan = async (qrCode) => {
		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		// let splitStr = qrCode.match(/.{1,3}/g);
		// let convertedQrCode = '';
		// splitStr.map((_e) => { return convertedQrCode += String.fromCharCode(_e); });

		let res = await fetch(`${API_ENDPOINT}/inventory-items?search=${encodeURIComponent(qrCode)}&branch_id=${encodeURIComponent(branchID)}&offset=0&limit=20`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});
		let json = await res.json();

		if(json.list.length === 0) {
			swal({
				title: 'ไม่พบสินค้าในระบบ',
				icon: 'error'
			}).then((val) => {
				OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: '' });
				OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: '' });
				OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: 1 });
				OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_PRICE, value: 0 });
			});
		}
		else {
      const _currentSum = OrderCreateReducer.state.priceSum || 0;
      const _vatValue = OrderCreateReducer.state.vatValue || 0;
      const _productPrice = json.list[0].product_info.price * OrderCreateReducer.state.productAmount;
      const _sum = _currentSum - _vatValue;
      const _newSum = Number.parseFloat(_sum + _productPrice);
			const _rawVat = Number.parseFloat((_sum * 0.07).toFixed(2));
      const _newVat = Math.floor(_rawVat);

			let _arr = [ ...OrderCreateReducer.state.tableData ];
			_arr.push({
				order: OrderCreateReducer.state.tableData.length + 1,
				id: json.list[0].product_info.item_id,
				code: json.list[0].product_info.item_code,
				name: json.list[0].product_info.name,
				amount: OrderCreateReducer.state.productAmount || 1,
				price: Number.parseFloat(json.list[0].product_info.price).toFixed(2)
      });
      
      const _isAddVat = OrderCreateReducer.state.includeVat; // bool

			OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: '' });
			OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: '' });
			OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: 1 });
			OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_PRICE, value: 0 });
			OrderCreateReducer.dispatch({ type: Actions.SET_PRICE_SUM, value: (_isAddVat) ? _newSum + _newVat : _newSum });
      OrderCreateReducer.dispatch({ type: Actions.SET_VAT_VALUE, value: (_isAddVat) ? _newVat : 0 });
		}
	}

	const onSelectProductCode = async (qrCode) => {
		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		let res = await fetch(`${API_ENDPOINT}/inventory-items?only_available=true&inventory=${encodeURIComponent(qrCode)}&branch_id=${encodeURIComponent(branchID)}&offset=0&limit=20`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});
    let json = await res.json();
    
    const _price = OrderCreateReducer.state.priceSum || 0;
    const _discount = OrderCreateReducer.state.promoValue || 0;
    const _productPrice = json.list[0].product_info.price * OrderCreateReducer.state.productAmount;
    const _newPrice = Number.parseFloat(_price + _productPrice);
    const _sum = _newPrice - _discount;
		const _rawVat = Number.parseFloat((_sum * 0.07).toFixed(2));
    const _vatValue = Math.floor(_rawVat);

		let _arr = [ ...OrderCreateReducer.state.tableData ];
		_arr.push({
			order: OrderCreateReducer.state.tableData.length + 1,
			id: json.list[0].product_info.item_id,
			code: json.list[0].product_info.item_code,
			name: json.list[0].product_info.name,
			amount: OrderCreateReducer.state.productAmount || 1,
			price: Number.parseFloat(json.list[0].product_info.price).toFixed(2)
    });
    
    const _isAddVat = OrderCreateReducer.state.includeVat; // bool

		OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: '' });
		OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: '' });
		OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: 1 });
		OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_PRICE, value: 0 });
		OrderCreateReducer.dispatch({ type: Actions.SET_TABLE_DATA, value: [ ..._arr ] });
		OrderCreateReducer.dispatch({ type: Actions.SET_PRICE_SUM, value: _sum });
    OrderCreateReducer.dispatch({ type: Actions.SET_VAT_VALUE, value: (_isAddVat) ? _vatValue : 0 });
	}

	const onSelectProductName = async (itemName) => {
		let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
		let res = await fetch(`${API_ENDPOINT}/inventory-items?only_available=true&inventory=${encodeURIComponent(itemName)}&branch_id=${encodeURIComponent(branchID)}&offset=0&limit=20`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + WebCookie.get('iwear_credential'),
				'Content-Type': 'application/json'
			}
		});
    let json = await res.json();
    
    const _price = OrderCreateReducer.state.priceSum || 0;
    const _discount = OrderCreateReducer.state.promoValue || 0;
    const _productPrice = json.list[0].product_info.price * OrderCreateReducer.state.productAmount;
    const _newPrice = Number.parseFloat(_price + _productPrice);
    const _sum = _newPrice - _discount;
		const _rawVat = Number.parseFloat((_sum * 0.07).toFixed(2));
    const _vatValue = Math.floor(_rawVat);

		let _arr = [ ...OrderCreateReducer.state.tableData ];
		_arr.push({
			order: OrderCreateReducer.state.tableData.length + 1,
			id: json.list[0].product_info.item_id,
			code: json.list[0].product_info.item_code,
			name: json.list[0].product_info.name,
			amount: OrderCreateReducer.state.productAmount || 1,
			price: Number.parseFloat(json.list[0].product_info.price).toFixed(2)
    });
    
    const _isAddVat = OrderCreateReducer.state.includeVat; // bool

		OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_CODE, value: '' });
		OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_NAME, value: '' });
		OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_AMOUNT, value: 1 });
		OrderCreateReducer.dispatch({ type: Actions.SET_PRODUCT_PRICE, value: 0 });
		OrderCreateReducer.dispatch({ type: Actions.SET_TABLE_DATA, value: [ ..._arr ] });
		OrderCreateReducer.dispatch({ type: Actions.SET_PRICE_SUM, value: _sum });
    OrderCreateReducer.dispatch({ type: Actions.SET_VAT_VALUE, value: (_isAddVat) ? _vatValue : 0 });
	}

	const onAddData = () => {
		// let _arr = [ ...tableData ];
		// let _data = [ ...productData ].filter((_e) => { return _e.name === productName });
		// let _sum = Number.parseFloat(priceSum + (productPrice * productAmount));
		// _arr.push({
		// 	order: tableData.length + 1,
		// 	id: _data[0].id,
		// 	code: _data[0].id,
		// 	name: productName,
		// 	amount: productAmount,
		// 	price: Number.parseFloat(productPrice).toFixed(2)
		// });
		//
		// setProductName('เลือกสินค้า');
		// setProductAmount(1);
		// setProductPrice(0);
		// setTableData([ ..._arr ]);
		// setPriceSum(_sum);
	}

	const onEditData = (idx) => {
		if(OrderCreateReducer.state.isEditing) {
			let _sum = 0;
			let _arr = [ ...OrderCreateReducer.state.tableData ];
			_arr.forEach((_e) => {
				_sum += _e.amount * _e.price;
      });
      
			const _rawVat = Number.parseFloat((_sum * 0.07).toFixed(2));
			const _vatValue = Math.floor(_rawVat);
      const _isAddVat = OrderCreateReducer.state.includeVat; // bool

      OrderCreateReducer.dispatch({ type: Actions.SET_PRICE_SUM, value: (_isAddVat) ? _sum + _vatValue : _sum });
      OrderCreateReducer.dispatch({ type: Actions.SET_VAT_VALUE, value: (_isAddVat) ? _vatValue : 0 });
			OrderCreateReducer.dispatch({ type: Actions.SET_EDIT_IDX, value: -1 });
			OrderCreateReducer.dispatch({ type: Actions.SET_IS_EDITING, value: false });
		}
		else {
			OrderCreateReducer.dispatch({ type: Actions.SET_EDIT_IDX, value: idx });
			OrderCreateReducer.dispatch({ type: Actions.SET_IS_EDITING, value: true });
		}
	}

	const onDeleteData = (idx) => {
		// console.log('DeleteIndex', idx);
		let _arr = [ ...OrderCreateReducer.state.tableData ];
		let _sum = 0;

		_arr.splice(idx, 1);
		_arr.forEach((_e) => {
			_sum += _e.amount * _e.price;
		});

		const _rawVat = Number.parseFloat((_sum * 0.07).toFixed(2));
		const _vatValue = Math.floor(_rawVat);
    const _isAddVat = OrderCreateReducer.state.includeVat; // bool

		const _moneyReceiveStateValue = (_isAddVat && _sum > 0) ? _sum + _vatValue : _sum;
		const _vatStateValue = (_isAddVat && _sum > 0) ? _vatValue : 0;
    OrderCreateReducer.dispatch({ type: Actions.SET_PRICE_SUM, value: _sum });
    OrderCreateReducer.dispatch({ type: Actions.SET_VAT_VALUE, value: _vatStateValue });
		OrderCreateReducer.dispatch({ type: Actions.SET_MONEY_RECEIVE, value: _moneyReceiveStateValue });
		OrderCreateReducer.dispatch({ type: Actions.SET_TABLE_DATA, value: [ ..._arr ] });
  }
  
  const onTickIncludeVat = (evt) => {
    const _checked = evt.target.checked;
    if(_checked) {
      /** Checked */
      const _price = OrderCreateReducer.state.priceSum || 0;
      const _discount = OrderCreateReducer.state.promoValue || 0;
      const _sum = _price - _discount;
      const _rawVat = Number.parseFloat((_sum * 0.07).toFixed(2));
      const _vatValue = Math.floor(_rawVat);
      OrderCreateReducer.dispatch({ type: Actions.SET_VAT_VALUE, value: _vatValue });
      OrderCreateReducer.dispatch({ type: Actions.SET_INCLUDE_VAT, value: _checked });
    }
    else {
      /** Unchecked */
      OrderCreateReducer.dispatch({ type: Actions.SET_VAT_VALUE, value: 0 });
      OrderCreateReducer.dispatch({ type: Actions.SET_INCLUDE_VAT, value: _checked });
    }
  }

	const onTickAssemble = (evt) => {
		OrderCreateReducer.dispatch({ type: Actions.SET_REQ_ASSEMBLE, value: evt.target.checked });
	}

	const checkEmptyEyeMeasureData = () => {
		let flag = 0;

		OrderCreateReducer.state.newMeasureData.forEach((_e) => {
			if(_e.leftValue === 0 && _e.rightValue === 0) {
				flag++;
			}
		});

		if(flag >= 9) {
			return true; // empty
		}

		return false; // not empty
	}

	const onClickPurchase = async () => {
		OrderCreateReducer.dispatch({ type: Actions.SET_IS_SUBMITTING, value: true });
		if(!OrderCreateReducer.state.userID) {
			OrderCreateReducer.dispatch({ type: Actions.SET_IS_SUBMITTING, value: false });
			swal({
				title: 'สั่งซื้อล้มเหลว',
				text: 'ไม่มีข้อมูลลูกค้า',
				icon: 'warning'
			});
		}
		else if((OrderCreateReducer.state.inspector.id !== -1 || OrderCreateReducer.state.inspector.name !== '') && checkEmptyEyeMeasureData()) {
			OrderCreateReducer.dispatch({ type: Actions.SET_IS_SUBMITTING, value: false });
			swal({
				title: 'สั่งซื้อล้มเหลว',
				text: 'ไม่มีข้อมูลค่าสายตา หากไม่มีค่าสายตา กรุณาเลือกผู้ตรวจวัดสายตาเป็น "ไม่มีการตรวจวัดสายตา"',
				icon: 'warning'
			});
		}
		else if(!OrderCreateReducer.state.deliverDate || OrderCreateReducer.state.deliverDate === '') {
			OrderCreateReducer.dispatch({ type: Actions.SET_IS_SUBMITTING, value: false });
			swal({
				title: 'สั่งซื้อล้มเหลว',
				text: 'ไม่มีข้อมูลวันนัดหมาย',
				icon: 'warning'
			});
		}
		else if(OrderCreateReducer.state.isPickUp && (!OrderCreateReducer.state.pickUpDate || OrderCreateReducer.state.pickUpDate === '')) {
			OrderCreateReducer.dispatch({ type: Actions.SET_IS_SUBMITTING, value: false });
			swal({
				title: 'สั่งซื้อล้มเหลว',
				text: 'ไม่มีข้อมูลวันรับสินค้า',
				icon: 'warning'
			});
		}
		else if(OrderCreateReducer.state.isPastOrder && (!OrderCreateReducer.state.pastDate || OrderCreateReducer.state.pastDate === '')) {
			OrderCreateReducer.dispatch({ type: Actions.SET_IS_SUBMITTING, value: false });
			swal({
				title: 'สั่งซื้อล้มเหลว',
				text: 'ไม่มีข้อมูลวันที่สั่งซื้อ',
				icon: 'warning'
			});
		}
		else if(!OrderCreateReducer.state.promoType) {
			OrderCreateReducer.dispatch({ type: Actions.SET_IS_SUBMITTING, value: false });
			swal({
				title: 'สั่งซื้อล้มเหลว',
				text: 'ไม่มีข้อมูลโปรโมชั่น',
				icon: 'warning'
			});
		}
		else {
			let branchID = JSON.parse(localStorage.getItem('iwear_accessor')).branch_info.branch_id;
			let _orders = [];
			OrderCreateReducer.state.tableData.forEach((_e) => {
				_orders.push({ quantity: _e.amount, item_id: _e.id });
			});

			let _promo = [ ...OrderCreateReducer.state.promoData ].filter((_f) => { return _f.code === OrderCreateReducer.state.promoType; });

      const _paramsInCommon = {
        customer_id: OrderCreateReducer.state.userID,
        branch_id: branchID,
        status: (OrderCreateReducer.state.payment === OrderCreateReducer.state.priceSum - OrderCreateReducer.state.promoValue) ? 'paid' : 'pending',
        discount: OrderCreateReducer.state.promoValue,
        price: OrderCreateReducer.state.priceSum,
        vat: OrderCreateReducer.state.vatValue || 0,
        deposit_first: OrderCreateReducer.state.payment,
        payment_type: OrderCreateReducer.state.paymentType,
        payment_info: [
          {
            payment_type: OrderCreateReducer.state.paymentType,
            amount: OrderCreateReducer.state.payment,
            is_first: (OrderCreateReducer.state.payment === OrderCreateReducer.state.priceSum - OrderCreateReducer.state.promoValue) // is_earnest
          }
        ],
        receive_date: OrderCreateReducer.state.deliverDate,
        pick_up_date: (OrderCreateReducer.state.isPickUp) ? OrderCreateReducer.state.pickUpDate || null : null,
        note: OrderCreateReducer.state.extInfo,
        order_detail: [ ..._orders ],
        is_custom_assemble: OrderCreateReducer.state.reqAssemble
      }

			let postParams = {};
			if(OrderCreateReducer.state.inspector.id === -1 || OrderCreateReducer.state.inspector.name === '') {
				postParams = { ..._paramsInCommon }
			}
			else {
				postParams = {
					..._paramsInCommon,
					eye_inspect: {
						staff_id: OrderCreateReducer.state.inspector.id,
						customer_id: OrderCreateReducer.state.userID,
						is_latest: true,
						sphere_left: OrderCreateReducer.state.newMeasureData[0].leftValue,
						sphere_right: OrderCreateReducer.state.newMeasureData[0].rightValue,
						cyl_left: OrderCreateReducer.state.newMeasureData[1].leftValue,
						cyl_right: OrderCreateReducer.state.newMeasureData[1].rightValue,
						axis_right: OrderCreateReducer.state.newMeasureData[2].leftValue,
						axis_left: OrderCreateReducer.state.newMeasureData[2].rightValue,
						add_left: OrderCreateReducer.state.newMeasureData[3].leftValue,
						add_right: OrderCreateReducer.state.newMeasureData[3].rightValue,
						dpd_left: OrderCreateReducer.state.newMeasureData[4].leftValue,
						dpd_right: OrderCreateReducer.state.newMeasureData[4].rightValue,
						npd_left: OrderCreateReducer.state.newMeasureData[5].leftValue,
						npd_right: OrderCreateReducer.state.newMeasureData[5].rightValue,
						seg_ht_left: OrderCreateReducer.state.newMeasureData[6].leftValue,
						seg_ht_right: OrderCreateReducer.state.newMeasureData[6].rightValue,
						prism_left: OrderCreateReducer.state.newMeasureData[7].leftValue,
						prism_right: OrderCreateReducer.state.newMeasureData[7].rightValue,
						mono_va_left: `${OrderCreateReducer.state.newMeasureData[8].leftValue}`,
						mono_va_right: `${OrderCreateReducer.state.newMeasureData[8].rightValue}`
					}
				}
			}

			if(_promo.length > 0) {
				postParams.promotion_id = _promo[0].promotion_id
			}

			if(OrderCreateReducer.state.isPastOrder) {
				postParams.create_at = OrderCreateReducer.state.pastDate;
			}

			let res = await fetch(`${API_ENDPOINT}/orders`, {
				method: 'POST',
				headers: {
					'Authorization': 'Bearer ' + state.auth.token,
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(postParams)
			});

			let json = await res.json();

			if(json.hasOwnProperty('order_id')) {
				swal({
					title: 'สั่งซื้อสำเร็จ',
					icon: 'success'
				}).then((value) => {
					OrderCreateReducer.dispatch({ type: Actions.SET_CREATED_ORDER_ID, value: json.order_id })
					if(printRef !== null || printRef !== undefined) {
						printRef.current.handleClick(); // print
					}
				});
			}
			else {
				OrderCreateReducer.dispatch({ type: Actions.SET_IS_SUBMITTING, value: false });
				swal({
					title: 'สั่งซื้อล้มเหลว',
					text: `ข้อมูลไม่ครบถ้วน หรือ มีข้อผิดพลาดบางอย่าง (${json.msg})`,
					icon: 'warning'
				});
			}
		}
	}

	const beforePrintHandler = () => {
		// OrderCreateReducer.dispatch({ type: Actions.SET_IS_PRINTING, value: true });
		beforePrintFetch(OrderCreateReducer.state.createdOrderID, OrderCreateReducer.state.name, OrderCreateReducer.state.tel);

		return new Promise((resolve) => {
			// console.log('InPromise');
			setTimeout(() => {
				// OrderCreateReducer.dispatch({ type: Actions.SET_IS_PRINTING, value: false });
				// console.log('Resolve');
				resolve();
			}, 3000);
		})
	}

	const beforePrintFetch = async (order_id, customer_name, customer_tel) => {
		let res_order = await fetch(`${API_ENDPOINT}/orders?order_id=${encodeURIComponent(order_id)}&to_print=true`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let json_order = await res_order.json();

		let res_order_detail = await fetch(`${API_ENDPOINT}/orders/${encodeURIComponent(order_id)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let json_order_detail = await res_order_detail.json();

		let _customerID = json_order.result[0].customer_id;

		let res_eye = await fetch(`${API_ENDPOINT}/customer-with-eye?customer_id=${encodeURIComponent(_customerID)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let json_eye = await res_eye.json();

		let res_inspector = await fetch(`${API_ENDPOINT}/eye-inspector?customer_id=${encodeURIComponent(_customerID)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let json_inspector = await res_inspector.json();

		let res_product = await fetch(`${API_ENDPOINT}/products?to_print=true&order_id=${encodeURIComponent(order_id)}`, {
			method: 'GET',
			headers: {
				'Authorization': 'Bearer ' + state.auth.token,
				'Content-Type': 'application/json'
			}
		});

		let json_product = await res_product.json();

		let result = {
			...json_order.result[0],
			eye_info: json_eye.list[json_eye.list.length - 1].eye_info,
			eye_inspector: (json_inspector.list.length > 0 && json_inspector.list[json_inspector.list.length - 1] && json_inspector.list[json_inspector.list.length - 1].staff_info)
				? json_inspector.list[json_inspector.list.length - 1].staff_info.full_name : '',
			glasses: {
				brand: (json_product && json_product.result) ? json_product.result.brand : '-',
				model: (json_product && json_product.result) ? json_product.result.model : '-',
			},
			lens: {
				type: json_product.lens_type
			},
			summary_price: {
				glass: (json_product && json_product.price) ? json_product.price.glass : 0,
				lens: (json_product && json_product.price) ? json_product.price.lens : 0
			},
			order_detail: [
				...json_order_detail.order_detail
			]
		}

		let _paid = 0;
		result.payment_info.forEach((_e) => { _paid += _e.amount; });
		const _sum = result.price - result.discount;
		const _rawVat = Number.parseFloat((_sum * 0.07).toFixed(2));
		const _vat = Math.floor(_rawVat);
		const _remaining = _sum + _vat - _paid;
		result.payment_summary = {
			total: result.price,
			discount: result.discount,
			paid: _paid,
			vat: _vat,
			remaining: (_remaining) ? _remaining : 0
		}

		// console.log('BeforePrintGetContent', result);
		setPrintData({ ...result });
		setTimeout(() => {
			OrderCreateReducer.dispatch({ type: Actions.SET_IS_SUBMITTING, value: false });
			props.history.push('/sales/order');
		}, 5000);
	}

	return (
		<Layout>
			<Sidebar mode={'sales'} currentRoute={'/sales/order/create/old'} history={ props.history } />
			<Layout>
				<TopBar mode={'sales'} currentRoute={'/sales/order/create/old'} history={ props.history } />
				<Layout.Content className={'custom-iwear-global-content-override-antd-layout'}>
					<Content
						{ ...OrderCreateReducer.state }

						onChangeTableData={ onChangeTableData }
						onChangeEditIdx={ onChangeEditIdx }
						onChangeIsEditing={ onChangeIsEditing }
						onChangePriceSum={ onChangePriceSum }
						onChangeQR={ onChangeQR }
						onChangeProduct={ onChangeProduct }
						onChangeQuantity={ onChangeQuantity }
						onChangePrice={ onChangePrice }
						onChangeOldMeasureData={ onChangeOldMeasureData }
						onChangeNewMeasureData={ onChangeNewMeasureData }
						onChangeTel={ onChangeTel }
						onChangeName={ onChangeName }
						onSelectName={ onSelectName }
						onChangeGender={ onChangeGender }
						onChangeCustomerChannel={ onChangeCustomerChannel }
						onChangeClaimModalVisible={ onChangeClaimModalVisible }
						onSelectInspector={ onSelectInspector }
						onChangePromoValue={ onChangePromoValue }
						onTickPastOrderOption={ onTickPastOrderOption }
						onChangePastOrderDate={ onChangePastOrderDate }
						onTickPickUpOption={ onTickPickUpOption }
						onChangePickUpDate={ onChangePickUpDate }
						onChangePromoType={ onChangePromoType }
						onChangeDeliverDate={ onChangeDeliverDate }
						onChangePayment={ onChangePayment }
						onChangePaymentType={ onChangePaymentType }
						onChangeMoneyReceive={ onChangeMoneyReceive }
						onChangeRemark={ onChangeRemark }

						onClickSearch={ onClickSearch }
						onScan={ onScan }
						onSelectProductCode={ onSelectProductCode }
						onSelectProductName={ onSelectProductName }
						onAddData={ onAddData }
						onEditData={ onEditData }
						onDeleteData={ onDeleteData }
            onClickPurchase={ onClickPurchase }
            onTickIncludeVat={ onTickIncludeVat }
						onTickAssemble={ onTickAssemble }

						beforePrintHandler={ beforePrintHandler }
						exportRef={ exportRef }
						printRef={ printRef }
						printData={ printData }

						globalReducer={{ state, dispatch }}
					/>
				</Layout.Content>
				<BottomBar />
			</Layout>
		</Layout>
	);
}

Container.propTypes = ContainerPropTypes;

export default Container;
