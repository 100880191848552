/**
 *	Appointment - Model
 */
import PropTypes from 'prop-types';

export const ContainerPropTypes = {
	history: PropTypes.object,
	location: PropTypes.object,
	match: PropTypes.object,
	staticContext: PropTypes.any
}

export const ContentPropTypes = {
	branchList: PropTypes.array.isRequired,
	branchName: PropTypes.string.isRequired,
	appointmentData: PropTypes.object.isRequired,
	selectedDate: PropTypes.instanceOf(Date).isRequired,
	isLoading: PropTypes.bool.isRequired,

	onChangeBranch: PropTypes.func.isRequired,
	onChangeDate: PropTypes.func.isRequired,
	onPanelClick: PropTypes.func.isRequired,
	onSubmitNewAppointmentDate: PropTypes.func.isRequired,

	globalReducer: PropTypes.object.isRequired
}

export const Actions = {
	SET_BRANCH_LIST: 'SET_BRANCH_LIST',
	SET_BRANCH_NAME: 'SET_BRANCH_NAME',
	SET_APPOINTMENT_DATA: 'SET_APPOINTMENT_DATA',
	SET_SELECTED_DATE: 'SET_SELECTED_DATE',
	SET_IS_LOADING: 'SET_IS_LOADING',
	SET_IS_EXPORTING: 'SET_IS_EXPORTING'
}
